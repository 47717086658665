import { ContentSection } from './../models/contentSection.model'
import { now } from 'moment'

export const homePageTitles: object = {
  Speaker: 'Bernardo',
  BackgroundImage: 'No Image',
  Room: 'Canal',
  SubRoom: 'Iniciar Sesión',
  Content: 'No Content',
  Time: 'Nosotros',
  Title: 'Técnicas',
  Index: 0,
  Background: 'No Background',
  createdAt: '11/20/2020',
  UrlImage: '../../assets/images/logo.png',
  UrlVideo: 'Videos',
  PhotoSource: 'No PhotoSource',
  PhotoInfo: 'No PhotoInfo',
  ExternalWeb1: 'Home',
  ExternalWeb2: 'Pide en línea',
  ExternalWeb3: 'Tu orden',
}

export const homePageTitlesArray: Array<ContentSection> = [

  {
    Speaker: 'Bernardo',
    BackgroundImage: 'No Image',
    Room: 'Channel',
    SubRoom: 'Products',
    Content: 'Sign In',
    Time: 'SO Journey?',
    Title: 'Techniques',
    Index: 0,
    Background: 'No Background',
    createdAt: new Date,
    UrlImage: '../../assets/images/logo.png',
    UrlVideo: 'Videos',
    PhotoSource: 'No PhotoSource',
    PhotoInfo: 'No PhotoInfo',
    ExternalWeb1: 'Home',
    ExternalWeb2: 'Order',
    ExternalWeb3: 'Your order',
    }

]

export const homePageTitlesArraySP: Array<ContentSection> = [

  {
    Speaker: 'Bernardo',
    BackgroundImage: 'No Image',
    Room: 'Canal',
    SubRoom: 'Productos',
    Content: 'Iniciar Sesión',
    Time: 'SO Journey?',
    Title: 'Técnicas',
    Index: 0,
    Background: 'No Background',
    createdAt: new Date,
    UrlImage: '../../assets/images/logo.png',
    UrlVideo: 'Videos',
    PhotoSource: 'No PhotoSource',
    PhotoInfo: 'No PhotoInfo',
    ExternalWeb1: 'Home',
    ExternalWeb2: 'Compra',
    ExternalWeb3: 'Tu orden',
    }

]

