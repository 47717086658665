import { Component, OnInit, Renderer2, Pipe, PipeTransform } from '@angular/core';
import { UploadInfoService } from '../shared/services/upload-info.service'
import { ContentSection } from './../shared/models/contentSection.model'
import {YoutubeService } from './../shared/services/youtube.service'
import { takeUntil } from 'rxjs/operators';
import { Observable , BehaviorSubject} from 'rxjs';
import { Subject } from 'rxjs';
import { NgxMasonryOptions } from 'ngx-masonry';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser'

// import { DecimalPipe } from '@angular/common';

import { ActivatedRoute } from '@angular/router';
import { AuthService } from '../shared/services/auth.service'
import { UserProfile } from './../shared/models/user-profile';
import { User } from './../shared/models/user.model'
import Utility  from './../shared/helpers/utility';

@Component({
  selector: 'app-myvideos',
  templateUrl: './myvideos.component.html',
  styleUrls: ['./myvideos.component.scss']
})
export class MyvideosComponent implements OnInit {
  contentSections: ContentSection[];
  videos: any[];
  channel: any;
  channelSelected: any;
  channelDetails: any;
  thumbnail: string = "";
  destroy$ = new BehaviorSubject<boolean>(false);
  private unsubscribe$: Subject<any> = new Subject();
  isFamily: boolean;
  category: string;
  userLoggedIn: UserProfile;
  userData: User;
  loadWithoutChannel: boolean = true;

  filteredContentSection: ContentSection[];
  filteredFamilyContentSection: ContentSection[];
  language = '';

  public masonryOptions: NgxMasonryOptions = {
		transitionDuration: '0.2s',
		gutter: 20,
		columnWidth: 300,
		// resize: true,
		initLayout: true,
		// fitWidth: true,
		// originLeft: false,
	};

	masonryImages;
	limit = 15;

  constructor(private contentservice: UploadInfoService, private youTubeService: YoutubeService, private route: ActivatedRoute, public authService: AuthService, private renderer: Renderer2) { }

  ngOnInit() {
    this.language = Utility.checkLanguage();
    this.channelSelected = null;
    this.route.queryParamMap.subscribe( params => {
      this.channelSelected = params.get('channel');
      if (this.channelSelected) {
        this.loadWithoutChannel=false;
      }
      this.videos = [];
      if (this.channelSelected) {
        this.youTubeService
          .getChannelInfo(this.channelSelected)
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe(lista => {
          this.channel = lista
          this.channelDetails = this.channel.items[0]
          this.thumbnail=this.channelDetails.snippet.thumbnails.high.url
          });
          this.getMoreVideos(15);
      }
    })

    if (JSON.parse(localStorage.getItem('user'))) {
      this.userData = JSON.parse(localStorage.getItem('user'));
      if (this.userData) {
        this.getUserbyId(this.userData.uid)
      }
    }

    this.contentservice.getContentbySection('MyVideos').subscribe(val => {
      this.contentSections = val;
      // console.log("this.contentSections", this.contentSections)
    })

    this.route.queryParamMap.subscribe( params => {
      this.category = params.get('category');
      this.filteredContentSection = (this.category) ? this.contentSections.filter(p => p.SubRoom == this.category) : this.contentSections
    })

    // this.masonryImages = this.contentSections.slice(0, this.limit);
  }

  getUserbyId(userUID:String) {
    this.authService.getUserInfobyId(userUID).subscribe(user => {
      this.userLoggedIn = user
      this.isFamily = this.authService.isFamily(this.userLoggedIn)
    })
  }


  getMoreVideos (numberOfVideos: number) {
      this.youTubeService
      .getVideosForChanel(this.channelSelected, numberOfVideos)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(lista => {
      for (let element of lista["items"]) {
          element.cleanUrl='https://www.youtube.com/embed/'+`${element.id.videoId}`+'?rel=0'
          this.videos.push(element)
      }
      });
      // console.log("videos content here", this.videos);
  }

  ngAfterViewInit() {
		const parent: HTMLElement = document.getElementById('main-footer');
		this.renderer.removeStyle(parent, 'position');
		this.renderer.removeStyle(parent, 'bottom');
		this.renderer.removeStyle(parent, 'width');
		}

}
