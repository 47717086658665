import { Component, OnInit, OnDestroy } from '@angular/core';
import { ShoppingCartService } from './../shared/services/shopping-cart.service';
import { ShoppingCart } from '../shared/models/shopping-cart.model';
import { Subscription } from 'rxjs';
import { switchMap, map, take} from 'rxjs/operators';
import { ShoppingCartItem } from '../shared/models/shopping-cart-item.model';

interface ItemsMap { [productId: string]: ShoppingCartItem }

@Component({
  selector: 'app-shopping-cart',
  templateUrl: './shopping-cart.component.html',
  styleUrls: ['./shopping-cart.component.scss']
})
export class ShoppingCartComponent implements OnInit, OnDestroy {
  carts : ShoppingCart[];
  cart: any[];
  subscription: Subscription
  cartShopping: ShoppingCart;
  impuesto = 0.16;
  totalPriceWithTax = 0;


  constructor(private shoppingCartService: ShoppingCartService) { }

  ngOnInit(): void {
    console.log("GET CART shopping cart");
    this.subscription = this.shoppingCartService.getCart().subscribe(cart => {
      this.cart = cart.map(e => {
        return {
          id: e.payload.doc.id,
          isEdit: false,
          title: e.payload.doc.data()['title'],
          description: e.payload.doc.data()['description'],
          category: e.payload.doc.data()['category'],
          price: e.payload.doc.data()['price'],
          quantity: e.payload.doc.data()['quantity'],
          imageUrl: e.payload.doc.data()['imageUrl'],
          totalPrice: e.payload.doc.data()['totalPrice'],
        };
      })
      let items: ShoppingCartItem[] = [];
      let itemsMap: { [productId: string]: ShoppingCartItem } = {}
      let products: [{'productId':string}] = [{'productId':null}]
      let productStr = [];
      for(let i=0; i<this.cart.length; i++){
        products.push({'productId':this.cart[i].id});
        productStr.push(this.cart[i].id);

    }
      for(let i=0; i<this.cart.length; i++){
        items.push(new ShoppingCartItem({ id:products[i], ...this.cart[i]}));
    }


  let itemsMapA: ItemsMap = {}
    for(let i=0; i<this.cart.length; i++){
      let str = productStr[i];
      itemsMapA[str] = new ShoppingCartItem()
      itemsMap = this.addElement(itemsMap,itemsMapA);
  }
      this.cartShopping = new ShoppingCart(itemsMap);
      this.cartShopping.items = this.cart

      // Send shopping cart total items counting to header shopping cart icon
      this.shoppingCartService.itemsCount.next(this.cartShopping.totalItemsCount)
    })
  }

  clearCart() {
    this.shoppingCartService.clearCart();
  }

  addElement (itemsMap2, element) {
    let newList = Object.assign(itemsMap2, element)
    return newList
}

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
