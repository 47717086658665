<!-- PAGE HEADER -->
<header id="page-header">
  <div class="container setDefaultSpace pd-2">
    <div class="row">
      <div class="col-md-6 m-auto text-center">
        <h1 class="fontOpenSans-RegularSmallDarkGray">Terms and Conditions</h1>
        <p class="fontOpenSans-RegularExtraSmallDarkGray">Consent  • Prohibited Uses  • Property Rights • Others</p>
        <div class="d-block d-md-none">
          </div>
      </div>
    </div>
  </div>
</header>

<!-- PAGE HEADER -->
<header id="page-header">
  <div class="container pt-4 pd-2">
    <div class="row">
      <div class="card mb-3">
          <div class="row no-gutters">

            <div class="col-md-12">
              <div class="card-body">
                  <h5 class="card-header centerName fontOpenSans-RegularSmallDarkGra">Terms of Use</h5>
                <p class="fontOpenSans-RegularExtraSmallDarkGray pt-4">The website www.so-journey.us (hereinafter "Website") is operated by Medscience Animations Studios Inc., 7935 Airport Pulling Rd North, Ste 4 PMB 269 , Naples, FL 34109  (hereinafter "we"). Please review the following terms and conditions that govern your access to and use of the Website.
                  </p>
                  <h5 class="card-header centerName fontOpenSans-RegularSmallDarkGra">Consent to the Terms of Use</h5>
                <p class="fontOpenSans-RegularExtraSmallDarkGray pt-4">By accessing and using the Website you acknowledge to have read and understood, and agree to follow and be bound by the following terms and conditions, the Privacy Policy, and any further policies or terms that govern the use of the Website, which are all incorporated herein by reference (collectively, the "Terms of Use"). If at any time, you do not agree to the Terms of Use, then you are not authorized to access or use the Website, and you must discontinue use of the Website immediately.<br>
                  <p class="fontOpenSans-RegularExtraSmallDarkGray pt-4">We reserve the right, at any time, to modify or update the Website and to change or modify the Terms of Use as provided herein, with or without notice to you, at our discretion. Your use of the Website following any such change constitutes your agreement to follow and be bound by the terms and conditions as changed.
                  </p>
                  <h5 class="card-header centerName fontOpenSans-RegularSmallDarkGra">Prohibited Uses</h5>
                <p class="fontOpenSans-RegularExtraSmallDarkGray pt-4">We impose certain restrictions on the permissible use of the Website. In particular, but not limited to, you are prohibited from violating or attempting to violate any security features of the Website or cause in anyway harm to the Website. Any violation of system or network security may subject you to civil and/or criminal liability. You may not circumvent any access or use restrictions, data encryption or content protection related to the Website.
                  </p>
                  <h5 class="card-header centerName fontOpenSans-RegularSmallDarkGra">Website Contents and Intellectual Property Rights</h5>
                <p class="fontOpenSans-RegularExtraSmallDarkGray pt-4">Unless stated otherwise or evident from the circumstances, the design of the Website, the Website as a whole, and all materials and information that are part of the Website (collectively, the "Content") are copyrights, trademarks, trade dress or other intellectual properties owned, controlled or licensed by us.<br>
                  <p class="fontOpenSans-RegularExtraSmallDarkGray pt-4">No right, title or interest in any of the Content is granted to you under any circumstances, and we reserve and retain all intellectual property rights in and to the Website and their Content. Linking or framing to the Website or any of its Content is prohibited without our prior written permission.<br>
                    <p class="fontOpenSans-RegularExtraSmallDarkGray pt-4">Except as expressly noted herein, you may not reproduce, copy, publish, transmit, distribute, display, modify, create derivative works from, sell or participate in any sale of, or exploit in any way, any of the Content or the Website. Unauthorized use or copying (including electronic copying or downloading) of the Website and Content without our express written consent is expressly prohibited.
                  </p>
                  <h5 class="card-header centerName fontOpenSans-RegularSmallDarkGra">Privacy Policy</h5>
                <p class="fontOpenSans-RegularExtraSmallDarkGray pt-4">Information that you provide to us via the Website is subject to our Privacy Policy. For more information, please read our full Privacy Policy.
                  </p>
                  <h5 class="card-header centerName fontOpenSans-RegularSmallDarkGra">No Guarantee</h5>
                <p class="fontOpenSans-RegularExtraSmallDarkGray pt-4">You acknowledge and agree that, to the fullest extent permitted by law, all use of the Website, any information and content contained at or provided through the Website, and any functionality contained at or provided through the Website, is provided without guarantee of or warranty of any kind, whether express or implied. Without limiting the foregoing, we make no warranty, promise or guarantee of any kind that (a) operation of the Website will be continuous, timely, uninterrupted, secure; (b) the Website or its server are free of errors, viruses or other harmful effects; and (c) the Website or any information obtained from the Website is free from mistakes and errors. The use of the Website is at your own risk.<br>
                  <p class="fontOpenSans-RegularExtraSmallDarkGray pt-4">Further, without limiting the foregoing, all information provided on the Website is without warranty as to its completeness, timeliness, accuracy, suitability or authenticity. It is your responsibility to evaluate the truth, accuracy, completeness, and continuing validity of any information set forth in or referenced on the Website. Likewise, we do not warrant the accuracy of any third party content contained within or referred to on the Website, including links to third party websites.<br>
                  </p>

                  <h5 class="card-header centerName fontOpenSans-RegularSmallDarkGra">Limitation of Liability</h5>
                  <p class="fontOpenSans-RegularExtraSmallDarkGray pt-4">We are not liable for any content contained at or provided through the Website or the use of the Website and such content, nor for any damages arising therefrom. Further, we are not liable for any defamatory, offensive or illegal conduct of any user, nor for any damages arising there from.
                  </p>
                  <h5 class="card-header centerName fontOpenSans-RegularSmallDarkGra">Third Party Content and Third Party Links</h5>
                  <p class="fontOpenSans-RegularExtraSmallDarkGray pt-4">Opinions, advice, statements, testimonials or any other information or content made available on the Website or through the Website, but not directly by us, are those of their respective authors, and should not necessarily be relied upon. Such authors are solely responsible for such content.<br>
                    <p class="fontOpenSans-RegularExtraSmallDarkGray pt-4">The Website may include links to other Internet sites maintained by third parties ("Linked Sites"). We provide Linked Sites to you solely as a convenience, and the inclusion of Linked Sites does not imply endorsement by us of the Linked Sites. You access Linked Sites at your own risk, and by accessing them, you leave the Website. Linked Sites are not under our control and we are not responsible for the content of any Linked Sites.<br>
                  </p>
                  <h5 class="card-header centerName fontOpenSans-RegularSmallDarkGra">Modifications to the Website and Denial of Access</h5>
                  <p class="fontOpenSans-RegularExtraSmallDarkGray pt-4">We reserve the right at any time to modify or discontinue, temporarily or permanently, the Website (or any part thereof) with or without notice to you in our sole discretion. We shall not be liable to you or to any third party for any modification, suspension or discontinuance of the Website.<br>
                    <p class="fontOpenSans-RegularExtraSmallDarkGray pt-4">We may deny you access to the Website at any time, in our sole discretion and for any reason whatsoever.<br>
                  </p>
              </div>
            </div>
          </div>
        </div>

    </div>
  </div>
</header>
