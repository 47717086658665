import { Injectable, ɵConsole } from '@angular/core';
import { AngularFireDatabase, AngularFireList} from '@angular/fire/database';
import { AngularFireAuth } from "@angular/fire/auth";
import { AngularFirestore, AngularFirestoreDocument, AngularFirestoreCollection } from '@angular/fire/firestore';
import { BehaviorSubject, Observable } from 'rxjs';
import { PictureTechnique } from './../models/pictureTechnique.model'
import { Section } from './../models/section.model'
import { SubCategory } from './../models/subcategory.model'
import { Rate } from './../models/rate.model'
import { Type } from './../models/type.model'
import { Source } from './../models/source.model'
import { ContentSection } from './../models/contentSection.model'
// import 'rxjs/add/operator/switchMap';
import {map, first} from 'rxjs/operators';
import {convertSnaps} from './db-utils';



// export interface Shirt { name: string; price: number; }
export interface PicId extends PictureTechnique { id: string; }


@Injectable({
  providedIn: 'root'
})
export class UploadInfoService {
  error = new BehaviorSubject<string>('');
  uploadSuccess = new BehaviorSubject<boolean>(false);

  private picCollection: AngularFirestoreCollection<PictureTechnique>;
  private sectionDocument: AngularFirestoreDocument<ContentSection>;
  private pictureDocument: AngularFirestoreDocument<PictureTechnique>;
  private categoryDocument: AngularFirestoreDocument<Section>;
  private subCategoryDocument: AngularFirestoreDocument<SubCategory>;
  private rateDocument: AngularFirestoreDocument<Rate>;
  private typeDocument: AngularFirestoreDocument<Type>;
  private sourceDocument: AngularFirestoreDocument<Source>;
  pics: Observable<PicId[]>;

  pics$: Observable<PictureTechnique[]>;
  sections$: Observable<Section[]>;
  contentSections$: Observable<ContentSection[]>;

  sizeFilter$: BehaviorSubject<string|null>;
  colorFilter$: BehaviorSubject<string|null>;

  content: ContentSection;


  constructor(private firebase: AngularFireDatabase, public afs: AngularFirestore) { }

    setCategoryData(imageDetailList, id) {
      const picRef: AngularFirestoreDocument<any> = this.afs.collection(imageDetailList.category).doc(String(id))
      picRef.set ({
        Title: imageDetailList.caption,
        SectionId: String(id),
        Category: imageDetailList.category,
        Type: imageDetailList.type,
      }).then(() => {
        console.log("Category saved succesfully")
        this.uploadSuccess.next(true)
      }).catch(err => {
        console.log("Failed to set Category")
      });
    }

    setSubCategoryData(imageDetailList, id) {
      const picRef: AngularFirestoreDocument<any> = this.afs.collection(imageDetailList.category).doc(String(id))
      picRef.set ({
        Title: imageDetailList.caption,
        SubCategoryId: String(id),
        Category: imageDetailList.category,
        Type: imageDetailList.type,
      }).then(() => {
        console.log("SubCategory saved succesfully")
        this.uploadSuccess.next(true)
      }).catch(err => {
        console.log("Failed to set SubCategory")
      });
    }

    setRateData(imageDetailList, id) {
      const picRef: AngularFirestoreDocument<any> = this.afs.collection(imageDetailList.category).doc(String(id))
      picRef.set ({
        Title: imageDetailList.caption,
        RateId: String(id),
        Category: imageDetailList.category,
        Type: imageDetailList.type,
      }).then(() => {
        console.log("Rate saved succesfully")
        this.uploadSuccess.next(true)
      }).catch(err => {
        console.log("Failed to set Rate")
      });
    }

    setTypeData(imageDetailList, id) {
      const picRef: AngularFirestoreDocument<any> = this.afs.collection(imageDetailList.category).doc(String(id))
      picRef.set ({
        Title: imageDetailList.caption,
        TypeId: String(id),
        Category: imageDetailList.category,
        Type: imageDetailList.type,
      }).then(() => {
        console.log("Type saved succesfully")
        this.uploadSuccess.next(true)
      }).catch(err => {
        console.log("Failed to set Type")
      });
    }

    setSourceData(imageDetailList, id) {
      const picRef: AngularFirestoreDocument<any> = this.afs.collection(imageDetailList.category).doc(String(id))
      picRef.set ({
        Title: imageDetailList.caption,
        SourceId: String(id),
        Category: imageDetailList.category,
        Type: imageDetailList.type,
      }).then(() => {
        console.log("Source saved succesfully")
        this.uploadSuccess.next(true)
      }).catch(err => {
        console.log("Failed to set Source")
      });
    }

    setSectionData(imageDetailList, id) {
    const picRef: AngularFirestoreDocument<any> = this.afs.collection(imageDetailList.category).doc(String(id))
    picRef.set ({
      Title: imageDetailList.caption,
      ProductId: imageDetailList.productId,
      Speaker: String(id),
      BackgroundImage: imageDetailList.backgroundImage,
      Room: imageDetailList.category,
      SubRoom: imageDetailList.subcategory,
      Content: imageDetailList.content,
      Time: imageDetailList.time,
      Index: parseInt(imageDetailList.index),
      Background: imageDetailList.background,
      CreatedAt: new Date,
      UrlImage: imageDetailList.imageUrl,
      UrlVideo: imageDetailList.videoUrl,
      PhotoSource: imageDetailList.sourceImage,
      PhotoInfo: imageDetailList.photoInfo,
      ExternalWeb1: imageDetailList.externalWeb1,
      ExternalWeb2: imageDetailList.externalWeb2,
      ExternalWeb3: imageDetailList.externalWeb3,


    }).then(() =>  {
      console.log("Section saved succesfully")
      this.uploadSuccess.next(true)
    }).catch(err => {
      console.log("Failed to save Section")
    });

  }

  setSectionDataCoverA(imageDetailList, id) {
    const picRef: AngularFirestoreDocument<any> = this.afs.collection(imageDetailList.categoryCoverA).doc(String(id))
    picRef.set ({
      Title: imageDetailList.captionCoverA,
      ProductId: imageDetailList.productIdCoverA,
      Speaker: String(id),
      BackgroundImage: imageDetailList.backgroundImageCoverA,
      Room: imageDetailList.categoryCoverA,
      SubRoom: imageDetailList.subcategoryCoverA,
      Content: imageDetailList.contentCoverA,
      Time: imageDetailList.timeCoverA,
      Index: parseInt(imageDetailList.indexCoverA),
      Background: imageDetailList.backgroundCoverA,
      CreatedAt: new Date,
      UrlImage: imageDetailList.imageUrlCoverA,
      UrlVideo: imageDetailList.videoUrlCoverA,
      PhotoSource: imageDetailList.sourceImageCoverA,
      PhotoInfo: imageDetailList.photoInfoCoverA,
      ExternalWeb1: imageDetailList.externalWeb1CoverA,
      ExternalWeb2: imageDetailList.externalWeb2CoverA,
      ExternalWeb3: imageDetailList.externalWeb3CoverA,


    }).then(() =>  {
      console.log("Cover saved succesfully")
      this.uploadSuccess.next(true)
    }).catch(err => {
      console.log("Failed to save Cover")
    });

  }

  setSectionDataCoverB(imageDetailList, id) {
    const picRef: AngularFirestoreDocument<any> = this.afs.collection(imageDetailList.categoryCoverB).doc(String(id))
    picRef.set ({
      Title: imageDetailList.titleCoverB,
      SubTitle: imageDetailList.subTitleCoverB,
      CreatedAt: new Date,
      UrlImage: imageDetailList.imageUrlCoverB,

    }).then(() =>  {
      console.log("CoverB saved succesfully")
      this.uploadSuccess.next(true)
    }).catch(err => {
      console.log("Failed to save CoverB ")
    });

  }

  // this.sectionDocument = this.afs.doc(`${content.Room}/${content.Speaker}`)
  // this.sectionDocument.delete()

  updateContentData(imageDetailList, id) {
    const picRef: AngularFirestoreDocument<any> = this.afs.collection(imageDetailList.category).doc(String(id))
    picRef.update ({
      Title: imageDetailList.caption,
      Speaker: String(id),
      BackgroundImage: imageDetailList.backgroundImage,
      Room: imageDetailList.category,
      SubRoom: imageDetailList.subcategory,
      Content: imageDetailList.content,
      Time: imageDetailList.time,
      Index: parseInt(imageDetailList.index),
      Background: imageDetailList.background,
      CreatedAt: new Date,
      UrlImage: imageDetailList.imageUrl,
      UrlVideo: imageDetailList.videoUrl,
      PhotoSource: imageDetailList.sourceImage,
      PhotoInfo: imageDetailList.photoInfo,
      ExternalWeb1: imageDetailList.externalWeb1,
      ExternalWeb2: imageDetailList.externalWeb2,
      ExternalWeb3: imageDetailList.externalWeb3,


    }).then(() =>  {
      console.log("Content updated succesfully")
      this.uploadSuccess.next(true)
    }).catch(err => {
      console.log("Failed to update Content")
    });

  }

  updateContentDataCoverA(imageDetailList, id) {
    const picRef: AngularFirestoreDocument<any> = this.afs.collection(imageDetailList.categoryCoverA).doc(String(id))
    picRef.update ({
      Title: imageDetailList.captionCoverA,
      Speaker: String(id),
      BackgroundImage: imageDetailList.backgroundImageCoverA,
      Room: imageDetailList.categoryCoverA,
      SubRoom: imageDetailList.subcategoryCoverA,
      Content: imageDetailList.contentCoverA,
      Time: imageDetailList.timeCoverA,
      Index: parseInt(imageDetailList.indexCoverA),
      Background: imageDetailList.backgroundCoverA,
      CreatedAt: new Date,
      UrlImage: imageDetailList.imageUrlCoverA,
      UrlVideo: imageDetailList.videoUrlCoverA,
      PhotoSource: imageDetailList.sourceImageCoverA,
      PhotoInfo: imageDetailList.photoInfoCoverA,
      ExternalWeb1: imageDetailList.externalWeb1CoverA,
      ExternalWeb2: imageDetailList.externalWeb2CoverA,
      ExternalWeb3: imageDetailList.externalWeb3CoverA,


    }).then(() =>  {
      console.log("Content updated succesfully")
      this.uploadSuccess.next(true)
    }).catch(err => {
      console.log("Failed to update Content")
    });

  }

  updateContentDataCoverB(imageDetailList, id) {
    const picRef: AngularFirestoreDocument<any> = this.afs.collection(imageDetailList.categoryCoverB).doc(String(id))
    picRef.update ({
      Title: imageDetailList.titleCoverB,
      SubTitle: imageDetailList.subTitleCoverB,
      CreatedAt: new Date,
      UrlImage: imageDetailList.imageUrlCoverB,
    }).then(() =>  {
      console.log("Content updated succesfully")
      this.uploadSuccess.next(true)
    }).catch(err => {
      console.log("Failed to update Content")
    });

  }

  updatePictureData(imageDetailList, id, imageUrl) {
    const picRef: AngularFirestoreDocument<any> = this.afs.collection(imageDetailList.category).doc(String(id))
    picRef.update ({
      Title: imageDetailList.caption,
      PicId: String(id),
      Category: imageDetailList.category,
      SubCategory: imageDetailList.subcategory,
      CreatedAt: new Date,
      UrlImage: imageUrl,
      PhotoSource: imageDetailList.sourceImage,
      PhotoInfo: imageDetailList.content,
      FileName: imageDetailList.fileName,
      Size: imageDetailList.size,
      Type: imageDetailList.type,
      Rate: imageDetailList.rate,
      Width: imageDetailList.width,
      Height: imageDetailList.height,
    }).then(() =>  {
      console.log("Photo updated succesfully")
      this.uploadSuccess.next(true)
    }).catch(err => {
      console.log("Failed to update photo")
    });

  }

  updateCategoryData(imageDetailList, id) {
    const picRef: AngularFirestoreDocument<any> = this.afs.collection(imageDetailList.category).doc(String(id))
    picRef.update ({
      Title: imageDetailList.caption,
      SectionId: String(id),
      Category: imageDetailList.category,
      Type: imageDetailList.type,
    }).then(() => {
      console.log("Category updated succesfully")
      this.uploadSuccess.next(true)
    }).catch(err => {
      console.log("Failed to update Category")
    });

  }

  updateSubCategoryData(imageDetailList, id) {
    const picRef: AngularFirestoreDocument<any> = this.afs.collection(imageDetailList.category).doc(String(id))
    picRef.update ({
      Title: imageDetailList.caption,
      SubCategoryId: String(id),
      Category: imageDetailList.category,
      Type: imageDetailList.type,
    }).then(() => {
      console.log("SubCategory updated succesfully")
      this.uploadSuccess.next(true)
    }).catch(err => {
      console.log("Failed to update SubCategory")
    });

  }

  updateRateData(imageDetailList, id) {
    const picRef: AngularFirestoreDocument<any> = this.afs.collection(imageDetailList.category).doc(String(id))
    picRef.update ({
      Title: imageDetailList.caption,
      RateId: String(id),
      Category: imageDetailList.category,
      Type: imageDetailList.type,
    }).then(() => {
      console.log("Rate updated succesfully")
      this.uploadSuccess.next(true)
    }).catch(err => {
      console.log("Failed to update Rate")
    });

  }

  updateTypeData(imageDetailList, id) {
    const picRef: AngularFirestoreDocument<any> = this.afs.collection(imageDetailList.category).doc(String(id))
    picRef.update ({
      Title: imageDetailList.caption,
      TypeId: String(id),
      Category: imageDetailList.category,
      Type: imageDetailList.type,
    }).then(() => {
      console.log("Type updated succesfully")
      this.uploadSuccess.next(true)
    }).catch(err => {
      console.log("Failed to update Type")
    });

  }

  updateSourceData(imageDetailList, id) {
    const picRef: AngularFirestoreDocument<any> = this.afs.collection(imageDetailList.category).doc(String(id))
    picRef.update ({
      Title: imageDetailList.caption,
      SourceId: String(id),
      Category: imageDetailList.category,
      Type: imageDetailList.type,
    }).then(() => {
      console.log("Source updated succesfully")
      this.uploadSuccess.next(true)
    }).catch(err => {
      console.log("Failed to update Source")
    });

  }

    getPictures() {
      console.log("pic data",this.afs.collection('Pictures').snapshotChanges());
      return this.afs.collection('Pictures').snapshotChanges();
    }

    getData() {

      this.picCollection = this.afs.collection<PictureTechnique>('Pictures');

      console.log("this.picCollection",this.picCollection)
    // .snapshotChanges() returns a DocumentChangeAction[], which contains
    // a lot of information about "what happened" with each change. If you want to
    // get the data and the id use the map operator.
    this.pics = this.picCollection.snapshotChanges().pipe(
      map(actions => actions.map(a => {
        const data = a.payload.doc.data() as PictureTechnique;
        const id = a.payload.doc.id;
        return { id, ...data };
      }))
    );

    }


    getData3() {
      // Get all the user's comments, no matter how deeply nested
  // var comments$ = this.afs.collectionGroup<PictureTechnique>('Pictures', ref => ref.where('Rate', '==', 'normal'))
  // .valueChanges().pipe(
  //     map(actions => actions.map(a => {
  //       const data = a.payload.doc.data() as PictureTechnique;
  //       const id = a.payload.doc.id;
  //       return { id, ...data };
  //     }));
  // console.log("data ",comments$)
    }

  //   getData4() {
  //     this.sizeFilter$ = new BehaviorSubject(null);
  //     this.colorFilter$ = new BehaviorSubject(null);
  //     this.items$ = Observable.combineLatest(
  //     this.sizeFilter$,
  //     this.colorFilter$
  //   ).switchMap(([size, color]) =>
  //     this.afs.collection<PictureTechnique>('items', ref => {
  //       let query : firebase.firestore.Query = ref;
  //       if (size) { query = query.where('size', '==', size) };
  //       if (color) { query = query.where('color', '==', color) };
  //       return query;
  //     }).valueChanges()
  //   );
  // }
  // filterBySize(size: string|null) {
  //   this.sizeFilter$.next(size);
  // }
  // filterByColor(color: string|null) {
  //   this.colorFilter$.next(color);
  // }
  //   }


getData5() {


  // this.sizeFilter$ = new BehaviorSubject(null);
  //     this.colorFilter$ = new BehaviorSubject(null);
  //     this.items$ = combineLatest(
  //     this.sizeFilter$,
  //     this.colorFilter$
  //   ).pipe(
  //     map(data => ({limit: data[0], offset: data[1]})),
  //     switchMap(data => this.pokemonService.getPokemon(data.limit, data.offset)),
  //     map((response: {results: Pokemon[]}) => response.results),
  //   );
  // }
  // filterBySize(size: string|null) {
  //   this.sizeFilter$.next(size);
  // }
  // filterByColor(color: string|null) {
  //   this.colorFilter$.next(color);
  // }

//   this.pokemon$ = combineLatest(limit$, offset$)
//        .pipe(
//         map(data => ({limit: data[0], offset: data[1]})),
//         switchMap(data => this.pokemonService.getPokemon(data.limit, data.offset)),
//         map((response: {results: Pokemon[]}) => response.results),
//       );
// }

// }
  }

  // getdData6() {

    // this.items$ = this.afs.collection('Pictures').valueChanges();
    // return  this.items$
  //   this.afs.collection("cities")
  //               .get()
  //               .then(function(querySnapshot) {
  //                   querySnapshot.forEach(function(doc) {
  //                       // doc.data() is never undefined for query doc snapshots
  //                       console.log(doc.id, " => ", doc.data());
  //                   });
  //               })
  //               .catch(function(error) {
  //                   console.log("Error getting documents: ", error);
  //               });

  // }

  //Using Firebase SDK directly
  //ver https://angular-university.io/lesson/querying-firestore-collections
  getData7() {
    this.afs.collection('Pictures').get().subscribe(snaps => {
      const pictures: PictureTechnique[]= snaps.docs.map(snap => {
        return<PictureTechnique>{
          id: snap.id,
          ...snap.data()
        }
      });
      console.log('getData7',pictures)
      return pictures
    })
  }

  // getData8() {
  //   this.pics$ = this.afs.collection('Pictures').snapshotChanges()
  //   .pipe(map(snaps => {
  //     return snaps.map(snap => {
  //       return <PictureTechnique> {
  //         id: snap.payload.doc.id,
  //         ...snap.payload.doc.data()
  //       }
  //     });
  //   }));
  //   }

  //Using AngularFire SDK ( Better because we are using Angular)
  // ver https://www.youtube.com/watch?v=k204K2mJHfk
  getData9() {
    this.afs.collection('Pictures').valueChanges().subscribe(val => {
      console.log('getData9',val)
    })
  }

  getData10() {
    return this.afs.collection('Pictures').valueChanges()
  }

  getSections() {
    return this.afs.collection('Sections').valueChanges()
  }

  getProducts() {
    return this.afs.collection('Products').valueChanges()
  }

  getSubCategories() {
    return this.afs.collection('SubCategories').valueChanges()
  }

  getFamilies() {
    return this.afs.collection('Families').valueChanges()
  }

  getRates() {
    return this.afs.collection('RatePictures').valueChanges()
  }

  getTypes() {
    return this.afs.collection('TypePictures').valueChanges()
  }

  getSources() {
    return this.afs.collection('SourcePictures').valueChanges()
  }

  getContentbySection(section:string) {
    return this.afs.collection(section).valueChanges()
  }

  getContentbyId(content:ContentSection) {
    this.sectionDocument = this.afs.doc(`${content.Room}/${content.Speaker}`)
    return this.sectionDocument.valueChanges()
  }

  getPicturebyId(picture:PictureTechnique) {
    this.pictureDocument = this.afs.doc(`Pictures/${picture.PicId}`)
    return this.pictureDocument.valueChanges()
  }

  getCategorybyId(category:Section) {
    this.categoryDocument = this.afs.doc(`Sections/${category.SectionId}`)
    return this.categoryDocument.valueChanges()
  }

  getSubCategorybyId(subcategory:SubCategory) {
    this.subCategoryDocument = this.afs.doc(`SubCategories/${subcategory.SubCategoryId}`)
    return this.subCategoryDocument.valueChanges()
  }

  getRatebyId(rate:Rate) {
    this.rateDocument = this.afs.doc(`RatePictures/${rate.RateId}`)
    return this.rateDocument.valueChanges()
  }

  getTypebyId(type:Type) {
    this.typeDocument = this.afs.doc(`TypePictures/${type.TypeId}`)
    return this.typeDocument.valueChanges()
  }

  getSourcebyId(source:Source) {
    this.sourceDocument = this.afs.doc(`SourcePictures/${source.SourceId}`)
    return this.sourceDocument.valueChanges()
  }

  loadAllCourses(): Observable<PictureTechnique[]> {
    return this.afs.collection(
        'Pictures',
            ref=> ref.orderBy("PicId")
        )
        .snapshotChanges()
        .pipe(
            map(snaps => convertSnaps<PictureTechnique>(snaps)),
            first());
}

deleteSection (content:ContentSection) {
  this.sectionDocument = this.afs.doc(`${content.Room}/${content.Speaker}`)
  this.sectionDocument.delete()
}

deletePicture (picture:PictureTechnique) {
  this.pictureDocument = this.afs.doc(`${picture.Category}/${picture.PicId}`)
  this.pictureDocument.delete()
}

deleteCategory (category:Section) {
  this.categoryDocument = this.afs.doc(`${category.Category}/${category.SectionId}`)
  this.categoryDocument.delete()
}

deleteSubCategory (subcategory:SubCategory) {
  this.subCategoryDocument = this.afs.doc(`${subcategory.Category}/${subcategory.SubCategoryId}`)
  this.subCategoryDocument.delete()
}

updateSection (content:ContentSection) {
  this.sectionDocument = this.afs.doc(`${content.Room}/${content.Speaker}`)
  this.sectionDocument.delete()
}
}

