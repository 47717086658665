
<div class="container">
<div class="row">
<div class= "col-md-6 offset-md-3 setDefaultSpace">
  <app-alert
      [message]="dataId"
      *ngIf="displayAlert"
      (close)="onHandleModal()"
    ></app-alert>
  <h2 class="text-center">Add New Category</h2>
  <form #f="ngForm" (ngSubmit)="addNewCategory(f)">
    <input type="text" class="form-control" name="nameCategory" ngModel placeholder="Name" required><br><br>
    <input type="text" class="form-control" name="description" ngModel placeholder="Description" required><br><br>
    <input type="file" ngModel name="image" required #image><br><br>

    <input type="submit" value="add" class="btn btn-primary" [disabled]="f.invalid">
  </form>
</div>
</div>

<div class="row">
  <div class= "col-md-6 offset-md-3 pt-3">
<h2 class="text-center"> Category Dashboard </h2> <br/>
<table class="table table-striped table-dark">
 <thead>
   <td>Name</td>
   <td>Photo</td>
   <td>Description</td>
   <td>Options</td>
 </thead>

 <tbody>
   <tr *ngFor="let category of CategoriesArr; index as i">
    <td>{{category.title}}</td>
    <td class="align-middle"><img src="{{category.image}}" class="img" height="50"></td>
    <td> {{category.description}}</td>
     <td>
       <button class="btn btn-primary" (click)="openModalCategory(category)" >Update</button>
       <button class="btn btn-danger" (click)="deleteCategory(i)"> Delete</button>
     </td>
  </tr>
 </tbody>

</table>
</div>
</div>

<div class="row">
  <div class= "col-md-6 offset-md-3 pt-3">
 <div class= "setDefaultSpace">
  <h2 class="text-center">Add New Product</h2>
  <form #fProduct="ngForm" (ngSubmit)="addNewProduct(fProduct)">
    <input type="text" class="form-control" name="name" ngModel placeholder="Name" required><br><br>
    <input type="text" class="form-control" name="descriptionProduct" ngModel placeholder="Description" required><br><br>

    <input type="number" class="form-control" name="price" ngModel placeholder="Price" required><br><br>

    <select ngModel name="category" id="category" class="form-control pt-2" required>
      <option *ngFor="let c of CategoriesArr" [value]="c.id">
        {{ c.title }}
      </option>
    </select>

    <input type="file" class="pt-4" ngModel name="imageProduct" required #imageProduct><br><br>

    <input type="submit" value="add" class="btn btn-primary" [disabled]="fProduct.invalid">
  </form>
</div>


<h2 class="text-center"> Products Dashboard </h2> <br/>
<table class="table table-striped table-dark">
 <thead>
   <td>Name</td>
   <td>Photo</td>
   <td>Description</td>
   <td>Category</td>
   <td>Price</td>
   <td>Options</td>
 </thead>

 <tbody>
   <tr *ngFor="let product of ProductsArr; index as i">
     <td>{{product.title}}</td>
     <td class="align-middle"><img src="{{product.image}}" class="img" height="50"></td>
     <td>{{product.description}}</td>
     <td>"{{getCategoryName(product.category) ? getCategoryName(product.category).title: null}}"</td>
     <td>{{product.price}}</td>
     <td>
       <button class="btn btn-primary" (click)="openModalProduct(product)" >Update</button>
       <button class="btn btn-danger" (click)="deleteProduct(i)"> Delete</button>
     </td>
  </tr>
 </tbody>

</table>
</div>
</div>
</div>
