<!-- PAGE HEADER -->
<header id="page-header">
  <div *ngIf="!mobile" class="container setDefaultSpace pd-2">
    <div class="row">
      <div class="col-12">
          <div *ngIf="(authService.isFamily(userLoggedIn))">
                <h1 class="fontOpenSans-RegularSmallDarkGray">Seleccionar Familia</h1>
                <p class="fontOpenSans-RegularExtraSmallDarkGray">Lista de familias que han aceptado pertenecer a este registro de la historia familiar</p>
                <app-channel-filter [category]="category" [isFamily]="isFamily"></app-channel-filter>
                <div class="d-block d-md-none">
                  </div>
          </div>

          <!-- <div *ngIf="!(authService.isFamily(userLoggedIn))"> -->

            <!-- <div *ngIf="language === 'English'">
              <h1 class="fontOpenSans-RegularSmallDarkGray">Training Channels</h1>
              <p class="fontOpenSans-RegularExtraSmallDarkGray">This is a list of channels with trainning techniques.</p>
            </div>
            <div *ngIf="language === 'Spanish'">
              <h1 class="fontOpenSans-RegularSmallDarkGray">Canales de entrenamiento</h1>
              <p class="fontOpenSans-RegularExtraSmallDarkGray">Esta es una lista de canales de entrenamiento para diferentes técnicas.</p>
            </div> -->
            <div *ngIf="language === 'English'">
              <h1 class="fontOpenSans-RegularSmallDarkGray  text-center pb-3">Channels - {{mobileLanguage}} {{mobileTone}}</h1>
              <p class="fontOpenSans-RegularExtraSmallDarkGray pt-3">You will find a selection of channels on YouTube related with the topic of Super Orgasms. In some cases the channel does not specify Super Orgasm as the main topic but it contains content that is part of the process to train the brain, body and muscles to get one.
              </p>
              <p class="fontOpenSans-RegularExtraSmallDarkGray pt-3">Please select the category
              </p>

            </div>
            <div *ngIf="language === 'Spanish'">
              <h1 class="fontOpenSans-RegularSmallDarkGray text-center pb-3">Canales</h1>
              <p class="fontOpenSans-RegularExtraSmallDarkGray pt-3">Encontrará una selección de canales en YouTube relacionados con el tema de los Super Orgasmos. En algunos casos el canal no especifica Super Orgasmo como tema principal, pero contiene contenido que es parte del proceso para entrenar el cerebro, el cuerpo y los músculos para conseguirlo.
              </p>
              <p class="fontOpenSans-RegularExtraSmallDarkGray pt-3">Por favor seleccione la categoría
              </p>
            </div>
            <div class="text-center pt-3">
              <app-channel-filter [category]="category" [isFamily]="isFamily"></app-channel-filter>
            </div>
              <div class="d-block d-md-none">
              </div>
        <!-- </div> -->

      </div>
    </div>
  </div>
  <div *ngIf="mobile" class="container pd-2">
    <div class="row">
      <div class="col-12">
          <div *ngIf="(authService.isFamily(userLoggedIn))">
                <h1 class="fontOpenSans-RegularSmallDarkGray">Seleccionar Familia</h1>
                <p class="fontOpenSans-RegularExtraSmallDarkGray">Lista de familias que han aceptado pertenecer a este registro de la historia familiar</p>
                <app-channel-filter [category]="category" [isFamily]="isFamily"></app-channel-filter>
                <div class="d-block d-md-none">
                  </div>
          </div>

          <!-- <div *ngIf="!(authService.isFamily(userLoggedIn))"> -->

            <!-- <div *ngIf="language === 'English'">
              <h1 class="fontOpenSans-RegularSmallDarkGray">Training Channels</h1>
              <p class="fontOpenSans-RegularExtraSmallDarkGray">This is a list of channels with trainning techniques.</p>
            </div>
            <div *ngIf="language === 'Spanish'">
              <h1 class="fontOpenSans-RegularSmallDarkGray">Canales de entrenamiento</h1>
              <p class="fontOpenSans-RegularExtraSmallDarkGray">Esta es una lista de canales de entrenamiento para diferentes técnicas.</p>
            </div> -->
            <div *ngIf="language === 'English'">
              <h1 class="fontOpenSans-RegularSmallWhite  text-center pb-3">Channels</h1>
              <p class="fontOpenSans-RegularExtraSmallWhite pt-3">You will find a selection of channels on YouTube related with the topic of Super Orgasms. In some cases the channel does not specify Super Orgasm as the main topic but it contains content that is part of the process to train the brain, body and muscles to get one.
              </p>
              <p class="fontOpenSans-RegularExtraSmallWhite pt-3">Please select the category
              </p>

            </div>
            <div *ngIf="language === 'Spanish'">
              <h1 class="fontOpenSans-RegularSmallWhite  text-center pb-3">Canales</h1>
              <p class="fontOpenSans-RegularExtraSmallWhite pt-3">Encontrará una selección de canales en YouTube relacionados con el tema de los Super Orgasmos. En algunos casos el canal no especifica Super Orgasmo como tema principal, pero contiene contenido que es parte del proceso para entrenar el cerebro, el cuerpo y los músculos para conseguirlo.
              </p>
              <p class="fontOpenSans-RegularExtraSmallWhite pt-3">Por favor seleccione la categoría
              </p>
            </div>
            <div class="text-center pt-3">
              <app-channel-filter [category]="category" [isFamily]="isFamily"></app-channel-filter>
            </div>
              <div class="d-block d-md-none">
              </div>
        <!-- </div> -->

      </div>
    </div>
  </div>
</header>

<!-- PAGE HEADER -->
<!-- <header id="page-header">
<div class="container pt-4 pb-4">
  <div class="row">
    <div class="card mb-3">
        <div class="row no-gutters">
          <div class="col-md-4">
            <img src="{{thumbnail}}" alt="" class="img-fluid card-img-top">

          </div>
          <div class="col-md-8">
            <div class="card-body">
              <img src="../../assets/images/youtube_logo.png" style="height: 20px;width:auto" class="img-fluid card-img-top">
              <h5 class="card-header centerName fontOpenSans-RegularSmallDarkGra">Nuestro canal de Youtube : {{channelDetails.snippet.title}}</h5>
              <p class="fontOpenSans-RegularExtraSmallDarkGray pt-4">{{channelDetails.snippet.description}}</p>
              <p class="fontOpenSans-RegularExtraSmallDarkGray pt-4"><span class="fontOpenSans-RegularSmallDarkGray">ID :&nbsp;</span> {{channelDetails.id}}</p>
              <p class="fontOpenSans-RegularExtraSmallDarkGray pt-4"><span class="fontOpenSans-RegularSmallDarkGray">Suscritos :&nbsp;</span>{{channelDetails.statistics.subscriberCount}}</p>
              <p class="fontOpenSans-RegularExtraSmallDarkGray pt-4"><span class="fontOpenSans-RegularSmallDarkGray">Visitas :&nbsp;</span> {{channelDetails.statistics.viewCount}}</p>
              <p class="fontOpenSans-RegularExtraSmallDarkGray pt-4"><span class="fontOpenSans-RegularSmallDarkGray">Videos :&nbsp;</span> {{channelDetails.statistics.videoCount}}</p>
            </div>
          </div>
        </div>
      </div>

  </div>
</div>
</header>

<div class="no-scrolling">
  <ngx-masonry class="positioning" [options]="masonryOptions" [useImagesLoaded]="true">
    <ngxMasonryItem *ngFor="let video of videos" class="masonry-item drop-shadow">
            <div class="card">
                <div>
                  <iframe width="100%" height="auto" [src]="video.cleanUrl | safeHtml"
                  frameborder="0"allow="autoplay; ancrypted-media" allowFullScreen="allowFullScreen"></iframe>
                </div>
                    <div class="card-body">
                        <div class="card-header">{{ video.snippet.title }}</div>
                        <div class="card-body">
                                <h5 class="card-title">{{ video.snippet.description }}</h5>
                        </div>
                    </div>
                </div>
    </ngxMasonryItem>
  </ngx-masonry>
  </div> -->

  <!-- <div class="row">
    <div class="col">
      <div class="row">
          <ng-container *ngFor="let p of filteredContentSection; let i = index">
            <div class="col-12 col-md-6">
              <app-channel-card [channel]="p"></app-channel-card>
            </div>
            <div *ngIf="(i + 1) % 2 === 0" class="w-100"></div>
          </ng-container>
        </div>
    </div>
  </div> -->

  <div class="no-scrolling">
        <ngx-masonry class="positioning" [options]="masonryOptions" [useImagesLoaded]="true">
          <ngxMasonryItem *ngFor="let p of filteredContentSection" class="masonry-item drop-shadow">
                      <app-channel-card [channel]="p"></app-channel-card>
          </ngxMasonryItem>
        </ngx-masonry>
      <!-- <div *ngIf="authService.isFamily(userLoggedIn)">
          <ngx-masonry class="positioning" [options]="masonryOptions" [useImagesLoaded]="true">
            <ngxMasonryItem *ngFor="let p of filteredFamilyContentSection" class="masonry-item drop-shadow">
                        <app-channel-card [channel]="p"></app-channel-card>
            </ngxMasonryItem>
          </ngx-masonry>
        </div> -->
  </div>
