<div class="container">
  <div *ngIf="!hideProcess">
  <div class="row justify-content-center">
  <div class="col-12 col-md-12">
  <p class='fontOpenSans-SemiBoldSmallContent'>Número de Orden : {{ id }}</p>
  <p class='fontOpenSans-SemiBoldSmallContent'>Fecha de Orden : {{ order.object.datePlaced  | date: 'EEEE, d/M/yy, h:mm a':'es-CO'}}</p>
  <p class='fontOpenSans-SemiBoldSmallContent'>{{name}}</p>
  <div class="pb-5">
  <table >
    <tr>
      <td>Nombre cliente</td>
      <td>{{ order.object.shipInfo.name}}</td>
    </tr>
    <tr>
      <td>Teléfono</td>
      <!-- <td>{{ order.object.shipInfo.phone}}</td> -->
      <td>{{ order.object.shipInfo.addressLine2}}</td>
    </tr>
    <tr>
      <td>Dirección</td>
      <td>{{ order.object.shipInfo.addressLine1}}</td>
    </tr>
    <tr>
      <td>Ciudad</td>
      <td>{{ order.object.shipInfo.city}}</td>
    </tr>
  </table>
  <ul class="list-group list-group-flush pt-5">
    <table>
      <tr class="title-details">
      <th>Producto Id</th>
      <th>Descripción</th>
      <th>Cantidad</th>
      <th>Precio unitario</th>
      <th>Total</th>
    </tr>
      <tr *ngFor="let item of order.object.items">
        <td>{{ item.product.id }}</td>
        <td class='details-description'>{{  item.product.title }}</td>
        <td class='details-quantity'>{{ item.quantity }}</td>
        <td>{{ item.product.price | currency}}</td>
        <td>{{ item.totalPrice | currency }}</td>
    </tr>
  </table>
    <li class="list-group-item font-weight-bold bckgnd">
      SubTotal
      <div class="float-right pr-2 pl-2">
        {{ totalPrice | currency }}
      </div>
    </li>
    <li class="list-group-item font-weight-bold bckgnd">
      IVA<span>({{impuesto | percent}})</span>
      <div class="float-right pr-2 pl-2">
        {{ totalPrice*impuesto | currency }}
      </div>
    </li>
    <li class="list-group-item font-weight-bold bckgnd">
      Total orden
      <div class="float-right pr-2 pl-2">
        {{ totalPrice + totalPrice*impuesto | currency }}
      </div>
    </li>
  </ul>
</div>
</div>
</div>
<div class="row justify-content-center">
  <div class="col-12 col-md-12">
    <h2>Puedes revisar el estado de tu orden en cualquier momento.</h2>
  <div class="alert-box-actions">
      <ul class="timeline pt-5">

        <!--=====================================
        1 Momento: Órden de entrega recibida
        ======================================-->

          <li
      *ngIf="order.object.process[0].stage == 'received' && order.object.process[0].status== 'ok'"
          class="success">

              <!-- <h5>{{order.process[0].date | date: "EEEE, d/M/yy, h:mm a"}}</h5> -->
              <h5>{{order.object.process[0].date | date: 'EEEE, d/M/yy, h:mm a':'es-CO'}}</h5>
              <p class="text-success">Orden recibida <i class="fas fa-check"></i></p>
              <p>Comment: {{order.object.process[0].comment}}</p>

          </li>

          <!--=====================================
        2 Momento: Cuando se envía el producto
        ======================================-->

        <!-- Cuando el status es ok -->

        <li
      *ngIf="order.object.process[1].stage == 'processing' && order.object.process[1].status == 'ok'"
          class="success">

              <h5>{{order.object.process[1].date | date: 'EEEE, d/M/yy, h:mm a':'es-CO'}}</h5>
              <p class="text-success">Orden en proceso elaboración <i class="fas fa-check"></i></p>
              <p>Comment: {{order.object.process[1].comment}}</p>

          </li>

          <!-- Cuando el status es pendiente -->

          <li
          *ngIf="order.object.process[1].stage == 'processing' && order.object.process[1].status != 'ok'"
          class="process">
              <h5>{{order.object.process[1].date | date: 'EEEE, d/M/yy, h:mm a':'es-CO'}}</h5>
              <p>Orden en proceso elaboración</p>
              <button class="btn btn-primary" disabled>
                <span class="spinner-border spinner-border-sm"></span>
                In Progress
              </button>
          </li>

          <!--=====================================
        3 Momento: Cuando se entrega el producto
        ======================================-->

        <!-- Cuando el status es ok -->

        <li
      *ngIf="order.object.process[2].stage == 'ready' && order.object.process[2].status == 'ok'"
          class="success">

              <h5>{{order.object.process[2].date | date: 'EEEE, d/M/yy, h:mm a':'es-CO'}}</h5>
              <p class="text-success">Orden lista para envío <i class="fas fa-check"></i></p>
              <p>Comment: {{order.object.process[2].comment}}</p>

          </li>

          <!-- Cuando el status es pendiente -->

          <li
          *ngIf="order.object.process[2].stage == 'ready' && order.object.process[2].status != 'ok'"
          class="process">
              <h5>{{order.object.process[2].date | date: 'EEEE, d/M/yy, h:mm a':'es-CO'}}</h5>
              <p>Orden lista para envío</p>
              <button class="btn btn-primary" disabled>
                <span class="spinner-border spinner-border-sm"></span>
                In process
              </button>
          </li>

          <!-- Cuando el status es ok -->

        <li
        *ngIf="order.object.process[3].stage == 'delivery' && order.object.process[3].status == 'ok'"
            class="success">

                <h5>{{order.object.process[3].date | date: 'EEEE, d/M/yy, h:mm a':'es-CO'}}</h5>
                <p class="text-success">Orden empacada <i class="fas fa-check"></i></p>
                <p>Comment: {{order.object.process[3].comment}}</p>

            </li>

          <!-- Cuando el status es pendiente -->

          <li
          *ngIf="order.object.process[3].stage == 'delivery' && order.object.process[3].status != 'ok'"
          class="process">
              <h5>{{order.object.process[3].date | date: 'EEEE, d/M/yy, h:mm a':'es-CO'}}</h5>
              <p>Orden empacada</p>
              <button class="btn btn-primary" disabled>
                <span class="spinner-border spinner-border-sm"></span>
                In process
              </button>
          </li>

          <!-- Cuando el status es ok -->

        <li
        *ngIf="order.object.process[4].stage == 'transit' && order.object.process[4].status == 'ok'"
            class="success">

                <h5>{{order.object.process[4].date | date: 'EEEE, d/M/yy, h:mm a':'es-CO'}}</h5>
                <p class="text-success">En tránsito <i class="fas fa-check"></i></p>
                <p>Comment: {{order.object.process[4].comment}}</p>

            </li>

          <!-- Cuando el status es pendiente -->

          <li
          *ngIf="order.object.process[4].stage == 'transit' && order.object.process[4].status != 'ok'"
          class="process">
              <h5>{{order.object.process[4].date | date: 'EEEE, d/M/yy, h:mm a':'es-CO'}}</h5>
              <p>En tránsito</p>
              <button class="btn btn-primary" disabled>
                <span class="spinner-border spinner-border-sm"></span>
                In process
              </button>
          </li>

          <!-- Cuando el status es ok -->

        <li
        *ngIf="order.object.process[5].stage == 'delivered' && order.object.process[5].status == 'ok'"
            class="success">

                <h5>{{order.object.process[5].date | date: 'EEEE, d/M/yy, h:mm a':'es-CO'}}</h5>
                <p class="text-success">Entregado <i class="fas fa-check"></i></p>
                <p>Comment: {{order.object.process[5].comment}}</p>

            </li>

          <!-- Cuando el status es pendiente -->

          <li
          *ngIf="order.object.process[5].stage == 'delivered' && order.object.process[5].status != 'ok'"
          class="process">
              <h5>{{order.object.process[5].date | date: 'EEEE, d/M/yy, h:mm a':'es-CO'}}</h5>
              <p>Entregado</p>
              <button class="btn btn-primary" disabled>
                <span class="spinner-border spinner-border-sm"></span>
                In process
              </button>
          </li>

      </ul>
    </div>
  </div>

</div>
</div>

<div *ngIf="hideProcess">
  <div class="row justify-content-center">
  <div class="col-12 col-md-12">
  <p class='fontOpenSans-SemiBoldSmallContent'>Número de Orden : {{ id }}</p>
  <p class='fontOpenSans-SemiBoldSmallContent'>Fecha de Orden : {{ order.object.datePlaced  | date: 'EEEE, d/M/yy, h:mm a':'es-CO'}}</p>
  <p class='fontOpenSans-SemiBoldSmallContent'>{{name}}</p>
  <div class="pb-5">
  <table >
    <tr>
      <td>Nombre cliente</td>
      <td>{{ order.object.shipInfo.name}}</td>
    </tr>
    <tr>
      <td>Teléfono</td>
      <!-- <td>{{ order.object.shipInfo.phone}}</td> -->
      <td>{{ order.object.shipInfo.addressLine2}}</td>
    </tr>
    <tr>
      <td>Dirección</td>
      <td>{{ order.object.shipInfo.addressLine1}}</td>
    </tr>
    <tr>
      <td>Ciudad</td>
      <td>{{ order.object.shipInfo.city}}</td>
    </tr>
  </table>
  <ul class="list-group list-group-flush pt-5">
    <table>
      <tr class="title-details">
      <th>Producto Id</th>
      <th>Descripción</th>
      <th>Cantidad</th>
      <th>Precio unitario</th>
      <th>Total</th>
    </tr>
      <tr *ngFor="let item of order.object.items">
        <td>{{ item.product.id }}</td>
        <td class='details-description'>{{  item.product.title }}</td>
        <td class='details-quantity'>{{ item.quantity }}</td>
        <td>{{ item.product.price | currency}}</td>
        <td>{{ item.totalPrice | currency }}</td>
    </tr>
  </table>
    <li class="list-group-item font-weight-bold bckgnd">
      SubTotal
      <div class="float-right pr-2 pl-2">
        {{ totalPrice | currency }}
      </div>
    </li>
    <li class="list-group-item font-weight-bold bckgnd">
      IVA<span>({{impuesto | percent}})</span>
      <div class="float-right pr-2 pl-2">
        {{ totalPrice*impuesto | currency }}
      </div>
    </li>
    <li class="list-group-item font-weight-bold bckgnd">
      Total orden
      <div class="float-right pr-2 pl-2">
        {{ totalPrice + totalPrice*impuesto | currency }}
      </div>
    </li>
  </ul>
</div>
</div>
</div>
</div>
</div>
