import { Component, OnInit, Input } from '@angular/core';
import { ShoppingCart } from './../shared/models/shopping-cart.model';

@Component({
  selector: 'app-shopping-cart-summary',
  templateUrl: './shopping-cart-summary.component.html',
  styleUrls: ['./shopping-cart-summary.component.scss']
})
export class ShoppingCartSummaryComponent implements OnInit {
  @Input('cart') cartData:any
  cart: ShoppingCart;
  impuesto = 0.16;
  totalPrice = 0;

  constructor() { }

  ngOnInit(): void {
    this.cart = this.cartData.cartData;
    console.log("shopping-cart flag", this.cartData.flag);
    console.log("shopping-cart cart", this.cartData.cartData);
    console.log("shopping-cart summary form component totalItemsCount",this.cart.totalItemsCount);
    console.log("shopping-cart summary form component items",this.cart.items);
    for(let i=0;i<this.cart.items.length;i++) {
      this.totalPrice = this.totalPrice + this.cart.items[i].totalPrice;
    }
  }

}
