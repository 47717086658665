import { Component, OnInit, OnDestroy, ɵConsole, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AngularFireStorage } from '@angular/fire/storage';
import { finalize, map, filter } from "rxjs/operators";
import { ImageService } from './../../shared/services/image.service';
import { UploadInfoService } from './../../shared/services/upload-info.service'
import { Observable, Subscription } from 'rxjs';
import { Section } from './../../shared/models/section.model';
import { SubCategory } from './../../shared/models/subcategory.model'
import { Rate } from './../../shared/models/rate.model'
import { Type } from './../../shared/models/type.model'
import { Source } from './../../shared/models/source.model'
import { ContentSection } from './../../shared/models/contentSection.model'
import { PictureTechnique } from './../../shared/models/pictureTechnique.model'

import { AuthService } from '../../shared/services/auth.service'
import { UserProfile } from './../../shared/models/user-profile';
import { User } from './../../shared/models/user.model';




let authObs: Observable<UploadInfoService>;

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})

export class AdminComponent implements OnInit {

  imgSrc: string;
  selectedImage: any = null;
  isSubmitted: boolean;
  isSubmittedCategory: boolean;
  isSubmittedSubCategory: boolean;
  isSubmittedRate: boolean;
  isSubmittedType: boolean;
  isSubmittedSource: boolean;
  isSubmittedSection: boolean;

  localFileName: string = '';
  testBoolean: boolean = false;

  pics: PictureTechnique[];
  picSelected: PictureTechnique;
  picSelectedUrl: string = '';
  picSelectedInfo: string = '';
  picSelectedSource: string = '';
  pictures$: Observable<PictureTechnique[]>;

  assetsPics: PictureTechnique[];
  assetsPicSelected: PictureTechnique;
  assetsPicSelectedTitle: string = '';

  sections: Section[];
  sectionSelected: Section;
  sectionSelectedTitle: string = '';
  sectionSelectedCategory: string = '';
  sectionSelectedType: string = '';
  sectionSelectedSectionId: string = '';

  subcategories: SubCategory[];
  subcategorySelected: Section;
  subcategorySelectedTitle: string = '';
  subcategorySelectedCategory: string = '';
  subcategorySelectedType: string = '';
  subcategorySelectedSSubCategoryId: string = '';

  rates: Rate[];
  rateSelected: Rate;
  rateSelectedStr: string = "Low";

  types: Type[];
  typeSelected: Type;
  typeSelectedStr: string = "Hetereo";

  sources: Source[];
  sourceSelected: Source;
  sourceSelectedStr: string = "https://www.google.com";

  contentSections: ContentSection[];
  contentSection: ContentSection;
  contentSelectedBackground: string = '';


  ratePictures$: Observable<PictureTechnique[]>;
  assetsPictures$: Observable<PictureTechnique[]>;

  typePictures$: Observable<PictureTechnique[]>;

  private successSub: Subscription;
  private listSub: Subscription;

  p: number = 1;

  isOperatorKitchen: boolean;
  isAdmin: boolean;
  userLoggedIn: UserProfile;
  userData: User;



  formTemplate = new FormGroup({
    caption: new FormControl('', Validators.required),
    content: new FormControl('', Validators.required),
    category: new FormControl('', Validators.required),
    subcategory: new FormControl('', Validators.required),
    imageUrl: new FormControl('', Validators.required),
    rate: new FormControl('', Validators.required),
    fileName: new FormControl('', Validators.required),
    type: new FormControl('', Validators.required),
    size: new FormControl('', Validators.required),
    width: new FormControl('', Validators.required),
    height: new FormControl('', Validators.required),
    sourceImage: new FormControl('', Validators.required)
  })

  formUpdatePictureTemplate = new FormGroup({
    caption: new FormControl('', Validators.required),
    content: new FormControl('', Validators.required),
    category: new FormControl('', Validators.required),
    subcategory: new FormControl('', Validators.required),
    rate: new FormControl('', Validators.required),
    fileName: new FormControl('', Validators.required),
    type: new FormControl('', Validators.required),
    size: new FormControl('', Validators.required),
    width: new FormControl('', Validators.required),
    height: new FormControl('', Validators.required),
    sourceImage: new FormControl('', Validators.required)
  })

  formCategoryTemplate = new FormGroup({
    category: new FormControl('', Validators.required),
    caption: new FormControl('', Validators.required),
    type: new FormControl('', Validators.required),
  })

  formSubCategoryTemplate = new FormGroup({
    category: new FormControl('', Validators.required),
    caption: new FormControl('', Validators.required),
    type: new FormControl('', Validators.required),
  })

  formRateTemplate = new FormGroup({
    category: new FormControl('', Validators.required),
    caption: new FormControl('', Validators.required),
    type: new FormControl('', Validators.required),
  })

  formTypeTemplate = new FormGroup({
    category: new FormControl('', Validators.required),
    caption: new FormControl('', Validators.required),
    type: new FormControl('', Validators.required),
  })

  formSourceTemplate = new FormGroup({
    category: new FormControl('', Validators.required),
    caption: new FormControl('', Validators.required),
    type: new FormControl('', Validators.required),
  })

  formSectionTemplate = new FormGroup({
    background: new FormControl('', Validators.required),
    imageUrl: new FormControl('', Validators.required),
    photoInfo: new FormControl('', Validators.required),
    videoUrl: new FormControl('', Validators.required),
    time: new FormControl('', Validators.required),
    backgroundImage: new FormControl('', Validators.required),
    externalWeb1: new FormControl('', Validators.required),
    externalWeb2: new FormControl('', Validators.required),
    externalWeb3: new FormControl('', Validators.required),
    caption: new FormControl('', Validators.required),
    content: new FormControl('', Validators.required),
    category: new FormControl('', Validators.required),
    subcategory: new FormControl('', Validators.required),
    sourceImage: new FormControl('', Validators.required),
    index: new FormControl('', Validators.required),
  })

  constructor(private storage: AngularFireStorage, private service: ImageService, private contentservice: UploadInfoService, public authService: AuthService) { }

  ngOnInit() {
    this.resetForm();
    this.resetPictureUpdateForm();
    this.resetCategoryForm();
    this.resetSubCategoryForm();
    this.resetRateForm();
    this.resetTypeForm();
    this.resetSourceForm();
    this.resetSectionForm();

    this.successSub = this.contentservice.uploadSuccess.subscribe((uploadSuccess) => {
      // this.resetCategoryForm();
      this.resetRateForm();
      this.resetTypeForm();
      this.resetSourceForm();
      this.resetSectionForm();
      this.resetPictureUpdateForm();
      this.resetCategoryForm();
      this.resetSubCategoryForm();
      this.contentservice.getSections().subscribe(val => {
        this.sections = val
      })
    });

    // this.reloadPictures();

    this.contentservice.getData10().subscribe(val => {
      this.pics = val
      this.assetsPics = this.getAssetsPicturesData(this.pics);
    })

    this.contentservice.getSections().subscribe(val => {
      this.sections = val
    })

    this.contentservice.getSubCategories().subscribe(val => {
      this.subcategories = val
    })

    this.contentservice.getRates().subscribe(val => {
      this.rates = val
    })

    this.contentservice.getTypes().subscribe(val => {
      this.types = val
    })

    this.contentservice.getSources().subscribe(val => {
      this.sources = val
      // console.log("source list",this.sources)
    })

  }


  getPicturesData() {
    this.contentservice.getData10().subscribe(val => {
      this.pics = val
      this.assetsPics = this.getAssetsPicturesData(this.pics);
    })
  }

  getAssetsPicturesData(picsArray:PictureTechnique[]): PictureTechnique[]   {
    let iphonePics: PictureTechnique[] = []
    iphonePics = picsArray.filter(e => e.PhotoSource === 'iPhone Assets')
    return iphonePics
  }

  getCategoryData() {
    this.contentservice.getSections().subscribe(val => {
      this.sections = val
    })
  }

  getSubCategoryData() {
    this.contentservice.getSubCategories().subscribe(val => {
      this.subcategories = val
    })
  }

  getRateData() {
    this.contentservice.getRates().subscribe(val => {
      this.rates = val
    })
  }

  getTypeData() {
    this.contentservice.getTypes().subscribe(val => {
      this.types = val
    })
  }

  getSourceData() {
    this.contentservice.getSources().subscribe(val => {
      this.sources = val
    })
  }

  reloadPictures() {
    this.pictures$ = this.contentservice.loadAllCourses();

    this.ratePictures$ = this.pictures$.pipe(
      map(pictures => pictures.filter(
        picture => picture.Rate.includes("low")))
    )

    // console.log("this.ratePictures$",this.ratePictures$);

    this.typePictures$ = this.pictures$.pipe(
      map(pictures => pictures.filter(
        picture => picture.Type.includes("neutral")))
    )

    // console.log("this.typePictures$",this.typePictures$);

    // this.beginnersCourses$ = this.pictures$.pipe(
    //     map(courses => courses.filter(
    //         course => course.categories.includes("BEGINNER"))));

    // this.advancedCourses$ = this.pictures$.pipe(
    //     map(courses => courses.filter(
    //         course => course.categories.includes("ADVANCED"))));
}

  showPreview(event: any) {
    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader();
      reader.onload = (e: any) => this.imgSrc = e.target.result;
      reader.readAsDataURL(event.target.files[0]);
      this.selectedImage = event.target.files[0];
      this.localFileName = this.selectedImage.name
    }
    else {
      this.imgSrc = "../../assets/images/image_placeholder.jpg";
      this.selectedImage = null;
    }
  }

  //Add Picture
  onSubmit(formValue) {
    this.isSubmitted = true;
    if (this.formTemplate.valid) {
      var filePath = `${formValue.category}/${new Date().getTime()}`;
      const fileRef = this.storage.ref(filePath);
      this.storage.upload(filePath, this.selectedImage).snapshotChanges().pipe(
        finalize(() => {
          fileRef.getDownloadURL().subscribe((url) => {
            formValue['imageUrl'] = url;
            const ID = new Date().getTime();
            this.service.setPictureData(formValue,ID);
            this.resetForm();
          })
        })
      ).subscribe();
    }
  }

  //Add Category
  onSubmitCategory(formValue) {
    this.isSubmittedCategory = true;
    if (this.formCategoryTemplate.valid) {
      // var filePath = `${formValue.category}/${new Date().getTime()}`;
      const ID = new Date().getTime();
      this.contentservice.setCategoryData(formValue,ID);
    }
  }

  //Add SubCategory
  onSubmitSubCategory(formValue) {
    this.isSubmittedSubCategory = true;
    if (this.formSubCategoryTemplate.valid) {
      // var filePath = `${formValue.category}/${new Date().getTime()}`;
      const ID = new Date().getTime();
      this.contentservice.setSubCategoryData(formValue,ID);
    }
  }

  //Add Content
  onSubmitContent(formValue) {
    this.isSubmittedSection = true;
    if (this.formSectionTemplate.valid) {
      // var filePath = `${formValue.category}/${new Date().getTime()}`;
      const ID = new Date().getTime();
      this.contentservice.setSectionData(formValue,ID);
    }
  }

  //Add Rate
  onSubmitRate(formValue) {
    this.isSubmittedRate = true;
    if (this.formRateTemplate.valid) {
      // var filePath = `${formValue.category}/${new Date().getTime()}`;
      const ID = new Date().getTime();
      this.contentservice.setRateData(formValue,ID);
    }
  }

  //Add Type
  onSubmitType(formValue) {
    this.isSubmittedType = true;
    if (this.formTypeTemplate.valid) {
      // var filePath = `${formValue.category}/${new Date().getTime()}`;
      const ID = new Date().getTime();
      this.contentservice.setTypeData(formValue,ID);
    }
  }

  //Add Source
  onSubmitSource(formValue) {
    this.isSubmittedSource = true;
    if (this.formSourceTemplate.valid) {
      // var filePath = `${formValue.category}/${new Date().getTime()}`;
      const ID = new Date().getTime();
      this.contentservice.setSourceData(formValue,ID);
    }
  }


  //Update Content
  onUpdateContent(formValue: ContentSection, ID: string) {
    this.isSubmittedSection = true;
    if (this.formSectionTemplate.valid) {
      this.contentservice.updateContentData(formValue,ID);
    }
  }

  //Update Picture
  onUpdatePicture(formValue: PictureTechnique, ID: string, imageUrl:string) {
    formValue.UrlImage = imageUrl;
    this.isSubmitted = true;
    if (this.formUpdatePictureTemplate.valid) {
      this.contentservice.updatePictureData(formValue,ID,imageUrl);
    }
  }

  //Update Category
  onUpdateCategory(formValue: Section, ID: string) {
    this.isSubmittedCategory = true;
    if (this.formCategoryTemplate.valid) {
      this.contentservice.updateCategoryData(formValue, ID);
    }
  }

  //Update SubCategory
  onUpdateSubCategory(formValue: SubCategory, ID: string) {
    this.isSubmittedSubCategory = true;
    if (this.formSubCategoryTemplate.valid) {
      this.contentservice.updateSubCategoryData(formValue, ID);
    }
  }

  //Update Rate
  onUpdateRate(formValue: Section, ID: string) {
    this.isSubmittedRate = true;
    if (this.formRateTemplate.valid) {
      this.contentservice.updateRateData(formValue, ID);
    }
  }

  //Update Type
  onUpdateType(formValue: Section, ID: string) {
    this.isSubmittedType = true;
    if (this.formTypeTemplate.valid) {
      this.contentservice.updateTypeData(formValue, ID);
    }
  }

  //Update Source
  onUpdateSource(formValue: Section, ID: string) {
    this.isSubmittedSource = true;
    if (this.formSourceTemplate.valid) {
      this.contentservice.updateSourceData(formValue, ID);
    }
  }

  get formControls() {
    return this.formTemplate['controls'];
  }

  get formPictureUpdateControls() {
    return this.formUpdatePictureTemplate['controls'];
  }

  get formCategoryControls() {
    return this.formCategoryTemplate['controls'];
  }

  get formSubCategoryControls() {
    return this.formSubCategoryTemplate['controls'];
  }

  get formRateControls() {
    return this.formRateTemplate['controls'];
  }

  get formTypeControls() {
    return this.formTypeTemplate['controls'];
  }

  get formSourceControls() {
    return this.formSourceTemplate['controls'];
  }

  get formSectionControls() {
    return this.formSectionTemplate['controls'];
  }
//Reset Picture Form (Add)
  resetForm() {
    this.pics = [];
    this.formTemplate.reset();
    this.formTemplate.setValue({
      caption: '',
      content: '',
      category: 'Pictures',
      subcategory:'noSubcategory',
      imageUrl:'',
      rate:'low',
      fileName:this.localFileName,
      type:'neutral',
      size:'400x227',
      width:'400',
      height:'227',
      sourceImage:'https://google.com'
    });
    this.imgSrc = '../../assets/images/image_placeholder.jpg';
    this.selectedImage = null;
    this.isSubmitted = false;
  }

  resetPictureUpdateForm() {
    this.pics = [];
    this.formUpdatePictureTemplate.reset();
    this.formUpdatePictureTemplate.setValue({
      caption: '',
      content: '',
      category: 'Pictures',
      subcategory:'noSubcategory',
      rate:'low',
      fileName:this.localFileName,
      type:'neutral',
      size:'400x227',
      width:'400',
      height:'227',
      sourceImage:'https://google.com'
    });
    this.imgSrc = '../../assets/images/image_placeholder.jpg';
    this.selectedImage = null;
    this.isSubmitted = false;
  }

  resetCategoryForm() {
    this.sections = [];
    this.formCategoryTemplate.reset();
    this.formCategoryTemplate.setValue({
      caption: '',
      category: 'Sections',
      type: 'category',
    });
    this.imgSrc = '../../assets/images/image_placeholder.jpg';
    this.selectedImage = null;
    this.isSubmittedCategory = false;
  }

  resetSubCategoryForm() {
    this.subcategories = [];
    this.formSubCategoryTemplate.reset();
    this.formSubCategoryTemplate.setValue({
      caption: '',
      category: 'SubCategories',
      type: 'blog',
    });
    this.imgSrc = '../../assets/images/image_placeholder.jpg';
    this.selectedImage = null;
    this.isSubmittedSubCategory = false;
  }

  resetRateForm() {
    this.rates = [];
    this.formRateTemplate.reset();
    this.formRateTemplate.setValue({
      caption: '',
      category: 'RatePictures',
      type: 'n/a',
    });
    this.imgSrc = '../../assets/images/image_placeholder.jpg';
    this.selectedImage = null;
    this.isSubmittedRate = false;
  }

  resetTypeForm() {
    this.types = [];
    this.formTypeTemplate.reset();
    this.formTypeTemplate.setValue({
      caption: '',
      category: 'TypePictures',
      type: 'n/a',
    });
    this.imgSrc = '../../assets/images/image_placeholder.jpg';
    this.selectedImage = null;
    this.isSubmittedType = false;
  }

  resetSourceForm() {
    this.sources = [];
    this.formSourceTemplate.reset();
    this.formSourceTemplate.setValue({
      caption: '',
      category: 'SourcePictures',
      type: 'n/a',
    });
    this.imgSrc = '../../assets/images/image_placeholder.jpg';
    this.selectedImage = null;
    this.isSubmittedSource = false;
  }

  resetSectionForm() {
    this.contentSections = [];
    this.formSectionTemplate.reset();
    this.formSectionTemplate.setValue({
      background: '',
      imageUrl: 'noimageUrl',
      photoInfo: '',
      videoUrl: '',
      time: '',
      backgroundImage: '',
      externalWeb1: '',
      externalWeb2: '',
      externalWeb3: '',
      caption: '',
      content: '',
      category: 'noCategory',
      subcategory:'noSubcategory',
      sourceImage:'https://google.com',
      index:'0'
    });
    this.imgSrc = '../../assets/images/image_placeholder.jpg';
    this.selectedImage = null;
    this.isSubmittedSection = false;
  }

  imageFilter(PicId:string) {
    this.picSelected = this.pics.find((item)=> item.PicId===PicId);
    this.picSelectedUrl = this.picSelected.UrlImage;
    this.picSelectedInfo = this.picSelected.PhotoInfo;
    this.picSelectedSource = this.picSelected.PhotoSource;
  }

  assetFilter(PicId:string) {
    this.assetsPicSelected = this.assetsPics.find((item)=> item.PicId===PicId);
    this.assetsPicSelectedTitle = this.assetsPicSelected.Title;
  }

  rateFilter(RateId:string) {
    this.rateSelected = this.rates.find((item)=> item.RateId===RateId);
    this.rateSelectedStr = this.rateSelected.Title;
  }

  typeFilter(TypeId:string) {
    this.typeSelected = this.types.find((item)=> item.TypeId===TypeId);
    this.typeSelectedStr = this.typeSelected.Title;
  }

  sourceFilter(SourceId:string) {
    this.sourceSelected = this.sources.find((item)=> item.SourceId===SourceId);
    this.sourceSelectedStr = this.sourceSelected.Title;
  }

  sectionFilter(SectionId:string) {
    this.sectionSelected = this.sections.find((item)=> item.SectionId===SectionId);
    this.sectionSelectedTitle = this.sectionSelected.Title;
    this.contentservice.getContentbySection(this.sectionSelectedTitle).subscribe(val => {
      this.contentSections = val
    })

  }

  contentFilterbyId(Content:ContentSection) {
    this.resetSectionForm();
    this.contentservice.getContentbyId(Content).subscribe(val => {
      this.contentSection = val
      this.picSelectedUrl = this.contentSection.UrlImage;
      this.picSelectedInfo = this.contentSection.PhotoInfo;
      this.picSelectedSource = this.contentSection.PhotoSource;
    })

  }

  pictureFilterbyId(Picture:PictureTechnique) {
    this.resetPictureUpdateForm();
    this.contentservice.getPicturebyId(Picture).subscribe(val => {
      this.picSelected = val
      this.picSelectedUrl = this.picSelected.UrlImage;
      this.picSelectedInfo = this.picSelected.PhotoInfo;
      this.picSelectedSource = this.picSelected.PhotoSource;
    })

  }

  categoryFilterbyId(Category:Section) {
    this.resetCategoryForm();
    this.contentservice.getCategorybyId(Category).subscribe(val => {
      this.sectionSelected = val
    })
  }

  subcategoryFilterbyId(SubCategory:SubCategory) {
    this.resetSubCategoryForm();
    this.contentservice.getSubCategorybyId(SubCategory).subscribe(val => {
      this.subcategorySelected = val
    })
  }

  rateFilterbyId(rate:Rate) {
    this.resetRateForm();
    this.contentservice.getRatebyId(rate).subscribe(val => {
      this.rateSelected = val
    })
  }

  typeFilterbyId(type:Type) {
    this.resetTypeForm();
    this.contentservice.getTypebyId(type).subscribe(val => {
      this.typeSelected = val
    })
  }

  sourceFilterbyId(source:Source) {
    this.resetSourceForm();
    this.contentservice.getSourcebyId(source).subscribe(val => {
      this.sourceSelected = val
    })
  }

  onDeleteSectionClick(content:ContentSection) {
    if(confirm("Are you sure want to delete this content?")) {
      this.contentservice.deleteSection(content);
    }
  }

  onDeletePictureClick(picture:PictureTechnique) {
    if(confirm("Are you sure want to delete this picture?")) {
      this.contentservice.deletePicture(picture);
    }
  }

  onDeleteCategoryClick(category:Section) {
    if(confirm("Are you sure want to delete this category?")) {
      this.contentservice.deleteCategory(category);
    }
  }

  testInfo(PicId:string) {
    // console.log("value XX",PicId );
  }

  ngOnDestroy() {
    this.successSub.unsubscribe()
  }

}
