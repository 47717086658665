import { Observable, BehaviorSubject, pipe } from 'rxjs';
import { ShoppingCart } from './../models/shopping-cart.model';
import { Product } from './../models/product.model';
import { ShoppingCartItem } from './../models/shopping-cart-item.model';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument, } from '@angular/fire/firestore';
import { AngularFireDatabase, AngularFireList } from '@angular/fire/database';
import { AngularFireStorage } from '@angular/fire/storage';
import { AuthService } from './../services/auth.service';
import { Injectable } from '@angular/core';
import { map, take, switchMap, subscribeOn} from 'rxjs/operators';
import { ProductQuantityComponent } from 'src/app/product-quantity/product-quantity.component';

@Injectable({
  providedIn: 'root'
})
export class ShoppingCartService {

  createShoppingCartIdSuccess = new BehaviorSubject<boolean>(false);
  item$: Observable<ShoppingCartItem[]>;
  item2$: AngularFirestoreDocument<ShoppingCartItem>;

  itemsCount = new BehaviorSubject<number>(0);
  itemsCount$ = this.itemsCount.asObservable();

  constructor(private db: AngularFireDatabase, private firestore: AngularFirestore , private as : AuthService) { }

 private async getOrCreateCartId(): Promise<string> {
   console.log("visiting getOrCreateCartId")
    let cartId = localStorage.getItem('cartId');
    if ((cartId)&&(cartId !== '')) return cartId;
    const id =  new Date().getTime()

    this.create(id);
    localStorage.setItem('cartId', String(id));
    return String(id);
  }

  getOrCreateCartIdtoGetCart() {
    console.log("visiting getOrCreateCartIdtoGetCart")
    let cartId = localStorage.getItem('cartId');
    if ((cartId)&&(cartId !== '')) return cartId;
    const id =  new Date().getTime()

    this.create(id);
    localStorage.setItem('cartId', String(id));
    return String(id);
  }

  create(id:number) {
    console.log("create shopping id",id);
    const picRef: AngularFirestoreDocument<any> = this.firestore.collection('Shopping-Carts').doc(String(id))
              picRef.set ({
                id:String(id),
                dateCreated: id,
              }).then(() => {
                        console.log("Cart created succesfully - service")
                        this.createShoppingCartIdSuccess.next(true)
                      }).catch(err => {
                        console.log("Failed to create Product")
                      });
                    }

   getCart() {
    let cartId =  "";
     cartId =this.getOrCreateCartIdtoGetCart()
     return this.firestore.collection(`Shopping-Carts`).doc(String(cartId)).collection<ShoppingCart>(`items`).snapshotChanges()
  }


  getItem(cartId: string, productId: string) {
      return this.firestore.collection<ShoppingCartItem>(`/Shopping-Carts`).doc(String(cartId)).collection('/items/').doc(String(productId)).valueChanges();
  }

  updateItem(product: Product, change: number) {
    let cartId =  "";
    this.getOrCreateCartId().then(res => {
      cartId = res;
      console.log(" shopping-card it for updateData",cartId);
        this.item2$ = this.firestore.collection<ShoppingCartItem>(`Shopping-Carts`).doc(String(cartId)).collection('items').doc(String(product.id))
        this.item2$.get().toPromise().then((docSnapshot) => {
          if(docSnapshot.exists) {
            if ((docSnapshot.data().quantity === 1)&&(change === -1)) {
              this.item2$.delete().then(() => {
                console.log("item delete from shopping cart succesfully")
                return
              }).catch(err => {
                console.log("Failed to remove item of Shopping Cart",err)
              });
            }
           this.item2$.update({
            quantity: docSnapshot.data().quantity+change,
            totalPrice: docSnapshot.data().price*(docSnapshot.data().quantity+change),
          }).then(() => {
            console.log("Shopping Cart Item updated succesfully")
            console.log("QUANTITY COUNT item shopping cart",docSnapshot.data().quantity)
            // if (docSnapshot.data().quantity === 0) {
            //   this.item2$.delete().then(() => {
            //     console.log("item delete from shopping cart succesfully")
            //   }).catch(err => {
            //     console.log("Failed to remove item of Shopping Cart",err)
            //   });
            // }
            return;
          }).catch(err => {
            console.log("Failed to update Shopping Cart Item")
            return;
          });
          } else {
            console.log("SET item shopping cart",product)
            this.item2$.set({
              id: product.id,
              title: product.title,
              price: product.price,
              category: product.category,
              imageUrl: product.imageUrl,
              description: product.description,
              quantity: 1,
              totalPrice: product.price,
            }
            ).then(() => {
              console.log("Shopping Cart Item added succesfully")
            }).catch(err => {
              console.log("Failed to set Shopping Cart Item",err)
            });
          }
        }

        ).catch(err => {
          console.log("Failed to Add Item Shopping Cart")
        });

  });
}

  addToCart(product: Product)
  {
    this.updateItem(product, 1);
  }

  removeFromCart(product: Product) {
    this.updateItem(product, -1);
  }

  clearCart() {
    console.log("clear cart step");
    let cartId =  "";
    this.getOrCreateCartId().then(res => {
        cartId = res;
        console.log("deleting", cartId)
        this.firestore.collection<ShoppingCartItem>(`Shopping-Carts`).doc(String(cartId)).collection('items')
        .get().toPromise().then(function(querySnapshot) {
          querySnapshot.forEach(function(doc) {
              doc.ref.delete();
              console.log('removing localstoragecart');
              localStorage.setItem('cartId','');
          });
          // this.items.next(0);
      }).catch(err => {
            console.log("Failed to remove items from shopping cart Cart",err)
            return;
          });;
    }).catch(err => {
          console.log("Failed to clean Shopping Cart")
        });
    }

}
