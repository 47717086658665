import { Component, OnInit, Input } from '@angular/core';
import { Product } from './../../shared/models/product.model';
import { ShoppingCart } from './../../shared/models/shopping-cart.model';
import { ShoppingCartService } from './../../shared/services/shopping-cart.service';

@Component({
  selector: 'app-product-card',
  templateUrl: './product-card.component.html',
  styleUrls: ['./product-card.component.scss']
})
export class ProductCardComponent implements OnInit {
  @Input('product') product: Product;
  @Input('show-actions') showActions = true;
  @Input('shopping-cart') shoppingCart: ShoppingCart;

  constructor(private cartService: ShoppingCartService) { }

  ngOnInit(): void {
    // console.log("shopping cart transfered to product-car",this.shoppingCart);
    // console.log("product transfered to product-car",this.product);
    // console.log("getQuantity transfered to product-car",this.shoppingCart.getQuantity(this.product));
  }
  addToCart() {
    this.cartService.addToCart(this.product);
  }

  getQuantity() {
    if (!this.shoppingCart) return 0;
    let item = this.shoppingCart.items[0].quantity
    return item ? item : 0
  }

}

