<div *ngIf="!mobile" class="sticky-top  -webkit-sticky-top">
  <nav class="navbar navbar-expand-xl navbar-dark bg-dark bgCustomDark">
    <div class="container">
    <!-- <a class="navbar-brand logoSubMark" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}" routerLink="/"> <img [src]="homePageTitles[0].UrlImage ? homePageTitles[0].UrlImage : '../../assets/images/logo.png'" class="img-fluid logo" alt="Kiay Chorizos y mas Logo"></a> -->
    <a class="navbar-brand logoSubMark" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}" routerLink="/"> SO Journey</a>
    <!-- <a [ngClass]="isAuthenticated ? authService.canDelete(userLoggedIn) ? 'navbar-left-authenticated-full' :'navbar-left-authenticated' : 'navbar-left'" class="nav-link" routerLink="/shopping-cart">
      <i class="material-icons shoppingStyle">shopping_cart</i>
      <span class="badge badge-warning badge-pill" *ngIf="cartShopping">
        {{ countingFromCall ?  cartShopping.totalItemsCount : countItems}}
      </span>
    </a> -->
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse tempclass" id="navbarNavAltMarkup">
      <div class="navbar-nav ml-auto">
          <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
            <a class="nav-item nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}" routerLink="/">{{homePageTitles[0].ExternalWeb1}} <span class="sr-only">(current)</span></a>
          </li>
          <!-- <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
            <a class="nav-item nav-link" routerLinkActive="active" *ngIf= !isAuthenticated routerLink="projects">Projects <span class="sr-only">(current)</span></a>
          </li>
          <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
            <a class="nav-item nav-link" routerLinkActive="active" *ngIf= !isAuthenticated routerLink="inspiration">Inspiration <span class="sr-only">(current)</span></a>
          </li>
          <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
            <a class="nav-item nav-link" routerLinkActive="active" routerLink="channels">Channels <span class="sr-only">(current)</span></a>
            <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
              <a class="nav-item nav-link" routerLinkActive="active" routerLink="videos"><i class="fa fa-video-camera"></i>&nbsp;Videos <span class="sr-only">(current)</span></a>
          <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
            <a class="nav-item nav-link" routerLinkActive="active" *ngIf= !isAuthenticated routerLink="bio">Biography <span class="sr-only">(current)</span></a>
          </li>
          <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
            <a class="nav-item nav-link" routerLinkActive="active" *ngIf= !isAuthenticated routerLink="contact">Contact <span class="sr-only">(current)</span></a>
          </li> -->
          <!-- <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
            <a class="nav-item nav-link" routerLinkActive="active" *ngIf= !isAuthenticated routerLink="catalog">{{homePageTitles[0].SubRoom}} <span class="sr-only">(current)</span></a>
          </li>
          <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
            <a class="nav-item nav-link" routerLinkActive="active" *ngIf= !isAuthenticated routerLink="menu">{{homePageTitles[0].ExternalWeb2}} <span class="sr-only">(current)</span></a>
          </li> -->
          <!-- <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
            <a class="nav-item nav-link" routerLinkActive="active" *ngIf= !isAuthenticated routerLink="tracking">{{homePageTitles[0].ExternalWeb3}} <span class="sr-only">(current)</span></a>
          </li> -->
          <!-- <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
            <a class="nav-item nav-link" routerLinkActive="active" routerLink="menu">Menu<span class="sr-only">(current)</span></a>
          </li> -->
          <!-- <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
            <a class="nav-item nav-link" routerLinkActive="active" *ngIf="(!isAuthenticated)" routerLink="videos"><i class="fa fa-video-camera"></i>&nbsp;{{homePageTitles[0].UrlVideo}} <span class="sr-only">(current)</span></a>
          </li> -->
          <ul class="navbar-nav">
            <li class="nav-item dropdown mr-3">
              <a *ngIf="language == 'Spanish'" href="#" class="nav-link dropdown-toggle" data-toggle="dropdown">
                <!-- <img *ngIf="language == 'English'" src='../../assets/images/flagUS.png' alt="Avatar" class="avatar">
                <img *ngIf="language == 'Spanish'" src='../../assets/images/flagSpain.png' alt="Avatar" class="avatar"> -->
                Videos & más
              </a>
              <a *ngIf="language == 'English'" href="#" class="nav-link dropdown-toggle" data-toggle="dropdown">
                <!-- <img *ngIf="language == 'English'" src='../../assets/images/flagUS.png' alt="Avatar" class="avatar">
                <img *ngIf="language == 'Spanish'" src='../../assets/images/flagSpain.png' alt="Avatar" class="avatar"> -->
                Videos & Research
              </a>
              <div class="dropdown-menu backgroundDropDown">
                <a routerLinkActive="active" *ngIf="(!isAuthenticated)" routerLink="videos" class="dropdown-item backgroundDropDown" data-toggle="collapse" data-target=".navbar-collapse.show">
                   <div *ngIf="language == 'English'">Videos by Category</div>
                   <div *ngIf="language == 'Spanish'">Videos por Categoría</div>
                </a>
                <a routerLinkActive="active" *ngIf="(!isAuthenticated)" routerLink="channels" class="dropdown-item backgroundDropDown" data-toggle="collapse" data-target=".navbar-collapse.show">
                  <div *ngIf="language == 'English'">Channels</div>
                   <div *ngIf="language == 'Spanish'">Canales</div>
                </a>
                <a routerLinkActive="active" *ngIf="(!isAuthenticated)" routerLink="research" class="dropdown-item backgroundDropDown" data-toggle="collapse" data-target=".navbar-collapse.show">
                  <div *ngIf="language == 'English'">Research</div>
                   <div *ngIf="language == 'Spanish'">Investigación</div>
                </a>
                <a routerLinkActive="active" *ngIf="(!isAuthenticated)" routerLink="myvideos" class="dropdown-item backgroundDropDown" data-toggle="collapse" data-target=".navbar-collapse.show">
                  <div *ngIf="language == 'English'">Our Channel</div>
                   <div *ngIf="language == 'Spanish'">Nuestro Canal</div>
                </a>
              </div>
            </li>
          </ul>
          <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
            <a class="nav-item nav-link" routerLinkActive="active" *ngIf="(!isAuthenticated)" routerLink="contact">{{homePageTitles[0].Time}}<span class="sr-only">(current)</span></a>
          </li>
          <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
            <a class="nav-item nav-link" routerLinkActive="active" *ngIf="(!isAuthenticated)" routerLink="courses">{{homePageTitles[0].Title}}<span class="sr-only">(current)</span></a>
          </li>
          <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
            <a class="nav-item nav-link" routerLinkActive="active" *ngIf="(authService.canDelete(userLoggedIn))&&(isAuthenticated)" routerLink="admin/products/new">
              <div *ngIf="language == 'English'">Store</div>
              <div *ngIf="language == 'Spanish'">Tienda</div>
              <span class="sr-only">(current)</span></a>
        </li>
        <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
          <a class="nav-item nav-link" routerLinkActive="active" *ngIf="(authService.canDelete(userLoggedIn))&&(isAuthenticated)" routerLink="admin">Admin<span class="sr-only">(current)</span></a>
      </li>
          <!-- <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
            <a class="nav-item nav-link" routerLinkActive="active" *ngIf="(authService.canDelete(userLoggedIn))&&(isAuthenticated)" routerLink="admin/orders">Seguimiento<span class="sr-only">(current)</span></a>
        </li>
        <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
          <a class="nav-item nav-link" routerLinkActive="active" *ngIf="((authService.canDelete(userLoggedIn))&&(isAuthenticated) || (authService.isOperatorKitchen(userLoggedIn))&&(isAuthenticated))" routerLink="admin/steps">Preparación<span class="sr-only">(current)</span></a>
      </li>
      <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
        <a class="nav-item nav-link" routerLinkActive="active" *ngIf="((authService.canDelete(userLoggedIn))&&(isAuthenticated) || (authService.isOperatorDelivery(userLoggedIn))&&(isAuthenticated))" routerLink="admin/deliverysteps">Entrega<span class="sr-only">(current)</span></a>
    </li> -->
        <!-- <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
          <a class="nav-item nav-link" routerLinkActive="active" *ngIf="(authService.canDelete(userLoggedIn))&&(isAuthenticated)" routerLink="admin">Admin <span class="sr-only">(current)</span></a>
      </li> -->
        <!-- <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
          <a class="nav-item nav-link" routerLinkActive="active" *ngIf= !isAuthenticated routerLink="sign-in"><i class="fas fa-user fa-1x pr-3"></i>{{homePageTitles[0].Content}} <span class="sr-only">(current)</span></a>
      </li> -->
      <ul class="navbar-nav">
        <li class="nav-item dropdown mr-3">
          <a href="#" class="nav-link dropdown-toggle" data-toggle="dropdown">
            <img *ngIf="language == 'English'" src='../../assets/images/flagUS.png' alt="Avatar" class="avatar">
            <img *ngIf="language == 'Spanish'" src='../../assets/images/flagSpain.png' alt="Avatar" class="avatar">
          </a>
          <div class="dropdown-menu backgroundDropDown">
            <a (click)="changeLanguage('English')" class="dropdown-item">
              <img src='../../assets/images/flagUS.png' class="avatarSmall"> English
            </a>
            <a (click)="changeLanguage('Spanish')" class="dropdown-item">
              <img src='../../assets/images/flagSpain.png' class="avatarSmall"> Español
            </a>
          </div>
        </li>
      </ul>
        <ul class="navbar-nav">
          <li *ngIf= isAuthenticated class="nav-item dropdown mr-3">
            <a href="#" class="nav-link dropdown-toggle" data-toggle="dropdown">
              <i class="fa fa-user"></i> <span class="pl-2">{{displayName}}</span>
            </a>
            <div class="dropdown-menu">
              <a href="profile.html" class="dropdown-item">
                <i class="fa fa-user-circle"></i> Profile
              </a>
              <a href="settings.html" class="dropdown-item">
                <i class="fa fa-cog"></i> Settings
              </a>
            </div>
          </li>
        </ul>
        <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
          <a class="nav-item nav-link" *ngIf= isAuthenticated routerLink="/" (click)="onLogout()">
            <div *ngIf="language == 'English'">Logout</div>
            <div *ngIf="language == 'Spanish'">Salir</div>
            <span class="sr-only">(current)</span></a>
      </li>
      </div>
    </div>
  </div>
  </nav>
  </div>
