import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Shipping } from './../shared/models/shipping.model';

@Component({
  selector: 'app-delivery',
  templateUrl: './delivery.component.html',
  styleUrls: ['./delivery.component.scss']
})
export class DeliveryComponent implements OnInit, AfterViewInit {
  shipping:Shipping = {};

  constructor() { }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
  }

}
