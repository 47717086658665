import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument, } from '@angular/fire/firestore';
import { AngularFireDatabase, AngularFireList } from '@angular/fire/database';
import { AngularFireStorage } from '@angular/fire/storage';
import { Observable, BehaviorSubject } from 'rxjs';
import { Product } from './../../shared/models/product.model';
import { map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ProductService {
  feeds: AngularFireList<any[]>;
  product$: Observable<Product[]>;
  addProductSuccess = new BehaviorSubject<boolean>(false);
  updateProductSuccess = new BehaviorSubject<boolean>(false);
  private productCollection: AngularFirestoreCollection<Product>;

  itemsCount = new BehaviorSubject<number>(0);
  itemsCount$ = this.itemsCount.asObservable();

  constructor(private firestore: AngularFirestore, private db: AngularFireDatabase, private storage : AngularFireStorage) {}

  getAll()
    {
      return this.firestore.collection<Product>('Products').snapshotChanges();
    }

    addProduct2(Name : string , Description : string ,Price : Number , Category: string, image : File)
    {
       let ref =   this.storage.ref('ProductsImages/' + image.name)
       ref.put(image).then ( () =>
       {
          ref.getDownloadURL().subscribe(ProductPath =>
            {
               this.firestore.collection('Products').add(
                 {
                   Name,
                   Description,
                   Price,
                   Category,
                   ProductPath

                 }
               ).then(() => {
                console.log("Product created succesfully")
                this.addProductSuccess.next(true)
              }).catch(err => {
                console.log("Failed to create Product")
              });
            })

       }

       )

    }

    addProduct(id: number, name : string , description : string ,price : Number , category: string, image : File)
    {
       let ref =   this.storage.ref('ProductsImages/' + image.name)
       ref.put(image).then ( () =>
       {
          ref.getDownloadURL().subscribe(ProductPath =>
            {
              const picRef: AngularFirestoreDocument<any> = this.firestore.collection('Products').doc(String(id))
              picRef.set ({
                id:String(id),
                title: name,
                description: description,
                price: price,
                category: category,
                imageUrl: ProductPath,
              }).then(() => {
                        console.log("Product created succesfully - Add product")
                        this.addProductSuccess.next(true)
                      }).catch(err => {
                        console.log("Failed to create Product")
                      });
                    })
       }

       )

    }

    updateProduct(id: number, name : string , description : string ,price : Number , category: string, image : File) {
      let ref =   this.storage.ref('ProductsImages/' + image.name)
       ref.put(image).then ( () =>
       {
          ref.getDownloadURL().subscribe(ProductPath =>
            {
              const picRef: AngularFirestoreDocument<any> = this.firestore.collection('Products').doc(String(id))
              picRef.update ({
                id: String(id),
                title: name,
                description: description,
                price: price,
                category: category,
                imageUrl: ProductPath,
              }).then(() => {
                        console.log("Product updated succesfully")
                        this.updateProductSuccess.next(true)
                      }).catch(err => {
                        console.log("Failed to update Product")
                      });
                    })
       }

       )


    }

   getProducts()
   {
      return this.firestore.collection('Products').snapshotChanges()
   }

   deleteProducts(id)
   {
      return this.firestore.doc(`Products/${id}`).delete()
   }

}
