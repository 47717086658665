<!-- <div class="container pt-4 pb-4">
  <div class="row">
    <div class="card mb-3">
        <div class="row no-gutters">
          <div class="col-md-4">

            <img src="{{thumbnail}}" alt="" class="img-fluid card-img-top">

          </div>
          <div class="col-md-8">
            <div class="card-body">
              <img src="../../assets/images/youtube_logo.png" style="height: 20px;width:auto" class="img-fluid card-img-top">
              <h5 class="card-header centerName fontOpenSans-RegularSmallDarkGra">Channel name : {{channelDetails.snippet.title}}</h5>
              <p class="fontOpenSans-RegularExtraSmallDarkGray pt-4">{{channelDetails.snippet.description}}</p>
              <p class="fontOpenSans-RegularExtraSmallDarkGray pt-4"><span class="fontOpenSans-RegularSmallDarkGray">ID :&nbsp;</span> {{channelDetails.id}}</p>
              <p class="fontOpenSans-RegularExtraSmallDarkGray pt-4"><span class="fontOpenSans-RegularSmallDarkGray">Subscribers :&nbsp;</span>{{channelDetails.statistics.subscriberCount}}</p>
              <p class="fontOpenSans-RegularExtraSmallDarkGray pt-4"><span class="fontOpenSans-RegularSmallDarkGray">Visits :&nbsp;</span> {{channelDetails.statistics.viewCount}}</p>
              <p class="fontOpenSans-RegularExtraSmallDarkGray pt-4"><span class="fontOpenSans-RegularSmallDarkGray">Videos :&nbsp;</span> {{channelDetails.statistics.videoCount}}</p>
            </div>
          </div>
        </div>
      </div>

  </div>
</div> -->


<div *ngIf="channelDetails" class="card">
  <div class="card-body">
      <p><img class=" img-fluid" src="{{thumbnail}}" alt="card image"></p>
      <div class="card-header">{{channelDetails.snippet.title}}</div>
      <div class="card-body">
              <h5 class="card-title">Photo source :{{ channel.PhotoInfo }}</h5>
              <!-- <h5 class="card-title">{{ channel.Time }}</h5> -->
          <div>
              <table>
                      <tr>
                              <th class="fontOpenSans-SemiboldSmallDarkGray">Channel Summary</th>
                            </tr>
                            <tr>
                              <td class="fontOpenSans-RegularExtraSmallDarkGray maxWidth">{{channelDetails.snippet.description}}</td>
                            </tr>
                      <tr>
                        <th class="fontOpenSans-SemiboldSmallDarkGray">Where do I use this channel?</th>
                      </tr>
                      <tr>
                        <!-- <td class="fontOpenSans-RegularExtraSmallDarkGray">{{ picture.ExternalWeb2 }}</td> -->
                      </tr>
                    </table>
                    <table>
                        <tr>
                          <th class="fontOpenSans-SemiboldSmallDarkGray">Key words to remind</th>
                        </tr>
                        <tr>
                          <!-- <td class="fontOpenSans-RegularExtraSmallDarkGray">{{ picture.ExternalWeb3 }}</td> -->
                        </tr>
                    </table>
                    <table>
                    </table>
          </div>
      </div>
  </div>
</div>
<div *ngIf="channelDetails" class="card p-3">
    <div class="row channelStyle">
        <img src="{{channel.UrlImage}}"  class="img-fluid card-img-top logoStyle">
  </div>
      <blockquote class="card-blockquote card-body fontOpenSans-SemiBoldSmallContent">
        <footer>
          <small class="fontOpenSans-SemiboldSmallDarkGray"> Subscribers :&nbsp; <span class="fontOpenSans-RegularExtraSmallDarkGray ">{{channelDetails.statistics.subscriberCount  | number}}</span></small><br>
          <small class="fontOpenSans-SemiboldSmallDarkGray"> Views :&nbsp; <span class="fontOpenSans-RegularExtraSmallDarkGray ">{{channelDetails.statistics.viewCount  | number}}</span></small><br>
          <small class="fontOpenSans-SemiboldSmallDarkGray"> Videos :&nbsp; <span class="fontOpenSans-RegularExtraSmallDarkGray ">{{channelDetails.statistics.videoCount  | number}}</span></small><br>
        </footer><br>
        <footer class='blockquote-footer linkStyle'>
            <div *ngIf="(authService.isFamily(userLoggedIn))">
              <small class="text-muted"> Mostrar videos de familia : &nbsp;<cite title="Source Title"><span><a routerLink="../videos" [queryParams]="{ channel: channelDetails.id }">Click here <span class="sr-only">(current)</span></a></span></cite></small><br>
            </div>
          <!-- <small class="text-muted"> Watch youtube channel videos : &nbsp;<cite title="Source Title"><span><a routerLink="../videos" [queryParams]="{ channel: channelDetails.id }">Click here <span class="sr-only">(current)</span></a></span></cite></small><br> -->
          <div *ngIf="!(authService.isFamily(userLoggedIn))">
              <small *ngIf="!mobile" class="fontOpenSans-SemiboldSmallDarkGray"> Watch youtube channel videos: &nbsp;<cite title="Source Title"><span class="fontOpenSans-RegularExtraSmallDarkGray "><a href="{{ channel.UrlVideo }}" target="_blank">Click here <span class="sr-only">(current)</span></a></span></cite></small><br>
              <small *ngIf="mobile" class="fontOpenSans-SemiboldSmallDarkGray"> Watch youtube channel videos: &nbsp;<cite title="Source Title"><span class="fontOpenSans-RegularExtraSmallDarkGray ">{{ channel.UrlVideo }} <span class="sr-only">(current)</span></span></cite></small><br>
        </div>
        </footer>
      </blockquote>
    </div>

