<div class="card">
  <div>
    <iframe width="100%" height="auto" [src]="linkVideoSecure"
    frameborder="0"allow="autoplay; ancrypted-media" allowFullScreen="allowFullScreen"></iframe>
  </div>
      <div class="card-body">
          <div class="card-header">{{ lab.Title }}</div>
          <div class="card-body">
                  <h5 class="card-title">{{ lab.Content }}</h5>
          </div>
      </div>
</div>

