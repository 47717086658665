import { Component, OnInit, ViewChild , ViewContainerRef,  ElementRef, TemplateRef} from '@angular/core';
import { Order } from './../../shared/models/order.model';
import { OrderService } from './../../shared/services/order.service';



@Component({
  selector: 'app-steps',
  templateUrl: './steps.component.html',
  styleUrls: ['./steps.component.scss']
})
export class StepsComponent implements OnInit {

  displayAlert = false;
  data: any = {};
  process_step1_start_preparation = [];
  process_step2_end_preparation = [];

  @ViewChild('modal_1') modal_1: TemplateRef<any>;
    @ViewChild('vc', {read: ViewContainerRef}) vc: ViewContainerRef;
    backdrop: any

  constructor(private orderService: OrderService) {}
  orders:Order[];

  ngOnInit(): void {
    this.orderService.getOrders().subscribe( data => {
      this.orders = data.map(e => {
        return {
          id: e.payload.doc.id,
          isEdit: false,
          items: e.payload.doc.data()['items'],
          datePlaced: e.payload.doc.data()['datePlaced'],
          process: e.payload.doc.data()['process'],
          shipInfo: e.payload.doc.data()['shipInfo'],
          userId: e.payload.doc.data()['userId'],
        };
      })
      this.orders.forEach((index)=> {if(index.process !== undefined){this.process_step1_start_preparation.push(index.process[1])}})
      this.orders.forEach((index)=> {if(index.process !== undefined){this.process_step2_end_preparation.push(index.process[2])}})
      })
    }

  openModalProduct(object)
  {
    this.displayAlert=true;
    this.data = {name:"Detalles Orden", id:object.id, object:object}
  }

  onHandleModal() {
    this.displayAlert = false;
  }

  updateProcess(step,order) {
    this.orderService.updateProcess(order.id,step,order);
  }
  }
