


import { Injectable, NgZone, EventEmitter } from '@angular/core';
import { AngularFireAuth } from "@angular/fire/auth";
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { Router } from "@angular/router";
import { BehaviorSubject } from 'rxjs';
import { User } from './../models/user.model';
import { UserProfile } from './../models/user-profile';
@Injectable({
  providedIn: 'root'
})
export class AuthService {

  user = new BehaviorSubject<User>(null);

  error = new BehaviorSubject<string>('');
  errorForgotPassword = new BehaviorSubject<string>('');
  message = new BehaviorSubject<string>(null);

  logged = new BehaviorSubject<Boolean>(false);

  userData: any; // Save logged in user data

  private userDocument: AngularFirestoreDocument<UserProfile>;

  // userProfile: UserProfile = {};
  userProfile: UserProfile = {
    email: "",
    displayName: "",
    language: "",
    photoUrl: "",
    age: "",
    role: {
      subscriber: true,
      kitchen: true,
      admin: true,
      editor: true
    }
  }

  statusChange: any = new EventEmitter<any>();
  newSubscription: any = new EventEmitter<any>();


  constructor(
    public afs: AngularFirestore,   // Inject Firestore service
    public afAuth: AngularFireAuth, // Inject Firebase auth service
    public router: Router,
    public ngZone: NgZone // NgZone service to remove outside scope warning
  ) {}

  set(userFromDatabase) {
    localStorage.setItem('user', JSON.stringify(userFromDatabase));
    this.statusChange.emit(userFromDatabase);
  }

  // Sign in with email/password
  SignIn(email, password) {
    console.log("method SIGNIN auth Service")
    return this.afAuth.signInWithEmailAndPassword(email, password)
      .then((result) => {
        if (result.user.emailVerified) {
          console.log("method SIGNIN auth Service emailVerified")
          if (JSON.parse(localStorage.getItem('profile')) ==null) {
            console.log('localstorege profile is NULL SignIn');
            this.getUserFromDatabase(result.user.uid).subscribe(data => {
              this.set(data);
              this.router.navigate(['']);
            })
          }

        } else {
          alert("Please review your email to verify your account. Thanks")
          this.SignOut();
        }
      })
      .catch((error) => {
        this.error.next('SignIn :'+error.message)
      })
  }

  // Sign up with email/password
  SignUp(userProfile:UserProfile, password) {
    console.log("method SIGNUP auth Service")
    return this.afAuth.createUserWithEmailAndPassword(userProfile.email, password)
      .then((result) => {
        /* Call the SendVerificaitonMail() function when new user sign
        up and returns promise */
        localStorage.setItem('profile', JSON.stringify(userProfile));
        this.SendVerificationMail();
        // this.SetUserData(result.user);
      }).catch((error) => {
        this.error.next('SignUp :'+error.message)
      })
  }

  // Send email verfificaiton when new user sign up
  // SendVerificationMail() {
  //   return this.afAuth.currentUser.sendEmailVerification()
  //   .then(() => {
  //     this.router.navigate(['verify-email-address']);
  //     // localStorage.setItem('user', null);
  //   })
  // }

  // Email verification when new user register
  // https://stackoverflow.com/questions/61214731/assistance-with-ts2570-error-property-sendemailverification-does-not-exist-on
  SendVerificationMail() {
    console.log("method SendVerificationMail auth Service")
    return this.afAuth.currentUser.then(u => u.sendEmailVerification())
    .then(() => {
      this.router.navigate(['verify-email-address']);
      // localStorage.setItem('user', null);
    })
  }

  // Reset Forggot password
  ForgotPassword(passwordResetEmail) {
    return this.afAuth.sendPasswordResetEmail(passwordResetEmail)
    .then(() => {
      this.message.next('Password reset email sent, check your inbox.');
      // window.alert('Password reset email sent, check your inbox.');
    }).catch((error) => {
      this.errorForgotPassword.next('ForgotPassword :'+error.message)
    })
  }

  // Returns true when user is looged in and email is verified
  get isLoggedIn(): boolean {
    const user = JSON.parse(localStorage.getItem('user'));
    return (user !== null && user.emailVerified !== false) ? true : false;
  }

  // Sign in with Google
  // GoogleAuth() {
  //   return this.AuthLogin(new auth.GoogleAuthProvider());
  // }

  // Reset server message
  ResetMessage() {
    this.message.next(null);
  }

  // Reset server error
  ResetForgotPasswordError() {
    this.errorForgotPassword.next(null);
  }

  // Auth logic to run auth providers
  AuthLogin(provider) {
    console.log("method AuthLogin auth Service")
    return this.afAuth.signInWithPopup(provider)
    .then((result) => {
       this.ngZone.run(() => {
          this.router.navigate(['']);
        })
      this.SetUserData(result.user);
    }).catch((error) => {
      this.error.next('AuthLogin :'+error.message)
    })
  }

  /* Setting up user data when sign in with username/password,
  sign up with username/password and sign in with social auth
  provider in Firestore database using AngularFirestore + AngularFirestoreDocument service */
  SetUserData2(user) {
    console.log("method SetUserData2 auth Service")
    const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${user.uid}`);
    const userData: UserProfile= {
      uid: user.uid,
      email: user.email,
      displayName: user.displayName,
      language: user.language,
      photoUrl: user.photoUrl,
      emailVerified: user.emailVerified,
      role: user.role,
      age: user.age,
      createdAt: user.createdAt
    }
    return userRef.set(userData, {
      merge: true,
    })
  }


  SetUserData(user) {
    console.log("method SetUserData auth Service")
    const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${user.uid}`);
    const userData: UserProfile= {
      uid: user.uid,
      email: user.email,
      displayName: user.displayName,
      language: user.language,
      photoUrl: user.photoUrl,
      emailVerified: user.emailVerified,
      role: user.role,
      age: user.age,
      createdAt: user.createdAt
    }
    userRef.set(userData, { merge: true }).then(function() {
    // userRef.set({userData}).then(function() {
      localStorage.setItem('profile', null);
    }).catch(err => {
      this.error.next('SetUserData :'+err.message);
    });
  }


  // Sign out
  SignOut() {
    console.log("method SignOut auth Service")
    return this.afAuth.signOut().then(() => {
      this.ngZone.run(() => {
        console.log("signed out OK");
        localStorage.removeItem('user');
         this.router.navigate(['/']);
      });
      // localStorage.removeItem('user');
      // this.router.navigate(['/']);
    }).catch(err => {
      console.log('Logout Error',err);
    })
  }

  //Get User Info
  getUserInfobyId(userUid:String) {
    this.userDocument = this.afs.doc(`users/${userUid}`)
    return this.userDocument.valueChanges()
  }

  //Get User Info
  getUserFromDatabase(userUid:String) {
    this.userDocument = this.afs.doc(`users/${userUid}`)
    return this.userDocument.valueChanges()
  }


  ///// Role-based Authorization //////

  canRead(user: UserProfile): boolean {
    const allowed = ['admin', 'editor', 'kitchen', 'delivery', 'subscriber']
    return this.checkAuthorization(user, allowed)
  }

  canEdit(user: UserProfile): boolean {
    const allowed = ['admin', 'editor', 'kitchen', 'delivery']
    return this.checkAuthorization(user, allowed)
  }

  canDelete(user: UserProfile): boolean {
    const allowed = ['admin', 'editor']
    return this.checkAuthorization(user, allowed)
  }

  isOperatorKitchen(user: UserProfile): boolean {
    const allowed = ['kitchen']
    return this.checkAuthorization(user, allowed)
  }

  isOperatorDelivery(user: UserProfile): boolean {
    const allowed = ['delivery']
    return this.checkAuthorization(user, allowed)
  }

  isFamily(user: UserProfile): boolean {
    const allowed = ['family']
    return this.checkAuthorization(user, allowed)
  }



  // determines if user has matching role
  private checkAuthorization(user: UserProfile, allowedRoles: string[]): boolean {
    if (!user) return false
    for (const role of allowedRoles) {
      if ( user.role[role] ) {
        return true
      }
    }
    return false
  }

}
