import { Component, OnInit, Input, ɵConsole, OnDestroy, Renderer2 } from '@angular/core';
import { UploadInfoService } from './../../shared/services/upload-info.service';
import { SubCategory } from './../../shared/models/subcategory.model';

import { ActivatedRoute } from '@angular/router';
import { AuthService } from '../../shared/services/auth.service'
import { UserProfile } from '../../shared/models/user-profile';
import { User } from './../../shared/models/user.model';
import Utility  from './../../shared/helpers/utility';
import { BridgeService } from './../../shared/services/bridge.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-channel-filter',
  templateUrl: './channel-filter.component.html',
  styleUrls: ['./channel-filter.component.scss']
})
export class ChannelFilterComponent implements OnInit, OnDestroy {
  categories$;
  @Input('category') category;
  @Input('isFamily') isFamily: boolean;

  subcategories: SubCategory[];

  families: SubCategory[];
  generalChannels: SubCategory[];
  generalChannelsSP: SubCategory[];
  generalChannelsTemp: SubCategory[];

  categorySelected: String;
  categorySelectedSP: String;
  language = '';
  mobile: boolean = false;
  private successSub!: Subscription

  constructor(private contentservice: UploadInfoService, private renderer: Renderer2, public bridgeService: BridgeService) { }

  ngOnInit() {
    this.language = Utility.checkLanguage();
    this.generalChannelsSP = [];
    this.generalChannelsTemp = [];
    this.generalChannels = [];
    this.contentservice.getSubCategories().subscribe(val => {
      this.subcategories = val
      console.log('checking this.subcategories',this.subcategories);
      // this.generalChannelsTemp = this.subcategories.filter(p => p.Type == 'channels')
      this.generalChannelsSP = this.subcategories.filter(p => p.Type == 'channels-SP')
      this.generalChannels = this.subcategories.filter(p => p.Type == 'channels')
      console.log('channels sp',this.generalChannelsSP)
      console.log('channels english',this.generalChannels)
      // for (let i = 0 ; i< this.generalChannelsTemp.length; i++) {
      //   if(this.generalChannelsTemp[i].Title.indexOf('SP') !== -1) {
      //     this.generalChannelsSP.push(this.generalChannelsTemp[i]);
      //   } else {
      //     this.generalChannels.push(this.generalChannelsTemp[i]);
      //   }
      // }
      // console.log('checking channels this.generalChannelsTemp',this.generalChannelsTemp);

      // this.generalChannels = this.subcategories.filter(p => p.Type == 'channels')
      this.families = this.subcategories.filter(p => p.Type == 'families')
      // console.log("generalChannels",this.generalChannels);
      // console.log("families",this.families);
    })

    this.successSub = this.bridgeService.mobileSuccess.subscribe(mobile => {
      if (mobile) {
        this.mobile = true
      }
      else {
        this.mobile = false
      }
    }
    )
    console.log('mobile',this.mobile)

  }

  randomFunction () : String  {
     return Math.random().toString()
  }

  setFooterBotom () {
    const parent: HTMLElement = document.getElementById('main-footer');
		this.renderer.removeStyle(parent, 'position');
		this.renderer.removeStyle(parent, 'bottom');
		this.renderer.removeStyle(parent, 'width');
  }

  setFooterDefault() {
    const parent: HTMLElement = document.getElementById('main-footer');
    this.renderer.setStyle(parent, 'position', 'absolute');
    this.renderer.setStyle(parent, 'bottom', '0px');
    this.renderer.setStyle(parent, 'width', '100%');
  }

  ngAfterViewInit() {
    this.setFooterBotom();
  }

  ngOnDestroy(): void {
    this.successSub.unsubscribe();
  }
}

