import { Component, OnInit, ViewChild , ViewContainerRef,  ElementRef, TemplateRef} from '@angular/core';
import { Order } from './../../shared/models/order.model';
import { OrderService } from './../../shared/services/order.service';

@Component({
  selector: 'app-admin-orders',
  templateUrl: './admin-orders.component.html',
  styleUrls: ['./admin-orders.component.scss']
})
export class AdminOrdersComponent implements OnInit {

  displayAlert = false;
  data: any = {};

  @ViewChild('image') image : ElementRef
  @ViewChild('imageProduct') imageProduct : ElementRef


  @ViewChild('modal_1') modal_1: TemplateRef<any>;
    @ViewChild('vc', {read: ViewContainerRef}) vc: ViewContainerRef;
    backdrop: any

  constructor(private orderService: OrderService) { }
  orders:Order[];

  ngOnInit(): void {
    this.orderService.getOrders().subscribe( data => {
      this.orders = data.map(e => {
        return {
          id: e.payload.doc.id,
          isEdit: false,
          items: e.payload.doc.data()['items'],
          datePlaced: e.payload.doc.data()['datePlaced'],
          process: e.payload.doc.data()['process'],
          shipInfo: e.payload.doc.data()['shipInfo'],
          userId: e.payload.doc.data()['userId'],
        };
      })
      })
    }

  openModalProduct(object)
  {
    this.displayAlert=true;
    this.data = {name:"Detalles Orden", id:object.id, object:object}
  }

  onHandleModal() {
    this.displayAlert = false;
  }
  }
