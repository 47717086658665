import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { ProductService } from './../../shared/services/product.service';
import { CategoryService } from './../../shared/services/category.service';
import { take } from 'rxjs/operators';
import { Product } from './../../shared/models/product.model';
import { NgForm } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-product-form',
  templateUrl: './product-form.component.html',
  styleUrls: ['./product-form.component.scss']
})
export class ProductFormComponent implements OnInit, OnDestroy {


  ProductsArr : Array<any> = [];
  CategoriesArr : Array<any> =[];
  displayAlert = false;
  dataId: any = {};

  @ViewChild('image') image : ElementRef
  @ViewChild('imageProduct') imageProduct : ElementRef

  constructor( private route: ActivatedRoute, private productService: ProductService, private categoryService: CategoryService ) { }

  private successAddProductSub: Subscription;
  private successAddCategorySub: Subscription;
  ngOnInit() {

      this.productService.getProducts().subscribe(data => {
        this.ProductsArr = data.map(e => {
          return {
            id: e.payload.doc.id,
            isEdit: false,
            title: e.payload.doc.data()['title'],
            category: e.payload.doc.data()['category'],
            description: e.payload.doc.data()['description'],
            price: e.payload.doc.data()['price'],
            image: e.payload.doc.data()['imageUrl'],
          };
        })
        // console.log(this.ProductsArr);

      });

      this.categoryService.getCategories().subscribe(data => {
        this.CategoriesArr = data.map(e => {
          return {
            id: e.payload.doc.id,
            isEdit: false,
            title: e.payload.doc.data()['title'],
            category: e.payload.doc.data()['category'],
            description: e.payload.doc.data()['description'],
            image: e.payload.doc.data()['imageUrl'],
          };
        })
        // console.log(this.CategoriesArr);

      });
  }

  addNewProduct(f : NgForm )
  {
     let name = f.value.name,
        description = f.value.descriptionProduct,
         price = f.value.price,
         category = f.value.category,
         image = (this.imageProduct.nativeElement as HTMLInputElement).files[0]
         const id = new Date().getTime();
         this.productService.addProduct( id, name , description, price , category, image)
         this.successAddProductSub = this.productService.addProductSuccess.subscribe((success) => {
          if (success) {
            f.resetForm();
          }
        });

  }

  openModalProduct(object)
  {
    this.displayAlert=true;
    this.dataId = {name:"Product", id:object.id, object:object}
  }

  deleteProduct(index)
  {
    this.productService.deleteProducts(this.ProductsArr[index].id)

  }


  addNewCategory(f : NgForm )
  {
     let name = f.value.nameCategory,
         description = f.value.description,
         image = (this.image.nativeElement as HTMLInputElement).files[0]
         const id = new Date().getTime();
         this.categoryService.addCategory( id, name , description , image)
         this.successAddCategorySub = this.categoryService.addCategorySuccess.subscribe((success) => {
          if (success) {
            f.resetForm();
          }
        });



  }

  openModalCategory(object)
  {
    this.displayAlert=true;
    this.dataId = {name:"Category", id:object.id, object:object}
  }

  deleteCategory(index)
  {
    this.categoryService.deleteCategory(this.CategoriesArr[index].id)

  }

  getCategoryName(id:string){
    return this.CategoriesArr.filter(item => item.id === id) ? this.CategoriesArr.filter(item => item.id === id)[0] : null

  }

  onHandleModal() {
    this.displayAlert = false;
  }

  ngOnDestroy() {
    // this.successAddProductSub.unsubscribe();
    // this.successAddCategorySub.unsubscribe();

  }
}
