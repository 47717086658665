import { Component, OnInit } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-order-success',
  templateUrl: './order-success.component.html',
  styleUrls: ['./order-success.component.scss']
})
export class OrderSuccessComponent implements OnInit {
  placeOrderSuccess = new BehaviorSubject<string>(null);

  constructor() { }

  ngOnInit(): void {
    this.placeOrderSuccess.next(null)
  }

}
