import { Component, OnInit, Input, ɵConsole } from '@angular/core';
import { UploadInfoService } from './../../shared/services/upload-info.service';
import { SubCategory } from './../../shared/models/subcategory.model';

import { ActivatedRoute } from '@angular/router';
import { AuthService } from '../../shared/services/auth.service'
import { UserProfile } from '../../shared/models/user-profile';
import { User } from './../../shared/models/user.model';
import Utility  from './../../shared/helpers/utility';


@Component({
  selector: 'app-myvideo-filter',
  templateUrl: './myvideo-filter.component.html',
  styleUrls: ['./myvideo-filter.component.scss']
})
export class MyvideoFilterComponent implements OnInit {
  categories$;
  @Input('category') category;
  // @Input('isFamily') isFamily: boolean;

  subcategories: SubCategory[];

  nonFamilyvideosType: SubCategory[];
  familyvideosType: SubCategory[];
  generalChannels: SubCategory[];

  categorySelected: String;

  isFamily: boolean;
  userLoggedIn: UserProfile;
  userData: User;
  language = '';

  constructor(private contentservice: UploadInfoService, private route: ActivatedRoute, public authService: AuthService) { }

  ngOnInit() {
    this.language = Utility.checkLanguage();
    this.contentservice.getSubCategories().subscribe(val => {
      this.subcategories = val
      this.familyvideosType = this.subcategories.filter(p => p.Type == 'families')
      this.nonFamilyvideosType = this.subcategories.filter(p => p.Type == 'videos')
      // console.log("familyvideosType",this.familyvideosType);
      // console.log("nonFamilyvideosType",this.nonFamilyvideosType);
    })

    if (JSON.parse(localStorage.getItem('user'))) {
      this.userData = JSON.parse(localStorage.getItem('user'));
      if (this.userData) {
        this.getUserbyId(this.userData.uid)
      }
    }

  }

  getUserbyId(userUID:String) {
    this.authService.getUserInfobyId(userUID).subscribe(user => {
      this.userLoggedIn = user
      this.isFamily = this.authService.isFamily(this.userLoggedIn)
    })
  }

}

