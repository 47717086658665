<!-- PAGE HEADER -->
<!-- <header id="page-header">
  <div class="container setDefaultSpace pd-2">
    <div class="row">
      <div class="col-md-6 m-auto text-center">
        <h1 class="fontOpenSans-RegularSmallDarkGray">Videos</h1>
        <p class="fontOpenSans-RegularExtraSmallDarkGray">Selection of videos that I strongly recommended for different reasons like inspiration, training, reference, culture, etc.</p>
        <div>
            <app-video-filter [category]="category"></app-video-filter>
        </div>
      </div>
    </div>
  </div>
</header> -->

<!-- PAGE HEADER -->
<div *ngIf="((authService.isFamily(userLoggedIn))&&loadWithoutChannel)">

  <!-- PAGE HEADER -->
<header id="page-header">
    <div class="container setDefaultSpace pd-2">
      <div class="row">
        <div class="col-md-6 m-auto text-center">
          <h1 class="fontOpenSans-RegularSmallDarkGray">Videos</h1>
          <p class="fontOpenSans-RegularExtraSmallDarkGray">Selecciona la familia de la cual quieres ver los momentos memorables registrados en video.</p>
          <div>
              <app-myvideo-filter [category]="category"></app-myvideo-filter>
          </div>
        </div>
      </div>
    </div>
  </header>

<!-- <header id="page-header">
<div class="container pt-4 pb-4">
  <div class="row">
    <div class="card mb-3">
        <div class="row no-gutters">
          <div class="col-md-4">
            <img src="{{thumbnail}}" alt="" class="img-fluid card-img-top">

          </div>
          <div class="col-md-8">
            <div class="card-body">
              <img src="../../assets/images/youtube_logo.png" style="height: 20px;width:auto" class="img-fluid card-img-top">
              <h5 class="card-header centerName fontOpenSans-RegularSmallDarkGra">Channel : {{channelDetails.snippet.title}}</h5>
              <p class="fontOpenSans-RegularExtraSmallDarkGray pt-4">{{channelDetails.snippet.description}}</p>
              <p class="fontOpenSans-RegularExtraSmallDarkGray pt-4"><span class="fontOpenSans-RegularSmallDarkGray">Subscribers :&nbsp;</span>{{channelDetails.statistics.subscriberCount  | number}}</p>
              <p class="fontOpenSans-RegularExtraSmallDarkGray pt-4"><span class="fontOpenSans-RegularSmallDarkGray">Views :&nbsp;</span> {{channelDetails.statistics.viewCount | number}}</p>
              <p class="fontOpenSans-RegularExtraSmallDarkGray pt-4"><span class="fontOpenSans-RegularSmallDarkGray">Videos :&nbsp;</span> {{channelDetails.statistics.videoCount | number}}</p>
            </div>
          </div>
        </div>
      </div>

  </div>
</div>
</header>

<div class="no-scrolling">
  <ngx-masonry class="positioning" [options]="masonryOptions" [useImagesLoaded]="true">
    <ngxMasonryItem *ngFor="let video of videos" class="masonry-item drop-shadow">
            <div class="card">
                <div>
                  <iframe width="100%" height="auto" [src]="video.cleanUrl | safeHtml"
                  frameborder="0"allow="autoplay; ancrypted-media" allowFullScreen="allowFullScreen"></iframe>
                </div>
                    <div class="card-body">
                        <div class="card-header">{{ video.snippet.title }}</div>
                        <div class="card-body">
                                <h5 class="card-title">{{ video.snippet.description }}</h5>
                        </div>
                    </div>
                </div>
    </ngxMasonryItem>
  </ngx-masonry>
  </div> -->

  <div class="no-scrolling">
      <ngx-masonry class="positioning" [options]="masonryOptions" [useImagesLoaded]="true">
        <ngxMasonryItem *ngFor="let p of filteredContentSection" class="masonry-item drop-shadow">
                    <app-myvideo-card [lab]="p"></app-myvideo-card>
        </ngxMasonryItem>
      </ngx-masonry>
  </div>

</div>


<!-- PAGE HEADER -->
<div *ngIf="((authService.isFamily(userLoggedIn))&&(!loadWithoutChannel))">

    <!-- PAGE HEADER -->

  <header id="page-header">
  <div class="container pt-4 pb-4">
    <div class="row">
      <div class="card mb-3">
          <div class="row no-gutters">
            <div class="col-md-4">
              <img src="{{thumbnail}}" alt="" class="img-fluid card-img-top">

            </div>
            <div class="col-md-8">
              <div class="card-body">
                <img src="../../assets/images/youtube_logo.png" style="height: 20px;width:auto" class="img-fluid card-img-top">
                <h5 class="card-header centerName fontOpenSans-RegularSmallDarkGra">Channel : {{channelDetails.snippet.title}}</h5>
                <p class="fontOpenSans-RegularExtraSmallDarkGray pt-4">{{channelDetails.snippet.description}}</p>
                <p class="fontOpenSans-RegularExtraSmallDarkGray pt-4"><span class="fontOpenSans-RegularSmallDarkGray">Subscribers :&nbsp;</span>{{channelDetails.statistics.subscriberCount  | number}}</p>
                <p class="fontOpenSans-RegularExtraSmallDarkGray pt-4"><span class="fontOpenSans-RegularSmallDarkGray">Views :&nbsp;</span> {{channelDetails.statistics.viewCount | number}}</p>
                <p class="fontOpenSans-RegularExtraSmallDarkGray pt-4"><span class="fontOpenSans-RegularSmallDarkGray">Videos :&nbsp;</span> {{channelDetails.statistics.videoCount | number}}</p>
              </div>
            </div>
          </div>
        </div>

    </div>
  </div>
  </header>

  <div class="no-scrolling">
    <ngx-masonry class="positioning" [options]="masonryOptions" [useImagesLoaded]="true">
      <ngxMasonryItem *ngFor="let video of videos" class="masonry-item drop-shadow">
              <div class="card">
                  <div>
                    <iframe width="100%" height="auto" [src]="video.cleanUrl | safeHtml"
                    frameborder="0"allow="autoplay; ancrypted-media" allowFullScreen="allowFullScreen"></iframe>
                  </div>
                      <div class="card-body">
                          <div class="card-header">{{ video.snippet.title }}</div>
                          <div class="card-body">
                                  <h5 class="card-title">{{ video.snippet.description }}</h5>
                          </div>
                      </div>
                  </div>
      </ngxMasonryItem>
    </ngx-masonry>
    </div>

  </div>


<div *ngIf="!(authService.isFamily(userLoggedIn))">

  <!-- PAGE HEADER -->
<header id="page-header">
    <div class="container setDefaultSpace pd-2">
      <div class="row">
        <div class="col-md-6 m-auto text-center">
          <h1 class="fontOpenSans-RegularSmallDarkGray">Videos</h1>
          <p class="fontOpenSans-RegularExtraSmallDarkGray">Some of the videos related with projects where Medscience Animations Studios In. has been contributed.</p>
          <div>
              <app-video-filter [category]="category"></app-video-filter>
          </div>
        </div>
      </div>
    </div>
  </header>

<div class="no-scrolling">
    <ngx-masonry class="positioning" [options]="masonryOptions" [useImagesLoaded]="true">
      <ngxMasonryItem *ngFor="let p of filteredContentSection" class="masonry-item drop-shadow">
                  <app-myvideo-card [lab]="p"></app-myvideo-card>
      </ngxMasonryItem>
    </ngx-masonry>
</div>
</div>

