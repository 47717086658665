<div class="container">
  <h1 class="setDefaultSpace">Orders</h1>

  <app-modal
        [order]="data"
        *ngIf="displayAlert"
        (close)="onHandleModal()"
      ></app-modal>
      <div class="row">
        <div class="col-12">
  <table class="table">
    <colgroup>
      <col class="white" />
      <col class="white" />
      <col class="white" />
      <col class="red" />
      <col class="grey" />
      <col class="grey" />
      <col class="white" />
    </colgroup>
    <thead>
      <tr>
        <th class="columnCenter"># de Orden</th>
        <th class="columnCenter">Customer</th>
        <th class="columnCenter">Fecha</th>
        <th class="columnCenter">Sin empezar</th>
        <th class="columnCenter">Empezar Preparación</th>
        <th class="columnCenter">Terminar Preparación</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let order of orders; let i = index">
        <td>{{ order.id }}</td>
        <td>{{ order.shipInfo.name }}</td>
        <td>{{ order.datePlaced | date: 'EEEE, d/M/yy, h:mm a':'es-CO'}}</td>
        <td class="columnCenter"><div *ngIf="order.process[1].status !== 'ok'"><i class="fa fa-check" aria-hidden="true"></i></div></td>
        <td class="columnCenter">
          <div *ngIf="order.process[1].status === 'ok'"><i class="fa fa-check" aria-hidden="true"></i></div>
          <div *ngIf="order.process[1].status !== 'ok'">
          <label>
          <input type="checkbox" (change)="updateProcess(1,order)"/>
          {{process_step1_start_preparation[i].stage}}
        </label>
        </div>

      </td>
      <td class="columnCenter">
        <div *ngIf="order.process[2].status === 'ok'"><i class="fa fa-check" aria-hidden="true"></i></div>
        <div *ngIf="order.process[2].status !== 'ok'">
        <label>
        <input type="checkbox" (change)="updateProcess(2,order)"/>
        {{process_step2_end_preparation[i].stage}}
      </label>
      </div>

    </td>
        <td class="columnCenter">
          <button class="btn btn-lg btn-outline-primary" (click)="openModalProduct(order)">Ver Orden</button>
        </td>
      </tr>
    </tbody>
  </table>
  </div>
  </div>
  </div>

