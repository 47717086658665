import { Component, OnInit, Input, Pipe, PipeTransform, OnDestroy } from '@angular/core';
import { ContentSection } from './../../shared/models/contentSection.model'
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser'
import { AuthService } from '../../shared/services/auth.service'
import {YoutubeService } from './../../shared/services/youtube.service'
import { takeUntil } from 'rxjs/operators';
import { BehaviorSubject} from 'rxjs';
import { Subject } from 'rxjs';
import { UserProfile } from '../../shared/models/user-profile';
import { User } from './../../shared/models/user.model';
import Utility  from './../../shared/helpers/utility';
import { BridgeService } from './../../shared/services/bridge.service';
import { Subscription } from 'rxjs';



@Component({
  selector: 'app-channel-card',
  templateUrl: './channel-card.component.html',
  styleUrls: ['./channel-card.component.scss']
})
export class ChannelCardComponent implements OnInit, OnDestroy {
  @Input('channel') channel: ContentSection;

  videos: any[];
  channels: any;
  channelDetails: any;
  thumbnail: string = "";
  destroy$ = new BehaviorSubject<boolean>(false);
  private unsubscribe$: Subject<any> = new Subject();

  isFamily: boolean;
  userLoggedIn: UserProfile;
  userData: User;
  language = '';
  mobile: boolean = false;
  private successSub!: Subscription

  constructor(private youTubeService: YoutubeService, public authService: AuthService, public bridgeService: BridgeService) {
  }

  ngOnInit() {
    this.language = Utility.checkLanguage();
    this.youTubeService
    .getChannelInfo(this.channel.ExternalWeb1)
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(lista => {
    this.channels = lista
    this.channelDetails = this.channels.items[0]
    console.log('channels data',this.channelDetails);
    if(this.channelDetails) {
      this.thumbnail=this.channelDetails.snippet.thumbnails.high.url
    }
    });


    if (JSON.parse(localStorage.getItem('user'))) {
      this.userData = JSON.parse(localStorage.getItem('user'));
      if (this.userData) {
        this.getUserbyId(this.userData.uid)
      }
    }

    this.successSub = this.bridgeService.mobileSuccess.subscribe(mobile => {
      if (mobile) {
        this.mobile = true
      }
      else {
        this.mobile = false
      }
    }
    )

  }

  getUserbyId(userUID:String) {
    this.authService.getUserInfobyId(userUID).subscribe(user => {
      this.userLoggedIn = user
      this.isFamily = this.authService.isFamily(this.userLoggedIn)
    })
  }

  ngOnDestroy(): void {
    this.successSub.unsubscribe();
  }

}

