import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ContentSection } from './../models/contentSection.model'
import { of } from 'rxjs'
import { homePageTitles } from './../localData/homepage.data'
import { homePageTitlesArray } from './../localData/homepage.data'
import { homePageTitlesArraySP } from './../localData/homepage.data'

@Injectable({
  providedIn: 'root'
})
export class LoadLocalDataService {

  dataArray = homePageTitlesArray;
  dataArraySP = homePageTitlesArraySP;

  constructor() { }

  getHeaderTitles(): Observable<ContentSection[]> {
    return of(this.dataArray)
  }

  getHeaderTitlesSP(): Observable<ContentSection[]> {
    return of(this.dataArraySP)
  }

 }
