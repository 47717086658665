<!-- <div class="no-scrolling setDefaultSpace">
  <ngx-masonry class="positioning" [options]="masonryOptions" [useImagesLoaded]="true">
      <ngxMasonryItem *ngFor="let picture of contentSections" class="masonry-item drop-shadow">
          <div *ngIf ="picture.category =='1601695269309'">
              <div class="card">
                      <div class="card-body">
                          <p><img class=" img-fluid" src="{{picture.imageUrl}}" alt="card image"></p>
                          <div class="card-header">{{ picture.title }}</div>
                          <div class="card-body">
                                  <h5 class="card-title">{{ picture.description }}</h5>
                              <div>
                                  <table>
                                          <tr>
                                                  <th class="fontOpenSans-SemiboldSmallDarkGray">Title</th>
                                                </tr>
                                                <tr>
                                                  <td class="fontOpenSans-RegularExtraSmallDarkGray">{{ picture.description }}</td>
                                                </tr>
                                          <tr>
                                            <th class="fontOpenSans-SemiboldSmallDarkGray">Thesis/Project</th>
                                          </tr>
                                          <tr>
                                            <td class="fontOpenSans-RegularExtraSmallDarkGray">{{ picture.price }}</td>
                                          </tr>
                                        </table>
                                        <table>
                                            <tr>
                                              <th class="fontOpenSans-SemiboldSmallDarkGray">Thesis Director</th>
                                            </tr>
                                            <tr>
                                              <td class="fontOpenSans-RegularExtraSmallDarkGray">{{ picture.title }}</td>
                                            </tr>
                                        </table>
                                        <table>
                                        </table>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div class="card p-3">
                          <blockquote class="card-blockquote card-body fontOpenSans-SemiBoldSmallContent">
                            <footer class='blockquote-footer'>
                              <small class="text-muted"> {{ picture.description }}<cite title="Source Title"></cite></small><br>
                            </footer>
                          </blockquote>
                        </div>
            </div>
      </ngxMasonryItem>
  </ngx-masonry>
  </div> -->


  <!-- PAGE HEADER -->
<header id="page-header">
  <div *ngIf="!mobile" class="container setDefaultSpace pd-2">
    <div class="row">
      <div class="col-12">
        <h1 class="fontOpenSans-RegularSmallDarkGray text-center">{{title}}</h1>
        <div *ngIf="language === 'English'" class="pb-5">
          <p class="fontOpenSans-RegularExtraSmallDarkGray">There is not a magic button for a Super-O but we believe that for each one of the selected techniques, there is some evidence-based on forums, a few scientific papers, years of reputation, and millennial culture that provide us a base of practitioners who report the immense pleasure that a Super-O can bring to the individuals who complete the Journey.

          </p>
          <p class="fontOpenSans-RegularExtraSmallDarkGray">The selected techniques are the Aneros prostate massage invented by Jiro Takashima, "The Key Sound Multiple Orgasm Trigger Protocol"™  (KSMO), developed and disseminated by Jack Johnston , the Tao technique of Mantak Chia, the Binaural Beats Orgasm, and the millennial Awake of the Kundalini, there is some common ground, all of these techniques expect you to <span class="fontOpenSans-SemiboldSmallDarkGray">avoid penis stimulation</span> and all of them involve <span class="fontOpenSans-SemiboldSmallDarkGray">Arousal amplification</span> meaning that the arousal grows "from the inside" of your body, so has a sublime feeling not often felt in regular sex.
          </p>
          <p class="fontOpenSans-RegularExtraSmallDarkGray">Each technique has its own guideline and sometimes the combination of the techniques can bring better results. The Aneros prostate massage is based primarily on physical stimulation of the prostate, the KSMO  teaches arousal amplification through a specific type of vocalization, breathing exercises, relaxation techniques, and mental focus. KSMO and the Aneros complement each other very well as KSMO provides much of the mental control while the Aneros provides enhanced physical stimulation. For the Tao technique of Mantak Chia the most important parts of the practice are breathing, squeezing your pelvic floor muscles, and learning to relax into a non ejaculatory orgasm. The Kundalini awakening occurs when the various parts of the brain, spine, and nerve ganglia, along with the different endocrine glands, begin to rejuvenate, better link, and synchronize. The gonads, adrenals, and digestive glands (connected to the experience of lower emotions) connect to the master glands at the heart, throat, and brain. The Binaural Beats Orgasm is based on the careful selection of specific frequencies carrying information that amplify the stimulation.
          </p>
          <p class="fontOpenSans-RegularExtraSmallDarkGray">The purpose of the presentation of these techniques is to provide a source of information about how to get details as alternative paths for the individual Journey of the Super-O.

          </p>
        </div>
        <div *ngIf="language === 'Spanish'" class="pb-5">
          <p class="fontOpenSans-RegularExtraSmallDarkGray">No existe un botón mágico para un Super-O pero creemos que para cada una de las técnicas seleccionadas, hay evidencia basada en foros, artículos científicos, años de reputación y cultura milenaria que nos brindan una base de practicantes que informan del inmenso placer que un Super-O puede brindar a las personas que completan el Viaje al Super-O.
          </p>
          <p class="fontOpenSans-RegularExtraSmallDarkGray">Las técnicas seleccionadas por nuestro equipo de investigación son el masaje de próstata con el producto Aneros desarrollado por Jiro Takashima, "The Key Sound Multiple Orgasm Trigger Protocol" ™ (KSMO), desarrollado y difundido por Jack Johnston, la técnica Tao de Mantak Chia, el Orgasmo causado por frecuencias de sonidos específicas ( Binaural Beats Orgasm ) y el milenario Despertar del Kundalini. Estas técnicas tienen algunos puntos en común , todas esperan que <span class="fontOpenSans-SemiboldSmallDarkGray">evites la estimulación del pene</span> y todas involucran la <span class="fontOpenSans-SemiboldSmallDarkGray">amplificación de la excitación</span>, lo que significa que la excitación crece "desde el interior" de tu cuerpo, por lo que tiene una sensación sublime que no se siente a menudo en el sexo regular.
          </p>
          <p class="fontOpenSans-RegularExtraSmallDarkGray">Cada técnica tiene su propia guía y en algunos casos la combinación de las técnicas puede traer mejores resultados. El masaje prostático con el producto Aneros se basa principalmente en la estimulación física de la próstata, el KSMO enseña amplificación de la excitación a través de un tipo específico de vocalización, ejercicios de respiración, técnicas de relajación y enfoque mental. KSMO y Aneros se complementan muy bien, ya que KSMO proporciona gran parte del control mental, mientras que Aneros proporciona una mayor estimulación física. Para la técnica Tao de Mantak Chia, las partes más importantes de la práctica son respirar, apretar los músculos del piso pélvico, aprender a relajarse en un orgasmo no eyaculatorio. El despertar de Kundalini ocurre cuando las diversas partes del cerebro, la columna vertebral y los ganglios nerviosos, en conjunto con las diferentes glándulas endocrinas, comienzan a rejuvenecer, enlazarse y sincronizarse. Las gónadas, las glándulas suprarrenales y digestivas (conectadas a la experiencia de las emociones inferiores) se conectan a las glándulas maestras en el corazón, la garganta y el cerebro. La técnica de Orgasmos por frecuencias se basa en la selección cuidadosa de frecuencias específicas portadoras de información que amplifican la estimulación.
          </p>
          <p class="fontOpenSans-RegularExtraSmallDarkGray">El propósito de la presentación de estas técnicas es proporcionar una fuente de información sobre cómo obtener detalles de caminos alternativos para el Viaje individual del Super-O.

          </p>
        </div>
        <!-- <p class="fontOpenSans-RegularExtraSmallDarkGray">{{subTitle}}</p> -->
        <div class="d-block d-md-none">
          </div>
      </div>
    </div>
  </div>
  <div *ngIf="mobile" class="container pd-2">
    <div class="row">
      <div class="col-12">
        <h1 class="fontOpenSans-RegularSmallWhite text-center">{{title}}</h1>
        <div *ngIf="language === 'English'" class="pb-5">
          <p class="fontOpenSans-RegularExtraSmallWhite">There is not a magic button for a Super-O but we believe that for each one of the selected techniques, there is some evidence-based on forums, a few scientific papers, years of reputation, and millennial culture that provide us a base of practitioners who report the immense pleasure that a Super-O can bring to the individuals who complete the Journey.

          </p>
          <p class="fontOpenSans-RegularExtraSmallWhite">The selected techniques are the Aneros prostate massage invented by Jiro Takashima, "The Key Sound Multiple Orgasm Trigger Protocol"™  (KSMO), developed and disseminated by Jack Johnston , the Tao technique of Mantak Chia, the Binaural Beats Orgasm, and the millennial Awake of the Kundalini, there is some common ground, all of these techniques expect you to <span class="fontOpenSans-SemiboldSmallWhite">avoid penis stimulation</span> and all of them involve <span class="fontOpenSans-SemiboldSmallWhite">Arousal amplification</span> meaning that the arousal grows "from the inside" of your body, so has a sublime feeling not often felt in regular sex.
          </p>
          <p class="fontOpenSans-RegularExtraSmallWhite">Each technique has its own guideline and sometimes the combination of the techniques can bring better results. The Aneros prostate massage is based primarily on physical stimulation of the prostate, the KSMO  teaches arousal amplification through a specific type of vocalization, breathing exercises, relaxation techniques, and mental focus. KSMO and the Aneros complement each other very well as KSMO provides much of the mental control while the Aneros provides enhanced physical stimulation. For the Tao technique of Mantak Chia the most important parts of the practice are breathing, squeezing your pc muscle, and learning to relax into a nonejaculatory orgasm and the Kundalini awakening occurs when the various parts of the brain, spine, and nerve ganglia, along with the different endocrine glands, begin to rejuvenate, better link, and synchronize. The gonads, adrenals, and digestive glands (connected to the experience of lower emotions) connect to the master glands at the heart, throat, and brain. The Binaural Beats Orgasm is based on the careful selection of specific frequencies carrying information that amplify the stimulation.
          </p>
          <p class="fontOpenSans-RegularExtraSmallWhite">The purpose of the presentation of these techniques is to provide a source of information about how to get details as alternative paths for the individual Journey of the Super-O.

          </p>
        </div>
        <div *ngIf="language === 'Spanish'" class="pb-5">
          <p class="fontOpenSans-RegularExtraSmallWhite">No existe un botón mágico para un Super-O pero creemos que para cada una de las técnicas seleccionadas, hay evidencia basada en foros, artículos científicos, años de reputación y cultura milenaria que nos brindan una base de practicantes que informan del inmenso placer que un Super-O puede brindar a las personas que completan el Viaje al Super-O.
          </p>
          <p class="fontOpenSans-RegularExtraSmallWhite">Las técnicas seleccionadas por nuestro equipo de investigación son el masaje de próstata con el producto Aneros desarrollado por Jiro Takashima, "The Key Sound Multiple Orgasm Trigger Protocol" ™ (KSMO), desarrollado y difundido por Jack Johnston, la técnica Tao de Mantak Chia, el Orgasmo causado por frecuencias de sonidos específicas ( Binaural Beats Orgasm ) y el milenario Despertar del Kundalini. Estas técnicas tienen algunos puntos en común , todas esperan que <span class="fontOpenSans-SemiboldSmallWhite">evites la estimulación del pene</span> y todas involucran la <span class="fontOpenSans-SemiboldSmallWhite">amplificación de la excitación</span>, lo que significa que la excitación crece "desde el interior" de tu cuerpo, por lo que tiene una sensación sublime que no se siente a menudo en el sexo regular.
          </p>
          <p class="fontOpenSans-RegularExtraSmallWhite">Cada técnica tiene su propia guía y en algunos casos la combinación de las técnicas puede traer mejores resultados. El masaje prostático con el producto Aneros se basa principalmente en la estimulación física de la próstata, el KSMO enseña amplificación de la excitación a través de un tipo específico de vocalización, ejercicios de respiración, técnicas de relajación y enfoque mental. KSMO y Aneros se complementan muy bien, ya que KSMO proporciona gran parte del control mental, mientras que Aneros proporciona una mayor estimulación física. Para la técnica Tao de Mantak Chia, las partes más importantes de la práctica son respirar, apretar el músculo pc y aprender a relajarse en un orgasmo no eyaculatorio y el despertar de Kundalini ocurre cuando las diversas partes del cerebro, la columna vertebral y los ganglios nerviosos, a lo largo de con las diferentes glándulas endocrinas, comienza a rejuvenecer, mejor enlazarse y sincronizarse. Las gónadas, las glándulas suprarrenales y digestivas (conectadas a la experiencia de las emociones inferiores) se conectan a las glándulas maestras en el corazón, la garganta y el cerebro. La técnica de Orgasmos por frecuencias se basa en la selección cuidadosa de específicas frecuencias portadoras de información que amplifican la estimulación.
          </p>
          <p class="fontOpenSans-RegularExtraSmallWhite">El propósito de la presentación de estas técnicas es proporcionar una fuente de información sobre cómo obtener detalles de caminos alternativos para el Viaje individual del Super-O.

          </p>
        </div>
        <!-- <p class="fontOpenSans-RegularExtraSmallDarkGray">{{subTitle}}</p> -->
        <div class="d-block d-md-none">
          </div>
      </div>
    </div>
  </div>
</header>

<div class="no-scrolling">
<ngx-masonry class="positioning" [options]="masonryOptions" [useImagesLoaded]="true">
  <ngxMasonryItem *ngFor="let picture of contentSections" class="masonry-item drop-shadow">
          <div class="card">
                  <div class="card-body">
                      <p><img class=" img-fluid" src="{{picture.UrlImage}}" alt="card image"></p>
                      <div *ngIf ="picture.ExternalWeb3 !='demo'" class="card-header">{{ picture.Title }}</div>
                      <div class="card-body">
                              <h5 class="card-title">{{ picture.PhotoInfo }}</h5>
                              <!-- <h5 class="card-title">{{ picture.Time }}</h5> -->
                          <div *ngIf ="picture.ExternalWeb3 !='demo'">
                              <table>
                                      <tr>
                                              <th class="fontOpenSans-SemiboldSmallDarkGray">{{title1}}</th>
                                            </tr>
                                            <tr>
                                              <td class="fontOpenSans-RegularExtraSmallDarkGray"> {{picture.Content}}</td>
                                            </tr>
                                      <!-- <tr>
                                        <th class="fontOpenSans-SemiboldSmallDarkGray">{{title2}}</th>
                                      </tr>
                                      <tr>
                                        <td class="fontOpenSans-RegularExtraSmallDarkGray">{{ picture.ExternalWeb1 }}</td>
                                      </tr> -->
                                    </table>
                                    <!-- <table>
                                        <tr>
                                          <th class="fontOpenSans-SemiboldSmallDarkGray">{{title3}}</th>
                                        </tr>
                                        <tr>
                                          <td class="fontOpenSans-RegularExtraSmallDarkGray">{{ picture.ExternalWeb3 }}</td>
                                        </tr>
                                    </table> -->
                                    <table>
                                    </table>
                          </div>
                      </div>
                  </div>
              </div>
              <div *ngIf ="picture.ExternalWeb3 !='demo'" class="card p-3">
                      <blockquote class="card-blockquote card-body fontOpenSans-SemiBoldSmallContent">
                        <footer class='blockquote-footer'>
                          <small class="fontOpenSans-RegularSmallDarkGrayTextMuted"> {{ picture.ExternalWeb2 }}<cite title="Source Title"></cite></small><br>
                          <a href="{{ picture.PhotoSource }}" target="blank_"><small class="fontOpenSans-RegularSmallDarkGrayTextMuted"> {{ picture.PhotoSource }}<cite title="Source Title"></cite></small></a>
                        </footer>
                      </blockquote>
                    </div>
  </ngxMasonryItem>
</ngx-masonry>
</div>
