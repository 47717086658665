<div class="pb-5">
  <div class="dropdown show pd-5">
      <!-- <a class="btn btn-secondary dropdown-toggle dropFonts" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        Select Category
      </a> -->

      <div *ngIf="!isFamily">
          <!-- <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
              <a
              *ngFor="let c of generalChannels"
              routerLink="/channels"
              [queryParams]="{ category: c.SubCategoryId }"
              class="dropdown-item dropFonts"
              [class.active]="category === c.SubCategoryId"
              >
              {{ c.Title }}
            </a>
          </div> -->
          <div *ngIf="!mobile">
          <div *ngIf="language === 'English'" >
          <label for="channels">Select Category:</label>

          <select id="channels" [(ngModel)]="categorySelected">
              <option
              *ngFor="let c of generalChannels"
              [value]="c.SubCategoryId"
              >
              {{ c.Title }}
          </option>
          </select>
        </div>
        <div *ngIf="language === 'Spanish'" >
          <label for="channels-SP">Seleccionar Categoría:</label>
          <select id="channels-SP" [(ngModel)]="categorySelectedSP">
              <option
              *ngFor="let c of generalChannelsSP"
              [value]="c.SubCategoryId"
              >
              {{ c.Title }}
          </option>
          </select>
        </div>
        </div>
        <div *ngIf="mobile">
          <div *ngIf="language === 'English'" >
          <label for="channels" class="fontOpenSans-RegularSmallWhite">Select Category:</label>

          <select id="channels" [(ngModel)]="categorySelected">
              <option
              *ngFor="let c of generalChannels"
              [value]="c.SubCategoryId"
              >
              {{ c.Title }}
          </option>
          </select>
        </div>
        <div *ngIf="language === 'Spanish'" >
          <label for="channels-SP" class="fontOpenSans-RegularSmallWhite">Seleccionar Categoría:</label>
          <select id="channels-SP" [(ngModel)]="categorySelectedSP">
              <option
              *ngFor="let c of generalChannelsSP"
              [value]="c.SubCategoryId"
              >
              {{ c.Title }}
          </option>
          </select>
        </div>
        </div>
        </div>
          <div *ngIf="categorySelected">
            <button><a [routerLink]="['/channels']" [queryParams]="{category: categorySelected}">Load Channel</a></button>
          </div>
          <div *ngIf="categorySelectedSP">
            <button><a [routerLink]="['/channels']" [queryParams]="{category: categorySelectedSP}">Cargar Canal</a></button>
          </div>


      <div *ngIf="isFamily">
          <!-- <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
              <a
              *ngFor="let c of generalChannels"
              routerLink="/channels"
              [queryParams]="{ category: c.SubCategoryId }"
              class="dropdown-item dropFonts"
              [class.active]="category === c.SubCategoryId"
              >
              {{ c.Title }}
            </a>
          </div> -->

          <label for="channels">Seleccione la familia:</label>

          <select id="channels" [(ngModel)]="categorySelected">
              <option
              *ngFor="let c of families"
              [value]="c.SubCategoryId"
              >
              {{ c.Title }}
          </option>
          </select>
          <div *ngIf="categorySelected">
            <button><a [routerLink]="['/channels']" [queryParams]="{category: categorySelected}">Cargar el canal</a></button>
          </div>
      </div>

    </div>
  </div>
