<div class="container setDefaultSpaceContent">
  <h1>Music Player</h1>

    <div class="music-container" id="music-container">
      <div class="music-info">
        <h4 id="title"></h4>
        <div class="progress-container" id="progress-container" (click)="setProgress($event)">
          <div class="progress" id="progress"></div>
        </div>
      </div>

      <audio src="../../assets/music/ukulele.mp3" id="audio"></audio>

      <div class="img-container">
        <img src="../../assets/images/ukulele.jpg" alt="music-cover" id="cover" />
      </div>
      <div class="navigation">
         <button id="prev" class="action-btn" (click)="nextSong()">
          <i class="fa fa-backward"></i>
        </button>
       <button id="play" class="action-btn action-btn-big">
          <i class="fa fa-play"></i>
        </button>
         <button id="next" class="action-btn" (click)="nextSong()">
          <i class="fa fa-forward"></i>
        </button>
      </div>
    </div>

</div>
