<footer *ngIf="!mobile" id="main-footer" [ngStyle]="{'backgroundImage' : largeBreakpoint ? 'url('+urlToImageFooterLarge+')': 'url('+urlToImageFooter+')'}" class="text-center p-4  footerBackground footerBack sticky-botton  -webkit-sticky-botton">
  <div class="container">
    <div class="row justify-content-center">
          <p class="fancy fontOpenSans-RegularSmall"><span>Follow us</span></p>
    </div>
    <div class="row justify-content-center">

      <!-- <a href="https://twitter.com/FoodKiay" target="blank_"><i class="fa fa-twitter fa-3x mb-2 lightGray pr-4"></i></a> -->
      <!-- <a href="https://google.com" target="blank_"><i class="fa fa-linkedin-square  fa-3x mb-2 lightGray pr-4"></i></a> -->
      <!-- <a href="https://www.facebook.com/dramarcela.deportologa/" target="blank_"><i class="fa fa-facebook  fa-3x mb-2 lightGray pr-4"></i></a> -->
      <!-- <a href="https://www.instagram.com/dramarcela.deportologa/?igshid=fmxlh7cg30i2" target="blank_"><i class="fa fa-instagram  fa-3x mb-2 lightGray pr-4"></i></a> -->
      <a href="https://google.com" target="blank_"><i class="fa fa-facebook  fa-3x mb-2 lightGray pr-4"></i></a>
      <a href="https://google.com" target="blank_"><i class="fa fa-instagram  fa-3x mb-2 lightGray pr-4"></i></a>
      <a href="https://www.youtube.com/user/MedscienceAnimations" target="blank_"><i class="fa fa-youtube-square  fa-3x mb-2 lightGray pr-4"></i></a>
      <!-- <i class="fa fa-youtube-square fa-3x mb-2 lightGray pr-4"></i> -->
    </div>
    <div class="row justify-content-center fontOpenSans-RegularExtraSmall linkStyle">
      Copyright © 2020 SO Journey  •&nbsp;
      <span><a routerLink="privacy">Privacy Policy <span class="sr-only">(current)</span></a></span>
      <span>&nbsp;•&nbsp;</span>
      <span><a routerLink="terms">Terms and Conditions <span class="sr-only">(current)</span></a></span>
    </div>
  </div>
</footer>
