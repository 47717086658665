import { Component, OnInit,Input, Output, EventEmitter, ViewChild, ElementRef, OnDestroy  } from '@angular/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit {
  @Input() order: any;
  @Output() close = new EventEmitter<void>();
  id:string = '';
  name:string = '';
  // product:Product;
  // category:Product;
  ProductsArr : Array<any>
  CategoriesArr : Array<any>
  private successUpdateProductSub: Subscription;
  private successUpdateCategorySub: Subscription;
  totalPrice = 0;
  impuesto = 0.16;

  @ViewChild('image') image : ElementRef
  @ViewChild('imageProduct') imageProduct : ElementRef


  constructor() { }

  ngOnInit(): void {
    this.id = this.order.id;
    this.name = this.order.name;
    console.log("order details", this.order)
    console.log("order details array", this.order.object.items)
    for(let i=0;i<this.order.object.items.length;i++) {
      this.totalPrice = this.totalPrice + this.order.object.items[i].totalPrice;
    }

  }

  onClose() {
    this.close.emit();
  }

}
