import { Component,  Input, Output, EventEmitter, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { Product } from './../../shared/models/product.model';
import { Category } from './../../shared/models/category.model';
import { ProductService } from '../services/product.service';
import { CategoryService } from '../services/category.service';
import { NgForm } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent  implements OnInit, OnDestroy  {
  @Input() message: any;
  @Output() close = new EventEmitter<void>();
  id:string = '';
  name:string = '';
  product:Product;
  category:Product;
  ProductsArr : Array<any>
  CategoriesArr : Array<any>
  private successUpdateProductSub: Subscription;
  private successUpdateCategorySub: Subscription;

  @ViewChild('image') image : ElementRef
  @ViewChild('imageProduct') imageProduct : ElementRef

  constructor(private productService: ProductService, private categoryService: CategoryService ) { }


  ngOnInit() {
    this.id = this.message.id;
    this.name = this.message.name;
    this.product = this.message.object;
    this.category = this.message.object;

    this.categoryService.getCategories().subscribe(data => {
      this.CategoriesArr = data.map(e => {
        return {
          id: e.payload.doc.id,
          isEdit: false,
          title: e.payload.doc.data()['title'],
          category: e.payload.doc.data()['category'],
          description: e.payload.doc.data()['description'],
          image: e.payload.doc.data()['imageUrl'],
        };
      })
      console.log(this.CategoriesArr);

    });

  }

  updateProduct(f : NgForm) {
    console.log("update product");
    let id = Number(this.id),
        name = f.value.name,
        description = f.value.descriptionProduct,
         price = f.value.price,
         category = f.value.category,
         image = (this.imageProduct.nativeElement as HTMLInputElement).files[0]
         this.productService.updateProduct( id, name , description, price , category, image)
         this.successUpdateProductSub = this.productService.updateProductSuccess.subscribe((success) => {
          if (success) {
            f.resetForm();
           console.log("Update product ok");
          }
        });

  }

  updateCategory(f : NgForm) {
    console.log("update category");
    let id = Number(this.id),
        name = f.value.nameCategory,
        description = f.value.descriptionCategory,
         image = (this.image.nativeElement as HTMLInputElement).files[0]
         this.categoryService.updateCategory( id, name , description, image)
         this.successUpdateCategorySub = this.categoryService.updateCategorySuccess.subscribe((success) => {
          if (success) {
            f.resetForm();
           console.log("Update category ok");
          }
        });

  }

  onClose() {
    this.close.emit();
  }

  ngOnDestroy() {
    // this.successUpdateProductSub.unsubscribe();
    // this.successUpdateCategorySub.unsubscribe();
  }

}
