<div class="container setDefaultSpace">
  <div class="row mapView">
    <div class="col-12 col-md-8">
      <div class="fontMontserratBold">ELIGE TU TIENDA</div>
      <div class="band Montserrat-Regular pl-3">ESTA ORDEN ES A DOMICILIO O PARA RECOGER?</div>
      <div>
        <p class="fancy fontOpenSans-RegularSmall pt-5"><a routerLink="/delivery" class="btn btn-danger">ENTREGA A DOMICILIO</a><span class="pl-4 pr-4">o</span><a routerLink="/maps" class="btn btn-danger">RECOGER EN TIENDA</a></p>
      </div>
      <div class="map-container">
        <div class="map-frame">
          <div id="map"></div>
        </div>
      </div>
    </div>
    <div class="col-12 col-md-4 d-none d-md-block">
      <div class="band Montserrat-Regular pl-3">MI ORDEN</div>
      <div class="pl-3 pt-3 bandBorder">Arma tu propia combinación</div>

        <div class="pt-5">
          <div class="bandBorderBajo">
      <div class="band Montserrat-Regular pl-3">OPCIONES DE TU PEDIDO</div>
      <div class="Montserrat-Regular"><span class="fontMontserratBoldSmallRed pl-3">Mi Tienda</span><a class="pl-3"routerLink="/maps">Cambiar</a></div>
      <div class="Montserrat-Regular pl-3">Chapinero</div>
      <div class="Montserrat-Regular pl-3">Avenida Caracas # 53 - 30 Local 2</div>
      <div class="Montserrat-Regular pl-3">Bogotá - Colombia</div>
      <div class="Montserrat-Regular pl-3">Tel: 315-900-8989</div>
      <div class="Montserrat-Regular pl-3">Metodo de Servicio</div>
      <div class="fontMontserratBoldSmallRed pl-3 pt-5">Metodo de Servicio</div>
      <div class="pl-3 pt-3">
      <div class="form-check">
        <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios1" value="option1" checked>
        <label class="form-check-label pl-2" for="exampleRadios1">
          Recoger en tienda
        </label>
      </div>
      <div class="form-checkpt-5 pl-3 pt-3 pb-5">
        <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios2" value="option2">
        <label class="form-check-label pl-2" for="exampleRadios2">
          Entrega a Domicilio
        </label>
      </div>
    </div>
    </div>
    </div>
    </div>
  </div>
</div>

