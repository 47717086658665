
<!-- <div *ngIf="channelDetails" class="card">
  <div class="card-body">
      <p><img class=" img-fluid" src="{{thumbnail}}" alt="card image"></p>
      <div class="card-header">{{channelDetails.snippet.title}}</div>
      <div class="card-body">
              <h5 class="card-title">Photo source :{{ channel.PhotoInfo }}</h5>
          <div>
              <table>
                      <tr>
                              <th class="fontOpenSans-SemiboldSmallDarkGray">Channel Summary</th>
                            </tr>
                            <tr>
                              <td class="fontOpenSans-RegularExtraSmallDarkGray maxWidth">{{channelDetails.snippet.description}}</td>
                            </tr>
                      <tr>
                        <th class="fontOpenSans-SemiboldSmallDarkGray">Where do I use this channel?</th>
                      </tr>
                      <tr>
                      </tr>
                    </table>
                    <table>
                        <tr>
                          <th class="fontOpenSans-SemiboldSmallDarkGray">Key words to remind</th>
                        </tr>
                        <tr>
                        </tr>
                    </table>
                    <table>
                    </table>
          </div>
      </div>
  </div>
</div>
<div *ngIf="channelDetails" class="card p-3">
    <div class="row channelStyle">
        <img src="{{channel.UrlImage}}"  class="img-fluid card-img-top logoStyle">
  </div>
      <blockquote class="card-blockquote card-body fontOpenSans-SemiBoldSmallContent">
        <footer>
          <small class="fontOpenSans-SemiboldSmallDarkGray"> Subscribers :&nbsp; <span class="fontOpenSans-RegularExtraSmallDarkGray ">{{channelDetails.statistics.subscriberCount  | number}}</span></small><br>
          <small class="fontOpenSans-SemiboldSmallDarkGray"> Views :&nbsp; <span class="fontOpenSans-RegularExtraSmallDarkGray ">{{channelDetails.statistics.viewCount  | number}}</span></small><br>
          <small class="fontOpenSans-SemiboldSmallDarkGray"> Videos :&nbsp; <span class="fontOpenSans-RegularExtraSmallDarkGray ">{{channelDetails.statistics.videoCount  | number}}</span></small><br>
        </footer><br>
        <footer class='blockquote-footer linkStyle'>
            <div *ngIf="(authService.isFamily(userLoggedIn))">
              <small class="text-muted"> Mostrar videos de familia : &nbsp;<cite title="Source Title"><span><a routerLink="../videos" [queryParams]="{ channel: channelDetails.id }">Click here <span class="sr-only">(current)</span></a></span></cite></small><br>
            </div>
          <div *ngIf="!(authService.isFamily(userLoggedIn))">
              <small class="fontOpenSans-SemiboldSmallDarkGray"> Watch youtube channel videos: &nbsp;<cite title="Source Title"><span class="fontOpenSans-RegularExtraSmallDarkGray "><a href="{{ channel.UrlVideo }}" target="_blank">Click here <span class="sr-only">(current)</span></a></span></cite></small><br>
        </div>
        </footer>
      </blockquote>
</div> -->

                <div class="card">
                        <div class="card-body">
                            <p><img class=" img-fluid" src="{{channel.UrlImage}}" alt="card image"></p>
                            <div *ngIf ="channel.ExternalWeb3 !='demo'" class="card-header">{{ channel.Title }}</div>
                            <div class="card-body">
                                    <h5 class="card-title">{{ channel.PhotoInfo }}</h5>
                                <div *ngIf ="channel.ExternalWeb3 !='demo'">
                                    <table>
                                            <tr>
                                                    <th class="fontOpenSans-SemiboldSmallDarkGray">{{title1}}</th>
                                                  </tr>
                                                  <tr>
                                                    <td class="fontOpenSans-RegularExtraSmallDarkGray"> {{channel.Content}}</td>
                                                  </tr>
                                          </table>
                                          <table>
                                          </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf ="channel.ExternalWeb3 !='demo'" class="card p-3">
                            <blockquote class="card-blockquote card-body fontOpenSans-SemiBoldSmallContent">
                              <footer class='blockquote-footer'>
                                <small class="fontOpenSans-RegularSmallDarkGrayTextMuted"> {{ channel.ExternalWeb2 }}<cite title="Source Title"></cite></small><br>
                                <a *ngIf="!mobile" href="{{ channel.PhotoSource }}" target="blank_"><small class="fontOpenSans-RegularSmallDarkGrayTextMuted"> {{ channel.PhotoSource }}<cite title="Source Title"></cite></small></a>
                                <span *ngIf="mobile"><small class="fontOpenSans-RegularSmallDarkGrayTextMuted"> {{ channel.PhotoSource }}<cite title="Source Title"></cite></small></span>
                              </footer>
                            </blockquote>
                          </div>



