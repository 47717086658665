import { Component, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { ShoppingCart } from './../shared/models/shopping-cart.model';
import { ShoppingCartItem } from './../shared/models/shopping-cart-item.model';
import { Product } from './../shared/models/product.model';
import { ShoppingCartService } from './../shared/services/shopping-cart.service';
import { Router, ActivatedRoute } from '@angular/router';

interface ItemsMap { [productId: string]: ShoppingCartItem }


@Component({
  selector: 'app-check-out',
  templateUrl: './check-out.component.html',
  styleUrls: ['./check-out.component.scss']
})
export class CheckOutComponent implements OnInit {
  products: Product[] = [];
  filteredProducts: Product[] = [];
  category: string;
  item : ShoppingCartItem
  cart: ShoppingCartItem[];
  cartShopping: ShoppingCart;
  subscription: Subscription
  cartId: string ='';
  flagCheckout: string ='EMPTY';
  dataCartShopping: Object = { cartData:{}, flag:""}


  constructor(private shoppingCartService: ShoppingCartService, private activatedRoute:ActivatedRoute,) { }

  ngOnInit() {
    console.log("GET CART check-out");
    if(this.activatedRoute.snapshot.queryParams["transactionState"] == 4){
      console.log("CHECK-OUT - URL RESPONSE 4")
      this.flagCheckout = "OK"
    }

    if(this.activatedRoute.snapshot.queryParams["transactionState"] == 6){
      console.log("CHECK-OUT - URL RESPONSE 6")
      this.flagCheckout = "FAILED"
    }

    if(this.activatedRoute.snapshot.queryParams["transactionState"] == 104){
      console.log("CHECK-OUT - URL RESPONSE 104")
      this.flagCheckout = "FAILED"
    }
    this.subscription = this.shoppingCartService.getCart().subscribe(cart => {
      this.cart = cart.map(e => {
        return {
          id: e.payload.doc.id,
          isEdit: false,
          title: e.payload.doc.data()['title'],
          description: e.payload.doc.data()['description'],
          category: e.payload.doc.data()['category'],
          price: e.payload.doc.data()['price'],
          quantity: e.payload.doc.data()['quantity'],
          imageUrl: e.payload.doc.data()['imageUrl'],
          totalPrice: e.payload.doc.data()['totalPrice'],
        };
      })
      let items: ShoppingCartItem[] = [];
      let itemsMap: { [productId: string]: ShoppingCartItem } = {}
      let products: [{'productId':string}] = [{'productId':null}]
      let productStr = [];
      for(let i=0; i<this.cart.length; i++){
        products.push({'productId':this.cart[i].id});
        productStr.push(this.cart[i].id);

    }
      for(let i=0; i<this.cart.length; i++){
        items.push(new ShoppingCartItem({ id:products[i], ...this.cart[i]}));
    }


  let itemsMapA: ItemsMap = {}
    for(let i=0; i<this.cart.length; i++){
      let str = productStr[i];
      itemsMapA[str] = new ShoppingCartItem()
      itemsMap = this.addElement(itemsMap,itemsMapA);
  }
      this.cartShopping = new ShoppingCart(itemsMap);
      this.cartShopping.items = this.cart
      // console.log("Check-out component - shopping cart",this.cartShopping);
      this.dataCartShopping = {
        cartData:this.cartShopping, flag:this.flagCheckout
      }
      console.log('Data checkout',this.dataCartShopping);
    })

  }

  addElement (itemsMap2, element) {
    let newList = Object.assign(itemsMap2, element)
    return newList
}

}
