
import { Component, OnInit, Renderer2 } from '@angular/core';
import { AuthService } from "../../shared/services/auth.service";
import { HttpClient, HttpParams } from '@angular/common/http';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { NgForm } from '@angular/forms';

interface MailChimpResponse {
  result: string;
  msg: string;
}

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})

export class ForgotPasswordComponent implements OnInit {


  message: string = null;
  private messageSub: Subscription;
  private errorSub: Subscription;
  displayMessage = false;
  isLoading = false;
  error = '';
  error_email = false;

  constructor(private http: HttpClient, public authService: AuthService, private renderer: Renderer2) {
    this.authService.ResetMessage();
    this.authService.ResetForgotPasswordError();
  }

  formTemplate = new FormGroup({
    emailControl: new FormControl("", [
      Validators.required,
      Validators.email,
    ])
  })

  ngOnInit() {

    this.message=null;
      this.messageSub = this.authService.message.subscribe(message => {
        if (message) {
          this.displayMessage=true;
          this.isLoading = false;
          this.message = message;
        } else {
          this.displayMessage=false;
          this.message = null;
        }
      });


    this.error=null;
    this.errorSub = this.authService.errorForgotPassword.subscribe(error => {
      if (error) {
        this.error=error;
        this.displayMessage=true;
        this.isLoading = false;
        this.message = "If user exists on our database an email will be sent to reset the password.";
      }
    });
  }

  onSubmit(form: NgForm) {
    if (!form.valid) {
      return;
    }
    const email = form.value.email;
    this.authService.ForgotPassword(email);
    this.isLoading = true;
    this.displayMessage=false;
    this.message = null;
    form.reset();
  }

  resetErrorMessage() {
    this.error = null;
  }

  ngAfterViewInit() {
    const parent: HTMLElement = document.getElementById('main-footer');
    this.renderer.setStyle(parent, 'position', 'absolute');
    this.renderer.setStyle(parent, 'bottom', '0px');
    this.renderer.setStyle(parent, 'width', '100%');
    }


  ngOnDestroy() {
    this.messageSub.unsubscribe()
    this.errorSub.unsubscribe()
  }

}
