import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument, } from '@angular/fire/firestore';
import { AngularFireDatabase, AngularFireList } from '@angular/fire/database';
import { AngularFireStorage } from '@angular/fire/storage';
import { Observable, BehaviorSubject } from 'rxjs';
import { Product } from './../../shared/models/product.model';
import { Category } from './../../shared/models/category.model';
import { map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CategoryService {
  addCategorySuccess = new BehaviorSubject<boolean>(false);
  updateCategorySuccess = new BehaviorSubject<boolean>(false);
  private categoryCollection: AngularFirestoreCollection<Category>;
  private categoryDocument: AngularFirestoreDocument<Category>;
  constructor(private firestore: AngularFirestore, private db: AngularFireDatabase, private storage : AngularFireStorage) {}

  //FIREBASE
//   getAll() {
// return this.db.list('/categories', ref => ref.orderByChild('name')).valueChanges();
//   }

// FIRESTORE
getAll()
{
  return this.firestore.collection<Category>('Categories').snapshotChanges();
}


addCategory2(Name : string , Description : string , image : File)
{
   let ref =   this.storage.ref('CategoriesImages/' + image.name)
   ref.put(image).then ( () =>
   {
      ref.getDownloadURL().subscribe(CategoryPath =>
        {
           this.firestore.collection('Categories').add(
             {
               Name,
               Description,
               CategoryPath

             }
           ).then(() => {
            console.log("Category created succesfully")
            this.addCategorySuccess.next(true)
          }).catch(err => {
            console.log("Failed to create Category")
          });
        })

   }

   )

}

addCategory(id: number, name : string , description : string , image : File)
    {
       let ref =   this.storage.ref('CategoriesImages/' + image.name)
       ref.put(image).then ( () =>
       {
          ref.getDownloadURL().subscribe(CategoryPath =>
            {
              const picRef: AngularFirestoreDocument<any> = this.firestore.collection('Categories').doc(String(id))
              picRef.set ({
                id:String(id),
                title: name,
                description: description,
                imageUrl: CategoryPath,
              }).then(() => {
                        console.log("Category created succesfully")
                        this.addCategorySuccess.next(true)
                      }).catch(err => {
                        console.log("Failed to create Category")
                      });
                    })
       }

       )

    }

    updateCategory(id: number, name : string , description : string , image : File) {
      let ref =   this.storage.ref('CategoriesImages/' + image.name)
       ref.put(image).then ( () =>
       {
          ref.getDownloadURL().subscribe(ProductPath =>
            {
              const picRef: AngularFirestoreDocument<any> = this.firestore.collection('Categories').doc(String(id))
              picRef.update ({
                id: String(id),
                title: name,
                description: description,
                imageUrl: ProductPath,
              }).then(() => {
                        console.log("Category updated succesfully")
                        this.updateCategorySuccess.next(true)
                      }).catch(err => {
                        console.log("Failed to update Category")
                      });
                    })
       }

       )


    }

getCategories()
{
  return this.firestore.collection('Categories').snapshotChanges()
}

deleteCategory(id)
{
  return this.firestore.doc(`Categories/${id}`).delete()
}

updateCategoryDescription(id) {
  console.log("update category pending");
}

getCategorybyId(category:Category) {
  this.categoryDocument = this.firestore.doc(`Sections/${category.id}`)
  return this.categoryDocument.valueChanges()
}

}
