
  <!-- SHOW SLIDER -->
<!-- <section id="showcase" class="setDefaultSpace">
  <div id="myCarousel" class="carousel slide" data-ride="carousel">
    <ol class="carousel-indicators">
      <li data-target="#myCarousel" data-slide-to="0" class="active"></li>
      <li data-target="#myCarousel" data-slide-to="1"></li>
      <li data-target="#myCarousel" data-slide-to="2"></li>
    </ol>
    <div class="carousel-inner">
      <div class="carousel-item carousel-image-1 active">
        <div class="container">
          <div class="carousel-caption text-right mb-5">
            <h1 class="display-3 fontConfortaRegular">Track your Journey</h1>
            <p class="lead fontOpenSans-RegularSmall">Every progress counts. Understand your "deep" body.
            </p>
          </div>
        </div>
      </div>

      <div class="carousel-item carousel-image-2">
        <div class="container">
          <div class="carousel-caption text-right  mb-5">
            <h1 class="display-3 fontConfortaRegular">Visualize your energy flow</h1>
            <p class="lead fontOpenSans-RegularSmall">Reinforce your progress visualizing your changes.
            </p>
          </div>
        </div>
      </div>

      <div class="carousel-item carousel-image-3">
        <div class="container">
          <div class="carousel-caption text-right mb-5">
            <h1 class="display-3 fontConfortaRegular">Deep Exercises</h1>
            <p class="lead fontOpenSans-RegularSmall">Practice your Kegel's
            </p>
          </div>
        </div>
      </div>
    </div>

    <a href="#myCarousel" data-slide="prev" class="carousel-control-prev">
      <span class="carousel-control-prev-icon"></span>
    </a>
    <a href="#myCarousel" data-slide="next" class="carousel-control-next">
      <span class="carousel-control-next-icon"></span>
    </a>
  </div>
</section> -->


<div class="container setDefaultSpaceContent">
<div class="row">
  <div class="col-12 col-md-3">
    <app-product-filter [category]="category"></app-product-filter>
  </div>
  <div class="col-12 col-md-9">
      <div class="row" *ngIf="cartShopping">
        <ng-container *ngFor="let p of filteredProducts; let i = index">
          <div class="col">
            <app-product-card [product]="p" [shopping-cart]="cartShopping"></app-product-card>
          </div>
          <div *ngIf="(i + 1) % 2 === 0" class="w-100"></div>
        </ng-container>
      </div>
  </div>
</div>
</div>
