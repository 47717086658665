import { Component, OnInit, OnDestroy, ɵConsole } from '@angular/core';
import { Product } from './../../shared/models/product.model';
import { Category } from './../../shared/models/category.model';
import { ShoppingCart } from './../../shared/models/shopping-cart.model';
import { ShoppingCartItem } from './../../shared/models/shopping-cart-item.model';
import { ActivatedRoute, Router,ParamMap  } from '@angular/router';
import { ProductService } from './../../shared/services/product.service';
import { CategoryService } from './../../shared/services/category.service';
import { switchMap, map, take} from 'rxjs/operators';
import { ShoppingCartService } from './../../shared/services/shopping-cart.service';
import { Observable, Subscription, pipe } from 'rxjs';

interface ItemsMap { [productId: string]: ShoppingCartItem }

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss']
})
export class ProductsComponent implements OnInit, OnDestroy {
  products: Product[] = [];
  categories: Category[] = [];
  filteredProducts: Product[] = [];
  filteredCombos: Product[] = [];
  category: string;
  cart$: Observable<ShoppingCart>;
  item : ShoppingCartItem
  cart: ShoppingCartItem[];
  cartShopping: ShoppingCart;
  subscription: Subscription
  cartId: string ='';


  constructor(private route: ActivatedRoute,
    private productService: ProductService,
    private categoryService: CategoryService,
    private shoppingCartService: ShoppingCartService) {}

   ngOnInit() {
    console.log("GET CART products");
      this.subscription = this.shoppingCartService.getCart().subscribe(cart => {
        this.cart = cart.map(e => {
          return {
            id: e.payload.doc.id,
            isEdit: false,
            title: e.payload.doc.data()['title'],
            description: e.payload.doc.data()['description'],
            category: e.payload.doc.data()['category'],
            price: e.payload.doc.data()['price'],
            quantity: e.payload.doc.data()['quantity'],
            imageUrl: e.payload.doc.data()['imageUrl'],
            totalPrice: e.payload.doc.data()['totalPrice'],
          };
        })
        let items: ShoppingCartItem[] = [];
        let itemsMap: { [productId: string]: ShoppingCartItem } = {}
        let products: [{'productId':string}] = [{'productId':null}]
        let productStr = [];
        for(let i=0; i<this.cart.length; i++){
          products.push({'productId':this.cart[i].id});
          productStr.push(this.cart[i].id);

      }
        for(let i=0; i<this.cart.length; i++){
          items.push(new ShoppingCartItem({ id:products[i], ...this.cart[i]}));
      }


    let itemsMapA: ItemsMap = {}
      for(let i=0; i<this.cart.length; i++){
        let str = productStr[i];
        itemsMapA[str] = new ShoppingCartItem()
        itemsMap = this.addElement(itemsMap,itemsMapA);
    }
        this.cartShopping = new ShoppingCart(itemsMap);
        this.cartShopping.items = this.cart

        // Send shopping cart total items counting to header shopping cart icon
        this.productService.itemsCount.next(this.cartShopping.totalItemsCount)
      })
      this.populateProducts();
    }

addElement (itemsMap2, element) {
    let newList = Object.assign(itemsMap2, element)
    return newList
}

    private populateProducts() {
      this.productService.getAll().subscribe( data => {
        this.products = data.map(e => {
          return {
            id: e.payload.doc.id,
            isEdit: false,
            title: e.payload.doc.data()['title'],
            price: e.payload.doc.data()['price'],
            category: e.payload.doc.data()['category'],
            description: e.payload.doc.data()['description'],
            imageUrl: e.payload.doc.data()['imageUrl'],
          };
        })
        this.route.queryParamMap.subscribe(params => {
          this.category = params.get('category');
          this.applyFilter();
        })
        // console.log(this.products);
      })

      this.categoryService.getAll().subscribe( data => {
        this.categories = data.map(e => {
          return {
            id: e.payload.doc.id,
            isEdit: false,
            title: e.payload.doc.data()['title'],
            category: e.payload.doc.data()['category'],
            subcategoryId: e.payload.doc.data()['subcategoryId'],
            image: e.payload.doc.data()['image'],
          };
        })
        // console.log(this.categories);
      })

    }

    private applyFilter() {
      this.filteredProducts = (this.category) ?
      this.products.filter(p => p.category === this.category) :
      this.products;
    }

    private applyCombos() {
      this.filteredCombos = (this.category) ?
      this.products.filter(p => p.category === 'Combos') :
      this.products;
    }

    ngOnDestroy() {
      this.subscription.unsubscribe();
    }
  }
