<!-- PAGE HEADER -->
<header id="page-header">
  <div *ngIf="!mobile" class="container setDefaultSpace pd-2">
    <div class="row">
      <div class="col-md-6 m-auto text-center">
        <h1 *ngIf="language === 'English'" class="fontOpenSans-RegularSmallDarkGray">WHY SO JOURNEY?</h1>
        <h1 *ngIf="language === 'Spanish'" class="fontOpenSans-RegularSmallDarkGray">POR QUÉ SO JOURNEY?</h1>
        <p  *ngIf="language === 'English'" class="fontOpenSans-RegularExtraSmallDarkGray">Orgasms are good for your health, your life, and your relationships</p>
        <p  *ngIf="language === 'Spanish'" class="fontOpenSans-RegularExtraSmallDarkGray">Orgasmos son buenos para su salud, su vida y sus relaciones</p>
        <div class="d-block d-md-none">
          </div>
      </div>
    </div>
  </div>
  <div *ngIf="mobile" class="container pd-2">
    <div class="row">
      <div class="col-md-6 m-auto text-center">
        <h1 *ngIf="language === 'English'" class="fontOpenSans-RegularSmallWhite">WHY SO JOURNEY?</h1>
        <h1 *ngIf="language === 'Spanish'" class="fontOpenSans-RegularSmallWhite">POR QUÉ SO JOURNEY?</h1>
        <p  *ngIf="language === 'English'" class="fontOpenSans-RegularExtraSmallWhite">Orgasms are good for your health, your life, and your relationships</p>
        <p  *ngIf="language === 'Spanish'" class="fontOpenSans-RegularExtraSmallWhite">Orgasmos son buenos para su salud, su vida y sus relaciones</p>
        <div class="d-block d-md-none">
          </div>
      </div>
    </div>
  </div>
</header>

<!-- PAGE HEADER -->
<header id="page-header">
<div class="container pt-4 pd-2">
  <div class="row">
    <div class="card mb-3">
        <div class="row no-gutters">
          <div [ngStyle]="{'background-color': mobile ? '#dc143c' : null }" class="col-md-4 ">
            <!-- <svg class="bd-placeholder-img" width="100%" height="250" xmlns="http://www.w3.org/2000/svg" aria-label="Placeholder: Image" preserveAspectRatio="xMidYMid slice" role="img"><title>Placeholder</title><rect width="100%" height="100%" fill="#868e96"/><text x="50%" y="50%" fill="#dee2e6" dy=".3em">Image</text></svg> -->
            <img src="../../assets/images/soJourney.png" alt="" class="imageContact">

          </div>
          <div class="col-md-8">
            <div [ngStyle]="{'background-color': mobile ? '#dc143c' : null }" class="card-body">
              <article>
              <h5 *ngIf="!mobile" class="card-header centerName fontOpenSans-RegularSmallDarkGra">Medscience Animations Studios Inc.</h5>
              <h5 *ngIf="mobile" class="card-header centerName fontOpenSans-RegularSmallWhite">Medscience Animations Studios Inc.</h5>
              <div *ngIf="language === 'English'&&!mobile" >
              <p class="fontOpenSans-RegularExtraSmallDarkGray pt-4">SO Journey project ( Super Orgasm Journey )  is the result of an internal long Journey for Medscience Animations Studios Inc. Our mission has been always the result of a unique combination of cutting-edge technologies in visualization applied to healthcare and science to improve the quality of life. We have been doing applications for more than 10 years to help patients with Urinary Incontinence or providing new options with 3D visualization tools for surgeons to improve their surgical skills with gynecological procedures or to help to understand the fascinating sexual female anatomy still with a lot of unknowns; all these projects have something in common, the need of a better understanding of the anatomy to improve the quality of life.</p><p class="fontOpenSans-RegularExtraSmallDarkGray pt-4">

                We have a hypothesis, orgasms are good for your health, your life, and your relationships. If orgasms are good, a Super Orgasm should be even better. The caveat of our hypothesis is that the process of the Super Orgasm is an individual Journey, it is a Journey that needs some guidelines where we think we can help. it is a complex and unknown territory that needs to be open to have a better world. Our job is to contribute to this fascinating and exciting journey.</p><p class="fontOpenSans-RegularExtraSmallDarkGray pt-4">

                This challenge starts with the definition of an orgasm, it is not part of the project to try to come with a definition of what is an orgasm, we are going to accept that an orgasm is one of the most intense pleasures attainable to an organism, yet its underlying mechanisms remain poorly <a href="contact/#footnotes" aria-describedby="footnote-label" id="footnotes-ref">understood.</a> The next step is to try to define a Super Orgasm, we are going to accept the definition of a Super-O as an overwhelmingly strong non-ejaculatory orgasm that may involve: durations of minutes at a time; full-body orgasmic waves of pleasure; intense pleasure throughout the pelvic region, particularly the prostate, rectum and surrounding muscles; loss of a sense of reality; strong emotional responses; flashes of color (optical activity in the brain); large muscle contractions; a strong sense of ejaculation (with no emission); protracted involuntary vocalizations, roars or screams; pleasant convulsions; pronounced deep or staccato pelvic thrusting or writhing; a sense of soulful release and relief; a sense of self-redefinition; and, an energized feeling immediately following orgasm and being ready for more (as opposed to post-ejaculation lethargy).<a href="contact/#css" aria-describedby="footnote-label" id="css-ref">lethargy).</a></p><p class="fontOpenSans-RegularExtraSmallDarkGray pt-4">

                  This definition of Super Orgasm can apply to experiences that describe kundalini awakening, transcendent sexual meditation, binaural beat meditation orgasm, or deep body resonance that awakens the unprecedented multi-orgasmic pleasure of The Multi Orgasm Trigger™. What is the relationship between all these techniques? Are they all somehow the same? Do they share the same principles? These are questions that we do not pretend to answer. We want to try to answer this question: Can we "reverse engineer" a Male Super Orgasm? We think so. We present a detailed step-by-step process based on the experience of thousands of people who have shared their exploration through Super Orgasm-loving forums and communities. We include anatomical visualization tools and listening guides designed exclusively to help you find the path to Super Orgasm, a path that will change your life forever and that of your partner.

                </p>
              </div>
              <div *ngIf="language === 'English'&&mobile" >

                  <p class="fontOpenSans-RegularExtraSmallWhite pt-4">SO Journey project ( Super Orgasm Journey )  is the result of an internal long Journey for Medscience Animations Studios Inc. Our mission has been always the result of a unique combination of cutting-edge technologies in visualization applied to healthcare and science to improve the quality of life. We have been doing applications for more than 10 years to help patients with Urinary Incontinence or providing new options with 3D visualization tools for surgeons to improve their surgical skills with gynecological procedures or to help to understand the fascinating sexual female anatomy still with a lot of unknowns; all these projects have something in common, the need of a better understanding of the anatomy to improve the quality of life.</p><p class="fontOpenSans-RegularExtraSmallWhite pt-4">

                    We have a hypothesis, orgasms are good for your health, your life, and your relationships. If orgasms are good, a Super Orgasm should be even better. The caveat of our hypothesis is that the process of the Super Orgasm is an individual Journey, it is a Journey that needs some guidelines where we think we can help. it is a complex and unknown territory that needs to be open to have a better world. Our job is to contribute to this fascinating and exciting journey.</p><p class="fontOpenSans-RegularExtraSmallWhite pt-4">

                    This challenge starts with the definition of an orgasm, it is not part of the project to try to come with a definition of what is an orgasm, we are going to accept that an orgasm is one of the most intense pleasures attainable to an organism, yet its underlying mechanisms remain poorly understood.[1] The next step is to try to define a Super Orgasm, we are going to accept the definition of a Super-O as an overwhelmingly strong non-ejaculatory orgasm that may involve: durations of minutes at a time; full-body orgasmic waves of pleasure; intense pleasure throughout the pelvic region, particularly the prostate, rectum and surrounding muscles; loss of a sense of reality; strong emotional responses; flashes of color (optical activity in the brain); large muscle contractions; a strong sense of ejaculation (with no emission); protracted involuntary vocalizations, roars or screams; pleasant convulsions; pronounced deep or staccato pelvic thrusting or writhing; a sense of soulful release and relief; a sense of self-redefinition; and, an energized feeling immediately following orgasm and being ready for more (as opposed to post-ejaculation lethargy).[2]</p><p class="fontOpenSans-RegularExtraSmallWhite pt-4">

                      This definition of Super Orgasm can apply to experiences that describe kundalini awakening, transcendent sexual meditation, binaural beat meditation orgasm, or deep body resonance that awakens the unprecedented multi-orgasmic pleasure of The Multi Orgasm Trigger™. What is the relationship between all these techniques? Are they all somehow the same? Do they share the same principles? These are questions that we do not pretend to answer. We want to try to answer this question: Can we "reverse engineer" a Male Super Orgasm? We think so. We present a detailed step-by-step process based on the experience of thousands of people who have shared their exploration through Super Orgasm-loving forums and communities. We include anatomical visualization tools and listening guides designed exclusively to help you find the path to Super Orgasm, a path that will change your life forever and that of your partner.

                    </p>
                </div>
              <div *ngIf="language === 'Spanish'&&!mobile" >
                <p class="fontOpenSans-RegularExtraSmallDarkGray pt-4">El proyecto SO Journey (Super Orgasm Journey) es el resultado de un largo viaje interno para Medscience Animations Studios Inc. Nuestra misión siempre ha sido el resultado de una combinación única de tecnologías de vanguardia en visualización aplicadas a la salud y la ciencia para mejorar la calidad de vida. Hemos estado haciendo aplicaciones durante más de 10 años para ayudar a pacientes con incontinencia urinaria o brindando nuevas opciones con herramientas de visualización 3D para que los cirujanos mejoren sus habilidades quirúrgicas con procedimientos ginecológicos o para ayudar a comprender la fascinante anatomía sexual femenina aún con muchas incógnitas. ; todos estos proyectos tienen algo en común, la necesidad de un mejor conocimiento de la anatomía para mejorar la calidad de vida.</p><p class="fontOpenSans-RegularExtraSmallDarkGray pt-4">

                  Tenemos una hipótesis, los orgasmos son buenos para su salud, su vida y sus relaciones. Si los orgasmos son buenos, un Super Orgasmo debería ser aún mejor. La salvedad de nuestra hipótesis es que el proceso del Super Orgasmo es un Viaje individual, es un Viaje que necesita algunas pautas donde creemos que podemos ayudar. es un territorio complejo y desconocido que necesita abrirse para tener un mundo mejor. Nuestro trabajo es contribuir a este fascinante y emocionante viaje.</p><p class="fontOpenSans-RegularExtraSmallDarkGray pt-4">

                  Este desafío comienza con la definición de un orgasmo, no es parte del proyecto tratar de llegar a una definición de lo que es un orgasmo, vamos a aceptar que un orgasmo es uno de los placeres más intensos alcanzables para un organismo, sin embargo, sus mecanismos subyacentes siguen siendo poco <a href="contact/#footnotes" aria-describedby="footnote-label" id="footnotes-ref">conocidos.</a> El siguiente paso es tratar de definir un Super Orgasmo, vamos a aceptar la definición de un Super-O como un orgasmo sin eyaculación abrumadoramente fuerte que puede involucrar: duraciones de minutos a la vez; ondas orgásmicas de cuerpo entero de placer; placer intenso en toda la región pélvica, particularmente la próstata, el recto y los músculos circundantes; pérdida del sentido de la realidad; fuertes respuestas emocionales; destellos de color (actividad óptica en el cerebro); grandes contracciones musculares; una fuerte sensación de eyaculación (sin emisión); vocalizaciones, rugidos o gritos involuntarios prolongados; convulsiones agradables; movimientos pélvicos pronunciados, profundos o entrecortados, o contorsiones; una sensación de alivio y alivio conmovedores; un sentido de auto-redefinición; y una sensación de energía inmediatamente después del orgasmo quedando listo para más (en contraposición al letargo posterior a la <a href="contact/#css" aria-describedby="footnote-label" id="css-ref">eyaculación).</a></p><p class="fontOpenSans-RegularExtraSmallDarkGray pt-4">

                    Esta definición de Súper Orgasmo puede ser aplicable experiencias que describen el despertar de kundalini, la meditación sexual trascendente, el orgasmo de meditación con latidos binaurales o la resonancia de cuerpo profundo que despierta el placer multiorgásmico sin precedentes del The Multi Orgasm Trigger™. ¿Cuál es la relación entre todas estas técnicas? ¿Son todos de alguna manera iguales? ¿Comparten los mismos principios? Estas son preguntas que no pretendemos responder, la pregunta que queremos tratar de responder es: ¿Podemos hacer "ingeniería inversa" de un Súper Orgasmo masculino? Creemos que si.  Presentamos un detallado proceso de paso a paso basado en la experiencia de miles de personas que han compartido su exploraciön a traves de foros y comunidades amantes del Super Orgasmo. Le incluimos herramientas de visualizacion anatomica y guias auditivas diseñadas exclusivamente para ayudarle a encontrar el camino del Super Orgasmo, camino que cambiara su vida para siempre y la de su pareja.
                  </p>
                </div>
                <div *ngIf="language === 'Spanish'&&mobile" >
                    <p class="fontOpenSans-RegularExtraSmallWhite pt-4">El proyecto SO Journey (Super Orgasm Journey) es el resultado de un largo viaje interno para Medscience Animations Studios Inc. Nuestra misión siempre ha sido el resultado de una combinación única de tecnologías de vanguardia en visualización aplicadas a la salud y la ciencia para mejorar la calidad de vida. Hemos estado haciendo aplicaciones durante más de 10 años para ayudar a pacientes con incontinencia urinaria o brindando nuevas opciones con herramientas de visualización 3D para que los cirujanos mejoren sus habilidades quirúrgicas con procedimientos ginecológicos o para ayudar a comprender la fascinante anatomía sexual femenina aún con muchas incógnitas. ; todos estos proyectos tienen algo en común, la necesidad de un mejor conocimiento de la anatomía para mejorar la calidad de vida.</p><p class="fontOpenSans-RegularExtraSmallWhite pt-4">

                      Tenemos una hipótesis, los orgasmos son buenos para su salud, su vida y sus relaciones. Si los orgasmos son buenos, un Super Orgasmo debería ser aún mejor. La salvedad de nuestra hipótesis es que el proceso del Super Orgasmo es un Viaje individual, es un Viaje que necesita algunas pautas donde creemos que podemos ayudar. es un territorio complejo y desconocido que necesita abrirse para tener un mundo mejor. Nuestro trabajo es contribuir a este fascinante y emocionante viaje.</p><p class="fontOpenSans-RegularExtraSmallWhite pt-4">

                      Este desafío comienza con la definición de un orgasmo, no es parte del proyecto tratar de llegar a una definición de lo que es un orgasmo, vamos a aceptar que un orgasmo es uno de los placeres más intensos alcanzables para un organismo, sin embargo, sus mecanismos subyacentes siguen siendo poco conocidos.[1] El siguiente paso es tratar de definir un Super Orgasmo, vamos a aceptar la definición de un Super-O como un orgasmo sin eyaculación abrumadoramente fuerte que puede involucrar: duraciones de minutos a la vez; ondas orgásmicas de cuerpo entero de placer; placer intenso en toda la región pélvica, particularmente la próstata, el recto y los músculos circundantes; pérdida del sentido de la realidad; fuertes respuestas emocionales; destellos de color (actividad óptica en el cerebro); grandes contracciones musculares; una fuerte sensación de eyaculación (sin emisión); vocalizaciones, rugidos o gritos involuntarios prolongados; convulsiones agradables; movimientos pélvicos pronunciados, profundos o entrecortados, o contorsiones; una sensación de alivio y alivio conmovedores; un sentido de auto-redefinición; y una sensación de energía inmediatamente después del orgasmo quedando listo para más (en contraposición al letargo posterior a la eyaculación).[2]</p><p class="fontOpenSans-RegularExtraSmallWhite pt-4">

                        Esta definición de Súper Orgasmo puede ser aplicable experiencias que describen el despertar de kundalini, la meditación sexual trascendente, el orgasmo de meditación con latidos binaurales o la resonancia de cuerpo profundo que despierta el placer multiorgásmico sin precedentes del The Multi Orgasm Trigger™. ¿Cuál es la relación entre todas estas técnicas? ¿Son todos de alguna manera iguales? ¿Comparten los mismos principios? Estas son preguntas que no pretendemos responder, la pregunta que queremos tratar de responder es: ¿Podemos hacer "ingeniería inversa" de un Súper Orgasmo masculino? Creemos que si.  Presentamos un detallado proceso de paso a paso basado en la experiencia de miles de personas que han compartido su exploraciön a traves de foros y comunidades amantes del Super Orgasmo. Le incluimos herramientas de visualizacion anatomica y guias auditivas diseñadas exclusivamente para ayudarle a encontrar el camino del Super Orgasmo, camino que cambiara su vida para siempre y la de su pareja.
                      </p>
                  </div>
                <div>
                  <footer>
                    <h2 class="visually-hidden" id="footnote-label">Footnotes</h2>
                    <ol *ngIf="!mobile">
                      <li id="footnotes"><a target="_blank" href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC5087698/">What is orgasm? A model of sexual trance and climax via rhythmic entrainment</a><a href="contact/#footnotes-ref" aria-label="Back to content">↩</a></li>
                      <li id="css"><a target="_blank" href="http://wiki.malegspot.com/index.php?title=The_Super-O">The Super-O</a> <a href="contact/#css-ref" aria-label="Back to content">↩</a></li>
                      <li id="kmo"><a target="_blank" href="https://multiples.com/">The Multi Orgasm Trigger™</a> <a href="contact/#kmo-ref" aria-label="Back to content">↩</a></li>
                    </ol>
                  </footer>
                  <footer>
                    <h2 class="visually-hidden" id="footnote-label">Footnotes</h2>
                    <ol *ngIf="mobile">
                      <li id="footnotes" [ngStyle]="{'color': mobile ? 'white' : null }">What is orgasm? A model of sexual trance and climax via rhythmic entrainment - https://www.ncbi.nlm.nih.gov/pmc/articles/PMC5087698/</li>
                      <li id="css" [ngStyle]="{'color': mobile ? 'white' : null }">The Super-O - http://wiki.malegspot.com/index.php?title=The_Super-O </li>
                      <li id="kmo" [ngStyle]="{'color': mobile ? 'white' : null }">The Multi Orgasm Trigger - https://multiples.com/™ </li>
                    </ol>
                  </footer>
                </div>
              </article>
            </div>
          </div>
        </div>
      </div>

  </div>
</div>
</header>
