import { Component, OnInit, OnDestroy } from '@angular/core';
import { UploadInfoService } from '../shared/services/upload-info.service'
import { ContentSection } from './../shared/models/contentSection.model'
import {YoutubeService } from './../shared/services/youtube.service'
import { takeUntil } from 'rxjs/operators';
import { Observable , BehaviorSubject} from 'rxjs';
import { Subject } from 'rxjs';
import { NgxMasonryOptions } from 'ngx-masonry';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser'

declare const notifier: Observable<any>;

@Component({
  selector: 'app-display',
  templateUrl: './display.component.html',
  styleUrls: ['./display.component.scss']
})
export class DisplayComponent implements OnInit {

  contentSections: ContentSection[];
  videos: any[];
  channel: any;
  channelDetails: any;
  thumbnail: string = "";
  destroy$ = new BehaviorSubject<boolean>(false);
  private unsubscribe$: Subject<any> = new Subject();

  public masonryOptions: NgxMasonryOptions = {
		transitionDuration: '0.2s',
		gutter: 20,
		columnWidth: 300,
		// resize: true,
		initLayout: true,
		// fitWidth: true,
		// originLeft: false,
	};

	masonryImages;
	limit = 15;

  constructor(private contentservice: UploadInfoService, private youTubeService: YoutubeService) { }

  ngOnInit() {

    //UC_LtA_EtCr7Jp5ofOsYt18g
    //UCjOl2AUblVmg2rA_cRgZkFg
    //UCxMubMiVAm1XjOKe4Fc-wEA
    this.videos = [];
    this.youTubeService
    .getVideosForChanel('UCUiyFVY_Gskbfl3NPGz_mpw', 15)
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(lista => {
    for (let element of lista["items"]) {
    element.cleanUrl='https://www.youtube.com/embed/'+`${element.id.videoId}`+'?rel=0'
    this.videos.push(element)
    }
    });
    console.log("this videos", this.videos)

    this.youTubeService
    .getChannelInfo('UCUiyFVY_Gskbfl3NPGz_mpw')
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(lista => {
    this.channel = lista
    this.channelDetails = this.channel.items[0]
    this.thumbnail=this.channelDetails.snippet.thumbnails.high.url
    console.log("this.channelDetails", this.channel.items[0])
    console.log("thumbnail", this.thumbnail)
    });

    this.contentservice.getContentbySection('MyVideos').subscribe(val => {
      this.contentSections = val;
      console.log("this.contentSections", this.contentSections)
    })

    this.masonryImages = this.contentSections.slice(0, this.limit);

  }

  // cleanUrl(url:string): string {
  //   return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  // }

  ngOnDestroy() {
    this.destroy$.next(true);
    // Now let's also unsubscribe from the subject itself:
    this.destroy$.unsubscribe();
  }
}

