<div *ngIf="product.title" class="card">
  <img *ngIf="product.imageUrl" class="card-img-top" [src]="product.imageUrl" alt="{{ product.title }}">
  <div class="card-body">
    <h4 class="card-title">{{ product.title }}</h4>
    <!-- <p class="card-text">{{ product.price | currency:'USD':true }}</p> -->
    <p class="card-text">{{ product.price | currency }}</p>
  </div>
  <div *ngIf="showActions && shoppingCart" class="card-footer">
    <div *ngIf="showActions" class="card-footer">
    <button
      *ngIf="shoppingCart.getQuantity(product) === 0; else updateQuantity"
      (click)="addToCart()"
      class="btn btn-secondary btn-block">Add to Cart</button>
      <!-- <button
      (click)="addToCart()"
      class="btn btn-secondary btn-block">Add to Cart</button> -->
      <!-- <div>{{getQuantity()}}</div> -->
    <ng-template #updateQuantity>
      <app-product-quantity [product]="product" [shopping-cart]="shoppingCart">
      </app-product-quantity>
    </ng-template>
  </div>
  </div>
</div>

