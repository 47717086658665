<!-- <div class="pb-5">
  <div class="dropdown show pd-5">
      <a class="btn btn-secondary dropdown-toggle" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        Select Category
      </a>

      <div *ngIf="!(authService.isFamily(userLoggedIn))">
          <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
              <a
              *ngFor="let c of nonFamilyvideosType"
              routerLink="/videos"
              [queryParams]="{ category: c.SubCategoryId }"
              class="dropdown-item"
              [class.active]="category === c.SubCategoryId"
              >
              {{ c.Title }}
            </a>
          </div>
      </div>

      <div *ngIf="(authService.isFamily(userLoggedIn))">
          <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
              <a
              *ngFor="let c of familyvideosType"
              routerLink="/videos"
              [queryParams]="{ category: c.SubCategoryId }"
              class="dropdown-item"
              [class.active]="category === c.SubCategoryId"
              >
              {{ c.Title }}
            </a>
          </div>
      </div>
    </div>
  </div> -->


  <div class="pb-5">
    <div class="dropdown show pd-5">
        <div *ngIf="!(authService.isFamily(userLoggedIn))">
            <label for="channels">Select Video:</label>

            <select id="channels" [(ngModel)]="categorySelected">
                <option
                *ngFor="let c of nonFamilyvideosType"
                [value]="c.SubCategoryId"
                >
                {{ c.Title }}
            </option>
            </select>
            <div *ngIf="categorySelected">
              <button><a [routerLink]="['/myvideos']" [queryParams]="{category: categorySelected}">Load Videos</a></button>
            </div>
        </div>

        <div *ngIf="(authService.isFamily(userLoggedIn))">
            <label for="channels">Seleccione familia:</label>

            <select id="channels" [(ngModel)]="categorySelected">
                <option
                *ngFor="let c of familyvideosType"
                [value]="c.SubCategoryId"
                >
                {{ c.Title }}
            </option>
            </select>
            <div *ngIf="categorySelected">
              <button><a [routerLink]="['/videos']" [queryParams]="{category: categorySelected}">Cargar videos por familia</a></button>
            </div>
        </div>

      </div>
    </div>

