import { Injectable, ɵConsole } from '@angular/core';
import { AngularFireDatabase, AngularFireList} from '@angular/fire/database';
import { AngularFireAuth } from "@angular/fire/auth";
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { PictureTechnique } from './../models/pictureTechnique.model';
import { BehaviorSubject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class ImageService {
  imageDetailList: AngularFireList<any>;
  error = new BehaviorSubject<string>('');
  uploadSuccess = new BehaviorSubject<boolean>(false);

  constructor(private firebase: AngularFireDatabase, public afs: AngularFirestore) { }

  getImageDetailList() {
      if (this.firebase.list('imageDetails')) {
        this.imageDetailList = this.firebase.list('imageDetails');
      } else {
          return;
      }
  }

  insertImageDetails(imageDetails) {
    this.imageDetailList.push(imageDetails);
  }

  setPictureData(imageDetailList, id) {
    const picRef: AngularFirestoreDocument<any> = this.afs.collection(imageDetailList.category).doc(String(id))
    picRef.set ({
      Title: imageDetailList.caption,
      PicId: String(id),
      Category: imageDetailList.category,
      SubCategory: imageDetailList.subcategory,
      CreatedAt: new Date,
      UrlImage: imageDetailList.imageUrl,
      PhotoSource: imageDetailList.sourceImage,
      PhotoInfo: imageDetailList.content,
      FileName: imageDetailList.fileName,
      Size: imageDetailList.size,
      Type: imageDetailList.type,
      Rate: imageDetailList.rate,
      Width: imageDetailList.width,
      Height: imageDetailList.height,
    }).then(() =>  {
      // console.log("Photo saved succesfully")
    }).catch(err => {
      // console.log("Failed to update photo")
    });

  }

  updatePictureData(imageDetailList, id) {
    // console.log("service imageDetailList", imageDetailList)
    // console.log("service id", id)
    const picRef: AngularFirestoreDocument<any> = this.afs.collection('Pictures').doc(String(id))
    picRef.update ({
      Title: imageDetailList.caption,
      PicId: String(id),
      Category: imageDetailList.category,
      SubCategory: imageDetailList.subcategory,
      CreatedAt: new Date,
      UrlImage: imageDetailList.imageUrl,
      PhotoSource: imageDetailList.sourceImage,
      PhotoInfo: imageDetailList.content,
      FileName: imageDetailList.fileName,
      Size: imageDetailList.size,
      Type: imageDetailList.type,
      Rate: imageDetailList.rate,
      Width: imageDetailList.width,
      Height: imageDetailList.height,
    }).then(() =>  {
      console.log("Photo updated succesfully")
      this.uploadSuccess.next(true)
    }).catch(err => {
      console.log("Failed to update photo")
    });

  }


  // setCategoryData(imageDetailList, id) {
  //   const picRef: AngularFirestoreDocument<any> = this.afs.collection(imageDetailList.category).doc(String(id))
  //   picRef.set ({
  //     Title: imageDetailList.caption,
  //     Speaker: String(id),
  //     BackgroundImage: imageDetailList.imageUrl,
  //     Room: imageDetailList.category,
  //     SubRoom: imageDetailList.subcategory,
  //     Content: imageDetailList.content,
  //     Time: "April 5, 1:30",
  //     Index: parseInt(imageDetailList.index),
  //     Background: "400x267-sketch-anerosV3",
  //     CreatedAt: new Date,
  //     UrlImage: imageDetailList.imageUrl,
  //     UrlVideo: "pending video url",
  //     PhotoSource: imageDetailList.sourceImage,
  //     PhotoInfo: "pending photo source",
  //     ExternalWeb1: "pending external web 1",
  //     ExternalWeb2: "pending external web 2",
  //     ExternalWeb3: "pending external web 3",


  //   }).then(function() {
  //     console.log("Technique picture saved succesfully")
  //   }).catch(err => {
  //     console.log("Failed to update Technique picture saved succesfully")
  //   });

  // }
}
