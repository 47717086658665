import { Injectable } from '@angular/core';
import { ShoppingCartService } from './shopping-cart.service';
import { Order } from './../models/order.model';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument, } from '@angular/fire/firestore';
import { AngularFireDatabase, AngularFireList } from '@angular/fire/database';
import { AngularFireStorage } from '@angular/fire/storage';
import { Observable, BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class OrderService {
  placeOrderSuccess = new BehaviorSubject<string>(null);
  placeUpdatedSuccess = new BehaviorSubject<boolean>(false);

  constructor(private firestore: AngularFirestore, private db: AngularFireDatabase, private shoppingCartService: ShoppingCartService, private router: Router,) { }



  // async placeOrder(order) {
  //   let result = await this.db.list('/orders').push(order);
  //   this.shoppingCartService.clearCart();
  //   return result;
  // }

  placeOrder(id,order) {
    console.log("place order id",id);
    console.log("place order order",order);
    const picRef: AngularFirestoreDocument<Order> = this.firestore.collection('orders').doc(String(id))
              picRef.set ({
                id:String(id),
                items: order.items,
                shipInfo: order.shipInfo,
                datePlaced: order.datePlaced,
                process:order.process,
                userId: order.userId,
              }).then(() => {
                        console.log("Order placed succesfully")
                        this.placeOrderSuccess.next(id)
                        this.shoppingCartService.clearCart();
                        // this.router.navigate(['/order-success', id])
                      }).catch(err => {
                        console.log("Failed to place Order")
                      });
  }

  getOrders()
    {
      return this.firestore.collection<Order>('orders').snapshotChanges();
    }


    updateProcess(id,step,order) {
      console.log('update step',step);
      let process = order.process;

        switch(step) {
          case 0:
            process = [{stage:'received',status:'ok',comment:'',date:new Date().getTime()},{stage:'processing',status:'pending',comment:'',date:''},{stage:'ready',status:'pending',comment:'',date:''},{stage:'delivery',status:'pending',comment:'',date:''},{stage:'transit',status:'pending',comment:'',date:''},{stage:'delivered',status:'pending',comment:'',date:''},{stage:'feedback',status:'pending',comment:'',date:''}]
            break;
            case 1:
            process[1] = {stage:'processing',status:'ok',comment:'',date:new Date().getTime()}
            break;
            case 2:
              process[2] = {stage:'ready',status:'ok',comment:'',date:new Date().getTime()}
            break;
            case 3:
              process[3] = {stage:'delivery',status:'ok',comment:'',date:new Date().getTime()}
            break;
            case 4:
              process[4] = {stage:'transit',status:'ok',comment:'',date:new Date().getTime()}
            break;
            case 5:
              process[5] = {stage:'delivered',status:'ok',comment:'',date:new Date().getTime()}
            break;
            case 6:
              process[6] = {stage:'feedback',status:'ok',comment:'',date:new Date().getTime()}
            break;
        }

      const picRef: AngularFirestoreDocument<Order> = this.firestore.collection('orders').doc(String(id))
                picRef.update ({
                  id:String(id),
                  items: order.items,
                  shipInfo: order.shipInfo,
                  datePlaced: order.datePlaced,
                  process:process,
                  userId: order.userId,
                }).then(() => {
                          console.log("Order updated succesfully")
                          this.placeUpdatedSuccess.next(true)

                        }).catch(err => {
                          console.log("Failed to update Order")
                        });
    }

  // getOrders() {
  //   return this.db.list('/orders');
  // }

  // getOrdersByUser(userId: string) {
  //   return this.db.list('/orders', {
  //     query: {
  //       orderByChild: 'userId',
  //       equalTo: userId
  //     }
  //   });
  // }
}
