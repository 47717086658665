<!-- PAGE HEADER -->
<header id="page-header">
  <div *ngIf="!mobile" class="container setDefaultSpace pd-2">
    <div class="row">
      <div class="col-12">
          <div *ngIf="(authService.isFamily(userLoggedIn))">
                <h1 class="fontOpenSans-RegularSmallDarkGray">Seleccionar Familia</h1>
                <p class="fontOpenSans-RegularExtraSmallDarkGray">Lista de familias que han aceptado pertenecer a este registro de la historia familiar</p>
                <app-research-filter [category]="category" [isFamily]="isFamily"></app-research-filter>
                <div class="d-block d-md-none">
                  </div>
          </div>

          <!-- <div *ngIf="!(authService.isFamily(userLoggedIn))"> -->
            <div *ngIf="language === 'English'">
              <h1 class="fontOpenSans-RegularSmallDarkGray  text-center pb-5">Research Sources</h1>
              <p class="fontOpenSans-RegularExtraSmallDarkGray">Medscience Animations Studios research Team made a great effort to obtain valuable information from different sources related to the process, techniques, and results of the male Super Orgasms.
              </p>
              <p class="fontOpenSans-RegularExtraSmallDarkGray">The characteristics of the topic required an open mind approach. This is not rigorous scientific research, but we tried to combine different valuable data sources. Some data results from publications in medical and scientific journals, others by companies with non-traditional data sources ( forums ) with a tremendous value for the topic, others by influential bloggers, and others by YouTubers with good content.
              </p>
              <p class="fontOpenSans-RegularExtraSmallDarkGray">We tried to avoid exposing the research to adult content sources even if, in some cases, they can provide valuable information of the unknown of the topic. We chose some selected sources as a reference to display the experience of a Super Orgasm. We chose some selected sources as a reference to display the experience of a Super Orgasm. We want to present a visual content of the life-changing event that means to a man to get a Super Orgasm.
              </p>
              <p class="fontOpenSans-RegularExtraSmallDarkGray">We also analyzed the content of more than 10,000 users of forums described a Super Orgams(*)  We codified different approaches trying to get a pleasure pattern tracker. With this approach, we created a tool ( Super Orgasm tracker ) where the users can track their sessions, their progress and, more importantly, find the specific Super Orgasm pattern.
              </p>
              <p class="fontOpenSans-RegularExtraSmallDarkGray">The Team also identified common points between the different techniques and the pleasure model, such as the challenge to identify deep pelvic muscles, the connection between the brain and sounds to stimulate the nerves associated with the Super-O, and the importance of understanding visualizing the deep pelvic Anatomy. Our product mix is trying to provide tools to facilitate the process of the Super O.
              </p>
              <p class="fontOpenSans-RegularExtraSmallDarkGray">With a Super-O tracker and a series of tools to train the connection between the deep pelvic nerves, muscles, and arousal, we have the perfect portfolio to “reverse engineer” your own Super Orgasm.
              </p>
              <p class="fontOpenSans-RegularExtraSmallDarkGray">(*)
              </p>

            </div>
            <div *ngIf="language === 'Spanish'">
              <h1 class="fontOpenSans-RegularSmallDarkGray text-center pb-5">Fuentes de Investigación</h1>
              <p class="fontOpenSans-RegularExtraSmallDarkGray">El equipo de investigación de Medscience Animations Studios realizó un gran esfuerzo para obtener información valiosa de diferentes fuentes relacionadas con el proceso, técnicas y resultados de los Super Orgasmos masculinos.
              </p>
              <p class="fontOpenSans-RegularExtraSmallDarkGray">Las características del tema requirieron un enfoque de mente abierta, esta no es una investigación científica rigurosa , intentamos combinar diferentes fuentes valiosas de datos, algunas de ellas publicadas en revistas científicas médicas, otras por empresas con fuentes de datos no tradicionales (foros) con un valor tremendo para el tema, otros por bloggers influyentes y otros por youtubers con buen contenido.
              </p>
              <p class="fontOpenSans-RegularExtraSmallDarkGray">Intentamos evitar exponer la investigación a fuentes de contenido para adultos, incluso si en algunos casos pueden proporcionar una información valiosa por lo desconocido del tema. Elegimos algunas fuentes seleccionadas como referencia para mostrar lo que es la experiencia de un Super Orgasmo, queremos presentar un contenido visual de la experiencia que significa para un hombre obtener un Super Orgasmo.
              </p>
              <p class="fontOpenSans-RegularExtraSmallDarkGray">También analizamos el contenido con más de 10.000 usuarios de foros orientados a los Super Orgasmos. Codificamos diferentes enfoques tratando de obtener patrón de generación de placer de un Super Orgasmo. Con este enfoque creamos una herramienta (Super Orgasm tracker) donde los usuarios pueden seguir sus sesiones, su progreso y, lo que es más importante, encontrar el patrón específico del Super Orgasmo.
              </p>
              <p class="fontOpenSans-RegularExtraSmallDarkGray">El Equipo también identificó algunos puntos en común entre las diferentes técnicas y el modelo de placer como son el desafío de identificar los músculos pélvicos profundos, la conexión entre el cerebro y los sonidos para estimular los nervios asociados con el Super-O y la importancia de comprender y visualizar la Anatomía pélvica profunda. Nuestra mezcla de productos está tratando de proporcionar herramientas para facilitar el proceso de Super O.
              </p>
              <p class="fontOpenSans-RegularExtraSmallDarkGray">Con la combinación de un rastreador Super-O y una serie de herramientas para entrenar la conexión entre los nervios pélvicos profundos, los músculos y la excitación, tenemos el portafolio perfecto para “aplicar ingeniería inversa” a su propio Super Orgasmo.
              </p>
            </div>
            <div class="text-center pt-5">
              <app-research-filter [category]="category" [isFamily]="isFamily"></app-research-filter>
            </div>
              <div class="d-block d-md-none">
                  <!-- <app-channel-filter [category]="category"></app-channel-filter> -->
                </div>
        <!-- </div> -->

      </div>
    </div>
  </div>
  <div *ngIf="mobile" class="container pd-2">
    <div class="row">
      <div class="col-12">
          <div *ngIf="(authService.isFamily(userLoggedIn))">
                <h1 class="fontOpenSans-RegularSmallDarkGray">Seleccionar Familia</h1>
                <p class="fontOpenSans-RegularExtraSmallDarkGray">Lista de familias que han aceptado pertenecer a este registro de la historia familiar</p>
                <app-research-filter [category]="category" [isFamily]="isFamily"></app-research-filter>
                <div class="d-block d-md-none">
                  </div>
          </div>

          <!-- <div *ngIf="!(authService.isFamily(userLoggedIn))"> -->
            <div *ngIf="language === 'English'">
              <h1 class="fontOpenSans-RegularSmallWhite  text-center pb-5">Research Sources</h1>
              <p class="fontOpenSans-RegularExtraSmallWhite">Medscience Animations Studios research Team made a great effort to obtain valuable information from different sources related with the process, techniques and results of the male Super Orgasms.
              </p>
              <p class="fontOpenSans-RegularExtraSmallWhite">The characteristics of the topic required an open mind approach, this is not a rigorous scientific research but we tried to combine different valuable sources of data, some of them published in medical scientific journals, others by companies with a non traditional data sources ( forums ) with a tremendous value for the topic, other by influential bloggers and others by youtubers with good content.
              </p>
              <p class="fontOpenSans-RegularExtraSmallWhite">We tried to avoid to expose the research to adult content sources even if in some cases they can provide a valuable information of the unknown of the topic. We choose some selected sources as a reference to display what is the experience of a Super Orgasm, we want to present a visual content of the life changing event that means to a man to get a Super Orgasm.
              </p>
              <p class="fontOpenSans-RegularExtraSmallWhite">We also analyzed the content of more than 10,000 users of forums described a Super Orgams. We codified different approaches trying to get a pleasure pattern tracker. With this approach we created a tool ( Super Orgasm tracker ) where the users can track their sessions, their progress and, what is more important, to find the specific Super Orgasm pattern.
              </p>
              <p class="fontOpenSans-RegularExtraSmallWhite">The Team also identified some common points between the different techniques and the pleasure model such as the challenge to identify deep pelvic muscles, the connection between the brain and sounds to stimulate the nerves associated with the Super-O and the importance to understand and visualize the deep pelvic Anatomy. Our product mix is trying to provide tools to facilitate the process of the Super O.
              </p>
              <p class="fontOpenSans-RegularExtraSmallWhite">With the combination of a Super-O tracker and a series of tools to train the connection between the deep pelvic nerves, muscles and arousal we have the perfect portfolio to “reverse engineer” your own Super Orgasm.
              </p>

            </div>
            <div *ngIf="language === 'Spanish'">
              <h1 class="fontOpenSans-RegularSmallWhite text-center pb-5">Fuentes de Investigación</h1>
              <p class="fontOpenSans-RegularExtraSmallWhite">El equipo de investigación de Medscience Animations Studios realizó un gran esfuerzo para obtener información valiosa de diferentes fuentes relacionadas con el proceso, técnicas y resultados de los Super Orgasmos masculinos.
              </p>
              <p class="fontOpenSans-RegularExtraSmallWhite">Las características del tema requirieron un enfoque de mente abierta, esta no es una investigación científica rigurosa , intentamos combinar diferentes fuentes valiosas de datos, algunas de ellas publicadas en revistas científicas médicas, otras por empresas con fuentes de datos no tradicionales (foros) con un valor tremendo para el tema, otros por bloggers influyentes y otros por youtubers con buen contenido.
              </p>
              <p class="fontOpenSans-RegularExtraSmallWhite">Intentamos evitar exponer la investigación a fuentes de contenido para adultos, incluso si en algunos casos pueden proporcionar una información valiosa por lo desconocido del tema. Elegimos algunas fuentes seleccionadas como referencia para mostrar lo que es la experiencia de un Super Orgasmo, queremos presentar un contenido visual de la experiencia que significa para un hombre obtener un Super Orgasmo.
              </p>
              <p class="fontOpenSans-RegularExtraSmallWhite">También analizamos el contenido con más de 10.000 usuarios de foros orientados a los Super Orgasmos. Codificamos diferentes enfoques tratando de obtener patrón de generación de placer de un Super Orgasmo. Con este enfoque creamos una herramienta (Super Orgasm tracker) donde los usuarios pueden seguir sus sesiones, su progreso y, lo que es más importante, encontrar el patrón específico del Super Orgasmo.
              </p>
              <p class="fontOpenSans-RegularExtraSmallWhite">El Equipo también identificó algunos puntos en común entre las diferentes técnicas y el modelo de placer como son el desafío de identificar los músculos pélvicos profundos, la conexión entre el cerebro y los sonidos para estimular los nervios asociados con el Super-O y la importancia de comprender y visualizar la Anatomía pélvica profunda. Nuestra mezcla de productos está tratando de proporcionar herramientas para facilitar el proceso de Super O.
              </p>
              <p class="fontOpenSans-RegularExtraSmallWhite">Con la combinación de un rastreador Super-O y una serie de herramientas para entrenar la conexión entre los nervios pélvicos profundos, los músculos y la excitación, tenemos el portafolio perfecto para “aplicar ingeniería inversa” a su propio Super Orgasmo.
              </p>
            </div>
            <div class="text-center pt-5">
              <app-research-filter [category]="category" [isFamily]="isFamily"></app-research-filter>
            </div>
              <div class="d-block d-md-none">
                  <!-- <app-channel-filter [category]="category"></app-channel-filter> -->
                </div>
        <!-- </div> -->

      </div>
    </div>
  </div>
</header>

<!-- PAGE HEADER -->
<!-- <header id="page-header">
<div class="container pt-4 pb-4">
  <div class="row">
    <div class="card mb-3">
        <div class="row no-gutters">
          <div class="col-md-4">
            <img src="{{thumbnail}}" alt="" class="img-fluid card-img-top">

          </div>
          <div class="col-md-8">
            <div class="card-body">
              <img src="../../assets/images/youtube_logo.png" style="height: 20px;width:auto" class="img-fluid card-img-top">
              <h5 class="card-header centerName fontOpenSans-RegularSmallDarkGra">Nuestro canal de Youtube : {{channelDetails.snippet.title}}</h5>
              <p class="fontOpenSans-RegularExtraSmallDarkGray pt-4">{{channelDetails.snippet.description}}</p>
              <p class="fontOpenSans-RegularExtraSmallDarkGray pt-4"><span class="fontOpenSans-RegularSmallDarkGray">ID :&nbsp;</span> {{channelDetails.id}}</p>
              <p class="fontOpenSans-RegularExtraSmallDarkGray pt-4"><span class="fontOpenSans-RegularSmallDarkGray">Suscritos :&nbsp;</span>{{channelDetails.statistics.subscriberCount}}</p>
              <p class="fontOpenSans-RegularExtraSmallDarkGray pt-4"><span class="fontOpenSans-RegularSmallDarkGray">Visitas :&nbsp;</span> {{channelDetails.statistics.viewCount}}</p>
              <p class="fontOpenSans-RegularExtraSmallDarkGray pt-4"><span class="fontOpenSans-RegularSmallDarkGray">Videos :&nbsp;</span> {{channelDetails.statistics.videoCount}}</p>
            </div>
          </div>
        </div>
      </div>

  </div>
</div>
</header>

<div class="no-scrolling">
  <ngx-masonry class="positioning" [options]="masonryOptions" [useImagesLoaded]="true">
    <ngxMasonryItem *ngFor="let video of videos" class="masonry-item drop-shadow">
            <div class="card">
                <div>
                  <iframe width="100%" height="auto" [src]="video.cleanUrl | safeHtml"
                  frameborder="0"allow="autoplay; ancrypted-media" allowFullScreen="allowFullScreen"></iframe>
                </div>
                    <div class="card-body">
                        <div class="card-header">{{ video.snippet.title }}</div>
                        <div class="card-body">
                                <h5 class="card-title">{{ video.snippet.description }}</h5>
                        </div>
                    </div>
                </div>
    </ngxMasonryItem>
  </ngx-masonry>
  </div> -->

  <!-- <div class="row">
    <div class="col">
      <div class="row">
          <ng-container *ngFor="let p of filteredContentSection; let i = index">
            <div class="col-12 col-md-6">
              <app-channel-card [channel]="p"></app-channel-card>
            </div>
            <div *ngIf="(i + 1) % 2 === 0" class="w-100"></div>
          </ng-container>
        </div>
    </div>
  </div> -->

  <div class="no-scrolling vertical-center">
        <ngx-masonry class="positioning" [options]="masonryOptions" [useImagesLoaded]="true">
          <ngxMasonryItem *ngFor="let p of filteredContentSection" class="masonry-item drop-shadow">
                      <app-research-card [channel]="p"></app-research-card>
          </ngxMasonryItem>
        </ngx-masonry>
      <!-- <div *ngIf="authService.isFamily(userLoggedIn)">
          <ngx-masonry class="positioning" [options]="masonryOptions" [useImagesLoaded]="true">
            <ngxMasonryItem *ngFor="let p of filteredFamilyContentSection" class="masonry-item drop-shadow">
                        <app-channel-card [channel]="p"></app-channel-card>
            </ngxMasonryItem>
          </ngx-masonry>
        </div> -->
  </div>
