import { Component, OnInit, Input, ɵConsole, OnDestroy } from '@angular/core';
import { UploadInfoService } from './../../shared/services/upload-info.service';
import { SubCategory } from './../../shared/models/subcategory.model';

import { ActivatedRoute } from '@angular/router';
import { AuthService } from '../../shared/services/auth.service'
import { UserProfile } from '../../shared/models/user-profile';
import { User } from './../../shared/models/user.model';
import Utility  from './../../shared/helpers/utility';
import { BridgeService } from './../../shared/services/bridge.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-video-filter',
  templateUrl: './video-filter.component.html',
  styleUrls: ['./video-filter.component.scss']
})
export class VideoFilterComponent implements OnInit, OnDestroy {
  categories$;
  @Input('category') category;
  // @Input('isFamily') isFamily: boolean;

  subcategories: SubCategory[];

  nonFamilyvideosType: SubCategory[];
  nonFamilyvideosTypeSP: SubCategory[];
  nonFamilyvideosTemp: SubCategory[];
  familyvideosType: SubCategory[];
  generalChannels: SubCategory[];

  categorySelectedEnglish: String;
  categorySelectedSpanish: String;
  categorySelected: String;

  isFamily: boolean;
  userLoggedIn: UserProfile;
  userData: User;
  language = '';
  changeLanguage = false;
  mobile: boolean = false;
  private successSub!: Subscription


  constructor(private contentservice: UploadInfoService, private route: ActivatedRoute, public authService: AuthService, public bridgeService: BridgeService) { }

  ngOnInit() {
    this.language = Utility.checkLanguage();
    this.nonFamilyvideosType = [];
    this.nonFamilyvideosTypeSP = [];

    this.contentservice.getSubCategories().subscribe(val => {
      this.subcategories = val
      this.familyvideosType = this.subcategories.filter(p => p.Type == 'families')
      // this.nonFamilyvideosType = this.subcategories.filter(p => p.Type == 'videos')
      // this.nonFamilyvideosTemp = this.subcategories.filter(p => p.Type == 'videos')
      // for (let i = 0 ; i< this.nonFamilyvideosTemp.length; i++) {
      //   if(this.nonFamilyvideosTemp[i].Title.indexOf('SP') !== -1) {
      //     this.nonFamilyvideosTypeSP.push(this.nonFamilyvideosTemp[i]);
      //   } else {
      //     this.nonFamilyvideosType.push(this.nonFamilyvideosTemp[i]);
      //   }
      // }
      if ( this.language === 'English') {
        this.categorySelectedSpanish = null;
        this.nonFamilyvideosType = this.subcategories.filter(p => p.Type == 'videos')
      }
      if ( this.language === 'Spanish') {
        this.categorySelectedEnglish = null;
        this.nonFamilyvideosTypeSP = this.subcategories.filter(p => p.Type == 'videos-SP')
      }
    })

    if (JSON.parse(localStorage.getItem('user'))) {
      this.userData = JSON.parse(localStorage.getItem('user'));
      if (this.userData) {
        this.getUserbyId(this.userData.uid)
      }
    }

    this.successSub = this.bridgeService.mobileSuccess.subscribe(mobile => {
      if (mobile) {
        this.mobile = true
      }
      else {
        this.mobile = false
      }
    }
    )

  }

  getUserbyId(userUID:String) {
    this.authService.getUserInfobyId(userUID).subscribe(user => {
      this.userLoggedIn = user
      this.isFamily = this.authService.isFamily(this.userLoggedIn)
    })
  }

  ngOnDestroy(): void {
    this.successSub.unsubscribe();
  }

}

