<div *ngIf="cart.totalItemsCount>0" class="card">
  <div class="card-body">
    <h4 class="card-title">Order Summary</h4>
    <p class="card-text">You have {{ cart.totalItemsCount }} items in your shopping cart.</p>
    <ul class="list-group list-group-flush">
      <li *ngFor="let item of cart.items" class="list-group-item">
        {{ item.quantity }} x {{ item.title }}
        <!-- <div class="float-right">
          {{ item.totalPrice | currency:'USD':true }}
        </div> -->
        <div class="float-right">
          {{ item.totalPrice | currency }}
        </div>
      </li>
      <li class="list-group-item font-weight-bold">
        SubTotal
        <!-- <div class="float-right">
          {{ cart.totalPrice | currency:'USD':true }}
        </div> -->
        <div class="float-right">
          {{ cart.totalPrice | currency}}
        </div>
      </li>
      <li class="list-group-item font-weight-bold bckgnd">
        IVA<span>({{impuesto | percent}})</span>
        <div class="float-right pr-2 pl-2">
          {{ totalPrice*impuesto | currency }}
        </div>
      </li>
      <li class="list-group-item font-weight-bold bckgnd">
        Total orden
        <div class="float-right pr-2 pl-2">
          {{ totalPrice + totalPrice*impuesto | currency }}
        </div>
      </li>
    </ul>
  </div>
</div>
