import { Component, OnInit, Renderer2, OnDestroy, ElementRef } from '@angular/core';
import { UploadInfoService } from '../shared/services/upload-info.service'
import { ContentSection } from './../shared/models/contentSection.model'
import {YoutubeService } from './../shared/services/youtube.service'
import { takeUntil } from 'rxjs/operators';
import { Observable , BehaviorSubject} from 'rxjs';
import { Subject } from 'rxjs';
import { NgxMasonryOptions } from 'ngx-masonry';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser'


import { ActivatedRoute } from '@angular/router';
import { AuthService } from '../shared/services/auth.service'
import { UserProfile } from '../shared/models/user-profile';
import { User } from './../shared/models/user.model';
import Utility  from './../shared/helpers/utility';
import { BridgeService } from './../shared/services/bridge.service';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-channels',
  templateUrl: './channels.component.html',
  styleUrls: ['./channels.component.scss']
})
export class ChannelsComponent implements OnInit, OnDestroy {

  contentSections: ContentSection[];
  contentSection: ContentSection;
  filteredContentSection: ContentSection[];
  filteredFamilyContentSection: ContentSection[];
  contentSelectedBackground: string = '';
  category = null;
  isFamily: boolean;
  userLoggedIn: UserProfile;
  userData: User;
  language = '';
  mobileLanguage = null;
  mobileTone = null;
  mobileInput : any = null;
  mobile: boolean = false;
  private successSub!: Subscription


  videos: any[];
  channel: any;
  channelDetails: any;
  thumbnail: string = "";
  destroy$ = new BehaviorSubject<boolean>(false);
  private unsubscribe$: Subject<any> = new Subject();

  public masonryOptions: NgxMasonryOptions = {
		transitionDuration: '0.2s',
		gutter: 20,
		columnWidth: 300,
		// resize: true,
		initLayout: true,
		// fitWidth: true,
		// originLeft: false,
	};

	masonryImages;
	limit = 15;

  constructor(private contentservice: UploadInfoService, private youTubeService: YoutubeService, private route: ActivatedRoute, public authService: AuthService, private renderer: Renderer2, public bridgeService: BridgeService, private elementRef: ElementRef) { }

  ngOnInit() {
    console.log('loading channel')
    this.language = Utility.checkLanguage();
    this.route.queryParamMap.subscribe( params => {
      this.mobileLanguage = params.get('language');
      if (this.mobileLanguage !== null) {
        if (this.mobileLanguage != this.language) {
          this.changeLanguage(this.mobileLanguage);
        }
        console.log("mobile language selected",this.mobileLanguage)
      }

      this.mobileTone = params.get('tone');
      if (this.mobileTone !== null) {
        console.log("mobile language selected",this.mobileTone)
      }
      this.mobileInput = params.get('mobile');
      if (this.mobileInput !== null) {
        // this.changeMobileInput(this.mobileInput);
        this.bridgeService.confirmMobile();
      }

      this.successSub = this.bridgeService.mobileSuccess.subscribe(mobile => {
        if (mobile) {
          this.mobile = true
        }
        else {
          this.mobile = false
        }
      }
      )
  })
    this.videos = [];
    this.youTubeService
    .getVideosForChanel('UCxMubMiVAm1XjOKe4Fc-wEA', 15)
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(lista => {
    for (let element of lista["items"]) {
    element.cleanUrl='https://www.youtube.com/embed/'+`${element.id.videoId}`+'?rel=0'
    this.videos.push(element)
    }
    });

    this.youTubeService
    .getChannelInfo('UCxMubMiVAm1XjOKe4Fc-wEA')
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(lista => {
    this.channel = lista
    this.channelDetails = this.channel.items[0]
    this.thumbnail=this.channelDetails.snippet.thumbnails.high.url
    });

    if (this.language === 'English') {
      this.contentservice.getContentbySection('Channels').subscribe(val => {
        this.contentSections = val;
        console.log('channels list',this.contentSections);
      })
    }
    if (this.language === 'Spanish') {
      this.contentservice.getContentbySection('ChannelsSP').subscribe(val => {
        this.contentSections = val;
        console.log('lista de canales',this.contentSections);
      })
    }


    this.route.queryParamMap.subscribe( params => {

        this.category = params.get('category');
        if (this.category !== null) {
          if(this.contentSections) {
            this.filteredContentSection = (this.category) ? this.contentSections.filter(p => p.SubRoom == this.category) : this.contentSections
          }
          // this.filteredContentSection = (this.category) ? this.contentSections.filter(p => p.SubRoom == this.category) : this.contentSections
        }

      // this.category = params.get('category');
      // this.filteredContentSection = (this.category) ? this.contentSections.filter(p => p.SubRoom == this.category) : this.contentSections
    })

    this.route.queryParamMap.subscribe( params => {

      this.mobileLanguage = params.get('language');
      if (this.mobileLanguage !== null) {
        if (this.mobileLanguage != this.language) {
          this.changeLanguage(this.mobileLanguage);
        }
        console.log("mobile language selected",this.mobileLanguage)
      }

      this.mobileTone = params.get('tone');
      if (this.mobileTone !== null) {
        console.log("mobile language selected",this.mobileTone)
      }

    // this.category = params.get('category');
    // this.filteredContentSection = (this.category) ? this.contentSections.filter(p => p.SubRoom == this.category) : this.contentSections
  })

    // this.route.queryParamMap.subscribe( params => {
    //   this.category = params.get('category');
    //   this.filteredFamilyContentSection = (this.category) ? this.contentSections.filter(p => p.SubRoom == this.category) : this.contentSections
    // })


    // this.masonryImages = this.contentSections.slice(0, this.limit);

    if (JSON.parse(localStorage.getItem('user'))) {
      this.userData = JSON.parse(localStorage.getItem('user'));
      if (this.userData) {
        this.getUserbyId(this.userData.uid)
      }
    }
  }

  getUserbyId(userUID:String) {
    this.authService.getUserInfobyId(userUID).subscribe(user => {
      this.userLoggedIn = user
      this.isFamily = this.authService.isFamily(this.userLoggedIn)
    })
  }

  setFooterBotom () {
    const parent: HTMLElement = document.getElementById('main-footer');
		this.renderer.removeStyle(parent, 'position');
		this.renderer.removeStyle(parent, 'bottom');
		this.renderer.removeStyle(parent, 'width');
  }

  setFooterDefault() {
    const parent: HTMLElement = document.getElementById('main-footer');
    this.renderer.setStyle(parent, 'position', 'absolute');
    this.renderer.setStyle(parent, 'bottom', '0px');
    this.renderer.setStyle(parent, 'width', '100%');
  }

  ngAfterViewInit() {
    this.setFooterBotom();
    // this.setFooterDefault();
    if (this.mobile) {
      this.elementRef.nativeElement.ownerDocument
            .body.style.backgroundColor = '#dc143c';
    }
  }

  changeLanguage(language) {
    if (language == 'Spanish') {
      localStorage.setItem('language', 'Spanish');
      window.location.reload();
    }
    if (language == 'English') {
      localStorage.setItem('language', 'English');
      window.location.reload();
    }
  }
  ngOnDestroy(): void {
    this.successSub.unsubscribe();
}


}
