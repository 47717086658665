<!-- PAGE HEADER -->
<header id="page-header">
  <div class="container setDefaultSpace pd-2">
    <div class="row">
      <div class="col-md-6 m-auto text-center">
        <h1 class="fontOpenSans-RegularSmallDarkGray">Videos & Our Youtube Channel</h1>
        <p class="fontOpenSans-RegularExtraSmallDarkGray">These are some of the video clips that we posted in our Youtube video channel</p>
        <div class="d-block d-md-none">
            <!-- <app-blog-filter [category]="category"></app-blog-filter> -->
          </div>
      </div>
    </div>
  </div>
</header>

<!-- PAGE HEADER -->
<header id="page-header">
<div class="container pt-4 pb-4">
  <div class="row">
    <div class="card mb-3">
        <div class="row no-gutters">
          <div class="col-md-4">
            <!-- <svg class="bd-placeholder-img" width="100%" height="250" xmlns="http://www.w3.org/2000/svg" aria-label="Placeholder: Image" preserveAspectRatio="xMidYMid slice" role="img"><title>Placeholder</title><rect width="100%" height="100%" fill="#868e96"/><text x="50%" y="50%" fill="#dee2e6" dy=".3em">Image</text></svg> -->
            <img src="{{thumbnail}}" alt="" class="img-fluid card-img-top">

          </div>
          <div class="col-md-8">
            <div class="card-body">
              <img src="../../assets/images/youtube_logo.png" style="height: 20px;width:auto" class="img-fluid card-img-top">
              <h5 class="card-header centerName fontOpenSans-RegularSmallDarkGra">Our youtube channel : {{channelDetails.snippet.title}}</h5>
              <p class="fontOpenSans-RegularExtraSmallDarkGray pt-4">{{channelDetails.snippet.description}}</p>
              <!-- <p class="fontOpenSans-RegularExtraSmallDarkGray pt-4"><span class="fontOpenSans-RegularSmallDarkGray">ID :&nbsp;</span> {{channelDetails.id}}</p> -->
              <p class="fontOpenSans-RegularExtraSmallDarkGray pt-4"><span class="fontOpenSans-RegularSmallDarkGray">Subscribers :&nbsp;</span>{{channelDetails.statistics.subscriberCount  | number}}</p>
              <p class="fontOpenSans-RegularExtraSmallDarkGray pt-4"><span class="fontOpenSans-RegularSmallDarkGray">Visits :&nbsp;</span> {{channelDetails.statistics.viewCount  | number}}</p>
              <p class="fontOpenSans-RegularExtraSmallDarkGray pt-4"><span class="fontOpenSans-RegularSmallDarkGray">Videos :&nbsp;</span> {{channelDetails.statistics.videoCount  | number}}</p>
            </div>
          </div>
        </div>
      </div>

  </div>
</div>
</header>

<!-- <section class="pb-5">
  <div class="container">
      <div class="row">
          <div class="col">
            <div class="row">
                <ng-container *ngFor="let p of contentSections; let i = index">
                    <app-video-card [lab]="p"></app-video-card>
                </ng-container>
              </div>
          </div>
        </div>
  </div>
</section> -->


<!-- <div class="row">
<div *ngFor="let video of videos" class="col-xl-3 col-md-6 mb-4">
  <div class="card border-0 shadow vh-50">
  <a href="https://www.youtube.com/watch?v={{video.id.videoId}}" target="_blank">
  <img [src]="video.snippet.thumbnails.medium.url" class="card-img-top" alt="..."></a>
  <div class="card-body text-center">
  <h5 class="card-title mb-0">
  <a href="https://www.youtube.com/watch?v={{video.id.videoId}}">{{video.snippet.title}}
  </a></h5>
  <div class="card-text text-black-50">{{video.snippet.description.slice(0, 100)}}</div>
  <p class="card-text">{{video.snippet.description}}</p>
  </div>
  </div>
  </div>
</div> -->


<div class="no-scrolling">
  <ngx-masonry class="positioning" [options]="masonryOptions" [useImagesLoaded]="true">
    <ngxMasonryItem *ngFor="let video of videos" class="masonry-item drop-shadow">
            <div class="card">
                <!-- <a href="https://www.youtube.com/watch?v={{video.id.videoId}}" target="_blank"> -->
                <!-- <p><img class=" img-fluid" [src]="video.snippet.thumbnails.medium.url"  alt="card image"></p> -->
                <!-- <div  class="embed-responsive embed-responsive-16by9"> -->
                <div>
                  <iframe width="100%" height="auto" [src]="video.cleanUrl | safeHtml"
                  frameborder="0"allow="autoplay; ancrypted-media" allowFullScreen="allowFullScreen"></iframe>
                <!-- <iframe width="100%" height="auto" src='https://www.youtube.com/embed/YTn-1PR_LjA?rel=0'
                  frameborder="0"allow="autoplay; ancrypted-media" allowfullscreem></iframe>                         -->
                  <!-- <iframe class="embed-responsive-item" src="https://www.youtube.com/watch?v={{video.id.videoId}}"></iframe> -->
                </div>
                    <div class="card-body">
                        <div class="card-header">{{ video.snippet.title }}</div>
                        <div class="card-body">
                                <!-- <h5 class="card-title">{{ video.snippet.description.slice(0, 100) }}</h5> -->
                                <h5 class="card-title">{{ video.snippet.description }}</h5>
                            <!-- <div *ngIf ="picture.ExternalWeb3 !='demo'">
                                <table>
                                        <tr>
                                                <th class="fontOpenSans-SemiboldSmallDarkGray">Short Biography</th>
                                              </tr>
                                              <tr>
                                                <td class="fontOpenSans-RegularExtraSmallDarkGray">{{ picture.Content }}</td>
                                              </tr>
                                        <tr>
                                          <th class="fontOpenSans-SemiboldSmallDarkGray">What inspired me?</th>
                                        </tr>
                                        <tr>
                                          <td class="fontOpenSans-RegularExtraSmallDarkGray">{{ picture.ExternalWeb1 }}</td>
                                        </tr>
                                      </table>
                                      <table>
                                          <tr>
                                            <th class="fontOpenSans-SemiboldSmallDarkGray">Key words to remind</th>
                                          </tr>
                                          <tr>
                                            <td class="fontOpenSans-RegularExtraSmallDarkGray">{{ picture.ExternalWeb3 }}</td>
                                          </tr>
                                      </table>
                                      <table>
                                      </table>
                            </div>  -->
                        </div>
                    </div>
                </div>
                <!-- <div *ngIf ="picture.ExternalWeb3 !='demo'" class="card p-3">
                        <blockquote class="card-blockquote card-body fontOpenSans-SemiBoldSmallContent">
                          <footer class='blockquote-footer'>
                            <small class="text-muted"> {{ picture.ExternalWeb2 }}<cite title="Source Title"></cite></small><br>
                            <small class="text-muted"> {{ picture.PhotoSource }}<cite title="Source Title"></cite></small>
                          </footer>
                        </blockquote>
                      </div> -->
    </ngxMasonryItem>
  </ngx-masonry>
  </div>

