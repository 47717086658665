import { Component, OnInit,Input, Output, EventEmitter, ViewChild, ElementRef, OnDestroy ,AfterViewInit } from '@angular/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-shopping-order-summary',
  templateUrl: './shopping-order-summary.component.html',
  styleUrls: ['./shopping-order-summary.component.scss']
})
export class ShoppingOrderSummaryComponent implements OnInit, AfterViewInit {

  @Input() order: any;

  id:string = '';
  name:string = '';
  hideProcess: boolean = false;
  // product:Product;
  // category:Product;
  ProductsArr : Array<any>
  CategoriesArr : Array<any>
  private successUpdateProductSub: Subscription;
  private successUpdateCategorySub: Subscription;
  totalPrice = 0;
  impuesto = 0.16;

  @ViewChild('image') image : ElementRef
  @ViewChild('imageProduct') imageProduct : ElementRef


  constructor() { }

  ngAfterViewInit() {
    console.log('view init', this.order);
    this.id = this.order.id;
    this.name = this.order.name;
    this.hideProcess = this.order.hideProcess;
    console.log('ngOnInit', this.order);
    console.log("order details", this.order.object)
    console.log("order details array", this.order.object.items)
    console.log("order details HIDE PROCESS", this.hideProcess)
    for(let i=0;i<this.order.object.items.length;i++) {
      this.totalPrice = this.totalPrice + this.order.object.items[i].totalPrice;
    }
  }

  ngOnInit(): void {
    // this.id = this.order.id;
    // this.name = this.order.name;
    // console.log('ngOnInit', this.order);
    // console.log("order details", this.order.object)
    // console.log("order details array", this.order.object.items)
    // for(let i=0;i<this.order.object.items.length;i++) {
    //   this.totalPrice = this.totalPrice + this.order.object.items[i].totalPrice;
    // }

  }
}
