import { Component, OnInit, OnDestroy } from '@angular/core';
import { ContentSection } from './../shared/models/contentSection.model'
import { UploadInfoService } from './../shared/services/upload-info.service';
import { SCREEN_SIZE } from './../shared/models/screensize.model'
import { ResizeServiceService } from './../shared/services/resize-service.service'
import { homePageTitlesArray } from '../shared/localData/homepage.data';
import { BridgeService } from './../shared/services/bridge.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit, OnDestroy {

  contentSectionsFooter: ContentSection[];
  urlToImageFooter = '../../assets/images/backgroundFooter.png'
  urlToImageFooterLarge = '../../assets/images/backgroundFooterXLarge.png'
  titleFooter = '';
  subTitleFooter = '';
  largeBreakpoint = false;
  mobile: boolean = false;
  private successSub!: Subscription;

  constructor(private contentservice: UploadInfoService, private resizeSvc: ResizeServiceService, public bridgeService: BridgeService) {
    // subscribe to the size change stream
    this.resizeSvc.onResize$.subscribe(x => {
      this.size = x;
      console.log('resize :',this.size);
      if (this.size> 3) {
        this.largeBreakpoint = true
      } else {
        this.largeBreakpoint = false
      }
    });
  }

  size: SCREEN_SIZE;

  ngOnInit(): void {

    this.contentservice.getContentbySection('CoverFooterSection').subscribe(val => {
      this.contentSectionsFooter = val;
      this.urlToImageFooter = this.contentSectionsFooter[0].UrlImage;
      this.urlToImageFooterLarge = this.contentSectionsFooter[0].Background;
      this.titleFooter = this.contentSectionsFooter[0].ExternalWeb1;
      this.subTitleFooter = this.contentSectionsFooter[0].ExternalWeb2;

    });
    this.successSub = this.bridgeService.mobileSuccess.subscribe(mobile => {
      if (mobile) {
        this.mobile = true
      }
      else {
        this.mobile = false
      }
    }
    )

  }

  ngOnDestroy() {
    this.successSub.unsubscribe();
  }

}
