import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Shipping } from './../shared/models/shipping.model';
import * as L from 'leaflet';

const logoIcon = L.icon({
  iconUrl:'/assets/images/logo.png',
  iconSize: [30,45],
  shadowSize: [9,9],
  iconAnchor:[15,42],
  shadowAncor:[0.0],
  popupAnchor:[-3,-1],
});

@Component({
  selector: 'app-mapview',
  templateUrl: './mapview.component.html',
  styleUrls: ['./mapview.component.scss']
})
export class MapviewComponent implements OnInit, AfterViewInit {
  shipping:Shipping = {};
  map:any;
  public markers: any[] = [];
  logoIcon = L.icon({
    iconUrl:'/assets/images/logo-symbol.png',
    iconSize: [50,54],
    shadowSize: [9,9],
    iconAnchor:[15,42],
    shadowAncor:[0.0],
    popupAnchor:[-3,-1],
  });

  access_token = "pk.eyJ1IjoibWVkc2NpZW5jZWFuaW1hdGlvbnMiLCJhIjoiY2tiYjR5ZDFlMDB6eDJ6bjNkOWh6MzlvNSJ9.wWgc0IHbiuKjEd-j_0182A"

  constructor() { }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.initMap();
    this.locateOnMap();

  }
  initMap(): void {
//     this.map = L.map('map').setView([-74.061102, 4.639122], 13);
//     L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
//     attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
// }).addTo(this.map);

// L.marker([51.5, -0.09]).addTo(this.map)
//     .bindPopup('A pretty CSS3 popup.<br> Easily customizable.')
//     .openPopup();
this.map = L.map('map').setView([4.639122, -74.061102], 13);
L.tileLayer('https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}', {
    attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
    maxZoom: 18,
    id: 'mapbox/streets-v11',
    tileSize: 512,
    zoomOffset: -1,
    accessToken: this.access_token,
}).addTo(this.map);

  }

  locateOnMap() {

    var marker1 = L.marker([4.639122, -74.061102],{icon: this.logoIcon}).addTo(this.map);
    var marker2 = L.marker([4.639122, -74.0661102],{icon: this.logoIcon}).addTo(this.map);

    const circle1 = L.circle([4.639122, -74.061102], {
      color: 'red',
      fillColor: '#f03',
      fillOpacity: 0.5,
      radius: 100
  }).addTo(this.map);

  const circle2 = L.circle([4.639122, -74.0661102], {
    color: 'red',
    fillColor: '#f03',
    fillOpacity: 0.5,
    radius: 100
}).addTo(this.map);

  marker1.bindPopup("<b>Chorizos y Más</b><br>Chapinero Alto.").openPopup();
  marker2.bindPopup("<b>Chorizos y Más</b><br>Teusaquillo.").openPopup();
circle1.bindPopup("Zona de despachos Chapinero Alto");
circle2.bindPopup("Zona de despachos Teusaquillo");
this.markers.push(marker1)
this.markers.push(marker2)
const group = new L.featureGroup(this.markers);
this.map.fitBounds(group.getBounds());

  }

}
