import { Component, OnInit } from '@angular/core';
import Utility  from './../shared/helpers/utility';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-relax',
  templateUrl: './relax.component.html',
  styleUrls: ['./relax.component.scss']
})
export class RelaxComponent implements OnInit {

  totalTime = 7500;
  breatheTime = (this.totalTime / 5) * 2;
  holdTime = this.totalTime / 5;
  language='';
  mobileLanguage = null;
  mobileTone = null;

  constructor(private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.language = Utility.checkLanguage();
    this.route.queryParamMap.subscribe( params => {

      this.mobileLanguage = params.get('language');
      if (this.mobileLanguage !== null) {
        if (this.mobileLanguage != this.language) {
          this.changeLanguage(this.mobileLanguage);
        }
        console.log("mobile language selected",this.mobileLanguage)
      }

      this.mobileTone = params.get('tone');
      if (this.mobileTone !== null) {
        console.log("mobile language selected",this.mobileTone)
      }
  })
    this.breathAnimation();
    setInterval(this.breathAnimation, this.totalTime);
  }

  changeLanguage(language) {
    if (language == 'Spanish') {
      localStorage.setItem('language', 'Spanish');
      window.location.reload();
    }
    if (language == 'English') {
      localStorage.setItem('language', 'English');
      window.location.reload();
    }
  }

  breathAnimation() {
    const text = document.getElementById('text');
    const container = document.getElementById('container');
    text.innerText = 'Breathe In!';
    container.className = 'container grow';

    setTimeout(() => {
      text.innerText = 'Hold';

      setTimeout(() => {
        text.innerText = 'Breathe Out!';
        container.className = 'container shrink';
      }, this.holdTime);
    }, this.breatheTime);
  }

}
