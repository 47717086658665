// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // firebaseConfig: {
  //   apiKey: "AIzaSyD_2J0KAG9Hq4t2DWkq-a4KAnbiBzsQE3I",
  //   authDomain: "deportologa.firebaseapp.com",
  //   databaseURL: "https://deportologa.firebaseio.com",
  //   projectId: "deportologa",
  //   storageBucket: "deportologa.appspot.com",
  //   messagingSenderId: "508039937601",
  //   appId: "1:508039937601:web:f4ef8e22000af3d6b050c0",
  //   measurementId: "G-3JN8GZYW80"
  // },
  firebaseConfig: {
    apiKey: "AIzaSyDsB39HYgs_Hii7xAyOZkJ0DnqSHNp2OMc",
    authDomain: "sojourney-d3bbc.firebaseapp.com",
    databaseURL: "https://sojourney-d3bbc.firebaseio.com",
    projectId: "sojourney-d3bbc",
    storageBucket: "sojourney-d3bbc.appspot.com",
    messagingSenderId: "961181933680",
    appId: "1:961181933680:web:443a6ff6f0c45ab4ce94b5",
    measurementId: "G-RYTQ7BL5SC"
  },
  api: {
    baseUrl: ""
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
