import { Component, OnInit, OnDestroy, Input,ViewChild, ElementRef, ComponentFactoryResolver } from '@angular/core';
import { ShoppingCart } from './../shared/models/shopping-cart.model';
import { OrderService } from './../shared/services/order.service';
import { AuthService } from './../shared/services/auth.service';
import { Payu} from './../shared/services/config';
import { User } from './../shared/models/user.model';
import { UserProfile } from './../shared/models/user-profile';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable, Subscription, pipe } from 'rxjs';
import { Order } from './../shared/models/order.model';
import { Shipping } from './../shared/models/shipping.model';
import { CheckoutService } from '../shared/services/checkout.service';
import { CheckoutMercadoPagoService } from '../shared/services/checkout-mercado-pago.service';
import { Md5 } from 'md5-typescript';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument, } from '@angular/fire/firestore';
import roundHalfEven from "round-half-even";

import { get } from 'scriptjs';

@Component({
  selector: 'app-shipping-form',
  templateUrl: './shipping-form.component.html',
  styleUrls: ['./shipping-form.component.scss']
})
export class ShippingFormComponent implements OnInit {
  @Input('cart') cartData:any
  cart: ShoppingCart;
  shipping:Shipping = {};
  userSubscription: Subscription;
  orderSubscription: Subscription;
  userId: string;
  purchaseStarted= false;
  userData: User;

  //PAYU

  action: string;
  merchantId: string;
  accountId: string;
  responseUrl: string;
  confirmationUrl: string;
  apiKey: string;
  test: number;
  description = '';
  signature: string;
  referenceCode: number;
  totalPrice: number;
  buyerEmail: string;
  displayPayuButton:boolean;
  sessionId: string;
  confirm_signature: string;
  orderId: number;
  orderPayu: Order;
  ordersArray: Order[];
  orderSaved: Order;
  dataOrderSaved: any = {};
  displayOrderSaved = false;
  messageOrderSaved = '';
  hideProcess = false;
  impuesto = 0.16;
  totalPriceWithTax = 0;
  onlyTax = 0;

  response_transactionState: number;
  response_polResponseCode: string;
  response_polPaymentMethodType: number;
  response_TX_VALUE: number;
  response_lapResponseCode: string;
  response_lapTransactionState: string;
  response_extra1: string;
  response_extra2: string;
  response_extra3: string;
  response_authorizationCode
  response_transactionId: string;
  response_message: string;
  response_signature: string;
  response_telephone: string;





  init_point: any;

  preference = {
    items: [
        {
            title: 'Mi producto',
            unit_price: 100,
            quantity: 1,
        }
    ]
  };

  constructor(private router: Router,
    private authService: AuthService,
    private orderService: OrderService,
    private checkout: CheckoutService,
    private checkoutService: CheckoutMercadoPagoService,
    private activatedRoute:ActivatedRoute,
    private firestore: AngularFirestore) { }

  ngOnInit(): void {
    this.cart = this.cartData.cartData;
    console.log("shipping form component");
    console.log("shipping form cart",this.cart);
    console.log("shipping form cart totalprice",this.cart.totalPrice);
    console.log("shipping form flag",this.cartData.flag);
    console.log("shipping form cart",this.cartData.cartData);
    for(let i=0;i<this.cart.items.length;i++) {
      this.totalPriceWithTax = this.totalPriceWithTax + this.cart.items[i].totalPrice;
    }
    this.onlyTax = this.cart.totalPrice * this.impuesto

    // localStorage.setItem('payu_shipping_Name', this.shipping.name);
    // localStorage.setItem('payu_shipping_addressLine1', this.shipping.addressLine1);
    // localStorage.setItem('payu_shipping_phone', this.shipping.addressLine2);
    // localStorage.setItem('payu_shipping_city', this.shipping.city);

    // if (localStorage.getItem('payu_shipping_Name')) {
    //     this.shipping.name = localStorage.getItem('payu_shipping_Name');
    //     this.shipping.addressLine1 = localStorage.getItem('payu_shipping_addressLine1');
    //     this.shipping.addressLine2 = localStorage.getItem('payu_shipping_phone');
    //     this.shipping.city = localStorage.getItem('payu_city');
    // }
    // localStorage.removeItem('payu_shipping_Name');
    // localStorage.removeItem('payu_shipping_addressLine1');
    // localStorage.removeItem('payu_shipping_phone');
    // localStorage.removeItem('payu_city');

    this.displayPayuButton = false;
    this.userData = JSON.parse(localStorage.getItem('user'));
    this.userId = this.userData.uid;
    this.sessionId =  String(new Date().getTime())
    // this.userSubscription = this.authService.user.subscribe(user => this.userId = user.uid);
    // this.userSubscription = this.authService.statusChange.subscribe(user => this.userId = user.uid);
    this.orderSubscription = this.orderService.placeOrderSuccess.subscribe(id => {
    });

    get("https://www.mercadopago.com.ar/integrations/v1/web-payment-checkout.js", () => {
      //library has been loaded...
    });


    /*=============================================
			Checkout con Payu
			=============================================*/

			this.action = Payu.action;
			this.merchantId = Payu.merchantId;
			this.accountId = Payu.accountId;
			this.responseUrl = Payu.responseUrl;
			this.confirmationUrl = Payu.confirmationUrl;
			this.apiKey = Payu.apiKey;
      this.test = Payu.test;
      // this.totalPrice = this.cart.totalPrice;
      this.totalPrice =parseFloat((this.totalPriceWithTax + this.onlyTax).toFixed(2));
      this.buyerEmail = this.userData.email;


      console.log('apiKey',this.apiKey);
      console.log('merchantId',this.merchantId);
      console.log('accountId',this.accountId);
      console.log('test',this.test);
      console.log('onlyTax',this.onlyTax);
      console.log('totalPrice',this.totalPrice);

			/*=============================================
			Capturar la descripción
			=============================================*/


			this.cart.items.forEach(product=>{

				this.description += `${product.title} x${product.quantity}, `

			})

      this.description = this.description.slice(0, -2);
      console.log('description',this.description);

			/*=============================================
			Creamos el código de referencia
			=============================================*/

      this.referenceCode = Math.ceil(Math.random()*1000000);
      console.log('referenceCode',this.referenceCode);

			/*=============================================
			Creamos la firma de Payu
      =============================================*/


      this.signature = Md5.init(`${this.apiKey}~${this.merchantId}~${this.referenceCode}~${this.totalPrice}~COP`);
      console.log('signature',this.signature)

			// let signature = Md5.init(`${apiKey}~${merchantId}~${referenceCode}~${this.totalPrice[0]}~USD`);

      if(this.activatedRoute.snapshot.queryParams["transactionState"] == 4){
          console.log("Payu transaction succesful");
          this.messageOrderSaved = 'Su transacción fue realizada satisfactoriamente. A continuación detalles de su orden'
          this.placeOrderPayu();
          this.displayOrderSaved = true;
          this.hideProcess = false;
      }
      if(this.activatedRoute.snapshot.queryParams["transactionState"] == 6){
        this.hideProcess = true;

        if (this.activatedRoute.snapshot.queryParams["response_code_pol"] === '4') {
          this.messageOrderSaved = 'Su transacción no fue realizada debido a que la red rechazó su pago. Favor reintentar su transacción nuevamente'
          console.log("PAYMENT_NETWORK_REJECTED")
      }
      if (this.activatedRoute.snapshot.queryParams["response_code_pol"] === '5') {
        this.messageOrderSaved = 'Su transacción no fue realizada debido a que la tarjeta fue declinada. Favor intentar con otra forma de pago'
        console.log("ENTITY_DECLINED")
      }
      if (this.activatedRoute.snapshot.queryParams["response_code_pol"] === '6') {
        this.messageOrderSaved = 'Su transacción no fue realizada debido a fondos insuficientes en su tarjeta. Favor intentar con otra forma de pago'
        console.log("INSUFFICIENT_FUNDS")
      }
      if (this.activatedRoute.snapshot.queryParams["response_code_pol"] === '7') {
        this.messageOrderSaved = 'Su transacción no fue realizada debido es una tarjeta invalida. Favor intentar nuevamente y revisar cuidadosamente la información de la tarjeta y reintentar de nuevo'
        console.log("INVALID_CARD")
      }
      if (this.activatedRoute.snapshot.queryParams["response_code_pol"] === '8') {
        this.messageOrderSaved = 'Su transacción no fue realizada favor contactar a la entidad financiera emisora de la tarjeta.'
        console.log("CONTACT_THE_ENTITY")
      }
      if (this.activatedRoute.snapshot.queryParams["response_code_pol"] === '9') {
        this.messageOrderSaved = 'Su transacción no fue realizada debido a que la tarjeta está expirada. Favor intentar con otra forma de pago '
        console.log("EXPIRED_CARD")
      }
      if (this.activatedRoute.snapshot.queryParams["response_code_pol"] === '10') {
        this.messageOrderSaved = 'Su transacción no fue realizada debido a que es una tarjeta restringida. Favor intentar con otra forma de pago '
        console.log("RESTRICTED_CARD")
      }
      if (this.activatedRoute.snapshot.queryParams["response_code_pol"] === '12') {
        this.messageOrderSaved = 'Su transacción no fue realizada debido a que se utilizó una fecha de expiración o código de seguridad no validos. Favor intentar nuevamente y revisar cuidadosamente la información de la tarjeta y reintentar de nuevo'
        console.log("INVALID_EXPIRATION_DATE_OR_SECURITY_CODE")
      }
      if (this.activatedRoute.snapshot.queryParams["response_code_pol"] === '13') {
        this.messageOrderSaved = 'Su transacción no fue realizada debido a que es una transacción repetida. Favor contactar a la entidad financiera emisora de la tarjeta para confirmación de transacción. '
        console.log("REPEAT_TRANSACTION")
      }
      if (this.activatedRoute.snapshot.queryParams["response_code_pol"] === '14') {
        this.messageOrderSaved = 'Su transacción no fue realizada debido a que es una transacción invalida. Favor intentar con otra forma de pago '
        console.log("INVALID_TRANSACTION")
      }
      if (this.activatedRoute.snapshot.queryParams["response_code_pol"] === '17') {
        this.messageOrderSaved = 'Su transacción no fue realizada debido a que la transacción excede el monto autorizado. Favor intentar con otra forma de pago '
        console.log("EXCEEDED_AMOUNT")
      }
      if (this.activatedRoute.snapshot.queryParams["response_code_pol"] === '19') {
        this.messageOrderSaved = 'Su transacción no fue realizada debido a que la transacción excede el monto autorizado. Favor intentar con otra forma de pago '
        console.log("ABANDONED_TRANSACTION")
      }
      if (this.activatedRoute.snapshot.queryParams["response_code_pol"] === '22') {
        this.messageOrderSaved = 'Su transacción no fue realizada debido a que la forma de pago utilizada no está autorizada para transacciones por internet. Favor intentar con otra forma de pago '
        console.log("CREDIT_CARD_NOT_AUTHORIZED_FOR_INTERNET_TRANSACTIONS")
      }
      if (this.activatedRoute.snapshot.queryParams["response_code_pol"] === '23') {
        this.messageOrderSaved = 'Su transacción no fue realizada debido a antifraude alerta. Favor intentar con otra forma de pago '
        console.log("ANTIFRAUD_REJECTED")
      }
      if (this.activatedRoute.snapshot.queryParams["response_code_pol"] === '9999') {
        this.messageOrderSaved = 'Su transacción no fue realizada debido a problemas internos con el sistema. Favor intentar con otra forma de pago.'
        console.log("INTERNAL SYSTEM ERROR")
      }
        //Pending what to do if order fails
        this.displayPayuButton = false;
        this.displayOrderSaved = true;
        this.getOrders(this.hideProcess);
    }

    if(this.activatedRoute.snapshot.queryParams["transactionState"] == 104){
      this.messageOrderSaved = 'Su transacción no fue realizada debido a problemas con el sistema. Favor intentar con otra forma de pago.'
      console.log("Payu transaction SYSTEM ERROR");
      //Pending what to do if order fails
      this.displayPayuButton = false;
      this.displayOrderSaved = true;
      this.hideProcess = true;
      this.getOrders(this.hideProcess);
  }

    this.displayResponseCodes();

}

  ngOnDestroy() {
    console.log('shipping-form');
    // this.userSubscription.unsubscribe();
    this.orderSubscription.unsubscribe();
  }

  displayResponseCodes() {

  this.response_transactionState = this.activatedRoute.snapshot.queryParams["transactionState"];
  this.response_polResponseCode = this.activatedRoute.snapshot.queryParams["polResponseCode"];
  this.response_polPaymentMethodType = this.activatedRoute.snapshot.queryParams["polPaymentMethodType"];
  this.response_TX_VALUE = parseFloat(this.activatedRoute.snapshot.queryParams["TX_VALUE"]);
  this.response_lapResponseCode = this.activatedRoute.snapshot.queryParams["lapResponseCode"];
  this.response_lapTransactionState = this.activatedRoute.snapshot.queryParams["lapTransactionState"];
  this.response_extra1 = this.activatedRoute.snapshot.queryParams["extra1"];
  this.response_extra2 = this.activatedRoute.snapshot.queryParams["extra2"];
  this.response_extra3 = this.activatedRoute.snapshot.queryParams["extra3"];
  this.response_authorizationCode = this.activatedRoute.snapshot.queryParams["authorizationCode"];
  this.response_transactionId = this.activatedRoute.snapshot.queryParams["transactionId"];
  this.response_message = this.activatedRoute.snapshot.queryParams["message"];
  this.response_signature = this.activatedRoute.snapshot.queryParams["signature"];
  this.response_telephone = this.activatedRoute.snapshot.queryParams["telephone"];

  console.log('this.response_transactionState',this.response_transactionState);
  console.log('this.response_polResponseCode',this.response_polResponseCode);
  console.log('this.response_polPaymentMethodType',this.response_polPaymentMethodType);
  console.log('this.activatedRoute.snapshot.queryParams["TX_VALUE"]',this.activatedRoute.snapshot.queryParams["TX_VALUE"]);
  console.log('this.response_TX_VALUE',this.response_TX_VALUE.toFixed(2));
  console.log('this.response_lapResponseCode',this.response_lapResponseCode);
  console.log('this.response_extra1',this.response_extra1);
  console.log('this.response_extra2',this.response_extra2);
  console.log('this.response_extra3',this.response_extra3);
  console.log('this.response_authorizationCode',this.response_authorizationCode);
  console.log('this.response_transactionId',this.response_transactionId);
  console.log('this.response_message',this.response_message);
  console.log('this.response_signature',this.response_signature);
  console.log('this.response_telephone',this.response_telephone);

if (this.response_TX_VALUE) {
  console.log('MD5');
  console.log('this.apiKey',this.apiKey);
  console.log('this.merchantId',this.merchantId);
  console.log('this.referenceCode',this.referenceCode);
  console.log('this.response_transactionState',this.response_transactionState);
  console.log('this.response_TX_VALUE',this.response_TX_VALUE.toFixed(2));
  // console.log('roundHalfEven(this.response_TX_VALUE, 1)',roundHalfEven(this.response_TX_VALUE.toFixed(2), 1));
  // this.confirm_signature = Md5.init(`${this.apiKey}~${this.merchantId}~${this.referenceCode}~${roundHalfEven(this.response_TX_VALUE.toFixed(2), 1)}~COP~${this.response_transactionState}`);
  // console.log('this.confirm_signature',this.confirm_signature);
}

  }

  placeOrder() {
    console.log("place order step");
    const id =  new Date().getTime()
    let order = new Order(this.userId, this.shipping, this.cart);
    this.orderService.placeOrder(id,order);

  }

  placeOrderSwipe() {
    console.log("place order step");
    const id =  new Date().getTime()
    let order = new Order(this.userId, this.shipping, this.cart);
    this.orderService.placeOrder(id,order);

    // Connection with Stripe Payment
    this.purchaseStarted = true;
    this.checkout.startCourseCheckoutSession(id.toString())
    .subscribe(
        session => {
            this.checkout.redirectToCheckout(session);
        },
        err => {
            console.log('Error creating checkout session', err);
            this.purchaseStarted = false;
        }
    );

  }

  placeOrderMercado() {
    console.log("place order step");
    const id =  new Date().getTime()
    let order = new Order(this.userId, this.shipping, this.cart);
    this.orderService.placeOrder(id,order);

    // Connection with Stripe Payment
    // this.purchaseStarted = true;
    // this.checkout.startCourseCheckoutSession(id.toString())
    // .subscribe(
    //     session => {
    //         this.checkout.redirectToCheckout(session);
    //     },
    //     err => {
    //         console.log('Error creating checkout session', err);
    //         this.purchaseStarted = false;
    //     }
    // );

  }

  onBuy() {
    this.checkoutService.goCheckOut(this.preference).then(result => {
      // Read result of the Cloud Function.
      this.init_point = result.data.result;
      console.log(this.init_point);
      window.location.href = this.init_point;
    }).catch(error => {
      console.log(error);
      return error
    });
  }

  createOrderforPayu() {
    localStorage.removeItem('payu_orderId');
    localStorage.removeItem('payu_shipping_Name');
    localStorage.removeItem('payu_shipping_addressLine1');
    localStorage.removeItem('payu_shipping_phone');
    localStorage.removeItem('payu_shipping_city');

    localStorage.removeItem('payu_merchantId');
    localStorage.removeItem('payu_accountId');
    localStorage.removeItem('payu_description');
    localStorage.removeItem('payu_referenceCode');
    localStorage.removeItem('payu_totalPrice');
    localStorage.removeItem('payu_onlyTax');
    localStorage.removeItem('payu_signature');
    localStorage.removeItem('payu_test');
    localStorage.removeItem('payu_buyerEmail');
    localStorage.removeItem('payu_responseUrl');
    localStorage.removeItem('payu_confirmationUrl');
    localStorage.removeItem('payu_sessionId');
    localStorage.removeItem('payu_userId');


    const id =  new Date().getTime()
    this.orderId = id;
    console.log("place order step - wating confirmation");
    this.displayPayuButton = true;
    localStorage.setItem('payu_orderId', this.orderId.toString());
    localStorage.setItem('payu_shipping_Name', this.shipping.name);
    localStorage.setItem('payu_shipping_addressLine1', this.shipping.addressLine1);
    localStorage.setItem('payu_shipping_phone', this.shipping.addressLine2);
    localStorage.setItem('payu_shipping_city', this.shipping.city);

    localStorage.setItem('payu_merchantId', this.merchantId);
    localStorage.setItem('payu_accountId', this.accountId);
    localStorage.setItem('payu_description', this.description);
    localStorage.setItem('payu_referenceCode', this.referenceCode.toString());
    localStorage.setItem('payu_totalPrice', this.totalPrice.toString());
    localStorage.setItem('payu_onlyTax', this.onlyTax.toString());
    localStorage.setItem('payu_signature', this.signature);
    localStorage.setItem('payu_test', this.test.toString());
    localStorage.setItem('payu_buyerEmail', this.buyerEmail);
    localStorage.setItem('payu_responseUrl', this.responseUrl);
    localStorage.setItem('payu_confirmationUrl', this.confirmationUrl);
    localStorage.setItem('payu_sessionId', this.sessionId);
    localStorage.setItem('payu_userId', this.userId);




    let order = new Order(this.userId, this.shipping, this.cart);
    this.orderPayu = order;
    this.orderService.placeOrder(this.orderId,this.orderPayu);
    const checkoutSessionData: any = {
      status:'ongoing',
      created: new Date,
      courseId: this.orderId,
      userId: this.userId,
    }

    this.firestore.collection('purchaseSessions').doc(this.sessionId).set(checkoutSessionData).then(() => {
      this.displayPayuButton = true;
      // this.router.navigate(['/tracking'])
    }).catch(error => {
      console.log("error creating order for Payu", error);
      return error;
    })

    // this.orderService.placeOrder(id,order);

    // const checkoutSessionData: any = {
    //   status:'payutest',
    //   created: new Date,
    //   courseId: id,
    //   userId: this.userId,
    // }


    // this.firestore.collection('purchaseSessions').doc(this.sessionId).set(checkoutSessionData).then(() => {
    //   this.displayPayuButton = true;
    //   console.log("expose Payu button");
    // }).catch(error => {
    //   console.log("error creating order for Payu", error);
    //   return error;
    // })

  };

  reTrycreateOrderforPayu() {
    this.displayPayuButton = true;
    this.orderId = Number(localStorage.getItem('payu_orderId'));
    this.shipping.name = localStorage.getItem('payu_shipping_Name');
    this.shipping.addressLine1 = localStorage.getItem('payu_shipping_addressLine1');
    this.shipping.addressLine2 = localStorage.getItem('payu_shipping_phone');
    this.shipping.city = localStorage.getItem('payu_shipping_city');
    this.merchantId = localStorage.getItem('payu_merchantId');
    this.accountId = localStorage.getItem('payu_accountId');
    this.description = localStorage.getItem('payu_description');
    this.referenceCode = Number(localStorage.getItem('payu_referenceCode'));
    this.totalPrice = Number(localStorage.getItem('payu_totalPrice'));
    this.onlyTax = Number(localStorage.getItem('payu_onlyTax'));
    this.signature = localStorage.getItem('payu_signature');
    this.test = Number(localStorage.getItem('payu_test'));
    this.buyerEmail = localStorage.getItem('payu_buyerEmail');
    this.responseUrl = localStorage.getItem('payu_responseUrl');
    this.confirmationUrl = localStorage.getItem('payu_confirmationUrl');
    this.sessionId = localStorage.getItem('payu_sessionId');
    this.userId = localStorage.getItem('payu_userId');

    console.log("Retry this.orderId",this.orderId);
    console.log("Retry this.shipping.name",this.shipping.name);
    console.log("Retry this.shipping.addressLine1",this.shipping.addressLine1);
    console.log("Retry this.shipping.addressLine2",this.shipping.addressLine2);
    console.log("Retry this.shipping.city",this.shipping.city);
    console.log("Retry this.merchantId",this.merchantId);
    console.log("Retry this.accountId",this.accountId);
    console.log("Retry this.description",this.description);
    console.log("Retry this.referenceCode",this.referenceCode);
    console.log("Retry this.totalPrice",this.totalPrice);
    console.log("Retry this.onlyTax",this.onlyTax);
    console.log("Retry this.signature",this.signature);
    console.log("Retry this.test",this.test);
    console.log("Retry this.buyerEmail",this.buyerEmail);
    console.log("Retry this.responseUrl",this.responseUrl);
    console.log("Retry this.confirmationUrl",this.confirmationUrl);
    console.log("Retry this.sessionId",this.sessionId);
    console.log("Retry this.userId",this.userId);



    alert("PENDING WHAT TO DO IF RETRY");

    // const id =  new Date().getTime()
    // this.orderId = id;
    // console.log("place order step - wating confirmation");
    // this.displayPayuButton = true;
    // localStorage.setItem('payu_orderId', this.orderId.toString());
    // localStorage.setItem('payu_shipping_Name', this.shipping.name);
    // localStorage.setItem('payu_shipping_addressLine1', this.shipping.addressLine1);
    // localStorage.setItem('payu_shipping_phone', this.shipping.addressLine2);
    // localStorage.setItem('payu_shipping_city', this.shipping.city);
    // let order = new Order(this.userId, this.shipping, this.cart);
    // this.orderPayu = order;
    // this.orderService.placeOrder(this.orderId,this.orderPayu);
    // const checkoutSessionData: any = {
    //   status:'ongoing',
    //   created: new Date,
    //   courseId: this.orderId,
    //   userId: this.userId,
    // }

    // this.firestore.collection('purchaseSessions').doc(this.sessionId).set(checkoutSessionData).then(() => {
    //   this.displayPayuButton = true;
    //   // this.router.navigate(['/tracking'])
    // }).catch(error => {
    //   console.log("error creating order for Payu", error);
    //   return error;
    // })

  };

    placeOrderPayu () {

    // localStorage.removeItem('payu_orderId');
    localStorage.removeItem('payu_shipping_Name');
    localStorage.removeItem('payu_shipping_addressLine1');
    localStorage.removeItem('payu_shipping_phone');
    localStorage.removeItem('payu_shipping_city');
    // this.router.navigate(['/tracking'])
    this.getOrders(this.hideProcess);

    }

    placeOrderContraEntrega() {
      console.log('place order contra entrega');
    }

  onSubmit() {

    console.log("Process PAYU payment");

    /*=============================================
			Checkout con Payu
			=============================================*/

			// let action = Payu.action;
			// let merchantId = Payu.merchantId;
			// let accountId = Payu.accountId;
			// let responseUrl = Payu.responseUrl;
			// let confirmationUrl = Payu.confirmationUrl;
			// let apiKey = Payu.apiKey;
			// let test = Payu.test;

			/*=============================================
			Capturar la descripción
			=============================================*/

			// let description = "";

			// this.cart.items.forEach(product=>{

			// 	description += `${product.title} x${product.quantity}, `

			// })

      // description = description.slice(0, -2);
      // console.log('description',description);

			/*=============================================
			Creamos el código de referencia
			=============================================*/

      // let referenceCode = Math.ceil(Math.random()*1000000);
      // console.log('referenceCode',referenceCode);

			/*=============================================
			Creamos la firma de Payu
      =============================================*/

      // let signature = Md5.init('4Vj8eK4rloUd272L48hsrarnUA~508029~TestPayU~20000~COP')
      // console.log('signature',signature)

      // let signature = Md5.init(`${apiKey}~${merchantId}~${referenceCode}~${this.totalPrice[0]}~USD`);

      // const purchaseSession = await this.firestore.collection('purchaseSessions').doc()

  }

  getOrders(hideProces:boolean) {
    this.orderService.getOrders().subscribe( data => {
      this.ordersArray = data.map(e => {
        return {
          id: e.payload.doc.id,
          isEdit: false,
          items: e.payload.doc.data()['items'],
          datePlaced: e.payload.doc.data()['datePlaced'],
          process: e.payload.doc.data()['process'],
          shipInfo: e.payload.doc.data()['shipInfo'],
          userId: e.payload.doc.data()['userId'],
        };
      })
      console.log("this.ordersArray SHIPPING-FORM",this.ordersArray);
      for(let i=0;i<this.ordersArray.length;i++) {
        if(this.ordersArray[i].id = localStorage.getItem('payu_orderId')) {
          this.orderSaved = this.ordersArray[i];
        }
      }
      console.log("order saved",this.orderSaved);
      this.dataOrderSaved = {name:this.messageOrderSaved, id:this.orderSaved.id, object:this.orderSaved, hideProcess:hideProces}
      console.log('this.dataOrderSaved',this.dataOrderSaved);
      // localStorage.removeItem('payu_orderId');
      })
  }

}
