import { Component, OnInit, Renderer2, OnDestroy, ElementRef } from '@angular/core';
import { UploadInfoService } from './../shared/services/upload-info.service';
import { ContentSection } from './../shared/models/contentSection.model'
import { Course } from './../shared/models/course.model';
import { NgxMasonryOptions } from 'ngx-masonry';
import Utility  from './../shared/helpers/utility';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser'
import { BridgeService } from './../shared/services/bridge.service';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-courses',
  templateUrl: './courses.component.html',
  styleUrls: ['./courses.component.scss']
})
export class CoursesComponent implements OnInit, OnDestroy {

  contentSections: ContentSection[];
  contentClean: ContentSection[];
  language = '';
  title = '';
  subTitle = '';
  photoSource = '';
  title1 = '';
  title2 = '';
  title3 = '';
  mobileLanguage = null;
  mobileTone = null;
  mobileInput : any = null;
  mobile: boolean = false;
  private successSub!: Subscription


  public masonryOptions: NgxMasonryOptions = {
		transitionDuration: '0.2s',
		gutter: 20,
		columnWidth: 300,
		// resize: true,
		initLayout: true,
		// fitWidth: true,
		// originLeft: false,
	};

	masonryImages;
	limit = 15;

  constructor(private route: ActivatedRoute , private contentservice: UploadInfoService, private renderer: Renderer2, private sanitizer: DomSanitizer, public bridgeService: BridgeService, private elementRef: ElementRef) { }


  ngOnInit() {
    this.language = Utility.checkLanguage();
    this.route.queryParamMap.subscribe( params => {

      this.mobileLanguage = params.get('language');
      if (this.mobileLanguage !== null) {
        if (this.mobileLanguage != this.language) {
          this.changeLanguage(this.mobileLanguage);
        }
        console.log("mobile language selected",this.mobileLanguage)
      }

      this.mobileTone = params.get('tone');
      if (this.mobileTone !== null) {
        console.log("mobile language selected",this.mobileTone)
      }
      this.mobileInput = params.get('mobile');
      if (this.mobileInput !== null) {
        // this.changeMobileInput(this.mobileInput);
        this.bridgeService.confirmMobile();
      }

      this.successSub = this.bridgeService.mobileSuccess.subscribe(mobile => {
        if (mobile) {
          this.mobile = true
        }
        else {
          this.mobile = false
        }
      }
      )
  })

    this.contentSections = [];
    if ( this.language === 'Spanish') {
      this.title = 'Técnicas'
      this.subTitle = 'Estas son lagunas de las técnicas'
      this.photoSource = 'Fuente de la foto : ';
      this.title1 = 'Breve descripción'
      this.title2 = 'Fundamentos'
      this.title3 = 'Por qué quizás funcione?'
      this.contentservice.getContentbySection('CoursesSP').subscribe(val => {
        this.contentSections = val;
      })
    }
    if ( this.language === 'English') {
      this.title = 'Techniques';
      this.subTitle = 'These are some of the techniques';
      this.photoSource = 'Photo source : ';
      this.title1 = 'Brief description'
      this.title2 = 'The basis'
      this.title3 = 'Why this may be works?'
      this.contentservice.getContentbySection('Courses').subscribe(val => {
        this.contentSections = val;
      })
    }

	// this.masonryImages = this.dummyPictures.slice(0, this.limit);
	this.masonryImages = this.contentSections.slice(0, this.limit);

  // this.successSub = this.bridgeService.mobileSuccess.subscribe(mobile => {
  //   if (mobile) {
  //     this.mobile = true
  //   }
  //   else {
  //     this.mobile = false
  //   }
  // }
  // )
  }

  changeLanguage(language) {
    if (language == 'Spanish') {
      localStorage.setItem('language', 'Spanish');
      window.location.reload();
    }
    if (language == 'English') {
      localStorage.setItem('language', 'English');
      window.location.reload();
    }
  }

  showMoreImages() {
		this.limit += 15;
		this.masonryImages = this.contentSections.slice(0, this.limit);
	}

	ngAfterViewInit() {
		const parent: HTMLElement = document.getElementById('main-footer');
		this.renderer.removeStyle(parent, 'position');
		this.renderer.removeStyle(parent, 'bottom');
		this.renderer.removeStyle(parent, 'width');
    if (this.mobile) {
      this.elementRef.nativeElement.ownerDocument
            .body.style.backgroundColor = '#dc143c';
    }

    }

    safeContent(content): string {
      return this.sanitizer.bypassSecurityTrustHtml(content) as string;
    }

    ngOnDestroy(): void {
      this.successSub.unsubscribe();
  }
}
