// import { Component, OnInit } from '@angular/core';

// @Component({
//   selector: 'app-home',
//   templateUrl: './home.component.html',
//   styleUrls: ['./home.component.scss']
// })
// export class HomeComponent implements OnInit {

//   constructor() { }

//   ngOnInit(): void {
//   }

// }


import { Component, OnInit, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { FormControl, Validators } from '@angular/forms';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Lightbox } from 'ngx-lightbox';
import { UploadInfoService } from './../shared/services/upload-info.service';
import { ContentSection } from './../shared/models/contentSection.model'
import Utility  from './../shared/helpers/utility';

interface MailChimpResponse {
  result: string;
  msg: string;
}


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  public _albums = [];
  language='';
  private _description = ["© Bernardo Tavera • Golden Gate Bridge - San Francisco CA","© Bernardo Tavera •  Sunset - Naples FL","© Bernardo Tavera •  Ráquira-Colombia","© Bernardo Tavera • Miami Open - Miami FL","© Bernardo Tavera • The Twelve Apostles - Great Ocean Road in Victoria Australia","© Bernardo Tavera • Stanford Dish Walk - Silicon Valley CA"]
  submitted = false;
  // mailChimpEndpoint = 'https://ivesas.us19.list-manage.com/subscribe/post-json?u=86acff4ada9cdd635968e384d&amp;id=b5b51b5dec&subscribe=Subscribe&';
  mailChimpEndpoint = 'https://so-journey.us7.list-manage.com/subscribe/post-json?u=09c2800388a897ac8667376f8&amp;id=fc9c6c8ba6&subscribe=Subscribe&';
  error = '';
  hero = new HeroContact( 23,"","","","","","","")
  displayNewsletter = true;
  error_duplicate = false;
  error_name = false;
  error_lastName = false;
  error_email = false;
  paises: string[] = [
    'United States',
    'Colombia',
    'Ecuador',
    'Panamá',
    'Puerto Rico',
    'República Dominicana',
    'Venezuela',
    'Other'
  ]

  contentSectionsGallery: ContentSection[];
  contentSectionsCarousel: ContentSection[];
  contentSectionsIntro: ContentSection[];
  contentSectionsBand: ContentSection[];
  contentSectionsMiddle: ContentSection[];



  urlToImageOne = '../../assets/images/image1.jpg';
  urlToImageTwo = '../../assets/images/image2.jpg';
  urlToImageThree = '../../assets/images/image3.jpg';
  titleCarouselOne = '';
  titleCarouselTwo = '';
  titleCarouselThree = '';
  subTitleCarouselOne = '';
  subTitleCarouselTwo = '';
  subTitleCarouselThree = '';
  authorCarouselOne = '';
  authorCarouselTwo = '';
  authorCarouselThree = '';
  sourceCarouselOne = '';
  sourceCarouselTwo = '';
  sourceCarouselThree = '';

  urlToImageIntroOne = '../../assets/images/business.png'
  urlToImageIntroTwo = '../../assets/images/painter-iconv2.png'
  urlToImageIntroThree = '../../assets/images/euler-ecuation.png'
  titleIntroOne = '';
  titleIntroTwo = '';
  titleIntroThree = '';
  subTitleIntroOne = '';
  subTitleIntroTwo = '';
  subTitleIntroThree = '';


  urlToImageBandOne = '../../assets/images/business.png'
  urlToImageBandTwo = '../../assets/images/painter-iconv2.png'
  urlToImageBandThree = '../../assets/images/painter-iconv2.png'
  titleBandOne = '';
  titleBandTwo = '';
  titleBandThree = '';
  subTitleBandOne = '';
  titleBandNewsletter = '';
  subTitleBandNewsletter = '';


  urlToImageMiddleOne = '../../assets/images/summary-projects.jpg'
  urlToImageMiddleTwo = '../../assets/images/summary-blog.jpg'
  urlToImageMiddleThree = '../../assets/images/summary-inspiration.jpg'
  titleMiddleOne = '';
  titleMiddleTwo = '';
  titleMiddleThree = '';
  subTitleMiddleOne = '';
  subTitleMiddleTwo = '';
  subTitleMiddleThree = '';
  textMiddleOne = '';
  textMiddleTwo = '';
  textMiddleThree = '';
  subTextMiddleOne = '';
  subTextMiddleTwo = '';
  subTextMiddleThree = '';
  notesMiddleOne = '';
  notesMiddleTwo = '';
  notesMiddleThree = '';
  authorSOJourney = '© 2022 SO-Journey.us';

  caroulseImage_0 = null;
  caroulseImage_1 = null;
  caroulseImage_2 = null;
  caroulseImage_3 = null;
  caroulseImage_4 = null;
  caroulseImage_5 = null;


  constructor( private _lightbox: Lightbox,private http: HttpClient, private router: Router, private renderer: Renderer2, private contentservice: UploadInfoService) { }

  ngOnInit() {
    this.language = Utility.checkLanguage();
    if (localStorage.getItem('language') ==null) {
      this.language = 'English'
      this.coverGallery('CoverGallery');
      this.coverCarouselSelection('CoverCarouselSection');
      this.coverIntroSection('CoverIntroSection');
      this.coverMiddleSection('CoverMiddleSection');
      this.coverBandSection('CoverBandSection');
    }

    if (localStorage.getItem('language') =='Spanish') {
      this.coverGallery('CoverGallerySP');
      this.coverCarouselSelection('CoverCarouselSectionSP');
      this.coverIntroSection('CoverIntroSectionSP');
      this.coverMiddleSection('CoverMiddleSectionSP');
      this.coverBandSection('CoverBandSectionSP');
    }

    if (localStorage.getItem('language') =='English') {
      this.coverGallery('CoverGallery');
      this.coverCarouselSelection('CoverCarouselSection');
      this.coverIntroSection('CoverIntroSection');
      this.coverMiddleSection('CoverMiddleSection');
      this.coverBandSection('CoverBandSection');
    }

  //   this.contentservice.getContentbySection('CoverGallery').subscribe(val => {
  //     this.contentSectionsGallery = val;
  //     console.log('portada',this.contentSectionsGallery);
  //     if (this.contentSectionsGallery.length > 0) {
  //       for (let i = 0; i < this.contentSectionsGallery.length; i++) {
  //         console.log('portada i',this.contentSectionsGallery[i].UrlImage);
  //         const src = this.contentSectionsGallery[i].UrlImage
  //         const caption = this.contentSectionsGallery[i].ExternalWeb1
  //         const thumb = this.contentSectionsGallery[i].UrlImage

  //         const album = {
  //            src: src,
  //            caption: caption,
  //            thumb: thumb
  //         };

  //         this._albums.push(album);
  //         console.log('albums', this._albums);
  //         if (i==0) {this.caroulseImage_0 = this.contentSectionsGallery[i].UrlImage}
  //         if (i==1) {this.caroulseImage_1 = this.contentSectionsGallery[i].UrlImage}
  //         if (i==2) {this.caroulseImage_2 = this.contentSectionsGallery[i].UrlImage}
  //         if (i==3) {this.caroulseImage_3 = this.contentSectionsGallery[i].UrlImage}
  //         if (i==4) {this.caroulseImage_4 = this.contentSectionsGallery[i].UrlImage}
  //         if (i==5) {this.caroulseImage_5 = this.contentSectionsGallery[i].UrlImage}
  //       }

  //     } else {
  //       for (let i = 0; i <= 5; i++) {
  //         const src = "../../../assets/images/APage_"+i+".jpg"
  //         const caption = this._description[i];
  //         const thumb = "../../../assets/images/APage_"+i+".jpg"

  //         const album = {
  //            src: src,
  //            caption: caption,
  //            thumb: thumb
  //         };

  //         this._albums.push(album);
  //       }
  //     }
  //   })


  //   this.contentservice.getContentbySection('CoverCarouselSection').subscribe(val => {
  //     this.contentSectionsCarousel = val;
  //     this.urlToImageOne = this.contentSectionsCarousel[0].UrlImage;
  //     this.urlToImageTwo = this.contentSectionsCarousel[1].UrlImage;
  //     this.urlToImageThree = this.contentSectionsCarousel[2].UrlImage;
  //     this.titleCarouselOne = this.contentSectionsCarousel[0].ExternalWeb1;
  //     this.titleCarouselTwo = this.contentSectionsCarousel[1].ExternalWeb1;
  //     this.titleCarouselThree = this.contentSectionsCarousel[2].ExternalWeb1;
  //     this.subTitleCarouselOne = this.contentSectionsCarousel[0].ExternalWeb2;
  //     this.subTitleCarouselTwo = this.contentSectionsCarousel[1].ExternalWeb2;
  //     this.subTitleCarouselThree = this.contentSectionsCarousel[2].ExternalWeb2;

  //   });

  //   this.contentservice.getContentbySection('CoverIntroSection').subscribe(val => {
  //     this.contentSectionsIntro = val;
  //     this.urlToImageIntroOne = this.contentSectionsIntro[0].UrlImage;
  //     this.urlToImageIntroTwo = this.contentSectionsIntro[1].UrlImage;
  //     this.urlToImageIntroThree = this.contentSectionsIntro[2].UrlImage;
  //     this.titleIntroOne = this.contentSectionsIntro[0].ExternalWeb1;
  //     this.titleIntroTwo = this.contentSectionsIntro[1].ExternalWeb1;
  //     this.titleIntroThree = this.contentSectionsIntro[2].ExternalWeb1;
  //     this.subTitleIntroOne = this.contentSectionsIntro[0].ExternalWeb2;
  //     this.subTitleIntroTwo = this.contentSectionsIntro[1].ExternalWeb2;
  //     this.subTitleIntroThree = this.contentSectionsIntro[2].ExternalWeb2;

  //   });

  //   this.contentservice.getContentbySection('CoverBandSection').subscribe(val => {
  //     this.contentSectionsBand = val;
  //     this.urlToImageBandOne = this.contentSectionsBand[0].UrlImage;
  //     this.urlToImageBandTwo = this.contentSectionsBand[0].Background;
  //     this.titleBandOne = this.contentSectionsBand[0].ExternalWeb1;
  //     this.titleBandTwo = this.contentSectionsBand[0].ExternalWeb2;
  //     this.titleBandThree = this.contentSectionsBand[0].UrlVideo;
  //     this.subTitleBandOne = this.contentSectionsBand[0].Time;
  //     this.titleBandNewsletter = this.contentSectionsBand[0].ExternalWeb3;
  //     this.subTitleBandNewsletter = this.contentSectionsBand[0].Title;

  //   });

  //   this.contentservice.getContentbySection('CoverMiddleSection').subscribe(val => {
  //     this.contentSectionsMiddle = val;
  //     this.urlToImageMiddleOne = this.contentSectionsMiddle[0].UrlImage;
  //     this.urlToImageMiddleTwo = this.contentSectionsMiddle[1].UrlImage;
  //     this.urlToImageMiddleThree = this.contentSectionsMiddle[2].UrlImage;
  //     this.titleMiddleOne = this.contentSectionsMiddle[0].ExternalWeb1;
  //     this.titleMiddleTwo = this.contentSectionsMiddle[1].ExternalWeb1;
  //     this.titleMiddleThree = this.contentSectionsMiddle[2].ExternalWeb1;
  //     this.subTitleMiddleOne = this.contentSectionsMiddle[0].ExternalWeb2;
  //     this.subTitleMiddleTwo = this.contentSectionsMiddle[1].ExternalWeb2;
  //     this.subTitleMiddleThree = this.contentSectionsMiddle[2].ExternalWeb2;
  //     this.textMiddleOne = this.contentSectionsMiddle[0].UrlVideo;
  //     this.textMiddleTwo = this.contentSectionsMiddle[1].UrlVideo;
  //     this.textMiddleThree = this.contentSectionsMiddle[2].UrlVideo;
  //     this.subTextMiddleOne = this.contentSectionsMiddle[0].Time;
  //     this.subTextMiddleTwo = this.contentSectionsMiddle[1].Time;
  //     this.subTextMiddleThree = this.contentSectionsMiddle[2].Time;
  //     this.notesMiddleOne = this.contentSectionsMiddle[0].ExternalWeb3;
  //     this.notesMiddleTwo = this.contentSectionsMiddle[1].ExternalWeb3;
  //     this.notesMiddleThree = this.contentSectionsMiddle[2].ExternalWeb3;
  //   });


  //   for (let i = 0; i <= 5; i++) {
  //     const src = "../../../assets/images/APage_"+i+".jpg"
  //     const caption = this._description[i];
  //     const thumb = "../../../assets/images/APage_"+i+".jpg"

  //     const album = {
  //        src: src,
  //        caption: caption,
  //        thumb: thumb
  //     };

  //     this._albums.push(album);
  //   }
   }

  coverGallery(identifier:string) {

    this.contentservice.getContentbySection(identifier).subscribe(val => {
      this.contentSectionsGallery = val;
      console.log('portada',this.contentSectionsGallery);
      if (this.contentSectionsGallery.length > 0) {
        for (let i = 0; i < this.contentSectionsGallery.length; i++) {
          console.log('portada i',this.contentSectionsGallery[i].UrlImage);
          const src = this.contentSectionsGallery[i].UrlImage
          const caption = this.contentSectionsGallery[i].ExternalWeb1
          const thumb = this.contentSectionsGallery[i].UrlImage

          const album = {
             src: src,
             caption: caption,
             thumb: thumb
          };

          this._albums.push(album);
          console.log('albums', this._albums);
          if (i==0) {this.caroulseImage_0 = this.contentSectionsGallery[i].UrlImage}
          if (i==1) {this.caroulseImage_1 = this.contentSectionsGallery[i].UrlImage}
          if (i==2) {this.caroulseImage_2 = this.contentSectionsGallery[i].UrlImage}
          if (i==3) {this.caroulseImage_3 = this.contentSectionsGallery[i].UrlImage}
          if (i==4) {this.caroulseImage_4 = this.contentSectionsGallery[i].UrlImage}
          if (i==5) {this.caroulseImage_5 = this.contentSectionsGallery[i].UrlImage}
        }

      } else {
        for (let i = 0; i <= 5; i++) {
          const src = "../../../assets/images/APage_"+i+".jpg"
          const caption = this._description[i];
          const thumb = "../../../assets/images/APage_"+i+".jpg"

          const album = {
             src: src,
             caption: caption,
             thumb: thumb
          };

          this._albums.push(album);
        }
      }
    })

  }

  coverCarouselSelection(identifier:string) {
    this.contentservice.getContentbySection(identifier).subscribe(val => {
      this.contentSectionsCarousel = val;
      this.urlToImageOne = this.contentSectionsCarousel[0].UrlImage;
      this.urlToImageTwo = this.contentSectionsCarousel[1].UrlImage;
      this.urlToImageThree = this.contentSectionsCarousel[2].UrlImage;
      this.titleCarouselOne = this.contentSectionsCarousel[0].ExternalWeb1;
      this.titleCarouselTwo = this.contentSectionsCarousel[1].ExternalWeb1;
      this.titleCarouselThree = this.contentSectionsCarousel[2].ExternalWeb1;
      this.subTitleCarouselOne = this.contentSectionsCarousel[0].ExternalWeb2;
      this.subTitleCarouselTwo = this.contentSectionsCarousel[1].ExternalWeb2;
      this.subTitleCarouselThree = this.contentSectionsCarousel[2].ExternalWeb2;
      this.authorCarouselOne = this.contentSectionsCarousel[0].Title;
      this.authorCarouselTwo = this.contentSectionsCarousel[1].Title;
      this.authorCarouselThree = this.contentSectionsCarousel[2].Title;
      this.sourceCarouselOne = this.contentSectionsCarousel[0].PhotoSource;
      this.sourceCarouselTwo = this.contentSectionsCarousel[1].PhotoSource;
      this.sourceCarouselThree = this.contentSectionsCarousel[2].PhotoSource;

    });
  }

  coverIntroSection(identifier:string) {
    this.contentservice.getContentbySection(identifier).subscribe(val => {
      this.contentSectionsIntro = val;
      this.urlToImageIntroOne = this.contentSectionsIntro[0].UrlImage;
      this.urlToImageIntroTwo = this.contentSectionsIntro[1].UrlImage;
      this.urlToImageIntroThree = this.contentSectionsIntro[2].UrlImage;
      this.titleIntroOne = this.contentSectionsIntro[0].ExternalWeb1;
      this.titleIntroTwo = this.contentSectionsIntro[1].ExternalWeb1;
      this.titleIntroThree = this.contentSectionsIntro[2].ExternalWeb1;
      this.subTitleIntroOne = this.contentSectionsIntro[0].ExternalWeb2;
      this.subTitleIntroTwo = this.contentSectionsIntro[1].ExternalWeb2;
      this.subTitleIntroThree = this.contentSectionsIntro[2].ExternalWeb2;

    });
  }

  coverMiddleSection (identifier:string) {
    this.contentservice.getContentbySection(identifier).subscribe(val => {
      this.contentSectionsMiddle = val;
      this.urlToImageMiddleOne = this.contentSectionsMiddle[0].UrlImage;
      this.urlToImageMiddleTwo = this.contentSectionsMiddle[1].UrlImage;
      this.urlToImageMiddleThree = this.contentSectionsMiddle[2].UrlImage;
      this.titleMiddleOne = this.contentSectionsMiddle[0].ExternalWeb1;
      this.titleMiddleTwo = this.contentSectionsMiddle[1].ExternalWeb1;
      this.titleMiddleThree = this.contentSectionsMiddle[2].ExternalWeb1;
      this.subTitleMiddleOne = this.contentSectionsMiddle[0].ExternalWeb2;
      this.subTitleMiddleTwo = this.contentSectionsMiddle[1].ExternalWeb2;
      this.subTitleMiddleThree = this.contentSectionsMiddle[2].ExternalWeb2;
      this.textMiddleOne = this.contentSectionsMiddle[0].UrlVideo;
      this.textMiddleTwo = this.contentSectionsMiddle[1].UrlVideo;
      this.textMiddleThree = this.contentSectionsMiddle[2].UrlVideo;
      this.subTextMiddleOne = this.contentSectionsMiddle[0].Time;
      this.subTextMiddleTwo = this.contentSectionsMiddle[1].Time;
      this.subTextMiddleThree = this.contentSectionsMiddle[2].Time;
      this.notesMiddleOne = this.contentSectionsMiddle[0].ExternalWeb3;
      this.notesMiddleTwo = this.contentSectionsMiddle[1].ExternalWeb3;
      this.notesMiddleThree = this.contentSectionsMiddle[2].ExternalWeb3;
    });

  }

  coverBandSection (identifier:string) {
    this.contentservice.getContentbySection(identifier).subscribe(val => {
      this.contentSectionsBand = val;
      this.urlToImageBandOne = this.contentSectionsBand[0].UrlImage;
      this.urlToImageBandTwo = this.contentSectionsBand[0].Background;
      this.urlToImageBandThree = this.contentSectionsBand[0].Content;
      this.titleBandOne = this.contentSectionsBand[0].ExternalWeb1;
      this.titleBandTwo = this.contentSectionsBand[0].ExternalWeb2;
      this.titleBandThree = this.contentSectionsBand[0].UrlVideo;
      this.subTitleBandOne = this.contentSectionsBand[0].Time;
      this.titleBandNewsletter = this.contentSectionsBand[0].ExternalWeb3;
      this.subTitleBandNewsletter = this.contentSectionsBand[0].Title;

    });
  }

  open(index: number): void {
    // open lightbox
    this._lightbox.open(this._albums, index);
  }


  onLoadProjects(){
    //complex iteractions here
    this.router.navigate(['/projects'])
  }

  onSubmit(hero:HeroContact) {

    // reactive form components
	let emailControl = new FormControl(this.hero.email, [
		Validators.required,
		Validators.email,
	]);
	let lastNameControl = new FormControl(this.hero.lastName);
  let nameControl = new FormControl(this.hero.name);
  let cityControl = new FormControl(this.hero.city, [

  ]);
  let phoneControl = new FormControl(this.hero.phone, [

  ]);
  let  countryControl= new FormControl(this.hero.country, [

  ]);
  let  messageControl= new FormControl(this.hero.message, [

  ]);


  this.hero = hero
  this.error = '';
  if (emailControl.status === 'VALID' && nameControl.status === 'VALID' && lastNameControl.status === 'VALID') {
    const params = new HttpParams()
      .set('FNAME', 'No Name')
      .set('LNAME', 'No Last Name')
      .set('EMAIL', emailControl.value)
      .set('b_09c2800388a897ac8667376f8_fc9c6c8ba6', ''); // hidden input name
    const mailChimpUrl = this.mailChimpEndpoint + params.toString();
    // 'c' refers to the jsonp callback param key. This is specific to Mailchimp
    this.http.jsonp<MailChimpResponse>(mailChimpUrl, 'c').subscribe(response => {
      if (response.result && response.result !== 'error') {
        // console.log("EXITO");
        this.submitted = true;
        this.displayNewsletter = false;
      }
      else {
        this.error = response.msg;
        // console.log("error :",this.error)
        setTimeout (() => {
          this.error_duplicate = false;
       }, 2000);
        this.error_duplicate = true;

      }
    }, error => {
      // console.error(error);
      this.error = 'Sorry, an error occurred.';
    });
  } else {

    if (nameControl.status != 'VALID') {
      this.error_name = true
    }
    if (lastNameControl.status != 'VALID') {
      this.error_lastName = true
    }
    if (emailControl.status != 'VALID') {
      this.error_email = true
    }
  }
}

ngAfterViewInit() {
  const parent: HTMLElement = document.getElementById('main-footer');
  this.renderer.removeStyle(parent, 'position');
  this.renderer.removeStyle(parent, 'bottom');
  this.renderer.removeStyle(parent, 'width');
  }

}






export class HeroContact {

  constructor(
    public id: number,
    public name: string,
    public lastName: string,
    public email: string,
    public city: string,
    public phone: string,
    public country: string,
    public message: string
  ) {  }
}

