import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})

export class BridgeService {
  mobileSuccess = new BehaviorSubject<boolean>(false);
  constructor() { }
  confirmMobile() {
    this.mobileSuccess.next(true)
  }
}
