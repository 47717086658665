import { Component, OnInit, ɵConsole } from '@angular/core';

@Component({
  selector: 'app-music',
  templateUrl: './music.component.html',
  styleUrls: ['./music.component.scss']
})
export class MusicComponent implements OnInit {

// playBtn: HTMLElement = document.getElementById('main-footer');

// musicContainer: HTMLElement = document.getElementById('music-container');
// playBtn = document.getElementById('play');
// prevBtn: HTMLElement = document.getElementById('prev');
// nextBtn: HTMLElement = document.getElementById('next');

audio = new Audio();
duration = 0.0;
progress: HTMLElement = document.getElementById('progress');
// progressContainer: HTMLElement = document.getElementById('progress-container');
// title: HTMLElement = document.getElementById('title');
// cover: HTMLElement = document.getElementById('cover');

// Song titles
songs = ['hey', 'summer', 'ukulele', 'KundaliniAwakening'];

// Keep track of song
songIndex = 2;
countPlays = 0;


  constructor() { }

  ngOnInit(): void {
    console.log('MUSICA');
    // Event listeners
  const playBtn: HTMLElement = document.getElementById('play');
  playBtn.addEventListener('click', () => {
  const musicContainer: HTMLElement = document.getElementById('music-container');
  const isPlaying = musicContainer.classList.contains('play');
  console.log('is playing', isPlaying);

      if (isPlaying) {
        console.log('is playing')
        if ( this.countPlays === 0 ) {

        }
          this.pauseSong();
      } else {
        console.log('is NOT playing')
        this.playSong();
      }
  });

  this.loadSong(this.songs[this.songIndex]);
  this.countPlays = 1;
  this.playSong();

// Change song
// const prevBtn: HTMLElement = document.getElementById('prev');
// const nextBtn: HTMLElement = document.getElementById('next');
// prevBtn.addEventListener('click', this.prevSong);
// nextBtn.addEventListener('click', this.nextSong);

// Time/song update
this.audio.addEventListener('timeupdate', this.updateProgress);

// Click on progress bar
const progressContainer: HTMLElement = document.getElementById('progress-container');
// progressContainer.addEventListener('click', this.setProgress);

// Song ends
this.audio.addEventListener('ended', this.nextSong);
  }


  // Update song details
loadSong(song) {
  console.log('load song', song);
  const title: HTMLElement = document.getElementById('title');
  const cover: HTMLElement = document.getElementById('cover');
  const audioData: HTMLElement = document.getElementById("audio");

  //   audioData.onloadedmetadata = function() {
  //   console.log('audioData',audioData);
  // };

  //   audioData.addEventListener('loadedmetadata',function(){
  //     audioData.setAttribute('data-time',audioData.duration);
  //   },false);


  title.innerText = song;
  this.audio.src = `../../assets/music/${song}.mp3`;
  // this.duration = this.audio.duration;
  setTimeout (() => {
    this.duration = this.audio.duration;
    console.log('loadSong duration',this.duration);
}, 2000);

  console.log('loadSong duration',this.duration);
  console.log('loadSong audio',this.audio);
  cover.setAttribute( 'src',`../../assets/images/${song}.jpg`);
}

// Play song
playSong() {
  console.log('play song');
  let musicContainer: HTMLElement = document.getElementById('music-container');
  const playBtn: HTMLElement = document.getElementById('play');
  musicContainer.classList.add('play');
  // musicContainer.classList.toggle("play");
  console.log('play song musicContainer',musicContainer);
  console.log('playBtn.querySelector',playBtn.querySelector('i.fa'))
  playBtn.querySelector('i.fa').classList.remove('fa-play');
  playBtn.querySelector('i.fa').classList.add('fa-pause');
  this.audio.play();
}

playCount() {
  this.countPlays = 1;
}


// Pause song
pauseSong() {
  console.log('pauseSong');
  this.countPlays = 0;
  const musicContainer: HTMLElement = document.getElementById('music-container');
  const playBtn: HTMLElement = document.getElementById('play');
  musicContainer.classList.remove('play');
  playBtn.querySelector('i.fa').classList.add('fa-play');
  playBtn.querySelector('i.fa').classList.remove('fa-pause');
  this.audio.pause();
}

// Previous song
prevSong() {
  this.songIndex--;

  if (this.songIndex < 0) {
    this.songIndex = this.songs.length - 1;
  }

  this.loadSong(this.songs[this.songIndex]);

  this.playSong();
}

// Next song
nextSong() {
  this.songIndex++;
  console.log('this songs length',this.songs.length);

  if (this.songIndex > this.songs.length - 1) {
    this.songIndex = 0;
  }

  this.loadSong(this.songs[this.songIndex]);

  this.playSong();
}

// Update progress bar
updateProgress(e) {
  const progress: HTMLElement = document.getElementById('progress');
  const progressContainer: HTMLElement = document.getElementById('progress-container');
  const { duration, currentTime } = e.srcElement;
  const progressPercent = (currentTime / duration) * 100;
  progress.style.width = `${progressPercent}%`;
}

// Set progress bar
setProgress(e) {
  const progressContainer: HTMLElement = document.getElementById('progress-container');
  console.log('setProgress',e);
  const width = progressContainer.clientWidth;
  const clickX = e.offsetX;
  // const duration = this.audio.duration;

  console.log('width',width)
  console.log('clickX',clickX)
  console.log('duration',this.duration)

  this.audio.currentTime = (clickX / width) * this.duration;
}

}
