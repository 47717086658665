export default class Utility {
  static checkLanguage() : string {
    if (localStorage.getItem('language') ==null) {
      return 'English'
    }

    if (localStorage.getItem('language') =='Spanish') {
      return 'Spanish'
    }

    if (localStorage.getItem('language') =='English') {
      return 'English'
    }
  }
}
