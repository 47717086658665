import { Component, OnInit, Input, Pipe, PipeTransform , OnDestroy} from '@angular/core';
import { ContentSection } from './../../shared/models/contentSection.model'
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser'
import { AuthService } from '../../shared/services/auth.service'
import {YoutubeService } from './../../shared/services/youtube.service'
import { takeUntil } from 'rxjs/operators';
import { BehaviorSubject} from 'rxjs';
import { Subject } from 'rxjs';
import { UserProfile } from '../../shared/models/user-profile';
import { User } from './../../shared/models/user.model';
import Utility  from './../../shared/helpers/utility';
import { NgxMasonryOptions } from 'ngx-masonry';
import { UploadInfoService } from './../../shared/services/upload-info.service';
import { BridgeService } from './../../shared/services/bridge.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-research-card',
  templateUrl: './research-card.component.html',
  styleUrls: ['./research-card.component.scss']
})
export class ResearchCardComponent implements OnInit, OnDestroy {
  @Input('channel') channel: ContentSection;

  videos: any[];
  channels: any;
  channelDetails: any;
  thumbnail: string = "";
  destroy$ = new BehaviorSubject<boolean>(false);
  private unsubscribe$: Subject<any> = new Subject();

  isFamily: boolean;
  userLoggedIn: UserProfile;
  userData: User;
  language = '';
  mobile: boolean = false;
  private successSub!: Subscription

// Add this to display the content as a card for research.
  contentSections: ContentSection[];
  contentClean: ContentSection[];
  title = '';
  subTitle = '';
  photoSource = '';
  title1 = '';
  title2 = '';
  title3 = '';


  public masonryOptions: NgxMasonryOptions = {
		transitionDuration: '0.2s',
		gutter: 20,
		columnWidth: 300,
		// resize: true,
		initLayout: true,
		// fitWidth: true,
		// originLeft: false,
	};

	masonryImages;
	limit = 15;


  constructor(private contentservice: UploadInfoService, private youTubeService: YoutubeService, public authService: AuthService, public bridgeService: BridgeService) { }

  ngOnInit(): void {
    // this.language = Utility.checkLanguage();
    // this.youTubeService
    // .getChannelInfo(this.channel.ExternalWeb1)
    // .pipe(takeUntil(this.unsubscribe$))
    // .subscribe(lista => {
    // this.channels = lista
    // this.channelDetails = this.channels.items[0]
    // console.log('channels data',this.channelDetails);
    // if(this.channelDetails) {
    //   this.thumbnail=this.channelDetails.snippet.thumbnails.high.url
    // }
    // });


    // if (JSON.parse(localStorage.getItem('user'))) {
    //   this.userData = JSON.parse(localStorage.getItem('user'));
    //   if (this.userData) {
    //     this.getUserbyId(this.userData.uid)
    //   }
    // }


    this.language = Utility.checkLanguage();
    this.contentSections = [];
    if ( this.language === 'Spanish') {
      this.title = 'Técnicas'
      this.subTitle = 'Estas son lagunas de las técnicas'
      this.photoSource = 'Fuente de la foto : ';
      this.title1 = 'Breve descripción'
      this.title2 = 'Fundamentos'
      this.title3 = 'Por qué quizás funcione?'
      // this.contentservice.getContentbySection('reserachSP').subscribe(val => {
      //   this.contentSections = val;
      // })
      this.contentSections[0] = this.channel;
    }
    if ( this.language === 'English') {
      this.title = 'Techniques';
      this.subTitle = 'These are some of the techniques';
      this.photoSource = 'Photo source : ';
      this.title1 = 'Brief description'
      this.title2 = 'The basis'
      this.title3 = 'Why this may be works?'
      // this.contentservice.getContentbySection('Courses').subscribe(val => {
      //   this.contentSections = val;
      // })
      this.contentSections[0] = this.channel;
    }

	// this.masonryImages = this.dummyPictures.slice(0, this.limit);
	this.masonryImages = this.contentSections.slice(0, this.limit);

  this.successSub = this.bridgeService.mobileSuccess.subscribe(mobile => {
    if (mobile) {
      this.mobile = true
    }
    else {
      this.mobile = false
    }
  }
  )

  }

  getUserbyId(userUID:String) {
    this.authService.getUserInfobyId(userUID).subscribe(user => {
      this.userLoggedIn = user
      this.isFamily = this.authService.isFamily(this.userLoggedIn)
    })
  }

  ngOnDestroy(): void {
    this.successSub.unsubscribe();
  }

}
