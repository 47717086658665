import { Product } from './product.model';

export class ShoppingCartItem {
  id:string;
  title: string;
  price: number;
  category: string;
  imageUrl: string;
  description: string
  quantity: number;
  totalPrice:number;

  constructor(init?: Partial<ShoppingCartItem>) {
    // console.log("shopping item", init);
    Object.assign(this, init);
  }
  // get totalPrice() { return this.price * this.quantity; }

}
