import { Component, OnInit, Input, Pipe, PipeTransform , OnDestroy} from '@angular/core';
import { ContentSection } from './../../shared/models/contentSection.model'
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser'

import {YoutubeService } from './../../shared/services/youtube.service'
import { takeUntil } from 'rxjs/operators';
import { BehaviorSubject} from 'rxjs';
import { Subject } from 'rxjs';
import { BridgeService } from './../../shared/services/bridge.service';
import { Subscription } from 'rxjs';

declare var $: any;


@Component({
  selector: 'app-video-card',
  templateUrl: './video-card.component.html',
  styleUrls: ['./video-card.component.scss']
})
export class VideoCardComponent implements OnInit, OnDestroy {
  @Input('lab') lab: ContentSection;

  linkVideoSecure:SafeResourceUrl;
  mobile: boolean = false;
  private successSub!: Subscription

  constructor(private sanitizer: DomSanitizer, public bridgeService: BridgeService) { }

  ngOnInit() {
    this.checkVideo(this.lab)
    this.linkVideoSecure = this.sanitizer.bypassSecurityTrustResourceUrl(this.lab.UrlVideo);
    // console.log("parseVideo",this.parseVideo(this.lab.UrlVideo))
    this.successSub = this.bridgeService.mobileSuccess.subscribe(mobile => {
      if (mobile) {
        this.mobile = true
      }
      else {
        this.mobile = false
      }
    }
    )
  }
  checkVideo(lab:ContentSection) : Boolean {
    const substring:string = "http"
    if (lab.UrlVideo.indexOf(substring) !== -1) {
      return true
    } else {
      return false
    }

  }

  parseVideo (url) {
    // - Supported YouTube URL formats:
    //   - http://www.youtube.com/watch?v=My2FRPA3Gf8
    //   - http://youtu.be/My2FRPA3Gf8
    //   - https://youtube.googleapis.com/v/My2FRPA3Gf8
    // - Supported Vimeo URL formats:
    //   - http://vimeo.com/25451551
    //   - http://player.vimeo.com/video/25451551
    // - Also supports relative URLs:
    //   - //player.vimeo.com/video/25451551

    url.match(/(http:|https:|)\/\/(player.|www.)?(vimeo\.com|youtu(be\.com|\.be|be\.googleapis\.com))\/(video\/|embed\/|watch\?v=|v\/)?([A-Za-z0-9._%-]*)(\&\S+)?/);

    if (RegExp.$3.indexOf('youtu') > -1) {
        var type = 'youtube';
    } else if (RegExp.$3.indexOf('vimeo') > -1) {
        var type = 'vimeo';
    }

    return {
        type: type,
        id: RegExp.$6
    };
}

createVideo (url, width, height) {
    // Returns an iframe of the video with the specified URL.
    var videoObj = this.parseVideo(url);
    var $iframe = $('<iframe>', { width: width, height: height });
    $iframe.attr('frameborder', 0);
    if (videoObj.type == 'youtube') {
        $iframe.attr('src', '//www.youtube.com/embed/' + videoObj.id);
    } else if (videoObj.type == 'vimeo') {
        $iframe.attr('src', '//player.vimeo.com/video/' + videoObj.id);
    }
    return $iframe;
}

getVideoThumbnail (url, cb) {
    // Obtains the video's thumbnail and passed it back to a callback function.
    var videoObj = this.parseVideo(url);
    if (videoObj.type == 'youtube') {
        cb('//img.youtube.com/vi/' + videoObj.id + '/maxresdefault.jpg');
    } else if (videoObj.type == 'vimeo') {
        // Requires jQuery
        $.get('http://vimeo.com/api/v2/video/' + videoObj.id + '.json', function(data) {
            cb(data[0].thumbnail_large);
        });
    }
}

ngOnDestroy(): void {
  this.successSub.unsubscribe();
}
}
