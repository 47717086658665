import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {CheckoutService} from '../shared/services/checkout.service';

@Component({
  selector: 'app-stripe-checkout',
  templateUrl: './stripe-checkout.component.html',
  styleUrls: ['./stripe-checkout.component.scss']
})
export class StripeCheckoutComponent implements OnInit {

  message = "Waiting for purchase to complete...";

  waiting = true;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private checkout: CheckoutService) {

  }

  ngOnInit() {
    console.log("stripe-checkout page");
      const result = this.route.snapshot.queryParamMap.get("purchaseResult");

      if (result == "success") {
        console.log("stripe-checkout page SUCESS");
          const ongoingPurchaseSessionId = this.route.snapshot.queryParamMap.get("ongoingPurchaseSessionId");
        console.log("purchasesessionID",ongoingPurchaseSessionId);
          this.checkout.waitForPurchaseCompleted(ongoingPurchaseSessionId)
              .subscribe(
                  () => {
                      this.waiting = false;
                      this.message = "Purchase SUCCESSFUL, redirecting...";
                      console.log("Purchase SUCCESSFUL, redirecting...");
                      // setTimeout(() => this.router.navigateByUrl("/products"), 3000);
                      setTimeout(() => this.router.navigate(['/tracking']), 3000);
                  })

      }
      else {
          this.waiting = false;
          this.message =  "Purchase CANCELED or FAILED, redirecting...";
          setTimeout(() => this.router.navigateByUrl("/menu"), 3000);
      }


  }

}

