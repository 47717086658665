import { ShoppingCart } from './shopping-cart.model';
import { Shipping } from './shipping.model';

export class Order {
  id:string;
  datePlaced: number;
  items: any[];
  process: any[];
  constructor(public userId: string, public shipInfo: Shipping, shoppingCart: ShoppingCart) {
    this.datePlaced = new Date().getTime();
    this.process = [{stage:'received',status:'ok',comment:'',date:new Date().getTime()},{stage:'processing',status:'pending',comment:'',date:''},{stage:'ready',status:'pending',comment:'',date:''},{stage:'delivery',status:'pending',comment:'',date:''},{stage:'transit',status:'pending',comment:'',date:''},{stage:'delivered',status:'pending',comment:'',date:''},{stage:'feedback',status:'pending',comment:'',date:''}]
    // console.log("order model shoppingcart",shoppingCart)
    this.items = shoppingCart.items.map(i => {
      return {
        product: {
          id:i.id,
          title: i.title,
          imageUrl: i.imageUrl,
          price: i.price
        },
        quantity: i.quantity,
        totalPrice: i.totalPrice
      }
    })
}
}
