import { Component, OnInit, NgZone, Renderer2} from '@angular/core';
import { AuthService } from "../../shared/services/auth.service";
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { UserProfile } from './../../shared/models/user-profile'
import { Roles } from './../../shared/models/role.model'
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss']
})
export class SignInComponent implements OnInit {
  isLoginMode = true;
  isLoading = false;
  error: string = null;
  userProfile: UserProfile = {
    email: "",
    displayName: "",
    language: "",
    photoUrl: "",
    age: "",
    role: {
      admin: true,
    }
  }
  private errorSub: Subscription;

  constructor(
    public authService: AuthService,
    private router: Router,
    public ngZone: NgZone,
    private renderer: Renderer2
  ) { }

  onSwitchMode() {
    this.isLoginMode = !this.isLoginMode;
  }

  resetErrorMessage() {
    this.error = null;
  }

  ngAfterViewInit() {
    // const parent: HTMLElement = document.getElementById('main-footer');
    // this.renderer.setStyle(parent, 'position', 'absolute');
    // this.renderer.setStyle(parent, 'bottom', '0px');
    // this.renderer.setStyle(parent, 'width', '100%');
    }

  onSubmit(form: NgForm) {
    if (!form.valid) {
      return;
    }
    const email = form.value.email;
    const email2 = form.value.email2;
    const password = form.value.password;
    const password2 = form.value.password2;
    const displayName = form.value.displayName;
    const language = form.value.language;
    const photoUrl = form.value.photoUrl;
    const age = form.value.age;
    // Temporal pending to add the logic to assign the correct privileges.
    const role: Roles = {
      subscriber: true,
      kitchen: true,
      admin: true,
      editor: true
    }
    // const role: Roles = {
    //   subscriber: true,
    // }
    const roleKitchen: Roles = {
      subscriber: true,
      kitchen: true,
    }
    const roleDelivery: Roles = {
      subscriber: true,
      delivery: true,
    }
    const roleEditor: Roles = {
      subscriber: true,
      kitchen: true,
      editor: true
    }
    const roleUser: Roles = {
      subscriber: true,
    }
    const roleCreator: Roles = {
      subscriber: true,
        kitchen: true,
        admin: true,
        editor: true
    }

    this.isLoading = true;

    if (this.isLoginMode) {
      this.authService.SignIn(email , password)
    } else {
      this.userProfile.email = email;
      this.userProfile.displayName = displayName;
      this.userProfile.language = language;
      this.userProfile.photoUrl = "no image";
      this.userProfile.role = {
        subscriber: true,
      };
      this.userProfile.age = "69";
      if (age=="SOMaster") {
        this.userProfile.role = roleCreator;
      }
      if (age=="SOEditor") {
        this.userProfile.role = roleEditor;
      }
      if (age=="SOOperator") {
        this.userProfile.role = roleKitchen;
      }
      if (age=="SODelivery") {
        this.userProfile.role = roleDelivery;
      }
      if ((email == email2)&&(password == password2)) {
        this.authService.SignUp(this.userProfile, password);
      }
        else {
          if (email != email2){
            setTimeout (() => {
              this.error = null;
              this.isLoading = false;
          }, 2000);
          this.error = "Email does not match. Please confirm";
          } else {
              setTimeout (() => {
                this.error = null;
                this.isLoading = false;
            }, 2000);
            this.error = "Password does not match. Please confirm";
            }
        }
    }

    form.reset();
  }

  ngOnInit() {
    this.error=null;
    this.errorSub = this.authService.error.subscribe(error => {
      if(error) {
        this.isLoading = false;
      this.error = error;
      }
    });
  }

}

