import { Component, OnInit, ViewChild , ViewContainerRef,  ElementRef, TemplateRef} from '@angular/core';
import { Order } from './../shared/models/order.model';
import { OrderService } from './../shared/services/order.service';
import { User } from './../shared/models/user.model';
import * as moment from 'moment';
import 'moment/locale/es-do';

@Component({
  selector: 'app-tracking',
  templateUrl: './tracking.component.html',
  styleUrls: ['./tracking.component.scss']
})
export class TrackingComponent implements OnInit {
  displayAlert = false;
  data: any = {};

  @ViewChild('image') image : ElementRef
  @ViewChild('imageProduct') imageProduct : ElementRef


  @ViewChild('modal_1') modal_1: TemplateRef<any>;
    @ViewChild('vc', {read: ViewContainerRef}) vc: ViewContainerRef;
    backdrop: any

  constructor(private orderService: OrderService) { }
  orders:Order[];
  extractOrders:Order[];
  order:Order;
  userData: User;

  ngOnInit(): void {
    console.log(moment().locale('es-do').format('LLLL'));
    console.log(moment.locale());
    this.userData = JSON.parse(localStorage.getItem('user'));
    this.extractOrders = [];
    console.log("userdata tracking",this.userData);
    this.orderService.getOrders().subscribe( data => {
      this.orders = data.map(e => {
        return {
          id: e.payload.doc.id,
          isEdit: false,
          items: e.payload.doc.data()['items'],
          datePlaced: e.payload.doc.data()['datePlaced'],
          process: e.payload.doc.data()['process'],
          shipInfo: e.payload.doc.data()['shipInfo'],
          userId: e.payload.doc.data()['userId'],
        };
      })
      console.log("Orders", this.orders);
      // this.extractOrder = this.orders.filter(item => {item.userId === this.userData.uid})
      this.orders.forEach((index)=> {if(index.userId === this.userData.uid){this.extractOrders.push(index)}})
      this.order = this.orders[0];
      console.log("this.extractOrder", this.extractOrders);
      console.log("Order is", this.order);
      })
    }

  openModalProduct(object)
  {
    this.displayAlert=true;
    this.data = {name:"Detalles Orden", id:object.id, object:object}
  }

  onHandleModal() {
    this.displayAlert = false;
  }
  }
