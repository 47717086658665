<div class="backdrop" (click)="onClose()"></div>
<div class="alert-box">
  <p>{{ id }}</p>
  <p>{{ name }}</p>
  <div class="alert-box-actions">
    <div class="container">
      <div class="row">
        <div class="col-6-md">
          <div *ngIf="name=='Product'">
          <h2 class="text-center">Update Product</h2>
          <form #fProduct="ngForm" (ngSubmit)="updateProduct(fProduct)">
            <input type="text" class="form-control" name="name" [(ngModel)]="product.title" placeholder="Name" required><br><br>
            <input type="text" class="form-control" name="descriptionProduct" [(ngModel)]="product.description" placeholder="Description" required><br><br>

            <input type="number" class="form-control" name="price" [(ngModel)]="product.price" placeholder="Price" required><br><br>

            <select [(ngModel)]="product.category" name="category" id="category" class="form-control pt-2" required>
              <option value="product.category">{{product.category}}</option>
              <option *ngFor="let c of CategoriesArr" [value]="c.id">
                {{ c.title }}
              </option>
            </select>
            <input type="file" class="pt-4" ngModel name="imageProduct" required #imageProduct><br><br>

            <input type="submit" value="add" class="btn btn-primary" [disabled]="fProduct.invalid">
          </form>
        </div>

          <!-- <td> <input type="number" placeholder="Price ($$)" [(ngModel)]="product.Price" min="1">$</td> -->

          <div *ngIf="name=='Category'">
            <h2 class="text-center">Update Category</h2>
          <form #fCategory="ngForm" (ngSubmit)="updateCategory(fCategory)">
            <input type="text" class="form-control" name="nameCategory" [(ngModel)]="category.title" placeholder="Name" required><br><br>
            <input type="text" class="form-control" name="descriptionCategory" [(ngModel)]="category.description" placeholder="Description" required><br><br>
            <input type="file" class="pt-4" ngModel name="image" required #image><br><br>

            <input type="submit" value="add" class="btn btn-primary" [disabled]="fCategory.invalid">
          </form>
          </div>
        </div>
      </div>
    </div>
    <button class="btn btn-primary" (click)="onClose()">Close</button>
  </div>
</div>
