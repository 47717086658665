import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { LOCALE_ID } from '@angular/core';
import { DEFAULT_CURRENCY_CODE } from '@angular/core';
import es from '@angular/common/locales/es';
import { registerLocaleData } from '@angular/common';
registerLocaleData(es);

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BsNavbarComponent } from './bs-navbar/bs-navbar.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { HomeComponent } from './home/home.component';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from "@angular/fire/auth";
import { AngularFireAnalyticsModule } from '@angular/fire/analytics';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { environment } from '../environments/environment';

import { AuthService } from "./shared/services/auth.service";
import { SignInComponent } from './components/sign-in/sign-in.component';
import { SignUpComponent } from './components/sign-up/sign-up.component';
import { VerifyEmailComponent } from './components/verify-email/verify-email.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { AdminComponent } from './components/admin/admin.component';


import { HttpClientModule, HttpClientJsonpModule } from '@angular/common/http';
import { FormsModule }   from '@angular/forms';
// import {GoogleAnalyticsService} from "./services/googleanalytics.service";
import { AngularFireStorageModule } from "@angular/fire/storage";
import { AngularFireDatabaseModule } from "@angular/fire/database";

import { ReactiveFormsModule } from "@angular/forms";

import { Routes, RouterModule } from '@angular/router';
import { LightboxModule } from 'ngx-lightbox';

import { SafeHtmlPipe } from './videos/videos.component';


// Import all the components for which navigation service has to be activated
// import { ProjectsComponent } from './projects/projects.component';
// import { InspirationComponent } from './inspiration/inspiration.component';
// import { BlogComponent } from './blog/blog.component';
// import { BioComponent } from './bio/bio.component';
// import { ShoppingComponent } from './shopping/shopping.component';
// import { LabsComponent } from './labs/labs.component';
// import { UsersComponent } from './users/users.component';
// import { UserComponent } from './user/user.component';
// import { AuthComponent } from './auth/auth.component';
// import { DashboardComponent } from './components/dashboard/dashboard.component';
// import { LoadingSpinnerComponent } from './shared/loading-spinner/loading-spinner.component';
import {NgxPaginationModule} from 'ngx-pagination';
import { DecimalPipe } from '@angular/common';

import { AuthGuard } from "./shared/guard/auth.guard";
// import { AuthSecureInnerPages } from "./auth/auth.secure-inner-pages.guard";
// import { ImagesComponent } from './uploadImagesA/images/images.component';
// import { ImageComponent } from './uploadImagesA/images/image/image.component';
// import { ImageListComponent } from './uploadImagesA/images/image-list/image-list.component';
import { SecureInnerPagesGuard } from "./shared/guard/secure-inner-pages.guard";
import { LoadingSpinnerComponent } from './shared/loading-spinner/loading-spinner.component';
import { ProductCardComponent } from './product-card/product-card/product-card.component';
import { ProductsComponent } from './products/products/products.component';
import { ProductFilterComponent } from './products/product-filter/product-filter/product-filter.component';
import { ProductFormComponent } from './admin/product-form/product-form.component';
import { CategoryService } from './shared/services/category.service';
import { AlertComponent } from './shared/alert/alert.component';
import { ProductQuantityComponent } from './product-quantity/product-quantity.component';
import { ShoppingCartComponent } from './shopping-cart/shopping-cart.component';
import { ProductService } from './shared/services/product.service';
import { ShoppingCartService } from './shared/services/shopping-cart.service';
import { ShippingFormComponent } from './shipping-form/shipping-form.component';
import { CheckOutComponent } from './check-out/check-out.component';
import { ShoppingCartSummaryComponent } from './shopping-cart-summary/shopping-cart-summary.component';
import { OrderSuccessComponent } from './order-success/order-success.component';
import { AdminOrdersComponent } from './admin/admin-orders/admin-orders.component';
import { DeliveryComponent } from './delivery/delivery.component';
import { MapviewComponent } from './mapview/mapview.component';
import { AboutusComponent } from './aboutus/aboutus.component';
import { TermsComponent } from './terms/terms.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { TrackingComponent } from './tracking/tracking.component';
import { StepsComponent } from './admin/steps/steps.component';
import { DeliveryStepsComponent } from './admin/delivery-steps/delivery-steps.component';
import { ModalComponent } from './shared/modal/modal.component';
import { StripeCheckoutComponent } from './stripe-checkout/stripe-checkout.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { PayuCheckoutComponent } from './payu-checkout/payu-checkout.component';
import { ShoppingOrderSummaryComponent } from './shopping-order-summary/shopping-order-summary.component';
import { CoursesComponent } from './courses/courses.component';
import { NgxMasonryModule } from 'ngx-masonry';
import { AdmincontentComponent } from './admin/admincontent/admincontent.component';
import { SizeDetectorComponent } from './shared/screen/size-detector/size-detector.component';
import { VideosComponent } from './videos/videos.component';
import { VideoCardComponent } from './videos/video-card/video-card.component';
import { VideoFilterComponent } from './videos/video-filter/video-filter.component';
import { ProfileComponent } from './components/profile/profile.component';
import { SettingsComponent } from './components/settings/settings.component';
import { ChannelsComponent } from './channels/channels.component';
import { ChannelCardComponent } from './channels/channel-card/channel-card.component';
import { ChannelFilterComponent } from './channels/channel-filter/channel-filter.component';
import { MyvideosComponent } from './myvideos/myvideos.component';
import { MyvideoFilterComponent } from './myvideos/myvideo-filter/myvideo-filter.component';
import { MyvideoCardComponent } from './myvideos/myvideo-card/myvideo-card.component';
import { DisplayComponent } from './display/display.component';
import { YoutubecontainerComponent } from './display/youtubecontainer/youtubecontainer.component';
import { ResearchComponent } from './research/research.component';
import { ResearchCardComponent } from './research/research-card/research-card.component';
import { ResearchFilterComponent } from './research/research-filter/research-filter.component';
import { CatalogComponent } from './catalog/catalog.component';
import { MusicComponent } from './music/music.component';
import { RelaxComponent } from './relax/relax.component';

// import { BlogCardComponent } from './blog/blog-card/blog-card.component';
// import { BlogFilterComponent } from './blog/blog-filter/blog-filter.component';
// import { ProjectCardComponent } from './projects/project-card/project-card.component';
// import { ProjectFilterComponent } from './projects/project-filter/project-filter.component';
// import { LabCardComponent } from './labs/lab-card/lab-card.component';
// import { LabFilterComponent } from './labs/lab-filter/lab-filter.component';
// import { SafeHtmlPipe } from './labs/lab-card/lab-card.component';

//https://www.npmjs.com/package/ngx-masonry - version 1.1.2
// import { NgxMasonryModule } from 'ngx-masonry';
// import { ContactComponent } from './contact/contact.component';
// import { PrivacyComponent } from './privacy/privacy.component';
// import { TermsComponent } from './terms/terms.component';
// import { ChannelsComponent } from './channels/channels.component';
// import { VideosComponent } from './videos/videos.component';
// import { ChannelFilterComponent } from './channels/channel-filter/channel-filter.component';
// import { ChannelCardComponent } from './channels/channel-card/channel-card.component';
// import { VideoCardComponent } from './videos/video-card/video-card.component';
// import { VideoFilterComponent } from './videos/video-filter/video-filter.component';


const appRoutes: Routes = [
  {path:'', component: HomeComponent},
  // {path:'bio', component: BioComponent},
  // {path:'blog', component: BlogComponent},
  // {path:'projects', component: ProjectsComponent},
  // {path:'privacy', component: PrivacyComponent},
  // {path:'terms', component: TermsComponent},
  // {path:'channels', component: ChannelsComponent},
  // {path:'videos', component: VideosComponent},
  // {path:'inspiration', component: InspirationComponent},
  // {path:'contact', component: ContactComponent},
  // // {path:'shopping', component: ShoppingComponent},
  // // {path:'labs', component: LabsComponent},
  // {path:'users', component: UsersComponent},
  // {path:'auth', component: AuthComponent},
  // {path:'user/:id/:name', component: UserComponent},

  // { path: '', redirectTo: '/sign-in', pathMatch: 'full' },
  { path: 'sign-in', component: SignInComponent, canActivate: [SecureInnerPagesGuard]},
  { path: 'register-user', component: SignUpComponent, canActivate: [SecureInnerPagesGuard]},
  // { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard] },
  // { path: 'shopping', component: ShoppingComponent, canActivate: [AuthGuard] },
  // { path: 'labs', component: LabsComponent, canActivate: [AuthGuard] },
  { path: 'admin', component: AdmincontentComponent, canActivate: [AuthGuard] },
  // { path: 'uploadA', component: ImagesComponent, canActivate: [AuthGuard] },
  { path: 'forgot-password', component: ForgotPasswordComponent, canActivate: [SecureInnerPagesGuard] },
  { path: 'verify-email-address', component: VerifyEmailComponent, canActivate: [SecureInnerPagesGuard] },
  { path: 'menu', component: ProductsComponent },
  { path: 'contact', component: AboutusComponent },
  { path: 'terms', component: TermsComponent },
  { path: 'privacy', component: PrivacyComponent },
  {path:'videos', component: VideosComponent},
  {path:'music', component: MusicComponent},
  {path:'relax', component: RelaxComponent},
  {path:'myvideos', component: DisplayComponent},
  {path:'channels', component: ChannelsComponent},
  {path:'research', component: ResearchComponent},
  { path: 'products', component: ProductsComponent },
  { path: 'courses', component: CoursesComponent },
  { path: 'catalog', component: CatalogComponent },
  { path: 'tracking', component: TrackingComponent, canActivate: [AuthGuard] },
  { path: 'delivery', component: DeliveryComponent },
  { path: 'maps', component: MapviewComponent },
  { path: 'shopping-cart', component: ShoppingCartComponent },
  { path: 'check-out', component: CheckOutComponent, canActivate: [AuthGuard] },
  { path: 'payu-checkout', component: PayuCheckoutComponent, canActivate: [AuthGuard] },
  { path: 'order-success/:id', component: OrderSuccessComponent, canActivate: [AuthGuard] },
  { path: 'admin/orders', component: AdminOrdersComponent, canActivate: [AuthGuard] },
  { path:'stripe-checkout',component: StripeCheckoutComponent, canActivate: [AuthGuard]},

  // { path: 'uploadA', redirectTo: 'image/upload', pathMatch: 'full' },
  // {
  //   path: 'image', component: ImagesComponent, children: [
  //     { path: 'upload', component: ImageComponent },
  //     { path: 'list', component: ImageListComponent }
  //   ]
  // }
  {path: 'admin/products/new', component:ProductFormComponent, canActivate:[AuthGuard]},
  {path: 'admin/steps', component:StepsComponent, canActivate:[AuthGuard]},
  {path: 'admin/deliverysteps', component:DeliveryStepsComponent, canActivate:[AuthGuard]},
  {path: 'admin/orders', component:AdminOrdersComponent, canActivate:[AuthGuard]},
  {path: 'not-found', component:NotFoundComponent},
  {path: '**', redirectTo:'not-found'},
]


@NgModule({
  declarations: [
    AppComponent,
    BsNavbarComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    SignInComponent,
    SignUpComponent,
    VerifyEmailComponent,
    ForgotPasswordComponent,
    AdminComponent,
    LoadingSpinnerComponent,
    ProductCardComponent,
    ProductsComponent,
    ProductFilterComponent,
    ProductFormComponent,
    AlertComponent,
    ProductQuantityComponent,
    ShoppingCartComponent,
    ShippingFormComponent,
    CheckOutComponent,
    ShoppingCartSummaryComponent,
    OrderSuccessComponent,
    AdminOrdersComponent,
    DeliveryComponent,
    MapviewComponent,
    AboutusComponent,
    TermsComponent,
    PrivacyComponent,
    TrackingComponent,
    StepsComponent,
    DeliveryStepsComponent,
    ModalComponent,
    StripeCheckoutComponent,
    NotFoundComponent,
    PayuCheckoutComponent,
    ShoppingOrderSummaryComponent,
    CoursesComponent,
    AdmincontentComponent,
    SizeDetectorComponent,
    VideosComponent,
    VideoCardComponent,
    VideoFilterComponent,
    SafeHtmlPipe,
    ProfileComponent,
    SettingsComponent,
    ChannelsComponent,
    ChannelCardComponent,
    ChannelFilterComponent,
    MyvideosComponent,
    MyvideoFilterComponent,
    MyvideoCardComponent,
    DisplayComponent,
    YoutubecontainerComponent,
    ResearchComponent,
    ResearchCardComponent,
    ResearchFilterComponent,
    CatalogComponent,
    MusicComponent,
    RelaxComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    HttpClientJsonpModule,
    FormsModule,
    RouterModule.forRoot(appRoutes),
    AppRoutingModule,
    AngularFireAuthModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireAnalyticsModule,
    AngularFirestoreModule,
    ReactiveFormsModule,
    NgxPaginationModule,
    NgxMasonryModule,
    LightboxModule,
  ],
  providers: [AuthService, AuthGuard, DecimalPipe, CategoryService, ProductService,ShoppingCartService, {provide: LOCALE_ID, useValue: 'es-CO' }, {provide: DEFAULT_CURRENCY_CODE, useValue: 'COP' }],
  bootstrap: [AppComponent],

})
export class AppModule { }



// @NgModule({
//   declarations: [
//     AppComponent,
//     HomeComponent,
//     ProjectsComponent,
//     HeaderComponent,
//     InspirationComponent,
//     BlogComponent,
//     BioComponent,
//     ShoppingComponent,
//     LabsComponent,
//     UsersComponent,
//     UserComponent,
//     FooterComponent,
//     AuthComponent,
//     DashboardComponent,
//     SignInComponent,
//     SignUpComponent,
//     ForgotPasswordComponent,
//     VerifyEmailComponent,
//     LoadingSpinnerComponent,
//     ImagesComponent,
//     ImageComponent,
//     ImageListComponent,
//     AdminComponent,
//     BlogCardComponent,
//     BlogFilterComponent,
//     ProjectCardComponent,
//     ProjectFilterComponent,
//     LabCardComponent,
//     LabFilterComponent,
//     SafeHtmlPipe,
//     ContactComponent,
//     PrivacyComponent,
//     TermsComponent,
//     ChannelsComponent,
//     VideosComponent,
//     ChannelFilterComponent,
//     ChannelCardComponent,
//     VideoCardComponent,
//     VideoFilterComponent
//   ],
//   imports: [
//     BrowserModule,
//     LightboxModule,
//     HttpClientModule,
//     HttpClientJsonpModule,
//     FormsModule,
//     RouterModule.forRoot(appRoutes),
//     AngularFireModule.initializeApp(environment.firebaseConfig),
//     AngularFireStorageModule,
//     AngularFireDatabaseModule,
//     AngularFireAuthModule,
//     AngularFirestoreModule,
//     ReactiveFormsModule,
//     NgxPaginationModule,
//     NgxMasonryModule
//   ],
//   providers: [GoogleAnalyticsService,AuthService, DecimalPipe],
//   bootstrap: [AppComponent]
// })
// export class AppModule {
//   //We inject the service here to keep it alive whole time
//   constructor(protected _googleAnalyticsService: GoogleAnalyticsService) { }
// }

