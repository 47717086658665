import { Component, OnInit, Renderer2 } from '@angular/core';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.scss']
})
export class TermsComponent implements OnInit {

  constructor(private renderer: Renderer2) { }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
		const parent: HTMLElement = document.getElementById('main-footer');
		this.renderer.removeStyle(parent, 'position');
		this.renderer.removeStyle(parent, 'bottom');
		this.renderer.removeStyle(parent, 'width');
		}

}
