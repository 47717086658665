<!-- PAGE HEADER -->
<header id="page-header">
  <div class="container setDefaultSpace pd-2">
    <div class="row">
      <div class="col-md-6 m-auto text-center">
        <h1 class="fontOpenSans-RegularSmallDarkGray">Privacy Policy</h1>
        <p class="fontOpenSans-RegularExtraSmallDarkGray">Cookies  • Data Collection  • Web Analysis • Others</p>
        <div class="d-block d-md-none">
          </div>
      </div>
    </div>
  </div>
</header>

<!-- PAGE HEADER -->
<header id="page-header">
  <div class="container pt-4 pd-2">
    <div class="row">
      <div class="card mb-3">
          <div class="row no-gutters">

            <div class="col-md-12">
              <div class="card-body">
                  <h5 class="card-header centerName fontOpenSans-RegularSmallDarkGra">1. Generalities</h5>
                <p class="fontOpenSans-RegularExtraSmallDarkGray pt-4">As the operator of the website www.so-journey.us (hereinafter "Website"), the protection of your personal data is a matter we take very seriously. We therefore exclusively collect and process your personal data in accordance with the applicable legal provisions regarding data protection, the EU-General Data Protection Regula-tion (hereinafter "GDPR") and the standards described hereinafter. Personal data means any information relating to an identified or identifiable natural person. Below, we inform you about the most important aspects of data processing with re-gard to this Website, in particular the collection and processing of personal data via this Website, the purposes of the collection and processing of personal data and your rights with respect thereto. The "controller" respectively body responsible for the collection and processing of your personal data as described in this Privacy Policy is Medscience Animations Studios Inc., 7935 Airport Pulling Rd North, Ste 4 PMB 269 , Naples, FL 34109 (hereinafter "we").
                  </p>
                  <h5 class="card-header centerName fontOpenSans-RegularSmallDarkGra">2. Data collection when accessing this Website (server log files)</h5>
                <p class="fontOpenSans-RegularExtraSmallDarkGray pt-4">When you access our Website, information from the browser used on your device is collected and automatically transmitted to the server of our Website. This information is temporarily stored in so-called server log files. Thereby, the following information is stored:
                  <li>IP address</li>
                  <li>date and time of access</li>
                  <li>name and URL of the retrieved file</li>
                  <li>website from which access is made (referrer URL)</li>
                  <li>browser type/version used</li>
                  <li>operating system used</li>
                  <li>name of your access provider</li>
                  <li>name of your access provider</li><br>
                  We will process the above mentioned data for the following purposes:
                  <li>ensuring a smooth connection of the Website</li>
                  <li>ensuring comfortable use of the Website</li>
                  <li>evaluation of system security and system stability</li>
                  <li>for other administrative purposes.</li><br>
                  The above mentioned personal data are not combined with other data sources. Under no circumstances this personal data is used by us for the purpose of drawing conclusions about you.<br>

                  The processing of personal data as described above is required for the above mentioned purposes in order to safeguard our legitimate interests pursuant to art. 6 para. 1 lit. f) GDPR.
                  </p>
                  <h5 class="card-header centerName fontOpenSans-RegularSmallDarkGra">3. Cookies</h5>
                <p class="fontOpenSans-RegularExtraSmallDarkGray pt-4">Our Website uses so-called cookies. Cookies are small text files that your browser automatically creates and stores on your computer or mobile device. We use cookies to make the use of our Website more comfortable for you. Some of the cookies we use are deleted after the end of the browser session, i.e. after closing your browser (so-called session cookies). Other cookies remain on your device until you delete them. These cookies enable us to recognize your browser on your next visit (so-called persistent cookies). You can configure your browser settings in such a way that you are informed about the setting of cookies and that you can decide in each individual case whether you allow the cookies or not. You can also configure your browser setting in such a way that the cookies are generally rejected. However, please note, that when doing so, it may not be possible to fully use all features of the Website.

                  The processing of personal data by cookies as described above is required for the above-mentioned purposes in order to safeguard our legitimate interests pursuant to art. 6 para. 1 lit. f) GDPR.
                  </p>
                  <h5 class="card-header centerName fontOpenSans-RegularSmallDarkGra">4. Web-Analysis</h5>
                  <h6 class="card-header centerName fontOpenSans-RegularSmallDarkGra">Introduction</h6>
                <p class="fontOpenSans-RegularExtraSmallDarkGray pt-4">In order to constantly improve and optimize our Website, we make use of Google Analytics, a so-called tracking technology. Usually, no personal data will be collected thereby, or if collected, such personal data will be anonymized or pseudonymized.

                  The data processing as described in this section is based on our legitimate interest in improving and optimizing our Website and in targeted advertising activities (art. 6 para. 1 lit. f) GDPR)
                  </p>
                  <h6 class="card-header centerName fontOpenSans-RegularSmallDarkGra">Google Analytics</h6>
                <p class="fontOpenSans-RegularExtraSmallDarkGray pt-4">On the basis of art. 6 para. 1 lit. f) GDPR (legitimate interest), we use Google Analytics, a web analytics service provided by Google Inc., 1600 Amphitheatre Parkway, Mountain View, CA 94043 USA (hereinafter "Google"). We thereby want to ensure a design that is tailored to the needs and the on-going optimization of our Website. In addition, we use the tracking measures to statistically record the use of our Website and to evaluate it for the purpose of optimizing our Website.<br>
                <p class="fontOpenSans-RegularExtraSmallDarkGray pt-4">Google uses cookies. Cookies are text files that are stored on your computer or mo-bile device. The information generated by the cookies about your use of the Website (such as access time, location from which access was made, frequency of your visits to our Website and IP address) are usually transferred to a Google server in the USA and stored there. Google will use this information on our behalf to evaluate the use of our Website in order to compile reports on the activities within this Website and to provide us with other services related to Website activity and internet usage. Under no circumstances will your IP address be merged with any other data provid-ed by Google.<br>
                <p class="fontOpenSans-RegularExtraSmallDarkGray pt-4">We only use Google Analytics with the IP anonymization activated. This means that your IP address is shortened by Google prior to transmission to the USA. The full IP address will only exceptionally be transmitted to a Google server in the USA and shortened there.<br>
                <p class="fontOpenSans-RegularExtraSmallDarkGray pt-4">You can prevent the installation of cookies by setting the browser software accordingly. However, please note, that when doing so, it may not be possible to fully use all features of the Website.<br>
                <p class="fontOpenSans-RegularExtraSmallDarkGray pt-4">You can also prevent the collection of data generated by the cookies regarding your use of the Website (including the IP address), as well as the processing of this data by Google, by downloading and installing the browser plug-in found at the following link: <a href="https://tools.google.com/dlpage/gaoptout?hl=en" target="_blank">https://tools.google.com/dlpage/gaoptout?hl=en</a> US-based Google LLC is certified under the data protection agreements.<br>
                <p class="fontOpenSans-RegularExtraSmallDarkGray pt-4">For more information regarding the use of data by Google, settings and options to appeal visit: <a href="https://policies.google.com/technologies/partner-sites?hl=en" target="_blank">https://policies.google.com/technologies/partner-sites?hl=en</a> ("How Google uses information from sites or apps that use our services") <a href="https://policies.google.com/technologies/ads?hl=en" target="_blank">https://policies.google.com/technologies/ads?hl=en</a>  ("Data use by Google for adver-tising purposes") and <a href="http://www.google.de/settings/ads" target="_blank">http://www.google.de/settings/ads</a> ("Manage information that Google uses to show you advertising").<br>
                  </p>
                  <h5 class="card-header centerName fontOpenSans-RegularSmallDarkGra">5. Integration of services and contents of third parties</h5>
                  <h6 class="card-header centerName fontOpenSans-RegularSmallDarkGra">Introduction</h6>
                <p class="fontOpenSans-RegularExtraSmallDarkGray pt-4">This Website provides links to several social networks and third party websites. In particular, we use social media plug-ins for the following social networks on our Website:<br>
                  <li>Facebook</li>
                  <li>Twitter</li>
                  <li>Instagram</li>
                  <p class="fontOpenSans-RegularExtraSmallDarkGray pt-4">These social media plug-ins are used in order to improve the profile of our Website and to increase awareness of our Website. This constitutes a legitimate interest pursuant to art. 6 para. 1 lit. f) GDPR. The responsibility for ensuring compliance of the services with data protection regulations lies within the provider of the respective social network or third party website.<br>
                  </p>
                  <h6 class="card-header centerName fontOpenSans-RegularSmallDarkGra">Facebook</h6>
                  <p class="fontOpenSans-RegularExtraSmallDarkGray pt-4">Our Website uses social media plug-ins from Facebook, which are provided by Fa-cebook Inc., 1601 Willow Road, Menlo Park, CA 94025, USA (hereinafter "Face-book").<br>
                  <p class="fontOpenSans-RegularExtraSmallDarkGray pt-4">When you visit a page of our Website that contains such a plug-in, the plug-in establishes a direct connection between your browser and the Facebook servers. We have no influence on the nature and scope of the data that the plug-in transmits to the servers of Facebook.<br>
                  <p class="fontOpenSans-RegularExtraSmallDarkGray pt-4">The plug-in informs Facebook that your browser has accessed the corresponding page of our Website, even if you do not have a Facebook profile or are not currently logged in. This information (including your IP address) is transmitted by your browser directly to the Facebook servers and stored there. If you are logged in as a member of Facebook, Facebook can assign the visit to your Facebook profile. When using plug-in functions (for example, pressing the "Like" but-ton or sharing a post), the corresponding information is also transmitted to Facebook and stored there.<br>
                  <p class="fontOpenSans-RegularExtraSmallDarkGray pt-4">Facebook may use this information for the purpose of advertising, market research and tailor-made Facebook pages. For example, Facebook creates profiles regarding usage, interests and relationships to evaluate your use of our Website with regard to the advertisements displayed on your Facebook profile.<br>
                  <p class="fontOpenSans-RegularExtraSmallDarkGray pt-4">If you are a Facebook member and do not want Facebook to assign the data collect-ed via our Website to your Facebook profile, you must log out of Facebook before visiting our Website.<br>
                  <p class="fontOpenSans-RegularExtraSmallDarkGray pt-4">Facebook processes your personal data in the USA. US-based Facebook, Inc. is certified under the data protection agreements.<br>
                  <p class="fontOpenSans-RegularExtraSmallDarkGray pt-4">To learn more about the purpose and scope of the data collection and the further processing and use of the data by Facebook, as well as your rights in relation to this matter and setting options for the protection of your privacy, please refer to Face-book’s privacy policy: <a href="https://www.facebook.com/about/privacy/" target="_blank">https://www.facebook.com/about/privacy/</a><br>
                  </p>
                  <h6 class="card-header centerName fontOpenSans-RegularSmallDarkGra">Twitter</h6>
                  <p class="fontOpenSans-RegularExtraSmallDarkGray pt-4">This Website uses a social media button provided by Twitter, a service of Twitter Inc., 795 Folsom St., Suite 600, San Francisco, CA 94107, USA (hereinafter "Twit-ter"). If you visit a page that contains such a button, a direct connection is established between your browser and the Twitter servers. By using Twitter and its re-tweet function, the websites you visit are linked to your Twitter account and communicated to other uses. This also transmits data to Twitter. Please note that we have no influence whatsoever on the nature and extent of the data transmitted to Twitter and on the processing of such data by Twitter. US-based Twitter is certified under the data protection agreements.<br>
                  <p class="fontOpenSans-RegularExtraSmallDarkGray pt-4">For more information about the collection and use of data by Twitter, as well as your related rights and ways to protect your privacy, please refer to the Twitter’s privacy policy at <a href="http://twitter.com/privacy" target="_blank">http://twitter.com/privacy</a><br>
                  </p>
                  <h6 class="card-header centerName fontOpenSans-RegularSmallDarkGra">Instagram</h6>
                  <p class="fontOpenSans-RegularExtraSmallDarkGray pt-4">This Website provides a link to Instagram, a service provided by Instagram Inc., 1601 Willow Rd, Menlo Park, CA 94025, USA (hereinafter "Instagram").<br>
                  <p class="fontOpenSans-RegularExtraSmallDarkGray pt-4">This link is shown as a logo on our Website and serves only as a link to Instagram. Personal data of the user will only be transmitted to Instagram after clicking on the link and being referred to the website of Instagram.<br>
                  <p class="fontOpenSans-RegularExtraSmallDarkGray pt-4">The purpose and scope of the collection and processing of your personal data by Instagram as well as your rights and potential settings to protect your personal data are explained in the Instagram privacy policy at <a href="https://help.instagram.com/155833707900388" target="_blank">https://help.instagram.com/155833707900388</a><br>
                  </p>
                  <h6 class="card-header centerName fontOpenSans-RegularSmallDarkGra">Online shops</h6>
                  <p class="fontOpenSans-RegularExtraSmallDarkGray pt-4">Our Website provides links to the Medscience Animations Studios Inc. online shops (United States), operated by Medscience Animations Studios Inc., 7935 Airport Pulling Rd North, Ste 4 PMB 269 , Naples, FL 34109 (hereinafter "Medscience Animations Studios").<br>
                  <p class="fontOpenSans-RegularExtraSmallDarkGray pt-4">Personal data of the user will only be transmitted to Medscience Animations Studios after clicking on the link and being referred to the respective website of Medscience Animations Studios.<br>
                  <p class="fontOpenSans-RegularExtraSmallDarkGray pt-4">The purpose and scope of the collection and processing of your personal data by Medscience Animations Studios as well as your rights and potential settings to protect your personal data are explained in the privacy policy of Medscience Animations Studios, available on the respective website: <a href="http://so-journey.us/privacy" target="_blank">http://so-journey.us/privacy</a> (United States);<br>
                    <p class="fontOpenSans-RegularExtraSmallDarkGray pt-4">The provision of the links to the Medscience Animations Studios Inc. online shops constitutes a legitimate interest pursuant to art. 6 para. 1 lit. f) GDPR.<br>
                  </p>
                  <h5 class="card-header centerName fontOpenSans-RegularSmallDarkGra">6. Retention period</h5>
                  <p class="fontOpenSans-RegularExtraSmallDarkGray pt-4">Your personal data is only retained as long as required for the purposes pursued with the processing and/or as long as legal retention and documentation obligations or legitimate interests exist.<br>
                  </p>
                  <h5 class="card-header centerName fontOpenSans-RegularSmallDarkGra">7. Data security</h5>
                  <p class="fontOpenSans-RegularExtraSmallDarkGray pt-4">We take appropriate technical and organizational measures for the security of your personal data, in particular to protect such personal data from unauthorized access by third parties and misuse. These security measures are continuously adapted to the current state of the art.<br>
                  </p>
                  <h5 class="card-header centerName fontOpenSans-RegularSmallDarkGra">8. Transfer of data to third parties and/or third countries</h5>
                  <p class="fontOpenSans-RegularExtraSmallDarkGray pt-4">For the purpose of operating this Website, in the context of our business activities or in accordance with the purposes of data processing as set forth in this Privacy Poli-cy, we may transfer personal data to third parties to the extent that such transfer is permitted by applicable law and we deem it appropriate, in order for such third parties to process personal data for our purposes or their own purposes, as the case may be.<br>
                    <p class="fontOpenSans-RegularExtraSmallDarkGray pt-4">Data transfer to third countries takes place, but only in compliance with the statutory requirements. If we do not have your consent, if the transfer is not required for as-serting, exercising or defending any legal claim, and if no other exceptions apply, we will only transfer your personal data to a third country, if there is an adequacy deci-sion pursuant to art. 45 GDPR or, in case that such personal data is transferred to a country without adequate legal data protection, if appropriate safeguards pursuant to art. 46 GDPR are in place.<br>
                  </p>
                  <h5 class="card-header centerName fontOpenSans-RegularSmallDarkGra">9. Rights of the concerned person</h5>
                  <p class="fontOpenSans-RegularExtraSmallDarkGray pt-4">In accordance with applicable law, you have the following rights:<br>
                      <li>Right of access, i.e. right to be informed about the personal data pertaining to you that is processed by us (art. 15 GDPR).</li>
                      <li>Right to rectification, i.e. right to demand correction of inaccurate or incomplete personal data pertaining to you that is processed by us (art. 16 GDPR).</li>
                      <li>Right to erasure (right to be "forgotten"), i.e. right to request the deletion of your personal data processed by us, unless the processing is necessary to exercise the right to freedom of expression and information, to fulfil a legal obligation, for reasons of public interest or to assert, exercise or defend legal claims (art. 17 GDPR).</li>
                      <li>Right to restriction of processing (art. 18 GDPR).</li>
                      <li>Right to data portability, i.e. right to receive the personal data you have provided to us in a structured, current and machine-readable format or to request its transfer to another person responsible, subject to certain conditions (art. 20 GDPR).</li>
                      <li>Right to object to the processing of personal data (art. 21 GDPR).</li>
                      <li>Right to withdraw your consent to the processing of your personal data at any time with future effect in accordance with art. 7 para. 3 GDPR. The lawfulness of the data processing based on the consent before its withdrawal remains unaf-fected.</li>
                      <li>Right to lodge a complaint with the supervisory authority (art. 77 GDPR).</li>
                  </p>
                  <h5 class="card-header centerName fontOpenSans-RegularSmallDarkGra">10. Enquiries with respect to data protection matters</h5>
                  <p class="fontOpenSans-RegularExtraSmallDarkGray pt-4">If you have any enquiries with respect to this Privacy Policy or any other data protection related matters, you can contact us by e-mail at privacy@so-journey.us<br>
                    <p class="fontOpenSans-RegularExtraSmallDarkGray pt-4">If you send us enquiries by e-mail, the personal data you voluntarily provide therein will be processed by us for the purpose of processing the enquiry.<br>
                      <p class="fontOpenSans-RegularExtraSmallDarkGray pt-4">The data processing as described in this section is based on art. 6 para. 1 lit. a) GDPR and/or art. 6 para. 1 lit. f) GDPR.<br>
                        <p class="fontOpenSans-RegularExtraSmallDarkGray pt-4">The personal data submitted by you in your e-mail will be retained by us until you request us to delete the personal data or the purpose of data retention ceases to apply (e.g. after the request has been processed). Mandatory statutory provisions, in particular retention periods, remain unaffected.<br>
                    </p>
                    <h5 class="card-header centerName fontOpenSans-RegularSmallDarkGra">11. Amendments to this Privacy Policy</h5>
                    <p class="fontOpenSans-RegularExtraSmallDarkGray pt-4">Please note that we may amend, change or update this Privacy Policy at any time without prior notice. The current version published on this Website shall apply.<br>
                      </p>
              </div>
            </div>
          </div>
        </div>

    </div>
  </div>
</header>
