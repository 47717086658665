<!-- HEADER -->
<header id="main-header" class="py-2 bg-primary text-white setDefaultSpace">
  <div class="container">
    <div class="row">
      <div class="col-md-6">
        <h1>
          <i class="fa fa-cog"></i> Dashboard</h1>
      </div>
    </div>
  </div>
</header>

<!-- ACTIONS -->
<section id="actions" class="py-4 mb-4 bg-light">
  <div class="container">
    <div class="row">
      <div class="col-md-3">
        <a href="#" class="btn btn-primary btn-block" data-toggle="modal" data-target="#addPicture">
          <i class="fa fa-plus"></i> Add Picture
        </a>
      </div>
      <div class="col-md-3">
        <a href="#" class="btn btn-success btn-block" data-toggle="modal" data-target="#addCategory">
          <i class="fa fa-plus"></i> Add Category
        </a>
      </div>
      <div class="col-md-3">
        <a href="#" class="btn btn-warning btn-block" data-toggle="modal" data-target="#addContent">
          <i class="fa fa-plus"></i> Add Content
        </a>
      </div>
    </div>
    <div class="row pt-5">
        <div class="col-md-3">
          <a href="#" class="btn btn-primary btn-block" data-toggle="modal" data-target="#addRate">
            <i class="fa fa-plus"></i> Add Rate (Pic)
          </a>
        </div>
        <div class="col-md-3">
          <a href="#" class="btn btn-primary btn-block" data-toggle="modal" data-target="#addType">
            <i class="fa fa-plus"></i> Add Type (Pic)
          </a>
        </div>
        <div class="col-md-3">
            <a href="#" class="btn btn-primary btn-block" data-toggle="modal" data-target="#addSource">
              <i class="fa fa-plus"></i> Add Source (Pic)
            </a>
        </div>
    </div>
    <div class="row pt-5">
        <div class="col-md-3">
          <a href="#" class="btn btn-success btn-block" data-toggle="modal" data-target="#addSubCategory">
            <i class="fa fa-plus"></i> Add Sub Category
          </a>
        </div>
    </div>
  </div>
</section>


<section>
    <div class="container">
    <ul class="nav nav-tabs">
        <li class="nav-item">
            <a href="#home" class="nav-link active" data-toggle="tab" (click)="resetCategoryForm(); getCategoryData()">Content</a>
        </li>
        <li class="nav-item">
            <a href="#profile" class="nav-link" data-toggle="tab" (click)="resetForm(); getPicturesData()">Pictures</a>
        </li>
        <li class="nav-item">
            <a href="#messages" class="nav-link" data-toggle="tab" (click)="resetSectionForm(); getCategoryData()">Categories</a>
        </li>
    </ul>
    <div class="tab-content">
        <div class="tab-pane fade show active" id="home">
            <div class="row">
                <div class="col-md-9">
                  <div class="card">
                    <div class="card-header">
                      <div class="row">
                          <div class="col-md-6">
                              Select a section to display the content
                          </div>
                          <div class="col-md-6">
                              <select class="form-control" (change)="sectionFilter($event.target.value)">
                                  <option value="nosectionObject">- Section Object -</option>
                                  <option *ngFor="let section of sections" [value]="section.SectionId">{{section.Title}}</option>
                              </select>
                          </div>
                        </div>
                    </div>
                    <!-- DISPLAY CONTENT -->
                    <table class="table table-striped">
                      <thead class="thead-dark">
                        <tr>
                          <th>Index</th>
                          <th>Title</th>
                          <th>SubRoom</th>
                          <th>Image</th>
                          <th>Edit</th>
                          <th>Delete</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let content of contentSections | paginate: { itemsPerPage: 10, currentPage: p }">
                          <td class="align-middle">{{content.Index}}</td>
                          <td class="align-middle">{{content.Title}}</td>
                          <td class="align-middle">{{content.SubRoom}}</td>
                          <td class="align-middle"><img src="{{content.UrlImage}}" class="img" height="50"></td>
                          <td class="align-middle">
                              <button (click)="contentFilterbyId(content)" class="btn btn-dark" data-toggle="modal" data-target="#detailsContent">Edit</button>
                          </td>
                          <td class="align-middle">
                              <button (click)="onDeleteSectionClick(content)" class="btn btn-danger">Delete</button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                      <!-- <pagination-controls (pageChange)="p = $event"></pagination-controls> -->
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="card text-center bg-primary text-white mb-3">
                    <div class="card-body">
                      <h3>Pictures</h3>
                      <h4 class="display-4">
                        <i class="fa fa-pencil-alt"></i> 6
                      </h4>
                      <a href="posts.html" class="btn btn-outline-light btn-sm">View</a>
                    </div>
                  </div>

                  <div class="card text-center bg-success text-white mb-3">
                    <div class="card-body">
                      <h3>Categories</h3>
                      <h4 class="display-4">
                        <i class="fa fa-folder"></i> 4
                      </h4>
                      <a href="categories.html" class="btn btn-outline-light btn-sm">View</a>
                    </div>
                  </div>

                  <div class="card text-center bg-warning text-white mb-3">
                    <div class="card-body">
                      <h3>Users</h3>
                      <h4 class="display-4">
                        <i class="fa fa-users"></i> 4
                      </h4>
                      <a href="users.html" class="btn btn-outline-light btn-sm">View</a>
                    </div>
                  </div>
                </div>
              </div>
        </div>
        <!-- DISPLAY PICTURES -->
        <div class="tab-pane fade" id="profile">
            <div class="row">
                <div class="col-md-9">
                  <div class="card">
                    <table class="table table-striped">
                      <thead class="thead-dark">
                        <tr>
                          <th>Title</th>
                          <th>Type</th>
                          <th>Rate</th>
                          <th>Image</th>
                          <th>Edit</th>
                          <th>Delete</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                          <tr *ngFor="let pic of pics | paginate: { itemsPerPage: 10, currentPage: p }">
                            <td class="align-middle">{{pic.Title}}</td>
                            <td class="align-middle">{{pic.Type}}</td>
                            <td class="align-middle">{{pic.Rate}}</td>
                            <td class="align-middle"><img src="{{pic.UrlImage}}" class="img" height="50"></td>
                              <td class="align-middle">
                                  <button (click)="pictureFilterbyId(pic)" class="btn btn-dark" data-toggle="modal" data-target="#detailsPicture">Edit</button>
                              </td>
                              <td class="align-middle">
                                  <button (click)="onDeletePictureClick(pic)" class="btn btn-danger">Delete</button>
                              </td>
                          </tr>
                      </tbody>
                    </table>
                    <!-- <pagination-controls (pageChange)="p = $event"></pagination-controls> -->
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="card text-center bg-primary text-white mb-3">
                    <div class="card-body">
                      <h3>Pictures</h3>
                      <h4 class="display-4">
                        <i class="fa fa-pencil-alt"></i> 6
                      </h4>
                      <a href="posts.html" class="btn btn-outline-light btn-sm">View</a>
                    </div>
                  </div>

                  <div class="card text-center bg-success text-white mb-3">
                    <div class="card-body">
                      <h3>Categories</h3>
                      <h4 class="display-4">
                        <i class="fa fa-folder"></i> 4
                      </h4>
                      <a href="categories.html" class="btn btn-outline-light btn-sm">View</a>
                    </div>
                  </div>

                  <div class="card text-center bg-warning text-white mb-3">
                    <div class="card-body">
                      <h3>Users</h3>
                      <h4 class="display-4">
                        <i class="fa fa-users"></i> 4
                      </h4>
                      <a href="users.html" class="btn btn-outline-light btn-sm">View</a>
                    </div>
                  </div>
                </div>
              </div>
        </div>
        <div class="tab-pane fade" id="messages">
            <div class="row">
                <div class="col-md-9">
                  <div class="card">
                    <div class="card-header">
                      <h4>Categories</h4>
                    </div>
                    <table class="table table-striped">
                      <thead class="thead-dark">
                        <tr>
                          <th>Title</th>
                          <th>Category</th>
                          <th>Type</th>
                          <th>Edit</th>
                          <th>Delete</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                          <tr *ngFor="let section of sections | paginate: { itemsPerPage: 10, currentPage: p }">
                            <td class="align-middle">{{section.Title}}</td>
                            <td class="align-middle">{{section.Category}}</td>
                            <td class="align-middle">{{section.Type}}</td>
                            <td class="align-middle">
                                <button (click)="categoryFilterbyId(section)" class="btn btn-dark" data-toggle="modal" data-target="#detailsCategory">Edit</button>
                            </td>
                            <td class="align-middle">
                                <button (click)="onDeleteCategoryClick(section)" class="btn btn-danger">Delete</button>
                            </td>
                          </tr>
                      </tbody>
                    </table>
                    <!-- <pagination-controls (pageChange)="p = $event"></pagination-controls> -->
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="card text-center bg-primary text-white mb-3">
                    <div class="card-body">
                      <h3>Pictures</h3>
                      <h4 class="display-4">
                        <i class="fa fa-pencil-alt"></i> 6
                      </h4>
                      <a href="posts.html" class="btn btn-outline-light btn-sm">View</a>
                    </div>
                  </div>

                  <div class="card text-center bg-success text-white mb-3">
                    <div class="card-body">
                      <h3>Categories</h3>
                      <h4 class="display-4">
                        <i class="fa fa-folder"></i> 4
                      </h4>
                      <a href="categories.html" class="btn btn-outline-light btn-sm">View</a>
                    </div>
                  </div>

                  <div class="card text-center bg-warning text-white mb-3">
                    <div class="card-body">
                      <h3>Users</h3>
                      <h4 class="display-4">
                        <i class="fa fa-users"></i> 4
                      </h4>
                      <a href="users.html" class="btn btn-outline-light btn-sm">View</a>
                    </div>
                  </div>
                </div>
              </div>
        </div>
        <pagination-controls (pageChange)="p = $event"></pagination-controls>
    </div>
    <!-- <pagination-controls (pageChange)="p = $event"></pagination-controls> -->
    </div>
</section>

<!-- MODALS -->

<!-- ADD CONTENT MODAL -->
<div class="modal fade" id="addContent">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header bg-primary text-white">
            <h5 class="modal-title">Add Content</h5>
            <button class="close" data-dismiss="modal">
              <span>&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form [formGroup]="formSectionTemplate" (submit)="onSubmitContent(formSectionTemplate.value)">
              <div class="form-group">
                  <div class="row">
                      <div class="col-md-3">
                        Photo
                      </div>
                      <div class="col-md-9">
                        <select class="form-control" (change)="imageFilter($event.target.value)">
                            <option value="noimageObject">- Image Object -</option>
                            <option *ngFor="let pic of pics" [value]="pic.PicId">{{pic.Title}}</option>
                        </select>
                      </div>
                  </div>
              </div>
                <div class="form-group">
                    <div class="row">
                        <div class="col-md-3">
                          Background (iPhone Assets)
                        </div>
                        <div class="col-md-9">
                            <select class="form-control" (change)="assetFilter($event.target.value)">
                                <option value="noimageObject">- Background Object -</option>
                                <option *ngFor="let asset of assetsPics" [value]="asset.PicId">{{asset.Title}}</option>
                            </select>
                          <input class="form-control" formControlName="background" [(ngModel)] = 'assetsPicSelectedTitle' [style.display]="'none'">
                          <div class="text-danger" *ngIf="isSubmittedSection && formSectionControls.caption.errors?.required">This field is required.
                          </div>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <input class="form-control" formControlName="imageUrl" placeholder="Image Url" [(ngModel)] = 'picSelectedUrl' [style.display]="'none'">
                    <div class="text-danger" *ngIf="isSubmittedSection && formSectionControls.caption.errors?.required">This field is required.
                    </div>
                  </div>
                  <div class="form-group">
                      <input class="form-control" formControlName="photoInfo" placeholder="Photo Info" [(ngModel)] = 'picSelectedInfo' [style.display]="'none'">
                      <div class="text-danger" *ngIf="isSubmittedSection && formSectionControls.caption.errors?.required">This field is required.
                      </div>
                    </div>
                    <div class="form-group">
                        <input class="form-control" formControlName="sourceImage" placeholder="Photo Info" [(ngModel)] = 'picSelectedSource' [style.display]="'none'">
                        <div class="text-danger" *ngIf="isSubmittedSection && formSectionControls.caption.errors?.required">This field is required.
                        </div>
                      </div>
                      <div class="form-group">
                          <input class="form-control" formControlName="backgroundImage" placeholder="Background Image" [(ngModel)] = 'picSelectedUrl' [style.display]="'none'">
                          <div class="text-danger" *ngIf="isSubmittedSection && formSectionControls.caption.errors?.required">This field is required.
                          </div>
                        </div>
                  <div class="form-group">
                      <input class="form-control" formControlName="videoUrl" placeholder="URL Video">
                      <div class="text-danger" *ngIf="isSubmittedSection && formSectionControls.caption.errors?.required">This field is required.
                      </div>
                    </div>
                    <div class="form-group">
                        <input class="form-control" formControlName="time" placeholder="Time">
                        <div class="text-danger" *ngIf="isSubmittedSection && formSectionControls.caption.errors?.required">This field is required.
                        </div>
                      </div>
                  <div class="form-group">
                      <input class="form-control" formControlName="externalWeb1" placeholder="externalWeb1">
                      <div class="text-danger" *ngIf="isSubmittedSection && formSectionControls.caption.errors?.required">This field is required.
                      </div>
                    </div>
                    <div class="form-group">
                        <input class="form-control" formControlName="externalWeb2" placeholder="externalWeb2">
                        <div class="text-danger" *ngIf="isSubmittedSection && formSectionControls.caption.errors?.required">This field is required.
                        </div>
                      </div>
                      <div class="form-group">
                          <input class="form-control" formControlName="externalWeb3" placeholder="externalWeb3">
                          <div class="text-danger" *ngIf="isSubmittedSection && formSectionControls.caption.errors?.required">This field is required.
                          </div>
                        </div>
              <div class="form-group">
                <input class="form-control" formControlName="caption" placeholder="Title">
                <div class="text-danger" *ngIf="isSubmittedSection && formSectionControls.caption.errors?.required">This field is required.
                </div>
              </div>
              <div class="form-group">
                  <label for="body">Content</label>
                  <textarea formControlName="content" class="form-control"></textarea>
                </div>

              <div class="form-group">
                  <select formControlName="category" class="form-control">
                      <option value="noCategory">- Category -</option>
                      <option *ngFor="let section of sections" [value]="section.Title">{{section.Title}}</option>
                  </select>
                </div>
              <div class="form-group">
                  <select formControlName="subcategory" class="form-control">
                      <option value="noSubcategory">- Subcategory -</option>
                      <option *ngFor="let subcategory of subcategories" [value]="subcategory.SubCategoryId">{{subcategory.Title}}</option>
                  </select>
                </div>
              <div class="form-group">
                <select formControlName="index" class="form-control">
                  <option value="0">0</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                  <option value="7">7</option>
                  <option value="8">8</option>
                  <option value="9">9</option>
                  <option value="10">10</option>
                  <option value="11">11</option>
                  <option value="12">12</option>
                  <option value="13">13</option>
                  <option value="14">14</option>
                  <option value="15">15</option>

                </select>
              </div>
              <div class="form-row">
                <div class="form-group col-md-6">
                  <button type="submit" class="btn btn-success btn-block float-right">Submit</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
  </div>


<!-- ADD PICTURES MODAL -->
<div class="modal fade" id="addPicture">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header bg-primary text-white">
        <h5 class="modal-title">Add Picture</h5>
        <button class="close" data-dismiss="modal">
          <span>&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form [formGroup]="formTemplate" (submit)="onSubmit(formTemplate.value)">
          <div class="text-center">
            <img [src]="imgSrc" width="350px" height="250px" (click)="fileUploader.click()">
          </div>
          <div class="form-group">
            <label>Select file to upload</label>
            <input type="file" accept="image/*" class="form-control" #fileUploader formControlName="imageUrl"
              (change)="showPreview($event)">
            <div class="text-danger" *ngIf="isSubmitted && formControls.imageUrl.errors?.required">This field is required.
            </div>
          </div>
          <div class="form-group">
            <input class="form-control" formControlName="caption" placeholder="Title">
            <div class="text-danger" *ngIf="isSubmitted && formControls.caption.errors?.required">This field is required.
            </div>
          </div>
          <div class="form-group">
            <input class="form-control" formControlName="content" placeholder="Photo Info">
            <div class="text-danger" *ngIf="isSubmitted && formControls.content.errors?.required">This field is required.
            </div>
          </div>
          <div>
          <select class="form-control" (change)="rateFilter($event.target.value)">
              <option value="noimageObject">- Rate Object -</option>
              <option *ngFor="let rate of rates" [value]="rate.RateId">{{rate.Title}}</option>
          </select>
        </div>
          <div class="form-group">
              <input class="form-control" formControlName="rate" [(ngModel)]= 'rateSelectedStr' >
              <div class="text-danger" *ngIf="isSubmitted && formControls.content.errors?.required">This field is required.
              </div>
            </div>
            <div>
                <select class="form-control" (change)="typeFilter($event.target.value)">
                    <option value="noimageObject">- Type Object -</option>
                    <option *ngFor="let type of types" [value]="type.TypeId">{{type.Title}}</option>
                </select>
            </div>
            <div class="form-group">
                  <input class="form-control" formControlName="type" [(ngModel)]= 'typeSelectedStr' >
                  <div class="text-danger" *ngIf="isSubmitted && formControls.content.errors?.required">This field is required.
                  </div>
            </div>
          <div class="form-group">
            <select formControlName="category" class="form-control">
              <option value="Pictures">Pictures</option>
            </select>
          </div>
          <div class="form-group">
              <select formControlName="subcategory" class="form-control">
                  <option value="noSubcategory">- Subcategory -</option>
                  <option *ngFor="let section of sections" [value]="section.Title">{{section.Title}}</option>
              </select>
            </div>
            <div>
                <select class="form-control" (change)="sourceFilter($event.target.value)">
                    <option value="noimageObject">- Source Object -</option>
                    <option *ngFor="let source of sources" [value]="source.SourceId">{{source.Title}}</option>
                </select>
            </div>
            <div class="form-group">
                  <input class="form-control" formControlName="sourceImage" [(ngModel)]= 'sourceSelectedStr' >
                  <div class="text-danger" *ngIf="isSubmitted && formControls.content.errors?.required">This field is required.
                  </div>
            </div>
          <div class="form-group">
            <input class="form-control" formControlName="fileName" placeholder="File Name" [(ngModel)] = 'localFileName'>
            <div class="text-danger" *ngIf="isSubmitted && formControls.caption.errors?.required">This field is required.
            </div>
          </div>
          <div class="form-group">
            <input class="form-control" formControlName="size" placeholder="Size ( w x h pixels">
            <div class="text-danger" *ngIf="isSubmitted && formControls.caption.errors?.required">This field is required.
            </div>
          </div>
          <div class="form-group">
            <input class="form-control" formControlName="width" placeholder="Width (px)">
            <div class="text-danger" *ngIf="isSubmitted && formControls.caption.errors?.required">This field is required.
            </div>
          </div>
          <div class="form-group">
            <input class="form-control" formControlName="height" placeholder="Height (px)">
            <div class="text-danger" *ngIf="isSubmitted && formControls.caption.errors?.required">This field is required.
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-6">
              <button type="submit" class="btn btn-success btn-block float-right">Submit</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<!-- ADD CATEGORY MODAL -->
<div class="modal fade" id="addCategory">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header bg-success text-white">
          <h5 class="modal-title">Add Category</h5>
          <button class="close" data-dismiss="modal">
            <span>&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form [formGroup]="formCategoryTemplate" (submit)="onSubmitCategory(formCategoryTemplate.value)">
            <div class="form-group">
              <input class="form-control" formControlName="caption" placeholder="Name of category">
              <div class="text-danger" *ngIf="isSubmittedCategory && formCategoryControls.caption.errors?.required">This field is required.
              </div>
            </div>
            <div class="form-group">
              <select formControlName="type" class="form-control">
                <option value="category">Category</option>
                <option value="subCategory">SubCategory</option>
                <option value="details">Details</option>
              </select>
            </div>
            <div class="form-group">
              <select formControlName="category" class="form-control">
                <option value="Sections">Sections</option>
              </select>
            </div>
            <div class="form-row">
              <div class="form-group col-md-6">
                <button type="submit" class="btn btn-success btn-block float-right">Submit</button>
              </div>
            </div>
          </form>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="addSubCategory">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header bg-success text-white">
          <h5 class="modal-title">Add Sub Category</h5>
          <button class="close" data-dismiss="modal">
            <span>&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form [formGroup]="formSubCategoryTemplate" (submit)="onSubmitSubCategory(formSubCategoryTemplate.value)">
            <div class="form-group">
              <input class="form-control" formControlName="caption" placeholder="Name of sub category">
              <div class="text-danger" *ngIf="isSubmittedSubCategory && formSubCategoryControls.caption.errors?.required">This field is required.
              </div>
            </div>
            <div class="form-group">
              <select formControlName="type" class="form-control">
                <option value="blog">Blogs</option>
                <option value="inspiration">Inspiration</option>
                <option value="product">Products</option>
                <option value="projects">Proyects</option>
                <option value="lab">Labs</option>
                <option value="research">Research</option>
                <option value="post">Posts</option>
                <option value="channels">Channels</option>
                <option value="videos">Videos</option>

              </select>
            </div>
            <div class="form-group">
              <select formControlName="category" class="form-control">
                <option value="SubCategories">SubCategory</option>
              </select>
            </div>
            <div class="form-row">
              <div class="form-group col-md-6">
                <button type="submit" class="btn btn-success btn-block float-right">Submit</button>
              </div>
            </div>
          </form>
      </div>
    </div>
  </div>
</div>

<!-- ADD RATE PICTURE -->
<div class="modal fade" id="addRate">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header bg-success text-white">
          <h5 class="modal-title">Add Rate</h5>
          <button class="close" data-dismiss="modal">
            <span>&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form [formGroup]="formRateTemplate" (submit)="onSubmitRate(formRateTemplate.value)">
            <div class="form-group">
                <div class="row">
                    <div class="col-md-3">
                        Rate Name
                     </div>
                     <div class="col-md-9">
                        <input class="form-control" formControlName="caption" placeholder="Name of category">
                        <div class="text-danger" *ngIf="isSubmittedRate && formRateControls.caption.errors?.required">This field is required.
                          </div>
                      </div>
                  </div>
            </div>
            <div class="form-group">
                <div class="row">
                    <div class="col-md-3">
                        Rate Type
                     </div>
                     <div class="col-md-9">
                        <select formControlName="type" class="form-control">
                          <option value="n/a">N/A</option>
                        </select>
                      </div>
                  </div>
            </div>
            <div class="form-group">
                <div class="row">
                    <div class="col-md-3">
                        Rate Category
                     </div>
                     <div class="col-md-9">
                        <select formControlName="category" class="form-control">
                          <option value="RatePictures">RatePictures</option>
                        </select>
                      </div>
                  </div>
        </div>
            <div class="form-row justify-content-center">
              <div class="form-group col-md-4">
                <button type="submit" class="btn btn-success btn-block float-right">Add Rate of Pictures</button>
              </div>
            </div>
          </form>
      </div>
    </div>
  </div>
</div>

<!-- ADD TYPE PICTURE -->
<div class="modal fade" id="addType">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header bg-success text-white">
          <h5 class="modal-title">Add Type</h5>
          <button class="close" data-dismiss="modal">
            <span>&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form [formGroup]="formTypeTemplate" (submit)="onSubmitType(formTypeTemplate.value)">
            <div class="form-group">
                <div class="row">
                    <div class="col-md-3">
                        Type Name
                     </div>
                     <div class="col-md-9">
                        <input class="form-control" formControlName="caption" placeholder="Name of category">
                        <div class="text-danger" *ngIf="isSubmittedType && formTypeControls.caption.errors?.required">This field is required.
                          </div>
                      </div>
                  </div>
            </div>
            <div class="form-group">
                <div class="row">
                    <div class="col-md-3">
                        Type Type
                     </div>
                     <div class="col-md-9">
                        <select formControlName="type" class="form-control">
                          <option value="n/a">N/A</option>
                        </select>
                      </div>
                  </div>
            </div>
            <div class="form-group">
                <div class="row">
                    <div class="col-md-3">
                        Type Category
                     </div>
                     <div class="col-md-9">
                        <select formControlName="category" class="form-control">
                          <option value="TypePictures">TypePictures</option>
                        </select>
                      </div>
                  </div>
        </div>
            <div class="form-row justify-content-center">
              <div class="form-group col-md-4">
                <button type="submit" class="btn btn-success btn-block float-right">Add Type of Pictures</button>
              </div>
            </div>
          </form>
      </div>
    </div>
  </div>
</div>

<!-- ADD SOURCE PICTURE -->
<div class="modal fade" id="addSource">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header bg-success text-white">
          <h5 class="modal-title">Add Source</h5>
          <button class="close" data-dismiss="modal">
            <span>&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form [formGroup]="formSourceTemplate" (submit)="onSubmitSource(formSourceTemplate.value)">
            <div class="form-group">
                <div class="row">
                    <div class="col-md-3">
                        Source Name
                     </div>
                     <div class="col-md-9">
                        <input class="form-control" formControlName="caption" placeholder="Name of category">
                        <div class="text-danger" *ngIf="isSubmittedSource && formSourceControls.caption.errors?.required">This field is required.
                          </div>
                      </div>
                  </div>
            </div>
            <div class="form-group">
                <div class="row">
                    <div class="col-md-3">
                        Source Type
                     </div>
                     <div class="col-md-9">
                        <select formControlName="type" class="form-control">
                          <option value="n/a">N/A</option>
                        </select>
                      </div>
                  </div>
            </div>
            <div class="form-group">
                <div class="row">
                    <div class="col-md-3">
                        Source Category
                     </div>
                     <div class="col-md-9">
                        <select formControlName="category" class="form-control">
                          <option value="SourcePictures">SourcePictures</option>
                        </select>
                      </div>
                  </div>
        </div>
            <div class="form-row justify-content-center">
              <div class="form-group col-md-4">
                <button type="submit" class="btn btn-success btn-block float-right">Add Source of Pictures</button>
              </div>
            </div>
          </form>
      </div>
    </div>
  </div>
</div>


<!-- UPDATE CONTENT MODAL -->
<div class="modal fade" id="detailsContent">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header bg-primary text-white">
          <h5 class="modal-title">Update Detail Content</h5>
          <button class="close" data-dismiss="modal">
            <span>&times;</span>
          </button>
        </div>
        <div *ngIf="contentSection" class="modal-body">
            <form [formGroup]="formSectionTemplate" (submit)="onUpdateContent(formSectionTemplate.value, contentSection.Speaker)">
                <div class="form-group">
                    <div class="text-center pb-3">
                        <img [src]="picSelectedUrl" height="250px">
                      </div>
                    <select  class="form-control" (change)="imageFilter($event.target.value)">
                        <option value="noimageObject">- Image Object -</option>
                        <option *ngFor="let pic of pics" [value]="pic.PicId">{{pic.Title}}</option>
                    </select>
                  </div>
                  <div class="form-group">
                    <div class="row">
                      <div class="col-md-3">
                        Background (local)
                      </div>
                      <div class="col-md-9">
                          <input class="form-control" formControlName="background" [(ngModel)]= 'contentSection.Background'>
                          <div class="text-danger" *ngIf="isSubmittedSection && formSectionControls.caption.errors?.required">This field is required.
                          </div>
                    </div>
                    </div>
                  </div>
                  <div class="form-group">
                      <input class="form-control" formControlName="imageUrl" [(ngModel)]= 'picSelectedUrl' [style.display]="'none'">
                      <div class="text-danger" *ngIf="isSubmittedSection && formSectionControls.caption.errors?.required">This field is required.
                      </div>
                    </div>
                    <div class="form-group">
                        <div class="row">
                            <div class="col-md-3">
                                Photo Info
                             </div>
                            <div class="col-md-9">
                                <input class="form-control" formControlName="photoInfo" [(ngModel)]= 'picSelectedInfo'>
                                <div class="text-danger" *ngIf="isSubmittedSection && formSectionControls.caption.errors?.required">This field is required.
                                </div>
                            </div>
                        </div>
                      </div>
                      <div class="form-group">
                          <div class="row">
                              <div class="col-md-3">
                                  Source Image
                               </div>
                               <div class="col-md-9">
                                  <input class="form-control" formControlName="sourceImage" [(ngModel)]= 'picSelectedSource'>
                                  <div class="text-danger" *ngIf="isSubmittedSection && formSectionControls.caption.errors?.required">This field is required.
                                  </div>
                              </div>
                          </div>
                      </div>
                        <div class="form-group">
                            <input class="form-control" formControlName="backgroundImage" [(ngModel)]= 'contentSection.BackgroundImage' [style.display]="'none'">
                            <div class="text-danger" *ngIf="isSubmittedSection && formSectionControls.caption.errors?.required">This field is required.
                            </div>
                          </div>
                    <div class="form-group">
                        <div class="row">
                            <div class="col-md-3">
                                Video Url
                             </div>
                             <div class="col-md-9">
                                <input class="form-control" formControlName="videoUrl" [(ngModel)]= 'contentSection.UrlVideo'>
                                <div class="text-danger" *ngIf="isSubmittedSection && formSectionControls.caption.errors?.required">This field is required.
                                </div>
                            </div>
                      </div>
                    </div>
                      <div class="form-group">
                          <div class="row">
                              <div class="col-md-3">
                                  Time
                               </div>
                               <div class="col-md-9">
                                <input class="form-control" formControlName="time" [(ngModel)]= 'contentSection.Time'>
                                <div class="text-danger" *ngIf="isSubmittedSection && formSectionControls.caption.errors?.required">This field is required.
                                </div>
                              </div>
                          </div>
                      </div>
                    <div class="form-group">
                        <div class="row">
                            <div class="col-md-3">
                                External Web 1
                             </div>
                             <div class="col-md-9">
                                <input class="form-control" formControlName="externalWeb1" [(ngModel)]= 'contentSection.ExternalWeb1'>
                                <div class="text-danger" *ngIf="isSubmittedSection && formSectionControls.caption.errors?.required">This field is required.
                                </div>
                          </div>
                      </div>
                    </div>
                    <div class="form-group">
                        <div class="row">
                            <div class="col-md-3">
                                External Web 2
                             </div>
                             <div class="col-md-9">
                              <input class="form-control" formControlName="externalWeb2" [(ngModel)]= 'contentSection.ExternalWeb2'>
                              <div class="text-danger" *ngIf="isSubmittedSection && formSectionControls.caption.errors?.required">This field is required.
                              </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="row">
                            <div class="col-md-3">
                                External Web 3
                             </div>
                             <div class="col-md-9">
                                <input class="form-control" formControlName="externalWeb3" [(ngModel)]= 'contentSection.ExternalWeb3'>
                                <div class="text-danger" *ngIf="isSubmittedSection && formSectionControls.caption.errors?.required">This field is required.
                                </div>
                             </div>
                        </div>
                     </div>
                     <div class="form-group">
                        <div class="row">
                            <div class="col-md-3">
                                Title
                             </div>
                             <div class="col-md-9">
                                <input class="form-control" formControlName="caption" [(ngModel)]= 'contentSection.Title'>
                                <div class="text-danger" *ngIf="isSubmittedSection && formSectionControls.caption.errors?.required">This field is required.
                                </div>
                            </div>
                          </div>
                      </div>
                <div class="form-group">
                    <div class="row">
                        <div class="col-md-3">
                            Content
                         </div>
                         <div class="col-md-9">
                            <textarea formControlName="content" class="form-control" [(ngModel)]= 'contentSection.Content'></textarea>
                          </div>
                    </div>
              </div>

                <div class="form-group" [style.display]="'none'">
                    <div class="row">
                        <div class="col-md-3">
                            Category
                         </div>
                         <div class="col-md-9">
                            <select formControlName="category" class="form-control" [(ngModel)]= 'contentSection.Room'>
                                <option value="noCategory">- Category -</option>
                                <option *ngFor="let section of sections" [value]="section.Title">{{section.Title}}</option>
                            </select>
                          </div>
                    </div>
                </div>
                <div class="form-group">
                    <div class="row">
                        <div class="col-md-3">
                            Sub-Category
                         </div>
                         <div class="col-md-9">
                            <select formControlName="subcategory" class="form-control" [(ngModel)]= 'contentSection.SubRoom'>
                                <option value="noSubcategory">- Subcategory -</option>
                                <option *ngFor="let subcategory of subcategories" [value]="subcategory.SubCategoryId">{{subcategory.Title}}</option>
                            </select>
                          </div>
                      </div>
                </div>
                <div class="form-group">
                    <div class="row">
                        <div class="col-md-3">
                            Index
                         </div>
                         <div class="col-md-9">
                            <select formControlName="index" class="form-control" [(ngModel)]= 'contentSection.Index'>
                              <option value="0">0</option>
                              <option value="1">1</option>
                              <option value="2">2</option>
                              <option value="3">3</option>
                              <option value="4">4</option>
                              <option value="5">5</option>
                              <option value="6">6</option>
                              <option value="7">7</option>
                              <option value="8">8</option>
                              <option value="9">9</option>
                              <option value="10">10</option>
                              <option value="11">11</option>
                              <option value="12">12</option>
                              <option value="13">13</option>
                              <option value="14">14</option>
                              <option value="15">15</option>
                            </select>
                          </div>
                      </div>
                  </div>
                <div class="form-row justify-content-center">
                  <div class="form-group col-md-4">
                    <button type="submit" class="btn btn-success btn-block float-right">Update Content</button>
                  </div>
                </div>
              </form>
        </div>
      </div>
    </div>
  </div>

  <!-- UPDATE PICTURE MODAL -->
<div class="modal fade" id="detailsPicture">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header bg-primary text-white">
          <h5 class="modal-title">Update Picture Information</h5>
          <button class="close" data-dismiss="modal">
            <span>&times;</span>
          </button>
        </div>
        <div *ngIf="picSelected" class="modal-body">
          <form [formGroup]="formUpdatePictureTemplate" (submit)="onUpdatePicture(formUpdatePictureTemplate.value, picSelected.PicId, picSelected.UrlImage)">
            <div class="text-center pb-3">
              <img [src]="picSelected.UrlImage" height="250px">
            </div>
            <div class="form-group">
                <div class="row">
                    <div class="col-md-3">
                        Title
                     </div>
                     <div class="col-md-9">
                        <input class="form-control" formControlName="caption" [(ngModel)]= 'picSelected.Title'>
                        <div class="text-danger" *ngIf="isSubmitted && formPictureUpdateControls.caption.errors?.required">This field is required.
                          </div>
                    </div>
                </div>
            </div>
            <div class="form-group">
                <div class="row">
                    <div class="col-md-3">
                        Photo Info
                     </div>
                     <div class="col-md-9">
                        <input class="form-control" formControlName="content" [(ngModel)]= 'picSelected.PhotoInfo'>
                        <div class="text-danger" *ngIf="isSubmitted && formPictureUpdateControls.content.errors?.required">This field is required.
                          </div>
                      </div>
                </div>
            </div>
            <div class="form-group">
                <div class="row">
                    <div class="col-md-3">
                        Rate
                     </div>
                     <div class="col-md-9">
                        <select formControlName="rate" class="form-control" [(ngModel)]= 'picSelected.Rate'>
                          <option value="low">Low</option>
                          <option value="medium">Medium</option>
                          <option value="high">High</option>
                          <option value="xxx">XXX</option>
                        </select>
                      </div>
                </div>
            </div>
            <div class="form-group">
                <div class="row">
                    <div class="col-md-3">
                        Type
                     </div>
                     <div class="col-md-9">
                        <select formControlName="type" class="form-control" [(ngModel)]= 'picSelected.Type'>
                          <option value="neutral">Neutral</option>
                          <option value="hetereo">Hetereo</option>
                          <option value="bi">Bi</option>
                          <option value="homo">Homo</option>
                        </select>
                      </div>
                    </div>
            </div>
            <div class="form-group">
                        <select formControlName="category" class="form-control" [(ngModel)]= 'picSelected.Category' [style.display]="'none'">
                          <option value="Pictures">Pictures</option>
                        </select>
            </div>
            <div class="form-group">
                <div class="row">
                    <div class="col-md-3">
                        SubCategory
                     </div>
                     <div class="col-md-9">
                        <select formControlName="subcategory" class="form-control" [(ngModel)]= 'picSelected.SubCategory'>
                            <option value="noSubcategory">- Subcategory -</option>
                            <option *ngFor="let section of sections" [value]="section.Title">{{section.Title}}</option>
                        </select>
                      </div>
                  </div>
            </div>
            <div class="form-group">
                <div class="row">
                    <div class="col-md-3">
                        Photo Source
                     </div>
                     <div class="col-md-9">
                        <select formControlName="sourceImage" class="form-control" [(ngModel)]= 'picSelected.PhotoSource'>
                          <option value="https://google.com">Google</option>
                          <option value="betax">Betax</option>
                          <option value="Medscience">Medscience</option>
                          <option value="SOJourney">SOJourney</option>
                        </select>
                      </div>
                </div>
            </div>
            <div class="form-group">
                <div class="row">
                    <div class="col-md-3">
                        File Name
                     </div>
                     <div class="col-md-9">
                        <input class="form-control" formControlName="fileName" [(ngModel)]= 'picSelected.FileName'>
                        <div class="text-danger" *ngIf="isSubmitted && formPictureUpdateControls.caption.errors?.required">This field is required.
                        </div>
                      </div>
                  </div>
            </div>
            <div class="form-group">
                <div class="row">
                    <div class="col-md-3">
                        Size ( W x H)(px)
                     </div>
                     <div class="col-md-9">
                        <input class="form-control" formControlName="size" [(ngModel)]= 'picSelected.Size'>
                        <div class="text-danger" *ngIf="isSubmitted && formPictureUpdateControls.caption.errors?.required">This field is required.
                        </div>
                      </div>
                </div>
            </div>
            <div class="form-group">
                <div class="row">
                    <div class="col-md-3">
                        Width(px)
                     </div>
                     <div class="col-md-9">
                        <input class="form-control" formControlName="width" [(ngModel)]= 'picSelected.Width'>
                        <div class="text-danger" *ngIf="isSubmitted && formPictureUpdateControls.caption.errors?.required">This field is required.
                        </div>
                  </div>
                </div>
            </div>
            <div class="form-group">
                <div class="row">
                    <div class="col-md-3">
                        Height(px)
                     </div>
                     <div class="col-md-9">
                        <input class="form-control" formControlName="height" [(ngModel)]= 'picSelected.Height'>
                        <div class="text-danger" *ngIf="isSubmitted && formPictureUpdateControls.caption.errors?.required">This field is required.
                        </div>
                      </div>
                  </div>
            </div>
            <div class="form-row justify-content-center">
              <div class="form-group col-md-4">
                <button type="submit" class="btn btn-success btn-block float-right">Update Picture Info</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>

  <!-- UPDATE CATEGORY MODAL -->
<div class="modal fade" id="detailsCategory">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header bg-success text-white">
          <h5 class="modal-title">Update Category</h5>
          <button class="close" data-dismiss="modal">
            <span>&times;</span>
          </button>
        </div>
        <div *ngIf="sectionSelected" class="modal-body">
          <form [formGroup]="formCategoryTemplate" (submit)="onUpdateCategory(formCategoryTemplate.value, sectionSelected.SectionId)">
            <div class="form-group">
                <div class="row">
                    <div class="col-md-3">
                        Title
                     </div>
                     <div class="col-md-9">
                        <input class="form-control" formControlName="caption" [(ngModel)]= 'sectionSelected.Title'>
                        <div class="text-danger" *ngIf="isSubmittedCategory && formCategoryControls.caption.errors?.required">This field is required.
                        </div>
                  </div>
            </div>
          </div>
            <div class="form-group">
                <div class="row">
                    <div class="col-md-3">
                        Type
                     </div>
                     <div class="col-md-9">
                        <select formControlName="type" class="form-control" [(ngModel)]= 'sectionSelected.Type'>
                          <option value="category">Category</option>
                          <option value="subCategory">SubCategory</option>
                          <option value="details">Details</option>
                        </select>
                      </div>
                  </div>
            </div>
            <div class="form-group">
              <select formControlName="category" class="form-control" [(ngModel)]= 'sectionSelected.Category' [style.display]="'none'">
                <option value="Sections">Category</option>
              </select>
            </div>
            <div class="form-row justify-content-center">
              <div class="form-group col-md-4">
                <button type="submit" class="btn btn-success btn-block float-right">Update Category</button>
              </div>
            </div>
          </form>
      </div>
    </div>
  </div>

<!-- ADD TEST MODAL -->
<div class="modal fade" id="addCategory2">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header bg-success text-white">
        <h5 class="modal-title">Add Category</h5>
        <button class="close" data-dismiss="modal">
          <span>&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form [formGroup]="formCategoryTemplate" (submit)="onSubmitCategory(formCategoryTemplate.value)">
          <div class="form-group">
            <input class="form-control" formControlName="caption" placeholder="Name of category">
            <div class="text-danger" *ngIf="isSubmittedCategory && formCategoryControls.caption.errors?.required">This field is required.
            </div>
          </div>
          <div class="form-group">
            <select formControlName="type" class="form-control">
              <option value="category">Category</option>
              <option value="subCategory">SubCategory</option>
              <option value="details">Details</option>
            </select>
          </div>
          <div class="form-group">
            <select formControlName="category" class="form-control">
              <option value="Sections">Sections</option>
            </select>
          </div>
          <div class="form-row">
            <div class="form-group col-md-6">
              <button type="submit" class="btn btn-success btn-block float-right">Submit</button>
            </div>
          </div>
        </form>
    </div>
  </div>
</div>

<!-- ADD USER MODAL -->
<div class="modal fade" id="addUserModal">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header bg-warning text-white">
        <h5 class="modal-title">Add Section</h5>
        <button class="close" data-dismiss="modal">
          <span>&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form>
          <div class="form-group">
            <label for="name">Name</label>
            <input type="text" class="form-control">
          </div>
          <div class="form-group">
            <label for="email">Email</label>
            <input type="email" class="form-control">
          </div>
          <div class="form-group">
            <label for="password">Password</label>
            <input type="password" class="form-control">
          </div>
          <div class="form-group">
            <label for="password2">Confirm Password</label>
            <input type="password" class="form-control">
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <button class="btn btn-warning" data-dismiss="modal">Save Changes</button>
      </div>
    </div>
  </div>
</div>

