<!-- PAGE HEADER -->
<!-- <header id="page-header">
  <div class="container setDefaultSpace pd-2">
    <div class="row">
      <div class="col-md-6 m-auto text-center">
        <h1 class="fontOpenSans-RegularSmallDarkGray">Videos</h1>
        <p class="fontOpenSans-RegularExtraSmallDarkGray">Selection of videos that I strongly recommended for different reasons like inspiration, training, reference, culture, etc.</p>
        <div>
            <app-video-filter [category]="category"></app-video-filter>
        </div>
      </div>
    </div>
  </div>
</header> -->

<!-- PAGE HEADER -->
<div *ngIf="((authService.isFamily(userLoggedIn))&&loadWithoutChannel)">

  <!-- PAGE HEADER -->
<header id="page-header">
    <div *ngIf="!mobile" class="container setDefaultSpace pd-2">
      <div class="row">
        <!-- <div class="col-md-6 m-auto text-center">
          <h1 class="fontOpenSans-RegularSmallDarkGray">Videos</h1>
          <p *ngIf="language === 'Spanish'" class="fontOpenSans-RegularExtraSmallDarkGray">Por favor seleccione la categoría</p>
          <p *ngIf="language === 'English'" class="fontOpenSans-RegularExtraSmallDarkGray">Please select the category</p>
          <div>
              <app-video-filter [category]="category"></app-video-filter>
          </div>
        </div> -->
        <div class="col-12">
          <div *ngIf="language === 'English'">
            <h1 class="fontOpenSans-RegularSmallDarkGray  text-center pb-5">Videos</h1>
            <p class="fontOpenSans-RegularExtraSmallDarkGray">Medscience Animations Studios research Team made a great effort to obtain valuable information from different sources related with the process, techniques and results of the male Super Orgasms.
            </p>
            <p class="fontOpenSans-RegularExtraSmallDarkGray">The characteristics of the topic required an open mind approach, this is not a rigorous scientific research but we tried to combine different valuable sources of data, some of them published in medical scientific journals, others by companies with a non traditional data sources ( forums ) with a tremendous value for the topic, other by influential bloggers and others by youtubers with good content.
            </p>
            <p class="fontOpenSans-RegularExtraSmallDarkGray">We tried to avoid to expose the research to adult content sources even if in some cases they can provide a valuable information of the unknown of the topic. We choose some selected sources as a reference to display what is the experience of a Super Orgasm, we want to present a visual content of the life changing event that means to a man to get a Super Orgasm.
            </p>
            <p class="fontOpenSans-RegularExtraSmallDarkGray">We also analyzed the content of more than 10,000 users of forums described a Super Orgams. We codified different approaches trying to get a pleasure pattern tracker. With this approach we created a tool ( Super Orgasm tracker ) where the users can track their sessions, their progress and, what is more important, to find the specific Super Orgasm pattern.
            </p>
            <p class="fontOpenSans-RegularExtraSmallDarkGray">The Team also identified some common points between the different techniques and the pleasure model such as the challenge to identify deep pelvic muscles, the connection between the brain and sounds to stimulate the nerves associated with the Super-O and the importance to understand and visualize the deep pelvic Anatomy. Our product mix is trying to provide tools to facilitate the process of the Super O.
            </p>
            <p class="fontOpenSans-RegularExtraSmallDarkGray">Please select the category
            </p>

          </div>
          <div *ngIf="language === 'Spanish'">
            <h1 class="fontOpenSans-RegularSmallDarkGray text-center pb-5">Videos</h1>
            <p class="fontOpenSans-RegularExtraSmallDarkGray">El equipo de investigación de Medscience Animations Studios realizó un gran esfuerzo para obtener información valiosa de diferentes fuentes relacionadas con el proceso, técnicas y resultados de los Super Orgasmos masculinos.
            </p>
            <p class="fontOpenSans-RegularExtraSmallDarkGray">Las características del tema requirieron un enfoque de mente abierta, esta no es una investigación científica rigurosa pero intentamos combinar diferentes fuentes valiosas de datos, algunas de ellas publicadas en revistas científicas médicas, otras por empresas con fuentes de datos no tradicionales (foros) con un valor tremendo para el tema, otros por bloggers influyentes y otros por youtubers con buen contenido.
            </p>
            <p class="fontOpenSans-RegularExtraSmallDarkGray">Intentamos evitar exponer la investigación a fuentes de contenido para adultos, incluso si en algunos casos pueden proporcionar una información valiosa por lo desconocido del tema. Elegimos algunas fuentes seleccionadas como referencia para mostrar lo que es la experiencia de un Super Orgasmo, queremos presentar un contenido visual de la experiencia que significa para un hombre obtener un Super Orgasmo.
            </p>
            <p class="fontOpenSans-RegularExtraSmallDarkGray">También analizamos el contenido con más de 10.000 usuarios de foros orientados a los Super Orgasmos. Codificamos diferentes enfoques tratando de obtener patrón de generación de placer de un Super Orgasmo. Con este enfoque creamos una herramienta (Super Orgasm tracker) donde los usuarios pueden seguir sus sesiones, su progreso y, lo que es más importante, encontrar el patrón específico del Super Orgasmo.
            </p>
            <p class="fontOpenSans-RegularExtraSmallDarkGray">El Equipo también identificó algunos puntos en común entre las diferentes técnicas y el modelo de placer como son el desafío de identificar los músculos pélvicos profundos, la conexión entre el cerebro y los sonidos para estimular los nervios asociados con el Super-O y la importancia de comprender y visualizar la Anatomía pélvica profunda. Nuestra mezcla de productos está tratando de proporcionar herramientas para facilitar el proceso de Super O.
            </p>
            <p class="fontOpenSans-RegularExtraSmallDarkGray">Por favor seleccione la categoría
            </p>
          </div>
          <div class="text-center pt-5">
              <app-video-filter [category]="category"></app-video-filter>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="mobile" class="container pd-2 mobile-Background">
      <div class="row">
        <div class="col-12">
          <div *ngIf="language === 'English'">
            <h1 class="fontOpenSans-RegularSmallWhite  text-center pb-5">Videos</h1>
            <p class="fontOpenSans-RegularExtraSmallDarkGray">Medscience Animations Studios research Team made a great effort to obtain valuable information from different sources related with the process, techniques and results of the male Super Orgasms.
            </p>
            <p class="fontOpenSans-RegularExtraSmallDarkGray">The characteristics of the topic required an open mind approach, this is not a rigorous scientific research but we tried to combine different valuable sources of data, some of them published in medical scientific journals, others by companies with a non traditional data sources ( forums ) with a tremendous value for the topic, other by influential bloggers and others by youtubers with good content.
            </p>
            <p class="fontOpenSans-RegularExtraSmallDarkGray">We tried to avoid to expose the research to adult content sources even if in some cases they can provide a valuable information of the unknown of the topic. We choose some selected sources as a reference to display what is the experience of a Super Orgasm, we want to present a visual content of the life changing event that means to a man to get a Super Orgasm.
            </p>
            <p class="fontOpenSans-RegularExtraSmallDarkGray">We also analyzed the content of more than 10,000 users of forums described a Super Orgams. We codified different approaches trying to get a pleasure pattern tracker. With this approach we created a tool ( Super Orgasm tracker ) where the users can track their sessions, their progress and, what is more important, to find the specific Super Orgasm pattern.
            </p>
            <p class="fontOpenSans-RegularExtraSmallDarkGray">The Team also identified some common points between the different techniques and the pleasure model such as the challenge to identify deep pelvic muscles, the connection between the brain and sounds to stimulate the nerves associated with the Super-O and the importance to understand and visualize the deep pelvic Anatomy. Our product mix is trying to provide tools to facilitate the process of the Super O.
            </p>
            <p class="fontOpenSans-RegularExtraSmallDarkGray">Please select the category
            </p>

          </div>
          <div *ngIf="language === 'Spanish'">
            <h1 class="fontOpenSans-RegularSmallWhite text-center pb-5">Videos</h1>
            <p class="fontOpenSans-RegularExtraSmallDarkGray">El equipo de investigación de Medscience Animations Studios realizó un gran esfuerzo para obtener información valiosa de diferentes fuentes relacionadas con el proceso, técnicas y resultados de los Super Orgasmos masculinos.
            </p>
            <p class="fontOpenSans-RegularExtraSmallDarkGray">Las características del tema requirieron un enfoque de mente abierta, esta no es una investigación científica rigurosa pero intentamos combinar diferentes fuentes valiosas de datos, algunas de ellas publicadas en revistas científicas médicas, otras por empresas con fuentes de datos no tradicionales (foros) con un valor tremendo para el tema, otros por bloggers influyentes y otros por youtubers con buen contenido.
            </p>
            <p class="fontOpenSans-RegularExtraSmallDarkGray">Intentamos evitar exponer la investigación a fuentes de contenido para adultos, incluso si en algunos casos pueden proporcionar una información valiosa por lo desconocido del tema. Elegimos algunas fuentes seleccionadas como referencia para mostrar lo que es la experiencia de un Super Orgasmo, queremos presentar un contenido visual de la experiencia que significa para un hombre obtener un Super Orgasmo.
            </p>
            <p class="fontOpenSans-RegularExtraSmallDarkGray">También analizamos el contenido con más de 10.000 usuarios de foros orientados a los Super Orgasmos. Codificamos diferentes enfoques tratando de obtener patrón de generación de placer de un Super Orgasmo. Con este enfoque creamos una herramienta (Super Orgasm tracker) donde los usuarios pueden seguir sus sesiones, su progreso y, lo que es más importante, encontrar el patrón específico del Super Orgasmo.
            </p>
            <p class="fontOpenSans-RegularExtraSmallDarkGray">El Equipo también identificó algunos puntos en común entre las diferentes técnicas y el modelo de placer como son el desafío de identificar los músculos pélvicos profundos, la conexión entre el cerebro y los sonidos para estimular los nervios asociados con el Super-O y la importancia de comprender y visualizar la Anatomía pélvica profunda. Nuestra mezcla de productos está tratando de proporcionar herramientas para facilitar el proceso de Super O.
            </p>
            <p class="fontOpenSans-RegularExtraSmallDarkGray">Por favor seleccione la categoría
            </p>
          </div>
          <div class="text-center pt-5">
              <app-video-filter [category]="category"></app-video-filter>
          </div>
        </div>
      </div>
    </div>
  </header>

<!-- <header id="page-header">
<div class="container pt-4 pb-4">
  <div class="row">
    <div class="card mb-3">
        <div class="row no-gutters">
          <div class="col-md-4">
            <img src="{{thumbnail}}" alt="" class="img-fluid card-img-top">

          </div>
          <div class="col-md-8">
            <div class="card-body">
              <img src="../../assets/images/youtube_logo.png" style="height: 20px;width:auto" class="img-fluid card-img-top">
              <h5 class="card-header centerName fontOpenSans-RegularSmallDarkGra">Channel : {{channelDetails.snippet.title}}</h5>
              <p class="fontOpenSans-RegularExtraSmallDarkGray pt-4">{{channelDetails.snippet.description}}</p>
              <p class="fontOpenSans-RegularExtraSmallDarkGray pt-4"><span class="fontOpenSans-RegularSmallDarkGray">Subscribers :&nbsp;</span>{{channelDetails.statistics.subscriberCount  | number}}</p>
              <p class="fontOpenSans-RegularExtraSmallDarkGray pt-4"><span class="fontOpenSans-RegularSmallDarkGray">Views :&nbsp;</span> {{channelDetails.statistics.viewCount | number}}</p>
              <p class="fontOpenSans-RegularExtraSmallDarkGray pt-4"><span class="fontOpenSans-RegularSmallDarkGray">Videos :&nbsp;</span> {{channelDetails.statistics.videoCount | number}}</p>
            </div>
          </div>
        </div>
      </div>

  </div>
</div>
</header>

<div class="no-scrolling">
  <ngx-masonry class="positioning" [options]="masonryOptions" [useImagesLoaded]="true">
    <ngxMasonryItem *ngFor="let video of videos" class="masonry-item drop-shadow">
            <div class="card">
                <div>
                  <iframe width="100%" height="auto" [src]="video.cleanUrl | safeHtml"
                  frameborder="0"allow="autoplay; ancrypted-media" allowFullScreen="allowFullScreen"></iframe>
                </div>
                    <div class="card-body">
                        <div class="card-header">{{ video.snippet.title }}</div>
                        <div class="card-body">
                                <h5 class="card-title">{{ video.snippet.description }}</h5>
                        </div>
                    </div>
                </div>
    </ngxMasonryItem>
  </ngx-masonry>
  </div> -->

  <div class="no-scrolling">
      <ngx-masonry class="positioning" [options]="masonryOptions" [useImagesLoaded]="true">
        <ngxMasonryItem *ngFor="let p of filteredContentSection" class="masonry-item drop-shadow">
                    <app-video-card [lab]="p"></app-video-card>
        </ngxMasonryItem>
      </ngx-masonry>
  </div>

</div>


<!-- PAGE HEADER -->
<div *ngIf="((authService.isFamily(userLoggedIn))&&(!loadWithoutChannel))">

    <!-- PAGE HEADER -->

  <header id="page-header">
  <div class="container pt-4 pb-4">
    <div class="row">
      <div class="card mb-3">
          <div class="row no-gutters">
            <div class="col-md-4">
              <img src="{{thumbnail}}" alt="" class="img-fluid card-img-top">

            </div>
            <div class="col-md-8">
              <div class="card-body">
                <img src="../../assets/images/youtube_logo.png" style="height: 20px;width:auto" class="img-fluid card-img-top">
                <h5 class="card-header centerName fontOpenSans-RegularSmallDarkGra">Channel : {{channelDetails.snippet.title}}</h5>
                <p class="fontOpenSans-RegularExtraSmallDarkGray pt-4">{{channelDetails.snippet.description}}</p>
                <p class="fontOpenSans-RegularExtraSmallDarkGray pt-4"><span class="fontOpenSans-RegularSmallDarkGray">Subscribers :&nbsp;</span>{{channelDetails.statistics.subscriberCount  | number}}</p>
                <p class="fontOpenSans-RegularExtraSmallDarkGray pt-4"><span class="fontOpenSans-RegularSmallDarkGray">Views :&nbsp;</span> {{channelDetails.statistics.viewCount | number}}</p>
                <p class="fontOpenSans-RegularExtraSmallDarkGray pt-4"><span class="fontOpenSans-RegularSmallDarkGray">Videos :&nbsp;</span> {{channelDetails.statistics.videoCount | number}}</p>
              </div>
            </div>
          </div>
        </div>

    </div>
  </div>
  </header>

  <div class="no-scrolling">
    <ngx-masonry class="positioning" [options]="masonryOptions" [useImagesLoaded]="true">
      <ngxMasonryItem *ngFor="let video of videos" class="masonry-item drop-shadow">
              <div class="card">
                  <div>
                    <iframe width="100%" height="auto" [src]="video.cleanUrl | safeHtml"
                    frameborder="0"allow="autoplay; ancrypted-media" allowFullScreen="allowFullScreen"></iframe>
                  </div>
                      <div class="card-body">
                          <div class="card-header">{{ video.snippet.title }}</div>
                          <div class="card-body">
                                  <h5 class="card-title">{{ video.snippet.description }}</h5>
                          </div>
                      </div>
                  </div>
      </ngxMasonryItem>
    </ngx-masonry>
    </div>

  </div>


<div *ngIf="!(authService.isFamily(userLoggedIn))">

  <!-- PAGE HEADER -->
<header id="page-header">
    <div *ngIf="!mobile" class="container setDefaultSpace pd-2">
      <div class="row">
        <div class="col-12">
          <div *ngIf="language === 'English'">
            <h1 class="fontOpenSans-RegularSmallDarkGray  text-center pb-3">Videos</h1>
            <p class="fontOpenSans-RegularExtraSmallDarkGray pt-3">You will find a selection of video clips on YouTube related with the topic of Super Orgasms. If the video has an age restriction you should login first in your YouTube account and if you are older than 18 years you will be able to play the video once you click on the YouTube link that is displayed on the video section.
            </p>
            <p class="fontOpenSans-RegularExtraSmallDarkGray pt-3">Please select the category
            </p>

          </div>
          <div *ngIf="language === 'Spanish'">
            <h1 class="fontOpenSans-RegularSmallDarkGray text-center pb-3">Videos</h1>
            <p class="fontOpenSans-RegularExtraSmallDarkGray pt-3">Encontrará una selección de videoclips en YouTube relacionados con el tema de los Super Orgasmos. Si el video tiene una restricción de edad  primero debe iniciar sesión en su cuenta de YouTube y si usted es mayor de 18 años  podrá reproducir el video una vez que haga click en el enlace de YouTube que se muestra en la sección de videos.
            </p>
            <p class="fontOpenSans-RegularExtraSmallDarkGray pt-3">Por favor seleccione la categoría
            </p>
          </div>
          <div class="text-center pt-3">
              <app-video-filter [category]="category"></app-video-filter>
          </div>
        </div>

      </div>
    </div>
    <div *ngIf="mobile" class="container pd-2 mobile-Background">
      <div class="row">
        <div class="col-12">
          <div *ngIf="language === 'English'">
            <h1 class="fontOpenSans-RegularSmallWhite  text-center pb-3">Videos</h1>
            <p class="fontOpenSans-RegularExtraSmallWhite pt-3">You will find a selection of video clips on YouTube related with the topic of Super Orgasms. If the video has an age restriction you should login first in your YouTube account and if you are older than 18 years you will be able to play the video once you click on the YouTube link that is displayed on the video section.
            </p>
            <p class="fontOpenSans-RegularExtraSmallWhite pt-3">Please select the category
            </p>

          </div>
          <div *ngIf="language === 'Spanish'">
            <h1 class="fontOpenSans-RegularSmallWhite text-center pb-3">Videos</h1>
            <p class="fontOpenSans-RegularExtraSmallWhite pt-3">Encontrará una selección de videoclips en YouTube relacionados con el tema de los Super Orgasmos. Si el video tiene una restricción de edad  primero debe iniciar sesión en su cuenta de YouTube y si usted es mayor de 18 años  podrá reproducir el video una vez que haga click en el enlace de YouTube que se muestra en la sección de videos.
            </p>
            <p class="fontOpenSans-RegularExtraSmallWhite pt-3">Por favor seleccione la categoría
            </p>
          </div>
          <div class="text-center pt-3">
              <app-video-filter [category]="category"></app-video-filter>
          </div>
        </div>

      </div>
    </div>
  </header>

<div class="no-scrolling">
    <ngx-masonry class="positioning" [options]="masonryOptions" [useImagesLoaded]="true">
      <ngxMasonryItem *ngFor="let p of filteredContentSection" class="masonry-item drop-shadow">
                  <app-video-card [lab]="p"></app-video-card>
      </ngxMasonryItem>
    </ngx-masonry>
</div>
</div>
