<div class="container">
<div class="setDefaultSpace setFooterBottom">
<h1>Carro de Compras</h1>
<div class="row col-12" *ngIf="cartShopping">
  <p>
    Usted tiene {{ cartShopping.totalItemsCount }} productos en su carrito de compras.
    <button
  *ngIf="cartShopping.items.length"
    (click)="clearCart()"
    class="btn btn-light btn-sm">Borrar carrito de compras</button>
  </p>
  <table class="table">
    <thead>
      <tr>
        <th></th>
        <th>Producto</th>
        <th class="text-center" style="width: 230px">Cantidad</th>
        <th class="text-right" style="width: 200px">Precio</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of cartShopping.items">
        <td>
          <div [style.backgroundImage]="'url(' + item.imageUrl + ')'" class="thumbnail">
          </div>
        </td>
        <td>
          {{ item.title }}
        </td>
        <td>
          <app-product-quantity [product]="item" [shopping-cart]="cartShopping">
          </app-product-quantity>
        </td>
        <!-- <td class="text-right">
          {{ item.totalPrice | currency:'USD':true }}
        </td> -->
        <td class="text-right">
          {{ item.totalPrice | currency }}
        </td>
      </tr>
    </tbody>
    <tfoot>
      <tr>
        <th></th>
        <th></th>
        <th>Subtotal</th>
        <!-- <th class="text-right">{{ cartShopping.totalPrice | currency:'USD':true }}</th> -->
        <th class="text-right">{{ cartShopping.totalPrice | currency }}</th>
      </tr>
      <tr>
        <th></th>
        <th></th>
        <th>IVA<span>({{impuesto | percent}})</span></th>
        <!-- <th class="text-right">{{ cartShopping.totalPrice | currency:'USD':true }}</th> -->
        <th class="text-right">{{ cartShopping.totalPrice*impuesto | currency }}</th>
      </tr>
      <tr>
        <th></th>
        <th></th>
        <th>Total Orden</th>
        <!-- <th class="text-right">{{ cartShopping.totalPrice | currency:'USD':true }}</th> -->
        <th class="text-right">{{ cartShopping.totalPrice*impuesto + cartShopping.totalPrice | currency }}</th>
      </tr>
    </tfoot>
  </table>
  <a
    *ngIf="cartShopping.items.length"
    routerLink="/check-out"
    class="btn btn-primary">Check Out</a>
</div>
</div>
</div>

