<div class="sticky-top2">
  <div class="list-group">
      <a
        class="list-group-item list-group-item-action"
        [class.active]="!category"
        routerLink="/"
        >All Categories</a>
      <a
        *ngFor="let c of categories"
        routerLink="/products"
        [queryParams]="{ category: c.id }"
        class="list-group-item list-group-item-action"
        [class.active]="category === c.id"
        >
        {{ c.title }}
      </a>
    </div>
</div>

