<!-- <div class="card">
  <div class="row">
      <div class="col-6">
          <img *ngIf="!checkVideo(lab)" class=" img-fluid" [src]="lab.UrlImage" alt="{{ lab.Title }}">
          <div  *ngIf="checkVideo(lab)"class="embed-responsive embed-responsive-16by9">
              <iframe class="embed-responsive-item" [src]="linkVideoSecure"></iframe>
          </div>
      </div>
      <div class="col-6">
          <div class="card-block px-2">
              <div *ngIf="lab.Title" class="card-title fontMontserratRegular">{{ lab.Title }}</div>
              <p class="card-text">{{ lab.Content }}</p>
          </div>
      </div>
  </div>
  <div class="card-footer w-100 text-muted">
      {{ lab.PhotoInfo }}
  </div>
</div> -->


<div class="card">
  <div>
    <iframe width="100%" height="auto" [src]="linkVideoSecure"
    frameborder="0"allow="autoplay; ancrypted-media" allowFullScreen="allowFullScreen"></iframe>
  </div>
      <div class="card-body">
          <!-- <div class="card-header">{{ video.snippet.title }}</div>
          <div class="card-body">
                  <h5 class="card-title">{{ video.snippet.description }}</h5>
          </div> -->
          <div class="card-header">{{ lab.Title }}</div>
          <div class="card-body">
                  <h5 class="card-title">{{ lab.Content }}</h5>
          </div>
      </div>
</div>

