import { Component, OnInit, Input } from '@angular/core';
import { CategoryService } from './../../../shared/services/category.service';
import { Category } from 'src/app/shared/models/category.model';

@Component({
  selector: 'app-product-filter',
  templateUrl: './product-filter.component.html',
  styleUrls: ['./product-filter.component.scss']
})
export class ProductFilterComponent implements OnInit {
  categories: Category[] = [];
  @Input('category') category;


  constructor(private categoryService: CategoryService) { }

  ngOnInit(): void {
    console.log("category product-filter",this.category);
    this.categoryService.getAll().subscribe( data => {
      this.categories = data.map(e => {
        return {
          id: e.payload.doc.id,
          isEdit: false,
          title: e.payload.doc.data()['title'],
          category: e.payload.doc.data()['title'],
          subCategoryId: e.payload.doc.data()['description'],
          image: e.payload.doc.data()['imageUrl'],
        };
      })
      console.log("categories - product-filter",this.categories);

    })
  }

}

