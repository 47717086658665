<div class="setDefaultSpaceContent bodyTemp">

<h1>Relaxer - {{language}}</h1>

    <div class="container" id="container">
      <div class="circle"></div>

      <p id="text"></p>

      <div class="pointer-container">
        <span class="pointer"></span>
      </div>

      <div class="gradient-circle"></div>
    </div>
  </div>

