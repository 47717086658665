import { Component, OnInit, OnDestroy, ElementRef } from '@angular/core';
import Utility  from './../shared/helpers/utility';
import { ActivatedRoute } from '@angular/router';
import { BridgeService } from './../shared/services/bridge.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-aboutus',
  templateUrl: './aboutus.component.html',
  styleUrls: ['./aboutus.component.scss']
})
export class AboutusComponent implements OnInit, OnDestroy {

  language='';
  mobileLanguage = null;
  mobileTone = null;
  mobileInput : any = null;
  mobile: boolean = false;
  private successSub!: Subscription

  constructor(private route: ActivatedRoute , public bridgeService: BridgeService, private elementRef: ElementRef) { }

  ngOnInit(): void {
    this.language = Utility.checkLanguage();
    this.route.queryParamMap.subscribe( params => {

      this.mobileLanguage = params.get('language');
      if (this.mobileLanguage !== null) {
        if (this.mobileLanguage != this.language) {
          this.changeLanguage(this.mobileLanguage);
        }
        console.log("mobile language selected",this.mobileLanguage)
      }

      this.mobileTone = params.get('tone');
      if (this.mobileTone !== null) {
        console.log("mobile language selected",this.mobileTone)
      }
      this.mobileInput = params.get('mobile');
      if (this.mobileInput !== null) {
        // this.changeMobileInput(this.mobileInput);
        this.bridgeService.confirmMobile();
      }

      this.successSub = this.bridgeService.mobileSuccess.subscribe(mobile => {
        if (mobile) {
          this.mobile = true
        }
        else {
          this.mobile = false
        }
      }
      )

    // this.category = params.get('category');
    // this.filteredContentSection = (this.category) ? this.contentSections.filter(p => p.SubRoom == this.category) : this.contentSections
  })
  }

  changeLanguage(language) {
    if (language == 'Spanish') {
      localStorage.setItem('language', 'Spanish');
      window.location.reload();
    }
    if (language == 'English') {
      localStorage.setItem('language', 'English');
      window.location.reload();
    }
  }

  ngAfterViewInit() {
    if (this.mobile) {
      this.elementRef.nativeElement.ownerDocument
            .body.style.backgroundColor = '#dc143c';
    }
		}

  ngOnDestroy(): void {
    this.successSub.unsubscribe();
}

}
