<!-- <div class="no-scrolling setDefaultSpace">
  <ngx-masonry class="positioning" [options]="masonryOptions" [useImagesLoaded]="true">
      <ngxMasonryItem *ngFor="let picture of contentSections" class="masonry-item drop-shadow">
          <div *ngIf ="picture.category =='1601695269309'">
              <div class="card">
                      <div class="card-body">
                          <p><img class=" img-fluid" src="{{picture.imageUrl}}" alt="card image"></p>
                          <div class="card-header">{{ picture.title }}</div>
                          <div class="card-body">
                                  <h5 class="card-title">{{ picture.description }}</h5>
                              <div>
                                  <table>
                                          <tr>
                                                  <th class="fontOpenSans-SemiboldSmallDarkGray">Title</th>
                                                </tr>
                                                <tr>
                                                  <td class="fontOpenSans-RegularExtraSmallDarkGray">{{ picture.description }}</td>
                                                </tr>
                                          <tr>
                                            <th class="fontOpenSans-SemiboldSmallDarkGray">Thesis/Project</th>
                                          </tr>
                                          <tr>
                                            <td class="fontOpenSans-RegularExtraSmallDarkGray">{{ picture.price }}</td>
                                          </tr>
                                        </table>
                                        <table>
                                            <tr>
                                              <th class="fontOpenSans-SemiboldSmallDarkGray">Thesis Director</th>
                                            </tr>
                                            <tr>
                                              <td class="fontOpenSans-RegularExtraSmallDarkGray">{{ picture.title }}</td>
                                            </tr>
                                        </table>
                                        <table>
                                        </table>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div class="card p-3">
                          <blockquote class="card-blockquote card-body fontOpenSans-SemiBoldSmallContent">
                            <footer class='blockquote-footer'>
                              <small class="text-muted"> {{ picture.description }}<cite title="Source Title"></cite></small><br>
                            </footer>
                          </blockquote>
                        </div>
            </div>
      </ngxMasonryItem>
  </ngx-masonry>
  </div> -->


  <!-- PAGE HEADER -->
  <header id="page-header">
    <div class="container setDefaultSpace pd-2">
      <div class="row">
        <div class="col-12">
          <h1 class="fontOpenSans-RegularSmallDarkGray text-center">{{title}}</h1>
          <div *ngIf="language === 'English'" class="pb-5">
            <p class="fontOpenSans-RegularExtraSmallDarkGray">There is a long Journey for Medscience Animations Studios Inc. to consolidate a solid technological background in the medical field to create “open mind” products offer to millions of men looking for new horizons on men’s health and men’s sexuality.
            </p>
            <p class="fontOpenSans-RegularExtraSmallDarkGray">Our core business is based on cutting edge technologies on 3D animation, augmented reality and artificial intelligence applied to healthcare, originally dedicated to women care with a portfolio of mobile apps for Urinary Incontinence, Women’s sexual anatomy, pelvic surgery training for surgeons, and women’s pelvic floor education. We partnered with a group of the most prestigious gynecologist in US, Brazil, Colombia, Chile, and Venezuela. All this background provided a solid experience to start the Journey toward the research of the nonconventional topic of the male Super Orgasm.
            </p>
            <p class="fontOpenSans-RegularExtraSmallDarkGray">The purpose of the six products that we offered is basically to help every man how to “reverse engineer” the Super Orgasm. It is an ambitious goal. A Super Orgasm is a personal Journey with a lot of variables, mystery, and fascination. The end result could be a new extension of the men’s sexuality, a better life, better relationships, and a better world.
            </p>
            <p class="fontOpenSans-RegularExtraSmallDarkGray">We are not making any medical advice with our products please be aware that  should ask your doctor before you try any of our products.

            </p>
          </div>
          <div *ngIf="language === 'Spanish'" class="pb-5">
            <p class="fontOpenSans-RegularExtraSmallDarkGray">Medscience Animations Studios Inc. ha recorrido un largo camino para consolidar una sólida formación tecnológica en el campo médico,  ahora estamos listos para crear una oferta de productos considerados “open mind" que se ofrecen a millones de hombres que buscan nuevos horizontes en su salud y sexualidad.
            </p>
            <p class="fontOpenSans-RegularExtraSmallDarkGray">Nuestro negocio principal se basa en tecnologías de vanguardia en animación 3D, realidad aumentada e inteligencia artificial aplicadas al cuidado de la salud, originalmente dedicado al cuidado de la mujer con un portafolio de aplicaciones móviles para incontinencia urinaria, anatomía sexual femenina, entrenamiento en cirugía pélvica para cirujanos y material educativo acerca del piso pélvico enfocado a la mujer. Nos asociamos con un grupo de los ginecólogos más prestigiosos de EE. UU., Brasil, Colombia, Chile y Venezuela. Todo este bagaje brindó una sólida experiencia para iniciar el viaje hacia la investigación del tema no convencional del Super Orgasmo masculino.
            </p>
            <p class="fontOpenSans-RegularExtraSmallDarkGray">El propósito de los seis productos que ofrecemos es básicamente ayudar a cada hombre a "aplicar ingeniería inversa" al Super Orgasmo. Es un objetivo ambicioso. Un súper orgasmo es un viaje personal con muchas variables, misterio y fascinación. El resultado final podría ser una nueva extensión de la sexualidad masculina, una vida más emocionante, mejores relaciones de pareja y un mundo mejor.
            </p>
            <p class="fontOpenSans-RegularExtraSmallDarkGray">No estamos dando ningún consejo médico con nuestros productos, tenga en cuenta que le sugerimos consultar con su médico antes de probar cualquiera de nuestros productos.

            </p>
          </div>
          <!-- <p class="fontOpenSans-RegularExtraSmallDarkGray">{{subTitle}}</p> -->
          <div class="d-block d-md-none">
            </div>
        </div>
      </div>
    </div>
  </header>

  <div class="no-scrolling">
  <ngx-masonry class="positioning" [options]="masonryOptions" [useImagesLoaded]="true">
    <ngxMasonryItem *ngFor="let picture of contentSections" class="masonry-item drop-shadow">
            <div class="card">
                    <div class="card-body">
                        <p><img class=" img-fluid" src="{{picture.UrlImage}}" alt="card image"></p>
                        <div *ngIf ="picture.ExternalWeb3 !='demo'" class="card-header">{{ picture.Title }}</div>
                        <div class="card-body">
                                <h5 class="card-title">{{ picture.PhotoInfo }}</h5>
                                <!-- <h5 class="card-title">{{ picture.Time }}</h5> -->
                            <div *ngIf ="picture.ExternalWeb3 !='demo'">
                                <table>
                                        <tr>
                                                <th class="fontOpenSans-SemiboldSmallDarkGray">{{title1}}</th>
                                              </tr>
                                              <tr>
                                                <td class="fontOpenSans-RegularExtraSmallDarkGray"> {{picture.Content}}</td>
                                              </tr>
                                        <!-- <tr>
                                          <th class="fontOpenSans-SemiboldSmallDarkGray">{{title2}}</th>
                                        </tr>
                                        <tr>
                                          <td class="fontOpenSans-RegularExtraSmallDarkGray">{{ picture.ExternalWeb1 }}</td>
                                        </tr> -->
                                      </table>
                                      <!-- <table>
                                          <tr>
                                            <th class="fontOpenSans-SemiboldSmallDarkGray">{{title3}}</th>
                                          </tr>
                                          <tr>
                                            <td class="fontOpenSans-RegularExtraSmallDarkGray">{{ picture.ExternalWeb3 }}</td>
                                          </tr>
                                      </table> -->
                                      <table>
                                      </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf ="picture.ExternalWeb3 !='demo'" class="card p-3">
                        <blockquote class="card-blockquote card-body fontOpenSans-SemiBoldSmallContent">
                          <footer class='blockquote-footer'>
                            <small class="fontOpenSans-RegularSmallDarkGrayTextMuted"> {{ picture.ExternalWeb2 }}<cite title="Source Title"></cite></small><br>
                            <a href="{{ picture.PhotoSource }}" target="blank_"><small class="fontOpenSans-RegularSmallDarkGrayTextMuted"> {{ picture.PhotoSource }}<cite title="Source Title"></cite></small></a>
                          </footer>
                        </blockquote>
                      </div>
    </ngxMasonryItem>
  </ngx-masonry>
  </div>
