import { Component, OnInit, Renderer2 } from '@angular/core';
import { UploadInfoService } from './../shared/services/upload-info.service';
import { ContentSection } from './../shared/models/contentSection.model'
import { Course } from './../shared/models/course.model';
import { NgxMasonryOptions } from 'ngx-masonry';
import Utility  from './../shared/helpers/utility';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser'


@Component({
  selector: 'app-catalog',
  templateUrl: './catalog.component.html',
  styleUrls: ['./catalog.component.scss']
})
export class CatalogComponent implements OnInit {
  contentSections: ContentSection[];
  contentClean: ContentSection[];
  language = '';
  title = '';
  subTitle = '';
  photoSource = '';
  title1 = '';
  title2 = '';
  title3 = '';


  public masonryOptions: NgxMasonryOptions = {
		transitionDuration: '0.2s',
		gutter: 20,
		columnWidth: 300,
		// resize: true,
		initLayout: true,
		// fitWidth: true,
		// originLeft: false,
	};

	masonryImages;
	limit = 15;
  constructor(private contentservice: UploadInfoService, private renderer: Renderer2, private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this.language = Utility.checkLanguage();
    this.contentSections = [];
    if ( this.language === 'Spanish') {
      this.title = 'Productos'
      this.subTitle = 'Estas son lagunas de las técnicas'
      this.photoSource = 'Fuente de la foto : ';
      this.title1 = 'Breve descripción'
      this.title2 = 'Fundamentos'
      this.title3 = 'Por qué quizás funcione?'
      this.contentservice.getContentbySection('catalogSP').subscribe(val => {
        this.contentSections = val;
      })
    }
    if ( this.language === 'English') {
      this.title = 'Products';
      this.subTitle = 'These are some of the techniques';
      this.photoSource = 'Photo source : ';
      this.title1 = 'Brief description'
      this.title2 = 'The basis'
      this.title3 = 'Why this may be works?'
      this.contentservice.getContentbySection('catalog').subscribe(val => {
        this.contentSections = val;
      })
    }

	// this.masonryImages = this.dummyPictures.slice(0, this.limit);
	this.masonryImages = this.contentSections.slice(0, this.limit);
  }

  showMoreImages() {
		this.limit += 15;
		this.masonryImages = this.contentSections.slice(0, this.limit);
	}

	ngAfterViewInit() {
		const parent: HTMLElement = document.getElementById('main-footer');
		this.renderer.removeStyle(parent, 'position');
		this.renderer.removeStyle(parent, 'bottom');
		this.renderer.removeStyle(parent, 'width');
    }

    safeContent(content): string {
      return this.sanitizer.bypassSecurityTrustHtml(content) as string;
    }
}
