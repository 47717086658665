<!-- PAGE HEADER -->
<div class="row vertical-center">
  <div class="container">
      <header id="page-header">
          <div class="container pd-2">
            <div class="row">
              <div class="col-md-6 m-auto text-center">
                <h1 class="fontOpenSans-RegularSmallDarkGray">Reset Password</h1>
                <p class="fontOpenSans-RegularExtraSmallDarkGray" *ngIf="!displayMessage">Please enter your email address to request a password reset. If password exists in database you will receive an email to reset the password.</p>
                <div class="d-block d-md-none">
                  </div>
              </div>
            </div>
          </div>
      </header>


  <div class="container">
      <div class="row authPosition">
          <div class="m-auto  col-9  col-sm-6 col-md-6  col-lg-4">
              <div *ngIf="isLoading" style="text-align: center;">
                    <app-loading-spinner></app-loading-spinner>
              </div>
            <form #authForm="ngForm" (ngSubmit)="onSubmit(authForm)" *ngIf="((!isLoading) && !(displayMessage))">
              <div class="form-group">
                <input
                  placeholder="E-Mail"
                  type="email"
                  id="email"
                  class="form-control"
                  ngModel
                  name="email"
                  required
                  email
                  (focus)="resetErrorMessage()"
                />
              </div>
              <div class="row">
                <div class="col-12 pt-4 positionCenter">
                  <button
                    class="btn btn-primary"
                    type="submit"
                    [disabled]="!authForm.valid"
                  >
                    Reset Password
                  </button>
              </div>
              <div class="col-12 pb-4 pt-4 positionCenter">
                <div class="fontOpenSans-RegularExtraSmallDarkGray linkStyle">
                    <div>Go back to ?&nbsp;&nbsp;<span><a routerLink="/sign-in">Sign In <span class="sr-only">(current)</span></a></span></div>
                </div>
              </div>
              </div>
            </form>
            <div *ngIf="displayMessage">

                <div class="row">
                  <div class="col-12 pb-4 pt-4 positionCenter fontOpenSans-RegularSmallDarkGray">
                      <div>{{message}}</div>
                  </div>
                </div>

                <div class="row">
                    <div class="col-12 pb-4 pt-4 positionCenter fontOpenSans-RegularExtraSmallDarkGray linkStyle">
                        <div>Go back to ?&nbsp;&nbsp;<span><a routerLink="/sign-in">Sign In <span class="sr-only">(current)</span></a></span></div>
                    </div>
                  </div>
            </div>
          </div>
      </div>
</div>
</div>
</div>
