import { Component, OnInit, OnDestroy, forwardRef,AfterViewInit } from '@angular/core';
import { Subscription, Observable, BehaviorSubject } from 'rxjs';
import { AuthService } from './../shared/services/auth.service';
import { User } from './../shared/models/user.model';
import { UserProfile } from './../shared/models/user-profile';
import { ShoppingCartService } from '../shared/services/shopping-cart.service';
import { CategoryService } from '../shared/services/category.service';
import { ProductService } from '../shared/services/product.service';
import { Product } from '../shared/models/product.model';
import { Category } from '../shared/models/category.model';
import { ShoppingCart } from '../shared/models/shopping-cart.model';
import { ShoppingCartItem } from '../shared/models/shopping-cart-item.model';
import { first } from 'rxjs/operators';
import { AngularFireAuth } from "@angular/fire/auth";
import { Router } from "@angular/router";
import { LoadLocalDataService } from '../shared/services/load-local-data.service';
import { ContentSection } from '../shared/models/contentSection.model';
import { UploadInfoService } from './../shared/services/upload-info.service';
import { HttpErrorResponse } from '@angular/common/http';
import { homePageTitlesArray } from '../shared/localData/homepage.data';
import { BridgeService } from './../shared/services/bridge.service';


interface ItemsMap { [productId: string]: ShoppingCartItem }

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})

export class HeaderComponent implements OnInit, OnDestroy, AfterViewInit {

  isAuthenticated = false;
  userLoggedIn: UserProfile;
  userData: User;
  private userSub: Subscription;

  isOperatorKitchen: boolean;
  isAdmin: boolean;
  isEditor: boolean;
  isUser: boolean;

  products: Product[] = [];
  categories: Category[] = [];
  filteredProducts: Product[] = [];
  category: string;
  cart$: Observable<ShoppingCart>;
  item : ShoppingCartItem
  cart: ShoppingCartItem[];
  cartShopping: ShoppingCart;
  subscription: Subscription
  cartId: string ='';
  countItems = 0;
  countingFromCall = false;
  subsCountingProducts: Subscription;
  subsCountingShopping: Subscription;
  userDataSub: any; // Save logged in user data
  user = new BehaviorSubject<User>(null);
  displayName = "";
  language = 'Spanish';
  homePageTitles: ContentSection[] = homePageTitlesArray;
  initializeBackend: ContentSection[];
  mobile: boolean = false;
  private successSub!: Subscription;



  constructor(
    public authService: AuthService,
    private productService: ProductService,
    private categoryService: CategoryService,
    private shoppingCartService: ShoppingCartService,
    public afAuth: AngularFireAuth, // Inject Firebase auth service
    public router: Router,
    public loadLocalService: LoadLocalDataService,
    private contentservice: UploadInfoService,
    public bridgeService: BridgeService
  ) { }

  ngAfterViewInit() {

  }

  ngOnInit() {
    // FIX SIGN IN ISSUE
    // this.userSub = this.authService.user.subscribe(user => {
    //   console.log("to fix this - option to logout after save new file");
    //   this.isAuthenticated = !!user;
    //   this.userData = user;
    //   console.log("header user",this.userData);
    //   if ( this.userData !== null) {
    //   if (JSON.parse(localStorage.getItem('user'))) {
    //     this.userData = JSON.parse(localStorage.getItem('user'));
    //     if (this.userData) {
    //       if (user.uid) {
    //         this.getUserbyId(user.uid)
    //       } else {
    //         this.authService.SignOut();
    //       }
    //     }
    //   }
    // } else {
    //   this.authService.SignOut();
    // }
    // });

    if (localStorage.getItem('language') ==null) {
      localStorage.setItem('language', 'English');
      this.language = 'English';
    }

    this.contentservice.getContentbySection('Initialize').subscribe(val => {
      this.initializeBackend = val;
      console.log('external DB',this.initializeBackend);
      if (this.initializeBackend[0].ExternalWeb1 === 'Yes') {
        localStorage.setItem('externalContent', 'Yes');
      }
      if (this.initializeBackend[0].ExternalWeb1 === 'No') {
        localStorage.setItem('externalContent', 'No');
      }

      if (localStorage.getItem('language') =='Spanish') {
        if (localStorage.getItem('externalContent') =='Yes') {
          this.contentservice.getContentbySection('NavigationSP').subscribe(val => {
            this.homePageTitles = val
            this.language = 'Spanish';
          })

         } else {
          this.loadLocalService.getHeaderTitlesSP().subscribe( data => {
            this.homePageTitles = data
            this.language = 'Spanish';
          })
         }
      }


      if (localStorage.getItem('language') =='English') {
        if (localStorage.getItem('externalContent') =='Yes') {
          this.contentservice.getContentbySection('Navigation').subscribe(val => {
            console.log('I am in this section');
            this.homePageTitles = val
            this.language = 'English';
          })

         } else {
          this.loadLocalService.getHeaderTitles().subscribe( data => {
            this.homePageTitles = data
            this.language = 'English';
          })
         }
      }

    },
    (err:HttpErrorResponse) => {
      localStorage.setItem('externalContent', 'No');
      if (localStorage.getItem('language') =='Spanish') {
          this.loadLocalService.getHeaderTitlesSP().subscribe( data => {
            this.homePageTitles = data
            this.language = 'Spanish';
          })
      }

      if (localStorage.getItem('language') =='English') {
          this.contentservice.getContentbySection('Navegation').subscribe(val => {
            this.homePageTitles = val
            this.language = 'English';
          })

         } else {
          this.loadLocalService.getHeaderTitles().subscribe( data => {
            this.homePageTitles = data
            this.language = 'English';
          })
      }
    })


    this.afAuth.authState.subscribe(user => {
      console.log("SIGNUP user checking profile",user);
      if (user) {
        this.userDataSub = user;
        localStorage.setItem('user', JSON.stringify(this.userDataSub));
        if (user.emailVerified == true ) {
          if (JSON.parse(localStorage.getItem('profile')) !=null) {
            console.log("CONSTRUCTOR profile is not null");
              const  userProfile: UserProfile = {
              email: "",
              displayName: "",
              language: "",
              photoUrl: "",
              age: "",
              role: {
                subscriber: true
              }
            }
            const user = JSON.parse(localStorage.getItem('user'));
            const profile = JSON.parse(localStorage.getItem('profile'));
            userProfile.email = user.email;
            userProfile.uid = user.uid;
            userProfile.displayName = profile.displayName;
            userProfile.language = profile.language;
            userProfile.emailVerified = user.emailVerified;
            userProfile.age = profile.age;
            userProfile.photoUrl = profile.photoUrl;
            userProfile.createdAt = new Date;
            userProfile.role = profile.role;
            this.displayName = profile.displayName;
            if (user.email == userProfile.email) {
              this.authService.SetUserData(userProfile)
              localStorage.setItem('profile', null);
              setTimeout(() => {
                console.log("verifying this.userData.uid",this.userDataSub.uid);
                this.getUserbyId(this.userDataSub.uid);
                this.isAuthenticated = true;
                this.router.navigate(['']);},100)
            }
          }
          console.log("verifying user profile = NULL")
          this.isAuthenticated = true;
          console.log("verifying user.uid",user.uid);
          this.getUserbyId(user.uid)
        } else {
          alert("Please review your email to verify your account. Thanks")
          this.isAuthenticated = false;
          this.authService.SignOut();
        }
      } else {
        localStorage.setItem('user', null);
      }
    });

    this.authService.statusChange.subscribe(userData => {
      if (userData) {
        this.isAuthenticated = true;
        this.userData = JSON.parse(localStorage.getItem('user'));
        if (this.userData.emailVerified == true ) {
          console.log("email verified");
          this.getUserbyId(this.userData.uid)
        }
        console.log("statuschanged exists",userData)
      } else {
        console.log("statuschanged NULL",userData)
        this.isAuthenticated = false;
        this.authService.SignOut();
      }
    });

    this.subsCountingProducts = this.productService.itemsCount.subscribe( count => {
      console.log("PRODUCTS");
      this.countingFromCall = false;
      this.countItems = count
    });

    this.subsCountingShopping = this.shoppingCartService.itemsCount$.subscribe( count => {
      console.log("SHOPPING");
      this.countingFromCall = false;
      this.countItems = count
    });

    // this.isAuthenticated = this.authService.isLoggedIn;
    // console.log('is Authenticated init?:',this.isAuthenticated);
    console.log("GET CART header");
    this.subscription = this.shoppingCartService.getCart().subscribe(cart => {
      this.cart = cart.map(e => {
        return {
          id: e.payload.doc.id,
          isEdit: false,
          title: e.payload.doc.data()['title'],
          description: e.payload.doc.data()['description'],
          category: e.payload.doc.data()['category'],
          price: e.payload.doc.data()['price'],
          quantity: e.payload.doc.data()['quantity'],
          imageUrl: e.payload.doc.data()['imageUrl'],
          totalPrice: e.payload.doc.data()['totalPrice'],
        };
      })
      let items: ShoppingCartItem[] = [];
      let itemsMap: { [productId: string]: ShoppingCartItem } = {}
      let products: [{'productId':string}] = [{'productId':null}]
      let productStr = [];
      for(let i=0; i<this.cart.length; i++){
        products.push({'productId':this.cart[i].id});
        productStr.push(this.cart[i].id);

    }
      for(let i=0; i<this.cart.length; i++){
        items.push(new ShoppingCartItem({ id:products[i], ...this.cart[i]}));
    }


  let itemsMapA: ItemsMap = {}
    for(let i=0; i<this.cart.length; i++){
      let str = productStr[i];
      itemsMapA[str] = new ShoppingCartItem()
      itemsMap = this.addElement(itemsMap,itemsMapA);
  }
      this.cartShopping = new ShoppingCart(itemsMap);
      this.cartShopping.items = this.cart
      this.countingFromCall = true;
    })

    this.successSub = this.bridgeService.mobileSuccess.subscribe(mobile => {
      if (mobile) {
        this.mobile = true
      }
      else {
        this.mobile = false
      }
    }
    )

  }

  onLogout() {
    // this.authService.logout();
    this.authService.set(null);
    this.authService.SignOut();
    // this.isAuthenticated = false;
    // console.log('is Authenticated out?:',this.isAuthenticated);

  }

  ngOnDestroy() {
    this.userSub.unsubscribe();
    this.subsCountingProducts.unsubscribe();
    this.subsCountingShopping.unsubscribe();
    console.log("DESTROY");
    this.countingFromCall = true;
    this.successSub.unsubscribe();
  }

  getUserbyId(userUID:String) {
    this.authService.getUserInfobyId(userUID).subscribe(user => {
      this.userLoggedIn = user
      this.displayName = this.userLoggedIn.displayName;
      console.log("print this.userLoggedIn",this.userLoggedIn);
      this.isAdmin = this.authService.canDelete(this.userLoggedIn)
      this.isOperatorKitchen = this.authService.isOperatorKitchen(this.userLoggedIn)

      // console.log("User logged in info:",this.userLoggedIn);
    })
  }

  addElement (itemsMap2, element) {
    let newList = Object.assign(itemsMap2, element)
    return newList
}

changeLanguage(language) {
  if (language == 'Spanish') {
    localStorage.setItem('language', 'Spanish');
    window.location.reload();
  }
  if (language == 'English') {
    localStorage.setItem('language', 'English');
    window.location.reload();
  }
}

}
