<div class="container setDefaultSpace">
  <div class="row">
    <div class="align-self-center"></div>
<div *ngFor="let order of extractOrders">
<ul class="timeline">

  <li><h5>Número de pedido:</h5><p>{{order.id}}</p></li>

  <!--=====================================
  1 Momento: Órden de entrega recibida
  ======================================-->

    <li
*ngIf="order.process[0].stage == 'received' && order.process[0].status== 'ok'"
    class="success">

        <!-- <h5>{{order.process[0].date | date: "EEEE, d/M/yy, h:mm a"}}</h5> -->
        <h5>{{order.process[0].date | date: 'EEEE, d/M/yy, h:mm a':'es-CO'}}</h5>
        <p class="text-success">Orden recibida <i class="fas fa-check"></i></p>
        <p>Comment: {{order.process[0].comment}}</p>

    </li>

    <!--=====================================
  2 Momento: Cuando se envía el producto
  ======================================-->

  <!-- Cuando el status es ok -->

  <li
*ngIf="order.process[1].stage == 'processing' && order.process[1].status == 'ok'"
    class="success">

        <h5>{{order.process[1].date | date: 'EEEE, d/M/yy, h:mm a':'es-CO'}}</h5>
        <p class="text-success">Orden en proceso elaboración <i class="fas fa-check"></i></p>
        <p>Comment: {{order.process[1].comment}}</p>

    </li>

    <!-- Cuando el status es pendiente -->

    <li
    *ngIf="order.process[1].stage == 'processing' && order.process[1].status != 'ok'"
    class="process">
        <h5>{{order.process[1].date | date: 'EEEE, d/M/yy, h:mm a':'es-CO'}}</h5>
        <p>Orden en proceso elaboración</p>
        <button class="btn btn-primary" disabled>
          <span class="spinner-border spinner-border-sm"></span>
          In Progress
        </button>
    </li>

    <!--=====================================
  3 Momento: Cuando se entrega el producto
  ======================================-->

  <!-- Cuando el status es ok -->

  <li
*ngIf="order.process[2].stage == 'ready' && order.process[2].status == 'ok'"
    class="success">

        <h5>{{order.process[2].date | date: 'EEEE, d/M/yy, h:mm a':'es-CO'}}</h5>
        <p class="text-success">Orden lista para envío <i class="fas fa-check"></i></p>
        <p>Comment: {{order.process[2].comment}}</p>

    </li>

    <!-- Cuando el status es pendiente -->

    <li
    *ngIf="order.process[2].stage == 'ready' && order.process[2].status != 'ok'"
    class="process">
        <h5>{{order.process[2].date | date: 'EEEE, d/M/yy, h:mm a':'es-CO'}}</h5>
        <p>Orden lista para envío</p>
        <button class="btn btn-primary" disabled>
          <span class="spinner-border spinner-border-sm"></span>
          In process
        </button>
    </li>

    <!-- Cuando el status es ok -->

  <li
  *ngIf="order.process[3].stage == 'delivery' && order.process[3].status == 'ok'"
      class="success">

          <h5>{{order.process[3].date | date: 'EEEE, d/M/yy, h:mm a':'es-CO'}}</h5>
          <p class="text-success">Orden empacada <i class="fas fa-check"></i></p>
          <p>Comment: {{order.process[3].comment}}</p>

      </li>

    <!-- Cuando el status es pendiente -->

    <li
    *ngIf="order.process[3].stage == 'delivery' && order.process[3].status != 'ok'"
    class="process">
        <h5>{{order.process[3].date | date: 'EEEE, d/M/yy, h:mm a':'es-CO'}}</h5>
        <p>Orden empacada</p>
        <button class="btn btn-primary" disabled>
          <span class="spinner-border spinner-border-sm"></span>
          In process
        </button>
    </li>

    <!-- Cuando el status es ok -->

  <li
  *ngIf="order.process[4].stage == 'transit' && order.process[4].status == 'ok'"
      class="success">

          <h5>{{order.process[4].date | date: 'EEEE, d/M/yy, h:mm a':'es-CO'}}</h5>
          <p class="text-success">En tránsito <i class="fas fa-check"></i></p>
          <p>Comment: {{order.process[4].comment}}</p>

      </li>

    <!-- Cuando el status es pendiente -->

    <li
    *ngIf="order.process[4].stage == 'transit' && order.process[4].status != 'ok'"
    class="process">
        <h5>{{order.process[4].date | date: 'EEEE, d/M/yy, h:mm a':'es-CO'}}</h5>
        <p>En tránsito</p>
        <button class="btn btn-primary" disabled>
          <span class="spinner-border spinner-border-sm"></span>
          In process
        </button>
    </li>

    <!-- Cuando el status es ok -->

  <li
  *ngIf="order.process[5].stage == 'delivered' && order.process[5].status == 'ok'"
      class="success">

          <h5>{{order.process[5].date | date: 'EEEE, d/M/yy, h:mm a':'es-CO'}}</h5>
          <p class="text-success">Entregado <i class="fas fa-check"></i></p>
          <p>Comment: {{order.process[5].comment}}</p>

      </li>

    <!-- Cuando el status es pendiente -->

    <li
    *ngIf="order.process[5].stage == 'delivered' && order.process[5].status != 'ok'"
    class="process">
        <h5>{{order.process[5].date | date: 'EEEE, d/M/yy, h:mm a':'es-CO'}}</h5>
        <p>Entregado</p>
        <button class="btn btn-primary" disabled>
          <span class="spinner-border spinner-border-sm"></span>
          In process
        </button>
    </li>

</ul>
</div>
</div>
</div>
