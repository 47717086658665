<!-- <div class="container setDefaultSpace">
  <div class="row justify-content-center pt-5">
    <p class="fancy fontOpenSans-RegularSmall"><span class="d-none d-md-block pr-md-2 pb-md-4">COMIENZA TU PEDIDO</span><a routerLink="/delivery" class="btn btn-danger">ENTREGA A DOMICILIO</a><span class="pl-4 pr-4">o</span><a routerLink="/maps" class="btn btn-danger">RECOGER EN TIENDA</a></p>
</div>
  <div class="row justify-content-center pb-5">
    <div class="row">
    <div class="col-md-8 col-12"><img class="img-fluid" src="../../assets/images/intro-Left.jpg" alt="Promotional-1"></div>
    <div class="col-md-4 col-12 introPositionPromoRight">
      <div class="row">
        <div class="col-12">
          <img src="../../assets/images/intro-RightTop2.jpg" class="img-fluid" alt="Promotional-2">
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <img src="../../assets/images/intro-RightBotton2.jpg" class="img-fluid" alt="Promotional-2">
        </div>
      </div>
    </div>
    </div>
  </div>
  <div class="row pb-5">
    <div class="col-12 introPositionCenter">
      <img src="../../assets/images/intro-aviso.jpg" class="img-fluid" alt="Legal Advice">
    </div>

  </div>

</div> -->





<!-- <p>
  home works!
  <button class="btn btn-primary" (click)="onLoadProjects()">Load Projects</button>
</p> -->
<div class="no-scrolling">
  <!-- SHOW SLIDER -->
  <section id="showcase">
    <div id="myCarousel" class="carousel slide" data-ride="carousel">
      <ol class="carousel-indicators">
        <li data-target="#myCarousel" data-slide-to="0" class="active"></li>
        <li data-target="#myCarousel" data-slide-to="1"></li>
        <li data-target="#myCarousel" data-slide-to="2"></li>
      </ol>
      <div class="carousel-inner">
        <div [style.backgroundImage]="'url('+urlToImageOne+')'" class="carousel-item carousel-image-1 active">
          <div class="container">
            <div class="carousel-caption text-right mb-5">
              <h1 class="display-3 fontConfortaRegularRed">{{titleCarouselOne}}</h1>
              <p class="lead fontOpenSans-RegularSmallRed">{{subTitleCarouselOne}}.
              </p>
              <a target="_blank" href="{{sourceCarouselOne}}" class="btn btn-primary btn-sm">{{authorCarouselOne}}</a>
            </div>
          </div>
        </div>

        <div [style.backgroundImage]="'url('+urlToImageTwo+')'" class="carousel-item carousel-image-2">
          <div class="container">
            <div class="carousel-caption text-right  mb-5">
              <h1 class="display-3 fontConfortaRegularRed">{{titleCarouselTwo}}</h1>
              <p class="lead fontOpenSans-RegularSmallRed">{{subTitleCarouselTwo}}
              </p>
              <a href="" class="btn btn-primary btn-sm">{{authorCarouselTwo}}</a>
              <!-- <a href="" class="btn btn-primary btn-lg">Catálogo</a> -->
            </div>
          </div>
        </div>

        <div [style.backgroundImage]="'url('+urlToImageThree+')'" class="carousel-item carousel-image-3">
          <div class="container">
            <div class="carousel-caption text-right mb-5">
              <h1 class="display-3 fontConfortaRegularRed">{{titleCarouselThree}}</h1>
              <p class="lead fontOpenSans-RegularSmallRed">{{subTitleCarouselThree}}
              </p>
              <a target="_blank" href="{{sourceCarouselThree}}" class="btn btn-primary btn-sm">{{authorCarouselThree}}</a>
              <!-- <a href="" class="btn btn-primary btn-lg">Catálogo</a> -->
            </div>
          </div>
        </div>
      </div>

      <a href="#myCarousel" data-slide="prev" class="carousel-control-prev">
        <span class="carousel-control-prev-icon"></span>
      </a>
      <a href="#myCarousel" data-slide="next" class="carousel-control-next">
        <span class="carousel-control-next-icon"></span>
      </a>
    </div>
  </section>

  <!--HOME ICON SECTION  -->
  <section id="home-icons" class="py-5">
    <div class="container backgroundIcons">
      <div class="row">
        <div class="col-md-4 mb-4 text-center">
          <!-- <i class="fa fa-signal fa-3x mb-2"></i> -->
          <img [src]="urlToImageIntroOne" alt="" class="craftPosition">
          <h3 class="fontMontserratBoldItalic">{{titleIntroOne}}</h3>
          <p class="fontMontserratLightItalic">{{subTitleIntroOne}}</p>
        </div>
        <div class="col-md-4 mb-4 text-center">
          <!-- <i class="fa fa-paint-brush fa-3x mb-2"></i> -->
          <img [src]="urlToImageIntroTwo" alt="" class="craftPosition">
          <h3 class="fontMontserratBoldItalic">{{titleIntroTwo}}</h3>
          <p class="fontMontserratLightItalic">{{subTitleIntroTwo}}</p>
        </div>
        <div class="col-md-4 mb-4 text-center">
          <!-- <i class="fa fa-handshake-o fa-3x mb-2"></i> -->
          <img [src]="urlToImageIntroThree" alt="" class="craftPosition">
          <h3 class="fontMontserratBoldItalic">{{titleIntroThree}}</h3>
          <p class="fontMontserratLightItalic">{{subTitleIntroThree}} </p>
        </div>
      </div>
    </div>
  </section>

  <!--APP linksSECTION  -->
  <section *ngIf="language==='English'" id="home-icons" class="py-5">
    <div class="container backgroundIcons">
      <div class="row">
        <div class="col-md-6 mb-4 text-center">
          <!-- <i class="fa fa-signal fa-3x mb-2"></i> -->
          <img src="../../assets/images/AppStoreEnglish_Black.svg" alt="" class="appsApplePosition">
          <h3 class="fontMontserratBoldItalicRed">Coming Soon !!</h3>
          <p class="fontMontserratLightItalic appsGoogleDisplayIntrok">The most complete source of information about Male Super Orgasms. Step-by-Step guidelines, 3D Visualization, and Audio tracks will guide you to unthinkable pleasure.</p>
        </div>
        <div class="col-md-6 mb-4 text-center appsGooglePositionBlock">
          <!-- <i class="fa fa-paint-brush fa-3x mb-2"></i> -->
          <img src="../../assets/images/google-play-badge.png" alt="" class="appsGooglePosition">
          <h3 class="fontMontserratBoldItalicRed appsGooglePositionText">Coming Soon !!</h3>
          <p class="fontMontserratLightItalic">The most complete source of information about Male Super Orgasms. Step-by-Step guidelines, 3D Visualization, and Audio tracks will guide you to unthinkable pleasure.</p>
        </div>
      </div>
    </div>
  </section>

  <section *ngIf="language==='Spanish'" id="home-icons" class="py-5">
    <div class="container backgroundIcons">
      <div class="row">
        <div class="col-md-6 mb-4 text-center">
          <!-- <i class="fa fa-signal fa-3x mb-2"></i> -->
          <img src="../../assets/images/AppStoreSpanish_Black.svg" alt="" class="appsApplePosition">
          <h3 class="fontMontserratBoldItalicRed">Muy pronto estará disponible !</h3>
          <p class="fontMontserratLightItalic appsGoogleDisplayIntrok">La fuente de información más completa sobre Súper Orgasmos Masculinos. Las pautas paso a paso, la visualización en 3D y las pistas de audio lo guiarán hacia un placer impensable.</p>
        </div>
        <div class="col-md-6 mb-4 text-center appsGooglePositionBlock">
          <!-- <i class="fa fa-paint-brush fa-3x mb-2"></i> -->
          <img src="../../assets/images/google-play-badge.png" alt="" class="appsGooglePosition">
          <h3 class="fontMontserratBoldItalicRed appsGooglePositionText">Muy pronto estará disponible !</h3>
          <p class="fontMontserratLightItalic">La fuente de información más completa sobre Súper Orgasmos Masculinos. Las pautas paso a paso, la visualización en 3D y las pistas de audio lo guiarán hacia un placer impensable.</p>
        </div>
      </div>
    </div>
  </section>

  <!-- HOME HEADING SECTION -->
  <section id="home-heading-1" [style.background]="'url('+urlToImageBandOne+')'" class="p-5">
    <div class="dark-overlay">
      <div class="row">
        <div class="col">
          <div class="container spaceBand">
            <h1 class="fontConfortaRegularSmall">{{titleBandOne}}</h1>
            <!-- <p class="d-none d-md-block fontChristopherHand">Fortune 500 & Innovative Start ups.</p> -->
          </div>
        </div>
      </div>
    </div>
    <small clas="text-muted">{{authorSOJourney}}</small>
  </section>


  <!--PROJECTS SECTION-->

  <section id="projects" class="py-3">
    <div class="container">
      <div class="row">
        <div class="col">
          <div class="card-columns">
            <div class="card drop-shadow">
              <img [src]="urlToImageMiddleOne" alt="" class="img-fluid card-img-top">
              <small clas="text-muted">{{authorSOJourney}}</small>
              <div class="card-body">
                <h4 class="card-title fontOpenSans-RegularSmallDarkGray">{{titleMiddleOne}}</h4>
                <small clas="text-muted">{{subTitleMiddleOne}}</small>
                <hr>
                <p class="card-text fontOpenSans-LightSmallContent">{{textMiddleOne}}</p>
              </div>
            </div>

            <div class="card p-3 drop-shadow">
              <blockquote class="card-blockquote card-body fontOpenSans-SemiBoldSmallContent">
                <p>{{subTextMiddleOne}}</p>
                <footer class='blockquote-footer'>
                  <small class="text-muted">{{notesMiddleOne}} <cite title="Source Title"></cite></small>
                </footer>
              </blockquote>
            </div>

            <div class="card drop-shadow">
              <img [src]="urlToImageMiddleTwo" alt="" class="img-fluid card-img-top">
              <small clas="text-muted">{{authorSOJourney}}</small>
              <div class="card-body">
                <h4 class="card-title fontOpenSans-RegularSmallDarkGray">{{titleMiddleTwo}}</h4>
                <small clas="text-muted">{{subTitleMiddleTwo}}</small>
                <hr>
                <p class="card-text fontOpenSans-LightSmallContent">{{textMiddleTwo}}</p>
              </div>
            </div>

            <div class="card p-3 drop-shadow">
              <blockquote class="card-blockquote card-body fontOpenSans-SemiBoldSmallContent">
                <p>{{subTextMiddleTwo}} </p>
                <footer class='blockquote-footer'>
                  <small class="text-muted">{{notesMiddleTwo}} <cite title="Source Title"></cite></small>
                </footer>
              </blockquote>
            </div>

            <div class="card drop-shadow">
              <img [src]="urlToImageMiddleThree" alt="" class="img-fluid card-img-top">
              <small clas="text-muted">{{authorSOJourney}}</small>
              <div class="card-body">
                <h4 class="card-title fontOpenSans-RegularSmallDarkGray">{{titleMiddleThree}}</h4>
                <small clas="text-muted">{{subTitleMiddleThree}}</small>
                <hr>
                <p class="card-text fontOpenSans-LightSmallContent">{{textMiddleThree}}</p>
              </div>
            </div>

            <div class="card p-3 drop-shadow">
              <blockquote class="card-blockquote card-body fontOpenSans-SemiBoldSmallContent">
                <p>{{subTextMiddleThree}}</p>
                <footer class='blockquote-footer'>
                  <small class="text-muted">{{notesMiddleThree}} <cite title="Source Title"></cite></small>
                </footer>
              </blockquote>
            </div>


          </div>
        </div>
      </div>
    </div>
  </section>

  <!-- HOME HEADING SECTION -->
  <section id="home-heading-2" [style.background]="'url('+urlToImageBandTwo+')'" class="p-5">
    <div class="dark-overlay">
      <div class="row">
        <div class="col">
          <div class="container spaceBand">
            <h1 class="fontConfortaRegularSmall">{{titleBandTwo}}</h1>
            <!-- <p class="d-none d-md-block fontChristopherHand">Creates the magic of technology working for you</p> -->
          </div>
        </div>
      </div>
    </div>
    <small clas="text-muted">{{authorSOJourney}}</small>
  </section>

  <!-- PHOTO GALLERY -->
  <section id="gallery" class="py-5">
    <div class="container">
      <h1 class="text-center fontOpenSans-RegularSmallDarkGray">{{titleBandThree}}</h1>
      <p class="text-center fontOpenSans-RegularExtraSmallDarkGray">{{subTitleBandOne}}</p>
      <div class="row mb-4">
        <div class="col-md-4" *ngIf="caroulseImage_0 != null">
          <img [src]="_albums[0].thumb" alt="" class="img-fluid" (click)="open(0)">
        </div>
        <div class="col-md-4" *ngIf="caroulseImage_1 != null">
          <img [src]="_albums[1].thumb" alt="" class="img-fluid" (click)="open(1)">
        </div>
        <div class="col-md-4" *ngIf="caroulseImage_2 != null">
          <img [src]="_albums[2].thumb" alt="" class="img-fluid" (click)="open(2)">
        </div>
      </div>

      <div class="row mb-4">
        <div class="col-md-4" *ngIf="caroulseImage_3 != null">
          <img [src]="_albums[3].thumb" alt="" class="img-fluid" (click)="open(3)">
        </div>
        <div class="col-md-4" *ngIf="caroulseImage_4 != null">
          <img [src]="_albums[4].thumb" alt="" class="img-fluid" (click)="open(4)">
        </div>
        <div class="col-md-4" *ngIf="caroulseImage_5 != null">
          <img [src]="_albums[5].thumb" alt="" class="img-fluid" (click)="open(5)">
        </div>
      </div>
    </div>

  </section>

  <!-- HOME HEADING SECTION -->
  <section id="home-heading-3" [style.background]="'url('+urlToImageBandThree+')'" class="p-5">
    <div class="dark-overlay">
      <div class="row">
        <div class="col">
          <div class="container spaceBandNewsletter">

            <div *ngIf="displayNewsletter" class="spaceBandNewsletterField">
              <h1 class="fontOpenSans-RegularSmall">{{titleBandNewsletter}}</h1>
              <p class="fontOpenSans-RegularExtraSmall">{{subTitleBandNewsletter}}</p>
              <form #dashboard="ngForm" (submit)="onSubmit(hero)" class="form-inline justify-content-center">
                <input type="text" required [(ngModel)]="hero.email" name="email" id="email" email #email="ngModel"
                  class="form-control mb-2 mr-2" placeholder="Email">
                <div *ngIf="(email.invalid) && (email.dirty || email.touched)" class="alert alert-danger">
                  <div> Verify email. Not valid</div>
                </div>
                <div *ngIf="(error_duplicate) && (email.dirty || email.touched)" class="alert alert-danger">
                  <div> Email is duplicated or wrong format</div>
                </div>
                <input type="hidden" name="b_09c2800388a897ac8667376f8_fc9c6c8ba6" />
                <button type="submit" class="btn btn-primary mb-2">Subscribe</button>
              </form>
            </div>
            <div *ngIf="!displayNewsletter" class="spaceBandConfirmation">
              <h1 *ngIf="language === 'English'">Thank you for your subscription.</h1>
              <h1 *ngIf="language === 'Spanish'">Gracias por su subscripción.</h1>
            </div>
            <div *ngIf="error_duplicate">

            </div>


          </div>
        </div>
      </div>
    </div>
    <small clas="text-muted">{{authorSOJourney}}</small>
  </section>

  <!-- NEWSLETTER -->
  <!-- <section id="newsletter" class="text-center p-5 bg-dark bgCustomDark text-white">
    <div class="container">
      <div class="row">
        <div class="col">
          <div *ngIf="displayNewsletter">
            <h1 class="fontOpenSans-RegularSmall">{{titleBandNewsletter}}</h1>
            <p class="fontOpenSans-RegularExtraSmall">{{subTitleBandNewsletter}}</p>
            <form #dashboard="ngForm" (submit)="onSubmit(hero)" class="form-inline justify-content-center">
              <input type="text" required [(ngModel)]="hero.email" name="email" id="email" email #email="ngModel"
                class="form-control mb-2 mr-2" placeholder="Email">
              <div *ngIf="(email.invalid) && (email.dirty || email.touched)" class="alert alert-danger">
                <div> Verify email. Not valid</div>
              </div>
              <div *ngIf="(error_duplicate) && (email.dirty || email.touched)" class="alert alert-danger">
                <div> Email is duplicated or wrong format</div>
              </div>
              <input type="hidden" name="b_09c2800388a897ac8667376f8_fc9c6c8ba6" />
              <button type="submit" class="btn btn-primary mb-2">Subscribe</button>
            </form>
          </div>
          <div *ngIf="!displayNewsletter">
            <h1 *ngIf="language === 'English'">Thank you for your subscription.</h1>
            <h1 *ngIf="language === 'Spanish'">Gracias por su subscripción.</h1>
          </div>
          <div *ngIf="error_duplicate">

          </div>
        </div>
      </div>
    </div>
  </section> -->


  <!-- NEWSLETTER -->
  <!-- <section id="newsletter" class="text-center p-5 bg-dark text-white">
    <div class="col-md-8">
      <div *ngIf="displayNewsletter">
        <div class="card p-4">
          <div class="card-body">
            <h3 class="text-center">Por favor envíenos la siguiente información para contactarle</h3>
            <hr>
            <form #dashboard="ngForm" (submit)="onSubmit(hero)" class="form-inline justify-content-center">
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group" style="padding-bottom: 20px;">
                    <input type="text" style="width:100%;" class="form-control" id="name" required [(ngModel)]="hero.name"
                      name="name" #name="ngModel" placeholder="Nombre">
                    <div *ngIf="(name.invalid) && (name.dirty || name.touched)" class="alert alert-danger">
                      <div> Nombre es requerido.</div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group" style="padding-bottom: 20px;width:100%;">
                    <input type="text" required [(ngModel)]="hero.lastName" name="lastName" id="lastName"
                      #lastName="ngModel" style="width:100%;" class="form-control" placeholder="Apellido">
                    <div *ngIf="(lastName.invalid) && (lastName.dirty || lastName.touched)" class="alert alert-danger">
                      <div> Apellido es requerido.</div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group" style="padding-bottom: 20px;">
                    <input type="text" required [(ngModel)]="hero.email" name="email" id="email" email #email="ngModel"
                      style="width:100%;" class="form-control mb-2 mr-2" placeholder="Email">
                    <div *ngIf="(email.invalid || email.email) && (email.dirty || email.touched)"
                      class="alert alert-danger">
                      <div> Verificar email. No válido</div>
                    </div>
                    <div *ngIf="(error_duplicate) && (email.dirty || email.touched)" class="alert alert-danger">
                      <div> Email duplicado o error de formato</div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group" style="padding-bottom: 20px;">
                    <input type="text" [(ngModel)]="hero.phone" name="phone" id="phone" #phone="ngModel"
                      style="width:100%;" class="form-control mb-2 mr-2" placeholder="Teléfono">
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group" style="padding-bottom: 20px;">
                    <input type="text" [(ngModel)]="hero.city" name="city" id="city" #city="ngModel" style="width:100%;"
                      class="form-control mb-2 mr-2" placeholder="Ciudad">
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group" style="padding-bottom: 20px;">
                    <select class="form-control" style="padding-bottom: 10px;" [(ngModel)]="hero.country" name="country"
                      id="country" #country="ngModel">
                      <option value="">Seleccione país</option>
                      <option *ngFor="let pais of paises" [value]="pais">
                        {{pais}}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="row" style="width:100%;">
                <div class="col-md-12" style="padding-left: 0px; padding-right: 0px;">
                  <div class="form-group" style="padding-bottom: 20px;">
                    <input type="text" [(ngModel)]="hero.message" name="message" id="message" #message="ngModel"
                      style="width:100%;" class="form-control" placeholder="Mensaje">
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group">
                    <input type="hidden" name="b_86acff4ada9cdd635968e384d_b5b51b5dec" />
                    <input type="submit" value="Submit" class="btn btn-outline-danger btn-block">
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div *ngIf="!displayNewsletter">
        <div class="col-md-8  estilo-aviso">
          <h1>Gracias por contactarnos, estaremos contactándole a la mayor brevedad.</h1>
        </div>
      </div>

    </div>
  </section> -->
  </div>
