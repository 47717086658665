<!-- <div class="row vertical-center"> -->
  <div class="row" [ngClass]="{'vertical-center':isLoginMode, 'verticalSignUp':!isLoginMode }">
    <div class="container">
            <!-- PAGE HEADER -->
            <header id="page-header">
                <div class="container pd-2">
                  <div class="row">
                    <div class="col-md-6 m-auto text-center">
                      <h1 class="fontOpenSans-RegularSmallDarkGray">Abrir sesión o crear cuenta</h1>
                      <p class="fontOpenSans-RegularExtraSmallDarkGray">Para crear una cuenta haga click en el botón de crear cuenta, solo se requiere de email y passord.</p>
                      <div class="d-block d-md-none">
                        </div>
                    </div>
                  </div>
                </div>
            </header>

            <div class="container">
                <div class="row">
                    <div class="m-auto  col-9  col-sm-6 col-md-6  col-lg-4">
                        <div class="alert alert-danger fontOpenSans-RegularExtraSmallDarkGray" *ngIf="error">
                            <p>{{ error }}</p>
                        </div>
                        <div *ngIf="isLoading" style="text-align: center;">
                              <app-loading-spinner></app-loading-spinner>
                        </div>
                      <form #authForm="ngForm" (ngSubmit)="onSubmit(authForm)" *ngIf="!isLoading">
                        <div class="form-group" *ngIf="!isLoginMode">
                          <input
                            placeholder="Nombre"
                            type="text"
                            id="displayName"
                            class="form-control"
                            ngModel
                            name="displayName"
                          />
                        </div>
                        <div class="form-group" *ngIf="!isLoginMode">
                          <input
                            placeholder="Lenguage Preferido"
                            type="text"
                            id="displayName"
                            class="form-control"
                            ngModel
                            name="displayName"
                          />
                          <select class="form-control" ngModel name="language">
                            <option value="spanish">Español</option>
                            <option value="english">English</option>
                        </select>
                        </div>
                        <div class="form-group" *ngIf="!isLoginMode">
                            <input
                              placeholder="Código (Opcional)"
                              type="text"
                              id="age"
                              class="form-control"
                              ngModel
                              name="age"
                            />
                        </div>

                        <div class="form-group">
                          <input
                            placeholder="E-Mail"
                            type="email"
                            id="email"
                            class="form-control"
                            ngModel
                            name="email"
                            required
                            email
                            (focus)="resetErrorMessage()"
                          />
                        </div>

                        <div class="form-group" *ngIf="!isLoginMode">
                            <input
                              placeholder="Confirmar E-Mail"
                              type="email"
                              id="email2"
                              class="form-control"
                              ngModel
                              name="email2"
                              required
                              email
                              (focus)="resetErrorMessage()"
                            />
                          </div>
                        <div class="form-group">
                          <input
                            placeholder="Password"
                            type="password"
                            id="password"
                            class="form-control"
                            ngModel
                            name="password"
                            required
                            minlength="6"
                          />
                        </div>
                        <div class="form-group" *ngIf="!isLoginMode">
                            <input
                              placeholder="Confirm Password"
                              type="password"
                              id="password2"
                              class="form-control"
                              ngModel
                              name="password2"
                              required
                              minlength="6"
                            />
                          </div>
                        <div>
                          <div class="row justify-content-center">
                              <button
                                class="btn btn-primary"
                                type="submit"
                                [disabled]="!authForm.valid"
                              >
                                {{ isLoginMode ? 'Sign In' : 'Sign Up' }}
                              </button>
                              <span class="pl-2 pr-2">|</span>
                              <button class="btn btn-primary" (click)="onSwitchMode()" type="button">
                                Switch to {{ isLoginMode ? 'Sign Up' : 'Sign In' }}
                              </button>
                        </div>
                        </div>
                        <div *ngIf="isLoginMode" class="row justify-content-center fontOpenSans-RegularExtraSmallDarkGray linkStyle pt-4">
                            <span><a routerLink="/forgot-password">Olvidó Password ? <span class="sr-only">(current)</span></a></span>
                        </div>
                      </form>
                    </div>
                </div>
            </div>
    </div>
  </div>
