<div class="container">
<h1 class="setDefaultSpace">Orders</h1>

<app-modal
      [order]="data"
      *ngIf="displayAlert"
      (close)="onHandleModal()"
    ></app-modal>
    <div class="row">
      <div class="col-12">
<table class="table">
  <colgroup>
    <col class="white" />
    <col class="white" />
    <col class="white" />
    <col class="red" />
    <col class="grey" />
    <col class="grey" />
    <col class="blue" />
    <col class="blue" />
    <col class="blue" />
    <col class="white" />
  </colgroup>
  <thead>
    <tr>
      <th class="columnCenter"># de Orden</th>
      <th class="columnCenter">Customer</th>
      <th class="columnCenter">Fecha</th>
      <th class="columnCenter">Sin empezar</th>
      <th class="columnCenter">En proceso</th>
      <th class="columnCenter">Preparado</th>
      <th class="columnCenter">Para Entregar</th>
      <th class="columnCenter">En transito</th>
      <th class="columnCenter">Entregado</th>
      <th></th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let order of orders">
      <td>{{ order.id }}</td>
      <td>{{ order.shipInfo.name }}</td>
      <td>{{ order.datePlaced | date}}</td>
      <td class="columnCenter"><div *ngIf="order.process[1].status !== 'ok'"><i class="fa fa-check" aria-hidden="true"></i></div></td>
      <td class="columnCenter"><div *ngIf="order.process[1].status === 'ok'"><i class="fa fa-check" aria-hidden="true"></i></div><div *ngIf="order.process[1].status !== 'ok'"><i class="fas fa-exclamation-triangle" aria-hidden="true"></i></div></td>
      <td class="columnCenter"><div *ngIf="order.process[2].status === 'ok'"><i class="fa fa-check" aria-hidden="true"></i></div><div *ngIf="order.process[2].status !== 'ok'"><i class="fas fa-exclamation-triangle" aria-hidden="true"></i></div></td>
      <td class="columnCenter"><div *ngIf="order.process[3].status === 'ok'"><i class="fa fa-check" aria-hidden="true"></i></div><div *ngIf="order.process[3].status !== 'ok'"><i class="fas fa-exclamation-triangle" aria-hidden="true"></i></div></td>
      <td class="columnCenter"><div *ngIf="order.process[4].status === 'ok'"><i class="fa fa-check" aria-hidden="true"></i></div><div *ngIf="order.process[4].status !== 'ok'"><i class="fas fa-exclamation-triangle" aria-hidden="true"></i></div></td>
      <td class="columnCenter"><div *ngIf="order.process[5].status === 'ok'"><i class="fa fa-check" aria-hidden="true"></i></div><div *ngIf="order.process[5].status !== 'ok'"><i class="fas fa-exclamation-triangle" aria-hidden="true"></i></div></td>
      <td class="columnCenter">
        <button class="btn btn-lg btn-outline-primary" (click)="openModalProduct(order)">Ver Orden</button>
      </td>
    </tr>
  </tbody>
</table>
</div>
</div>
</div>
