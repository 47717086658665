<div class="container">
  <div class="setDefaultSpace setFooterBottom">
    <div *ngIf="flagCheckout === 'OK'">
      <div class="row" *ngIf="cartShopping">
          <div class="col-12 col-md-6 pb-5">
            <app-shipping-form [cart]="dataCartShopping"></app-shipping-form>
          </div>
      </div>
  </div>
    <div *ngIf="flagCheckout === 'EMPTY'">
        <div class="row" *ngIf="cartShopping">
            <div class="col-12 col-md-6 pb-5">
              <app-shipping-form [cart]="dataCartShopping"></app-shipping-form>
            </div>
            <div class="col-12 col-md-6">
              <app-shopping-cart-summary [cart]="dataCartShopping"></app-shopping-cart-summary>
            </div>
        </div>
    </div>
    <div *ngIf="flagCheckout === 'FAILED'">
  <!-- <div class="col-12 col-md-12 pb-5">
    <app-shipping-form [cart]="dataCartShopping"></app-shipping-form>
  </div> -->
        <div class="row" *ngIf="cartShopping">
            <div class="col-12 col-md-12 pb-5">
              <app-shipping-form [cart]="dataCartShopping"></app-shipping-form>
            </div>
        </div>
    </div>
</div>
</div>

