<div class="container">
  <div class="pb-5">
  <app-shopping-order-summary  *ngIf="displayOrderSaved" [order]="dataOrderSaved"></app-shopping-order-summary>
</div>
<form *ngIf="!displayPayuButton" #f="ngForm">
  <div *ngIf="cartData.flag === 'EMPTY'">
  <h2>Dirección</h2>
  <div class="form-group">
    <label for="">Nombre y Apellido</label>
    <input #name="ngModel" name="name" [(ngModel)]="shipping.name" type="text" class="form-control" placeholder="Nombre y Apellido"required>
    <div class="alert alert-danger" *ngIf="name.touched && name.invalid">
      <div *ngIf="name.errors.required">Nombre es requerido.</div>
    </div>
  </div>
  <div class="form-group">
    <label for="">Dirección</label>
    <input #addressLine1="ngModel" name="addressLine1" [(ngModel)]="shipping.addressLine1" type="text" class="form-control" placeholder="Dirección" required>
    <div class="alert alert-danger" *ngIf="addressLine1.touched && addressLine1.invalid">
      <div *ngIf="addressLine1.errors.required">Dirección es requerida.</div>
    </div>
  </div>
  <div class="form-group">
    <label for="">Teléfono</label>
    <input #addressLine2="ngModel" name="addressLine2" [(ngModel)]="shipping.addressLine2" type="text" class="form-control" placeholder="Teléfono" required>
    <div class="alert alert-danger" *ngIf="addressLine2.touched && addressLine2.invalid">
      <div *ngIf="addressLine2.errors.required">Teléfono es requerido.</div>
    </div>
  </div>
  <div class="form-group">
    <label for="">Ciudad</label>
    <input #city="ngModel" name="city" [(ngModel)]="shipping.city" type="text" class="form-control" placeholder="Ciudad" required>
    <div class="alert alert-danger" *ngIf="city.touched && city.invalid">
      <div *ngIf="city.errors.required">Ciudad es requerida.</div>
    </div>
  </div>
</div>
  <!--  option for stripe -->
  <!-- <span class="pr-5">
  <button
    [disabled]="!f.valid"
    (click)="placeOrder()"
    class="btn btn-primary">Place Order</button>
  </span> -->

  <!--  option for Payu -->
  <div *ngIf="cartData.flag === 'EMPTY'">
  <span *ngIf="!displayPayuButton" class="pr-5">
  <button
    [disabled]="!f.valid"
    (click)="createOrderforPayu()"
    class="btn btn-primary">Siguiente</button>
  </span>
  </div>

  <div *ngIf="cartData.flag === 'FAILED'">
    <span *ngIf="!displayPayuButton" class="pr-5">
    <button
      [disabled]="!f.valid"
      (click)="reTrycreateOrderforPayu()"
      class="btn btn-primary">Reintentar otro medio de pago</button>
    </span>
    <div class="pt-5">
      <button
      (click)="placeOrderContraEntrega()"
      class="btn btn-primary">Pago contra entrega</button>
    </div>
    </div>

  <!-- <span  class="pr-5">
    <button
    [disabled]="!f.valid"
    (click)="placeOrderSwipe()"
    class="btn btn-primary">Place Order with Swipe payment</button></span>
    <span>
      <button
      [disabled]="!f.valid"
      (click)="placeOrderMercado()"
      class="btn btn-primary">Place Order with Mercado</button></span> -->
</form>
<!-- <form method="POST">
  <script
   src="https://www.mercadopago.com.ar/integrations/v1/web-payment-checkout.js"
   data-preference-id='<%= global.id %>'>
  </script>
</form> -->

<!--  option for Mercado Pago -->
<!-- <form action="/procesar-pago" method="POST">
  <button type="button" (click)="onBuy()"
  class="mercadopago-button" id="mercadopago-button">
  Buy now
</button>
</form> -->


<!-- <img src="assets/images/payu_logo.png" style="width:100px" />
<form ngNoForm action="https://sandbox.checkout.payulatam.com/ppp-web-gateway-payu/" target="_blank" method="POST">
  <input name="merchantId"    type="hidden"  value="${merchantId}"   >
  <input name="accountId"     type="hidden"  value="accountId" >
  <input name="description"   type="hidden"  value="description"  >
  <input name="referenceCode" type="hidden"  value="referenceCode" >
  <input name="amount"        type="hidden"  value="20000"   >
  <input name="tax"           type="hidden"  value="3193"  >
  <input name="taxReturnBase" type="hidden"  value="16806" >
  <input name="currency"      type="hidden"  value="COP" >
  <input name="signature"     type="hidden"  value="signature"  >
  <input name="test"          type="hidden"  value="1" >
  <input name="buyerEmail"    type="hidden"  value="test@test.com" >
  <input name="responseUrl"    type="hidden"  value="http://www.test.com/response" >
  <input name="confirmationUrl"    type="hidden"  value="http://www.test.com/confirmation" >
  <button type="submit">Siguiente</button>
</form> -->

<div *ngIf="displayPayuButton">
  <h2 class="pb-5">Por favor seleccione su método de pago</h2>
  <img src="assets/images/payu_logo.png" style="width:100px" />
  <form ngNoForm action="https://sandbox.checkout.payulatam.com/ppp-web-gateway-payu/" target="_blank" method="POST">
    <!-- <form ngNoForm action="http://localhost:5001/kiaywebcol/us-central1/init_payu/payu-pay" target="_blank" method="POST"> -->
    <input name="merchantId"    type="hidden"  [(ngModel)]="merchantId"   >
    <input name="accountId"     type="hidden"  [(ngModel)]="accountId" >
    <input name="description"   type="hidden"  [(ngModel)]="description"  >
    <input name="referenceCode" type="hidden"  [(ngModel)]="referenceCode" >
    <input name="amount"        type="hidden"  [(ngModel)]="totalPrice"   >
    <input name="tax"           type="hidden"  [(ngModel)]="onlyTax"  >
    <input name="taxReturnBase" type="hidden"  value="16806" >
    <input name="currency"      type="hidden"  value="COP" >
    <input name="signature"     type="hidden"  [(ngModel)]="signature"  >
    <input name="test"          type="hidden"  [(ngModel)]="test" >
    <input name="buyerEmail"    type="hidden"  [(ngModel)]="buyerEmail" >
    <input name="responseUrl"    type="hidden"  [(ngModel)]="responseUrl" >
    <input name="confirmationUrl"    type="hidden"  [(ngModel)]="confirmationUrl" >
    <input name="extra1"    type="hidden"  [(ngModel)]="sessionId" >
    <input name="extra2"    type="hidden"  [(ngModel)]="userId" >
    <input name="extra3"    type="hidden"  [(ngModel)]="orderId" >
    <input name="shippingAddress" type="hidden" [(ngModel)]="shipping.addressLine1">
    <input name="shippingCity" type="hidden" [(ngModel)]="shipping.city">
    <input name="shippingCountry" type="hidden" value ="CO">
    <input name="buyerFullName" type="hidden" [(ngModel)]="shipping.name">
    <input name="telephone" type="hidden" [(ngModel)]="shipping.addressLine2">
    <!-- <input name="customer_number"    type="hidden"  [(ngModel)]="sessionId" > -->
    <button class="btn btn-primary" type="submit">Opciones con tarjetas y otros</button>
  </form>
  <div class="pt-5">
  <button
  (click)="placeOrderContraEntrega()"
  class="btn btn-primary">Pago contra entrega</button>
</div>

</div>
</div>

